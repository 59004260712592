export enum SWR_KEY {
  ANALYTICS_INITIALIZE_EVENT_LIST = "@analytics/initializeEventList",
  ANALYTICS_INITIALIZE_TAG_LIST = "@analytics/initializeTagList",
  ANALYTICS_INITIALIZE_PARAMETERS = "@analytics/initializeParameters",
  ANALYTICS_INITIALIZE_HEATMAP = "@analytics/initializeHeatmap",
  ANALYTICS_INITIALIZE_TIMESERIES = "@analytics/initializeTimeseries",
  ANALYTICS_INITIALIZE_TOPTAG = "@analytics/initializeToptag",
  ANALYTICS_INITIALIZE_TOPTAG_BREAKDOWN = "@analytics/initializeToptagBreakdown",
  ANALYTICS_INITIALIZE_TRACKING_GROUP = "@analytics/initializeTrackingGroup",
  SENSE_API_INITIALIZE_USAGE = "@api/initializeUsage",
  SENSE_API_INITIALIZE_PROJECT_USAGE = "@api/initializeProjectAPIUsage",
  AUTH_INITIALIZE = "@auth/initialize",
  INVOICE_INITIALIZE_UPCOMING_INVOICE = "@invoice/initializeUpcomingInvoice",
  INVOICE_INITIALIZE_INVOICE_LIST = "@invoice/initializeInvoiceList",
  PAYMENT_METHOD_INITIALIZE_LIST = "@payment-method/initializeList",
  PRICEPLAN_INITIALIZE_PLANS = "@priceplan/initializePlans",
  PRICEPLAN_INITIALIZE_CURRENT_SUBSCRIPTIONS = "@priceplan/initializeCurrentSubscription",
  PROJECT_LIST_INITIALIZE = "@projectList/initialize",
  PROJECT_INITIALIZE_PROJECT_TAGS = "@project/initializeProjectTags",
  SENSE_SDK_INITIALIZE_CHANNEL_LIST = "@sdk/initializeChannelList",
  SENSE_SDK_INITIALIZE_SDK_QUOTA = "@sdk/initializeSdkQuota",
  SENSE_SDK_INITIALIZE_SDK_TAGS = "@sdk/initializeSdkTags",
  SENSE_SDK_INITIALIZE_PROJECT_USAGE = "@api/initializeProjectSDKUsage",
  ORGANIZATION_INITIALIZE_MEMBER_LIST = "@organization/initializeMemberList",
  ORGANIZATION_INITIALIZE_ORGANIZATION_LIST = "@organization/initializeOrganizationList",
  NOTIFICATION_INITIALIZE_NOTIFICATION_LIST = "@notification/initializeNotificationList",
  UPLOAD_INITIALIZE_HISTORY_LIST = "@upload/initializeUploadHistory",
  POST_ACTION_INITIALIZE_WEBHOOK_LIST = "@post-action/initializeWebhookList",
  POST_ACTION_INITIALIZE_WEBHOOK_HISTORY = "@post-action/initializeWebhookHistory",
  POST_ACTION_INITIALIZE_EMAIL_LIST = "@post-action/initializeEmailList",
  SURVEY_INITIALIZE_QUESTION_LIST = "@survey/initializeQuestionList",
  SURVEY_INITIALIZE_COMPLETED = "@survey/initializeSurveyCompleted",
  SPEAKER_RECOGNITION_VOICE_LIST = "@speakerRecognition/voiceList",
}
