import React, { FC, SVGProps } from "react";

const ExternalIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0217 2.16969L17.8297 2.97827L9.26398 11.544L8.45598 10.736L17.0217 2.16969Z"
      fill="#B2B2B2"
    />
    <path
      d="M18 7.71429H16.8571V3.14286H12.2857V2H18V7.71429Z"
      fill="#B2B2B2"
    />
    <path
      d="M15.1429 18H3.71429C2.74286 18 2 17.2571 2 16.2857V4.85714C2 3.88571 2.74286 3.14286 3.71429 3.14286H10V4.28571H3.71429C3.37143 4.28571 3.14286 4.51429 3.14286 4.85714V16.2857C3.14286 16.6286 3.37143 16.8571 3.71429 16.8571H15.1429C15.4857 16.8571 15.7143 16.6286 15.7143 16.2857V10H16.8571V16.2857C16.8571 17.2571 16.1143 18 15.1429 18Z"
      fill="#B2B2B2"
    />
  </svg>
);

export default ExternalIcon;
