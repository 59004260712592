import { SenseTags } from ".";

export enum ReadStatus {
  PENDING = "pending",
  IN_PROGRESS = "in-progress",
  DONE = "done",
  ERROR = "error",
}

export interface APIUsage {
  max_usage: number;
  usages: {
    date: string;
    usage: number;
  }[];
}

//https://docs.cochl.ai/sense/api/openapi/#/components/schemas/Page

export interface SensePage {
  count: number;
  next_token: string;
  offset: number;
  total: number;
}

export interface SenseTag {
  probability: number;
  name: SenseTags;
}

export interface SenseEvent {
  start_time: number;
  end_time: number;
  tags: SenseTag[];
}

export interface Session {
  session_id: string;
  chunk_sequence: number;
}

export interface SessionStatus {
  state: ReadStatus;
  data: SenseEvent[];
  has_more: boolean;
  error?: string;
}

export interface SenseUniqueTag {
  start: number;
  end: number;
  name: SenseTags | string;
  started: boolean;
}
