import { ArrowRightIcon } from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { CALENDAR_INPUT_NAMES } from "../types/calendar";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  ${media.query.md} {
    flex-direction: column;
    gap: 5px;
  }
`;

const InputContainer = styled.div<{ invalid?: boolean; $focused?: boolean }>`
  width: 169px;
  height: 30px;
  padding: 7.5px;

  border: ${(p) => {
    if (p.invalid) {
      return `1px solid ${p.theme.colors.red}`;
    }
    if (p.$focused) {
      return `1px solid ${p.theme.colors.black}`;
    }
    return `1px solid ${p.theme.colors.grey[30]}`;
  }};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 15px;
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  padding: 0;

  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: ${(p) => p.theme.colors.black};
  background: ${(p) => p.theme.colors.white};
  border: none;
`;

const InputArrowIcon = styled(ArrowRightIcon)`
  & path {
    fill: ${(p) => p.theme.colors.grey[60]};
  }
`;

const TimeInput = styled(Input)`
  min-width: 75px;
  margin-left: 3px;

  &::-webkit-datetime-edit-ampm-field,
  &::-webkit-datetime-edit-hour-field,
  &::-webkit-datetime-edit-minute-field {
    padding: 0;
  }
  &::-webkit-calendar-picker-indicator {
    color-scheme: ${(p) => p.theme.theme};
    padding: 0;
    margin-left: 0;
  }
`;

const START_DATE_INPUT_BASE_LIST: {
  type: "date" | "month" | "year";
  name: CALENDAR_INPUT_NAMES;
  maxLength: number;
  style?: React.CSSProperties;
}[] = [
  {
    type: "month",
    name: CALENDAR_INPUT_NAMES.STARTDATE_MONTH,
    maxLength: 2,
  },
  {
    type: "date",
    name: CALENDAR_INPUT_NAMES.STARTDATE_DATE,
    maxLength: 2,
  },
  {
    type: "year",
    name: CALENDAR_INPUT_NAMES.STARTDATE_YEAR,
    maxLength: 4,
  },
];
const END_DATE_INPUT_BASE_LIST: {
  type: "date" | "month" | "year";
  name: CALENDAR_INPUT_NAMES;
  maxLength: number;
  style?: React.CSSProperties;
}[] = [
  {
    type: "month",
    name: CALENDAR_INPUT_NAMES.ENDDATE_MONTH,
    maxLength: 2,
  },
  {
    type: "date",
    name: CALENDAR_INPUT_NAMES.ENDDATE_DATE,
    maxLength: 2,
  },
  {
    type: "year",
    name: CALENDAR_INPUT_NAMES.ENDDATE_YEAR,
    maxLength: 4,
  },
];

interface Props {
  startDateInvalid?: boolean;
  endDateInvalid?: boolean;
  inputValues: {
    startDate: {
      date: string;
      month: string;
      year: string;
      time?: string;
    };
    endDate: {
      date: string;
      month: string;
      year: string;
      time?: string;
    };
  };
  onStartDateInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  onStartDateInputBlur?: React.FocusEventHandler<HTMLInputElement>;
  onEndDateInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  onEndDateInputBlur?: React.FocusEventHandler<HTMLInputElement>;
  showTimeInput?: boolean;
}

const DayPickerRangeControllerInputs: FC<Props> = ({
  startDateInvalid,
  endDateInvalid,
  inputValues,
  onStartDateInputBlur,
  onStartDateInputChange,
  onEndDateInputBlur,
  onEndDateInputChange,
  showTimeInput,
}) => {
  const [startDateFocused, setStartDateFocused] = useState<boolean>(false);
  const [endDateFocused, setEndDateFocused] = useState<boolean>(false);

  return (
    <Container>
      <InputContainer invalid={startDateInvalid} $focused={startDateFocused}>
        {START_DATE_INPUT_BASE_LIST.map((item, index) => (
          <React.Fragment key={index}>
            <Input
              data-testid={`@cochl-calendar/DayPickerRangeControllerInputs-${item.name}`}
              key={item.name}
              name={item.name}
              maxLength={item.maxLength}
              value={inputValues.startDate[item.type]}
              onChange={onStartDateInputChange}
              onBlur={(e) => {
                onStartDateInputBlur && onStartDateInputBlur(e);
                setStartDateFocused(false);
              }}
              onFocus={() => setStartDateFocused(true)}
              style={{
                width:
                  showTimeInput &&
                  item.name === CALENDAR_INPUT_NAMES.STARTDATE_YEAR
                    ? "30px"
                    : "100%",
              }}
            />
            {index < START_DATE_INPUT_BASE_LIST.length - 1 && "/"}
          </React.Fragment>
        ))}
        {showTimeInput && (
          <TimeInput
            data-testid={`@cochl-calendar/DayPickerRangeControllerInputs-${CALENDAR_INPUT_NAMES.STARTDATE_TIME}`}
            type="time"
            name={CALENDAR_INPUT_NAMES.STARTDATE_TIME}
            value={inputValues.startDate.time}
            onChange={onStartDateInputChange}
            onBlur={(e) => {
              onStartDateInputBlur && onStartDateInputBlur(e);
              setStartDateFocused(false);
            }}
            onFocus={() => setStartDateFocused(true)}
          />
        )}
      </InputContainer>
      <InputArrowIcon width={12} height={12} />
      <InputContainer invalid={endDateInvalid} $focused={endDateFocused}>
        {END_DATE_INPUT_BASE_LIST.map((item, index) => (
          <React.Fragment key={index}>
            <Input
              data-testid={`@cochl-calendar/DayPickerRangeControllerInputs-${item.name}`}
              key={item.name}
              name={item.name}
              maxLength={item.maxLength}
              value={inputValues.endDate[item.type]}
              onChange={onEndDateInputChange}
              onBlur={(e) => {
                onEndDateInputBlur && onEndDateInputBlur(e);
                setEndDateFocused(false);
              }}
              onFocus={() => setEndDateFocused(true)}
              style={{
                width:
                  showTimeInput &&
                  item.name === CALENDAR_INPUT_NAMES.ENDDATE_YEAR
                    ? "30px"
                    : "100%",
              }}
            />
            {index < END_DATE_INPUT_BASE_LIST.length - 1 && "/"}
          </React.Fragment>
        ))}
        {showTimeInput && (
          <TimeInput
            data-testid={`@cochl-calendar/DayPickerRangeControllerInputs-${CALENDAR_INPUT_NAMES.ENDDATE_TIME}`}
            type="time"
            name={CALENDAR_INPUT_NAMES.ENDDATE_TIME}
            value={inputValues.endDate.time}
            onChange={onEndDateInputChange}
            onBlur={(e) => {
              onEndDateInputBlur && onEndDateInputBlur(e);
              setEndDateFocused(false);
            }}
            onFocus={() => setEndDateFocused(true)}
          />
        )}
      </InputContainer>
    </Container>
  );
};

export default DayPickerRangeControllerInputs;
