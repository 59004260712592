import { selectedAnalyticsState } from "~/client/lib/selectors";
import { setSelectedEvent, setServerError } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetHeatmapEvents } from "~/client/api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import {
  AnalyticsType,
  HeatmapEvent,
  TagFrequency,
} from "~/client/types/analytics";

function useAnalyticsHeatmap() {
  const dispatch = useAppDispatch();
  const params = useParams<{ projectId?: string }>();
  const [tagFrequency, setTagFrequency] = useState<HeatmapEvent>();
  const [highestValue, setHighestValue] = useState<number>();
  const [tags, setTags] = useState<TagFrequency[]>([]);
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const filters = useMemo(
    () =>
      filterParamsWithChecked
        ? filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY]
        : {},
    [filterParamsWithChecked],
  );

  const { data, error, isValidating, mutate } = useSWR(
    params.projectId && filters ? SWR_KEY.ANALYTICS_INITIALIZE_HEATMAP : null,
    async () => {
      if (!filters || !params.projectId) return null;
      const { tag, device, scale, fromTo } = filters;
      return (
        await fetchGetHeatmapEvents({
          params: {
            project_id: params.projectId!,
            ...fromTo!,
            tags: tag?.filter((item) => item.checked).map((item) => item.value),
            devices: device
              ?.filter((item) => item.checked)
              .map((item) => item.value),
            scale: scale?.find((item) => item.checked)?.value,
          },
        })
      ).data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const updateSelectedEvent = useCallback(
    (event) => {
      dispatch(setSelectedEvent(event));
    },
    [dispatch],
  );

  const initializeEventList = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    if (!data) return;

    let tempTagFrequency: TagFrequency[] = [];
    let tempCount = 0;
    const {
      data: { lines },
    } = data;

    tempTagFrequency = lines.map((line) => ({
      name: line.line_name,
      data: line.columns.map((column) => {
        if (column.total_count > tempCount) {
          tempCount = column.total_count;
        }
        return {
          x: column.column_name,
          y: column.total_count,
        };
      }),
    }));

    setTagFrequency(data);
    setHighestValue(tempCount);
    setTags(tempTagFrequency);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  useEffect(() => {
    initializeEventList();
  }, [initializeEventList, filters]);

  return {
    tagFrequency,
    tags,
    highestValue,
    updateSelectedEvent,
    isValidating,
  };
}

export default useAnalyticsHeatmap;
