import { useEffect, useState } from "react";

import { APIError } from "~/client/types";
import { OrganizationMember } from "~/client/types/organization";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetOrganizationMembers } from "~/client/api";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useAuthenticate from "./useAuthenticate";
import useSWR from "swr";

function useOrganization() {
  const dispatch = useAppDispatch();
  const { customer } = useAuthenticate();
  const [memberList, setMemberList] = useState<OrganizationMember[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<OrganizationMember[]>(
    [],
  );

  const { data, error, isValidating } = useSWR(
    customer ? SWR_KEY.ORGANIZATION_INITIALIZE_MEMBER_LIST : null,
    async () => {
      const res = await fetchGetOrganizationMembers({
        params: {
          id: customer!.organization_id,
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setMemberList(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return { memberList, selectedMembers, setSelectedMembers, isValidating };
}

export default useOrganization;
