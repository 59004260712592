import {
  Checkbox,
  ConfirmModal,
  DropdownButton,
  Input,
  ListDropdown,
  Scroll,
  Typo,
} from "@cochlearai/ui";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FullCenterLoading, TagCategory } from "~/client/components";
import {
  INTERVAL_UNIT_UI_LABELS,
  ONE_MINUTE_SECOND,
  SENSE_TAGS_BY_CATEGORY,
  TAG_INTERVAL_TIME_TYPE,
  Z_INDEXES,
} from "~/client/lib/constants";
import {
  MediumType,
  NotificationConfig,
  ProjectType,
  SenseCategory,
  TAG_INTERVAL_TIME_TYPE_VALUE,
  TagInterval,
  WebhookModalType,
} from "~/client/types";
import {
  convertSenseTagValueToUILabel,
  getIntervalTimeError,
} from "~/client/lib/helpers";
import styled, { useTheme } from "styled-components";
import {
  useAppDispatch,
  useCreateWebhookNC,
  useCurrentProject,
  useMultiDropdown,
  useProjectTags,
  useSdkTags,
  useTriggerCondition,
  useUpdateMedium,
} from "~/client/hooks";

import { SWR_KEY } from "~/client/types/swrKey";
import { media } from "@cochlearai/util";
import { setClientInfo } from "~/client/store/modules";
import { useSWRConfig } from "swr";
import { useTranslation } from "react-i18next";

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const WebhookURLInput = styled(Input)`
  margin-top: 10px;
`;

const IntervalTimeInput = styled.div`
  flex-direction: column;
  width: 100px;

  & input {
    padding: 6px 20px;
  }
  & p {
    margin: 0;
  }
`;

const TagsCountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  margin-left: 8px;
`;

const TagSettingContainer = styled(Scroll)`
  max-height: 300px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 10px;
`;

const Flex = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;

  ${media.query.md} {
    gap: 10px;
  }
`;

const TagSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 400px;
`;

const DateDropdownButton = styled(DropdownButton)<{
  selected: boolean;
}>`
  margin-left: auto;
  cursor: pointer;
  min-width: 100px;
  height: 32px;
  border-color: ${(p) =>
    p.selected ? p.theme.colors.grey[80] : p.theme.colors.grey[30]};
`;

const SenseTagText = styled(Typo)`
  margin-top: 5px;
  width: auto;
  ${media.query.md} {
    width: 120px;
  }
`;

enum IntervalType {
  INTERVAL = "interval",
  INTERVAL_UNIT = "interval_unit",
}

interface Props {
  type: WebhookModalType;
  webhook?: NotificationConfig;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const WebhookModal: FC<Props> = ({
  type,
  webhook,
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectedTagCategory, setSelectedTagCategory] = useState<SenseCategory>(
    SenseCategory.ALL,
  );
  const { isDropdownOpen, openDropdown, closeDropdown } = useMultiDropdown();
  const { currentProject } = useCurrentProject();
  const { sdkTags } = useSdkTags();
  const { projectTags } = useProjectTags({ currentProject });
  const {
    triggerCondition,
    setTriggerCondition,
    confirmToUpdate,
    errorInfo,
    isLoading,
  } = useTriggerCondition();
  const {
    validation,
    addWebhook,
    url,
    setUrl,
    isLoading: isCreating,
  } = useCreateWebhookNC({
    triggerCondition,
  });
  const { update, errorInfo: mediumError, isUpdating } = useUpdateMedium();

  const tagList = useMemo(() => {
    return (
      currentProject?.product_type === ProjectType.EDGE_SDK
        ? projectTags.map((tag) => ({ value: tag.id, label: tag.name }))
        : sdkTags
    ).filter(
      (item) =>
        SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(item.value) !== -1,
    );
  }, [currentProject?.product_type, projectTags, sdkTags, selectedTagCategory]);

  const reset = useCallback(() => {
    setUrl("");
    setTriggerCondition([]);
    setSelectedTagCategory(SenseCategory.ALL);
  }, [setTriggerCondition, setUrl]);

  const onClickConfirm = useCallback(async () => {
    if (type === WebhookModalType.ADD) {
      await addWebhook();
    } else if (webhook) {
      const { medium_values, medium_id } = webhook;

      if (medium_values[0] !== url) {
        await update(medium_id, [url], MediumType.WEBHOOK);
      }
      await confirmToUpdate(webhook);

      if (!errorInfo && !mediumError) {
        dispatch(setClientInfo({ text: "Webhook has been edited" }));
      }
    }
    mutate(SWR_KEY.POST_ACTION_INITIALIZE_WEBHOOK_LIST);
    reset();
    onConfirm && onConfirm();
  }, [
    addWebhook,
    confirmToUpdate,
    dispatch,
    errorInfo,
    mediumError,
    mutate,
    onConfirm,
    reset,
    type,
    update,
    url,
    webhook,
  ]);

  const onCloseHandler = useCallback(() => {
    reset();
    onClose && onClose();
  }, [onClose, reset]);

  const onChangeInterval = useCallback(
    (
      { value: tag_name }: TagInterval,
      type: IntervalType,
      value: number | string | undefined,
    ) => {
      setTriggerCondition((prev) =>
        prev.map((data) => {
          const interval =
            type === IntervalType.INTERVAL ? value : data.interval;
          const unit = (
            type === IntervalType.INTERVAL ? data.interval_unit : value
          ) as TAG_INTERVAL_TIME_TYPE_VALUE;
          const error = getIntervalTimeError(interval, unit, 0, 3600);
          return data.value === tag_name
            ? { ...data, [type]: value, error }
            : data;
        }),
      );
    },
    [setTriggerCondition],
  );

  const onChangeTag = useCallback(
    (item) => {
      if (!triggerCondition.find((data) => data.value === item.value)) {
        item = {
          interval: ONE_MINUTE_SECOND,
          interval_unit: TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS,
          ...item,
        };
        setTriggerCondition([...triggerCondition, ...[item]]);
      } else {
        setTriggerCondition(
          triggerCondition.filter((data) => data.value !== item.value),
        );
      }
    },
    [setTriggerCondition, triggerCondition],
  );

  const onClickSelectAll = useCallback(
    (e) => {
      setTriggerCondition(
        e.target.checked
          ? triggerCondition.concat(
              tagList.flatMap((tag) =>
                triggerCondition.find((tc) => tc.value === tag.value)
                  ? []
                  : [
                      {
                        interval: ONE_MINUTE_SECOND,
                        interval_unit: TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS,
                        ...tag,
                      },
                    ],
              ),
            )
          : triggerCondition.filter(
              (item) =>
                SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(
                  item.value,
                ) === -1,
            ),
      );
    },
    [selectedTagCategory, setTriggerCondition, tagList, triggerCondition],
  );

  useEffect(() => {
    if (webhook) {
      webhook.medium_values[0] && setUrl(webhook.medium_values[0]);
      webhook.trigger_conditions &&
        setTriggerCondition(
          webhook.trigger_conditions.map((data) => ({
            value: data.tag_name,
            label: convertSenseTagValueToUILabel(data.tag_name),
            interval: data.interval,
            interval_unit: TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS,
          })),
        );
    }
  }, [setTriggerCondition, setUrl, webhook]);

  useEffect(() => {
    let isNotSelected = false,
      i = 0;
    do {
      if (!triggerCondition.find((tag) => tag.value === tagList[i].value)) {
        isNotSelected = true;
      }
      i++;
    } while (!isNotSelected && i < tagList.length);

    setIsSelectAll(!isNotSelected);
  }, [tagList, triggerCondition]);

  return (
    <ConfirmModal
      title={t(`${type} webhook`, { ns: "common" })}
      subTitle="Input a valid URL to receive webhook requests and select sound events you want to do HTTP call."
      open={open}
      onClose={onCloseHandler}
      onConfirm={onClickConfirm}
      confirmText={t(`${type} webhook`, { ns: "common" })}
      confirmDisabled={
        (validation.validating && !!validation.error) ||
        !!triggerCondition.find((item) => item.error) ||
        !url ||
        triggerCondition.length === 0
      }
    >
      <LabelContainer style={{ marginTop: 0 }}>
        <Typo variant="h4">URL</Typo>
      </LabelContainer>
      <WebhookURLInput
        placeholder="https://example.com"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        validated={validation.validating ? validation.validated : undefined}
        error={
          validation.validating && !!validation.error
            ? validation.error
            : undefined
        }
        autoFocus
      />
      <LabelContainer style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typo variant="h4">Select events</Typo>
          <TagsCountBadge>
            <Typo variant="caption3">{triggerCondition.length}</Typo>
          </TagsCountBadge>
        </div>
        <Checkbox
          selected={isSelectAll}
          onChange={onClickSelectAll}
          label="Select all"
        />
      </LabelContainer>
      <TagCategory
        tags={tagList}
        onClickItem={onChangeTag}
        selectedItems={triggerCondition}
        selectedCategory={selectedTagCategory}
        onClickCategory={setSelectedTagCategory}
      />
      <TagSettingContainer>
        {triggerCondition
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((data, i) => (
            <TagSetting key={i}>
              <SenseTagText variant="body">{data.label}</SenseTagText>
              <Flex>
                <Typo
                  variant="body"
                  style={{ color: colors.grey[80], marginTop: "5px" }}
                >
                  Interval
                </Typo>
                <IntervalTimeInput>
                  <Input
                    type="number"
                    value={String(data.interval)}
                    onChange={(e) =>
                      onChangeInterval(
                        data,
                        IntervalType.INTERVAL,
                        e.target.value,
                      )
                    }
                    placeholder="Interval time"
                    validated={data.error ? false : undefined}
                    error={data.error}
                  />
                </IntervalTimeInput>
                <ListDropdown
                  align="end"
                  data={TAG_INTERVAL_TIME_TYPE}
                  isOpen={isDropdownOpen(i)}
                  onClickItem={(item) =>
                    onChangeInterval(data, IntervalType.INTERVAL_UNIT, item)
                  }
                  onClose={() => closeDropdown(i)}
                  onClickOutside={() => closeDropdown(i)}
                  style={{ zIndex: `${Z_INDEXES.MODAL}` }}
                >
                  <DateDropdownButton
                    onClick={() => openDropdown(i)}
                    selected={false}
                  >
                    <Typo variant="caption1" style={{ color: colors.black }}>
                      {data.interval_unit &&
                        INTERVAL_UNIT_UI_LABELS[data.interval_unit]}
                    </Typo>
                  </DateDropdownButton>
                </ListDropdown>
              </Flex>
            </TagSetting>
          ))}
      </TagSettingContainer>
      {(isCreating || isUpdating || isLoading) && <FullCenterLoading />}
    </ConfirmModal>
  );
};

export default WebhookModal;
