import { useEffect, useState } from "react";
import useSWR from "swr";
import { APIError } from "~/client/types";
import { fetchGetSDKTags } from "~/client/api";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";
import { SenseTags } from "~/client/types/sense";

function useSdkTags() {
  const dispatch = useAppDispatch();
  const [sdkTags, setSdkTags] = useState<
    Array<{
      value: SenseTags;
      label: string;
    }>
  >([]);

  const { data, error, isValidating } = useSWR(
    SWR_KEY.SENSE_SDK_INITIALIZE_SDK_TAGS,
    async () => {
      const res = await fetchGetSDKTags({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
      revalidateOnMount: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setSdkTags(
      data.tags?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      }),
    );
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    sdkTags,
    isValidating,
  };
}

export default useSdkTags;
