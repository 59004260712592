import React, { FC } from "react";
import styled, { useTheme } from "styled-components";

import { ShowMoreArrowDownIcon } from "@cochlearai/ui";

const FullWidthLineContainer = styled.div`
  width: 100%;
  cursor: pointer;
  margin-top: 60px;
`;

const ShowMoreWrapper = styled.div`
  width: 130px;
  height: 65px;
  text-align: center;
  margin: auto;
`;

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
}

const ShowMoreButton: FC<Props> = ({ onClick, label, className, style }) => {
  const { colors } = useTheme();
  return (
    <FullWidthLineContainer
      onClick={onClick}
      className={className}
      style={{ ...style }}
    >
      <ShowMoreWrapper>
        <h3>{label ?? "Show more"}</h3>
        <ShowMoreArrowDownIcon
          width={23.34}
          height={11.68}
          fill={colors.black}
          style={{ marginTop: "15px" }}
        />
      </ShowMoreWrapper>
    </FullWidthLineContainer>
  );
};

export default ShowMoreButton;
