import { EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE } from "~/client/types/sdk";

function getEdgeSDKDeviceListTableColumnWidth(
  columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE,
) {
  switch (columnType) {
    case EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.INSTALLED_DATE:
      return "200px";
    case EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.ID:
      return "420px";
    case EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME:
      return "300px";
    case EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.LAST_SEEN:
      return "130px";
    case EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.DELETE:
      return "150px";
    default:
      return "0px";
  }
}

export default getEdgeSDKDeviceListTableColumnWidth;
