import {
  DropdownItem,
  HistoryIcon,
  ToastCloseIcon,
  Typo,
} from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

const DropdownContainer = styled(DropdownItem)`
  width: 588px;
  height: 32px;
  border-radius: 0;

  &:hover {
    background-color: ${(p) => p.theme.colors.grey[20]};
  }

  ${media.query.md} {
    width: 300px;
  }
`;

const HistoryText = styled(Typo)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${media.query.md} {
    width: 230px;
  }
`;

interface Props {
  onClickItem: () => void;
  onDelete: () => void;
  value: string;
}

const AnalyticsSearchHistory: FC<Props> = ({
  onClickItem,
  onDelete,
  value,
}) => {
  const { colors } = useTheme();
  return (
    <DropdownContainer
      onClick={(e) => {
        e.stopPropagation();
        onClickItem();
      }}
    >
      <HistoryIcon style={{ marginRight: "8px" }} />
      <HistoryText variant="body">{value}</HistoryText>
      <ToastCloseIcon
        style={{
          cursor: "pointer",
          marginLeft: "auto",
          marginRight: "2px",
        }}
        width={8}
        height={8}
        fill={colors.grey[80]}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    </DropdownContainer>
  );
};

export default AnalyticsSearchHistory;
