import {
  alertReducer,
  analyticsReducer,
  audioReducer,
  authReducer,
  pageRouteReducer,
  paymentReducer,
  pricePlanReducer,
  projectReducer,
  searchReducer,
  surveyReducer,
  themeReducer,
  voiceReducer,
} from "~/client/store/modules";

import { configureStore } from "@reduxjs/toolkit";
import { uploadReducer } from "./modules/upload";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    pageRoute: pageRouteReducer,
    alert: alertReducer,
    pricePlan: pricePlanReducer,
    project: projectReducer,
    theme: themeReducer,
    payment: paymentReducer,
    audio: audioReducer,
    upload: uploadReducer,
    analytics: analyticsReducer,
    search: searchReducer,
    survey: surveyReducer,
    voice: voiceReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
