import { useCallback, useState } from "react";
import {
  fetchCreateTriggerCondition,
  fetchRemoveTriggerCondition,
  fetchUpdateTriggerCondition,
} from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";
import {
  APIError,
  APIErrorData,
  API_ERROR_STATUS,
  NotificationConfig,
  TagInterval,
  TAG_INTERVAL_TIME_TYPE_VALUE,
} from "~/client/types";
import { setClientError, setServerError } from "~/client/store/modules";
import { ONE_MINUTE_SECOND } from "~/client/lib/constants";

function useTriggerCondition() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorInfo, setErrorInfo] = useState<APIError<unknown> | null>(null);
  const [triggerCondition, setTriggerCondition] = useState<TagInterval[]>([]);

  const addTriggerCondition = useCallback(
    async (config_id: string, tag_name: string, interval?: number) => {
      if (!tag_name || !config_id) return;

      try {
        return await fetchCreateTriggerCondition({
          params: {
            config_id,
            tag_name,
            ...(typeof interval === "number" && { interval }),
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        setErrorInfo(error);
        if (error.response?.status === API_ERROR_STATUS.BAD_REQUEST) {
          const errorData = error.response?.data as APIErrorData;
          const message =
            typeof errorData.details === "object"
              ? errorData.details.message
              : errorData.details;

          if (errorData && message) {
            dispatch(
              setClientError({
                text: message,
              }),
            );
          } else {
            throw e;
          }
        }
      }
    },
    [dispatch],
  );

  const updateTriggerCondition = useCallback(
    async (config_id: string, id: string, interval?: number) => {
      if (!config_id || !id) return;

      try {
        return await fetchUpdateTriggerCondition({
          params: {
            id,
            config_id,
            ...(typeof interval === "number" && { interval }),
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        setErrorInfo(error);
        if (error.response?.status === API_ERROR_STATUS.BAD_REQUEST) {
          const errorData = error.response?.data as APIErrorData;
          const message =
            typeof errorData.details === "object"
              ? errorData.details.message
              : errorData.details;

          if (errorData && message) {
            dispatch(
              setClientError({
                text: message,
              }),
            );
          } else {
            throw e;
          }
        }
      }
    },
    [dispatch],
  );

  const removeTriggerCondition = useCallback(
    async (config_id: string, id: string) => {
      if (!config_id || !id) return;

      try {
        return await fetchRemoveTriggerCondition({
          params: {
            id,
            config_id,
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        setErrorInfo(error);
        dispatch(setServerError(error));
      }
    },
    [dispatch],
  );

  const confirmToUpdate = useCallback(
    async (nc: NotificationConfig) => {
      if (!nc || isLoading) return;
      const { trigger_conditions, id } = nc;
      setErrorInfo(null);
      setIsLoading(true);

      const promisesToUpdate = triggerCondition.map(async (tag) => {
        const calculatedTime =
          tag.interval !== undefined
            ? tag.interval *
              (tag.interval_unit === TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES
                ? ONE_MINUTE_SECOND
                : 1)
            : undefined;
        const foundIdx =
          trigger_conditions?.findIndex(
            (data) => data.tag_name === tag.value,
          ) ?? -1;

        if (trigger_conditions && foundIdx !== -1) {
          const target = trigger_conditions[foundIdx];
          if (
            tag.interval !== undefined &&
            target.interval !== calculatedTime
          ) {
            return await updateTriggerCondition(id, target.id, calculatedTime);
          }
        } else {
          return await addTriggerCondition(id, tag.value, calculatedTime);
        }
      });

      const promisesToRemove =
        trigger_conditions?.map(async (tag) => {
          const foundIdx =
            triggerCondition?.findIndex(
              (data) => data.value === tag.tag_name,
            ) ?? -1;

          if (!(trigger_conditions && foundIdx !== -1)) {
            return await removeTriggerCondition(id, tag.id);
          }
        }) ?? [];

      await Promise.all([...promisesToUpdate, ...promisesToRemove]);
      setIsLoading(false);
    },
    [
      addTriggerCondition,
      isLoading,
      removeTriggerCondition,
      triggerCondition,
      updateTriggerCondition,
    ],
  );

  return {
    addTriggerCondition,
    updateTriggerCondition,
    removeTriggerCondition,
    confirmToUpdate,
    triggerCondition,
    setTriggerCondition,
    errorInfo,
    isLoading,
  };
}

export default useTriggerCondition;
