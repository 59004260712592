import { FC } from "react";

import { Popover } from "@cochlearai/ui";
import { Z_INDEXES } from "~/client/lib/constants";

interface Props {
  isOpen: boolean;
  onClickOutside: () => void;
  content: JSX.Element;
  children: JSX.Element;
}

const ProfilePopover: FC<Props> = ({
  isOpen,
  onClickOutside,
  content,
  children,
}) => {
  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom"]}
      align="end"
      containerStyle={{
        zIndex: `${Z_INDEXES.POP_OVER}`,
      }}
      onClickOutside={onClickOutside}
      content={content}
      padding={20}
      reposition={false}
    >
      {children}
    </Popover>
  );
};

export default ProfilePopover;
