import {
  ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE,
  AnalyticsEventListTableColumnItem,
  AnalyticsType,
  FilterType,
} from "../types/analytics";
import { CARD_BRAND, PAYMENT_MORE_TYPE_VALUE } from "~/client/types/payment";
import {
  EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE,
  EdgeSDKDeviceListTableColumnItem,
} from "~/client/types/sdk";
import {
  INVOICE_HISTORY_TABLE_COLUMN_TYPE,
  INVOICE_TABLE_COLUMN_TYPE,
  InvoiceHistoryTableColumnItem,
  InvoiceTableColumnItem,
} from "~/client/types/invoice";
import {
  JOINED_MORE_TYPE_VALUE,
  MEMBER_MORE_TYPE_VALUE,
  MEMBER_ROLE,
  ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE,
  OrganizationMemberListTableColumnItem,
} from "~/client/types/organization";
import {
  PAGE_ROUTE_PATH_NAME,
  UPLOAD_VOICE_TABLE_COLUMN_TYPE,
  UploadVoiceTableColumnItem,
  VOICE_MORE_TYPE_VALUE,
  VOICE_SORT_TYPE_VALUE,
} from "../types";
import { PlanType, SubscriptionProjectType } from "~/client/types/priceplan";
import { ProjectFilterType, ProjectType } from "~/client/types/project";
import {
  SenseCategory,
  SenseIOTypes,
  SensePlatforms,
  SenseTags,
} from "~/client/types/sense";
import {
  TAG_INTERVAL_TIME_TYPE_VALUE,
  WEBHOOK_HISTORY_TABLE_COLUMN_TYPE,
  WEBHOOK_MORE_TYPE_VALUE,
  WebhookHistoryTableColumnItem,
} from "~/client/types/postAction";
import {
  UPLOAD_HISTORY_TABLE_COLUMN_TYPE,
  UploadHistoryTableColumnItem,
} from "~/client/types/upload";

export const COPY_TEXT_AREA_ID = "COCHL_COPY_TEXT_AREA";
export const BASIC_PLAN_AMOUNT = 9.99;
export const ENTERPRISE_PLAN_AMOUNT = 9.99;
export const STATIC_SIDEBAR_TOP_POSITION = 228;
export const EMAIL_SEND_LIMIT = 10;
export const CLOUD_API_USD_PRICE = 0.003;
export const CLOUD_API_TIME_FOR_PRICE = 15;
export const PROJECT_NAME_LIMIT = 40;
export const FILE_UPLOAD_CHUNK_SIZE = 1024 * 1024; //1mb
export const ONE_MINUTE_SECOND = 60;
export const FILE_UPLOAD_LIMIT_LENGTH = 20; //20m
export const FILE_UPLOAD_LIMIT_SIZE = 200; //200mb
export const AUDIO_FILE_TYPE = ["wav", "mp3", "ogg"];
export const AUDIO_FILE_DRAG_DROP_TYPE = [
  "audio/wav",
  "audio/mpeg",
  "audio/ogg",
];
export const CANVAS_WIDTH = 732;
export const CANVAS_HEIGHT = 200;
export const CANVAS_PADDING = 20;
export const FREE_USAGE_MIN = 20;
export const TRACKED_TAGS_LIMIT = 20;
export const TOP_TAGS_MIN_COUNT = 3;
export const TOP_TAGS_MAX_COUNT = 8;
export const TIMESERIES_MAX_TAGS = 15;
export const TAG_SOURCE_MAX_COUNT = 10;
export const HEATMAP_COLOR = ["#B7C7FF", "#7D99FE", "#B59AFF", "#7240FF"];
export const ANALYTICS_COLOR = [
  "#00088D",
  "#303CFB",
  "#6871FE",
  "#AAAFFE",
  "#DADCFF",
  "#205856",
  "#367B78",
  "#0B8580",
  "#17A5A0",
  "#63D7C6",
  "#335696",
  "#4275B8",
  "#5498DF",
  "#65B3EF",
  "#99D2F5",
];
export const UPLOAD_OFFSET = 1024;
export const UPLOAD_MAX_TIME = 600000;
export const IMAGE_PRELOAD = [
  "https://static.cochl.ai/dashboard/onboard1.png",
  "https://static.cochl.ai/dashboard/onboard2.png",
  "https://static.cochl.ai/dashboard/onboard3.png",
  "https://static.cochl.ai/dashboard/onboard4.png",
  "https://static.cochl.ai/dashboard/onboard1dark.png",
  "https://static.cochl.ai/dashboard/onboard2dark.png",
  "https://static.cochl.ai/dashboard/onboard3dark.png",
  "https://static.cochl.ai/dashboard/onboard4dark.png",
];

export const SHOW_MORE_LIMIT = {
  PROJECT: 12,
  INVOICE_HISTORY: 6,
  UPLOAD_HISTORY: 5,
  ANALYTICS: 10,
};

export const Z_INDEXES = {
  BUTTON: 16,
  HEADER: 18,
  FOOTER: 18,
  SIDE_BAR: 19,
  POP_OVER: 20,
  MODAL_BACKGROUND: 21,
  MODAL: 22,
  FULL_CENTER_LOADING: 2000,
};

export const MODAL_WIDTH_BY_SIZE = {
  small: "540px",
  medium: "821px",
  large: "990px",
};

export const EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_ITEMS: EdgeSDKDeviceListTableColumnItem[] =
  [
    {
      columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.INSTALLED_DATE,
      label: "Installed Date",
    },
    {
      columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.ID,
      label: "Channel id",
    },
    {
      columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME,
      label: "Device name",
    },
    {
      columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.LAST_SEEN,
      label: "Last online",
    },
    {
      columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.DELETE,
      label: "",
    },
  ];

export const ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_ITEMS: OrganizationMemberListTableColumnItem[] =
  [
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.NAME,
      label: "Name",
    },
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.EMAIL,
      label: "Email",
    },
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.ROLE,
      label: "Role",
    },
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.INVITED_DATE,
      label: "Invited date",
    },
  ];

export const ORGANIZATION_INVITED_TABLE_COLUMN_ITEMS: OrganizationMemberListTableColumnItem[] =
  [
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.NAME,
      label: "Organization name",
    },
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.EMAIL,
      label: "Email",
    },
    {
      columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.INVITED_DATE,
      label: "Joined date",
    },
  ];

export const INVOICE_TABLE_COLUMN_ITEMS: InvoiceTableColumnItem[] = [
  {
    columnType: INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION,
    label: "Description",
  },
  {
    columnType: INVOICE_TABLE_COLUMN_TYPE.QTY,
    label: "QTY",
  },
  {
    columnType: INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE,
    label: "Unit Price",
  },
  {
    columnType: INVOICE_TABLE_COLUMN_TYPE.AMOUNT,
    label: "Amount",
  },
];

export const INVOICE_HISTORY_TABLE_COLUMN_ITEMS: InvoiceHistoryTableColumnItem[] =
  [
    {
      columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE.DATE,
      label: "Date",
    },
    {
      columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE.INVOICE_NUMBER,
      label: "Invoice number",
    },
    {
      columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE.AMOUNT,
      label: "Amount",
    },
    {
      columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE.STATUS,
      label: "Status",
    },
  ];

export const UPLOAD_HISTORY_TABLE_COLUMN_ITEMS: UploadHistoryTableColumnItem[] =
  [
    {
      columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE.DATE,
      label: "Date",
    },
    {
      columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_NAME,
      label: "File Name",
    },
    {
      columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_SIZE,
      label: "File size",
    },
    {
      columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_LENGTH,
      label: "File length",
    },
  ];

export const WEBHOOK_HISTORY_TABLE_COLUMN_ITEMS: WebhookHistoryTableColumnItem[] =
  [
    {
      columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.DATE,
      label: "Attempted time",
    },
    {
      columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.HTTP_ADDRESS,
      label: "Http address",
    },
    {
      columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.RESPONSE_CODE,
      label: "Response code",
    },
    {
      columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.STATUS,
      label: "Status",
    },
    {
      columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.RESPONSE_MESSAGE,
      label: "Response message",
    },
  ];

export const UPLOAD_VOICE_TABLE_COLUMN_ITEMS: UploadVoiceTableColumnItem[] = [
  {
    columnType: UPLOAD_VOICE_TABLE_COLUMN_TYPE.FILE_NAME,
    label: "File name",
  },
  {
    columnType: UPLOAD_VOICE_TABLE_COLUMN_TYPE.FILE_SIZE,
    label: "File size",
  },
  {
    columnType: UPLOAD_VOICE_TABLE_COLUMN_TYPE.FILE_LENGTH,
    label: "File length",
  },
  {
    columnType: UPLOAD_VOICE_TABLE_COLUMN_TYPE.EXTRA,
    label: "",
  },
];

export const SENSE_TAGS_BY_CATEGORY = {
  [SenseCategory.ALL]: Object.values(SenseTags),
  [SenseCategory.VEHICLE]: [
    SenseTags.AIRCRAFT_NOISE,
    SenseTags.CAR_ALARM,
    SenseTags.CAR_BACKFIRE,
    SenseTags.CAR_HORN,
    SenseTags.LOUD_ENGINE_ACCELERATION,
    SenseTags.REVERSING_BEEP,
    SenseTags.TIRE_SQUEAL,
    SenseTags.WIND_NOISE,
  ],
  [SenseCategory.HOME_CONTEXT]: [
    SenseTags.BOILING,
    SenseTags.CHOPPING,
    SenseTags.COOKING,
    SenseTags.COOKING_TOOLS,
    SenseTags.DINING_TOOLS,
    SenseTags.DOOR_OPEN_CLOSE,
    SenseTags.FRYING,
    SenseTags.HAIR_DRYER,
    SenseTags.KNOCK,
    SenseTags.THUD,
    SenseTags.TOILET_FLUSH,
    SenseTags.TOOTHBRUSH,
    SenseTags.VACUUM_CLEANER,
    SenseTags.WATER_POUR,
    SenseTags.WATER_RUN,
    SenseTags.WATER_TAP,
  ],
  [SenseCategory.ALARM]: [
    SenseTags.ALARM,
    SenseTags.APPLIANCE_ALARM,
    SenseTags.BELL,
    SenseTags.DOORBELL,
    SenseTags.FIRE_SMOKE_ALARM,
    SenseTags.PHONE_VIBRATION,
  ],
  [SenseCategory.HUMAN_STATUS]: [
    SenseTags.BABY_CRY,
    SenseTags.BABY_LAUGHTER,
    SenseTags.CHILD_LAUGHTER,
    SenseTags.COUGH,
    SenseTags.FEMALE_LAUGHTER,
    SenseTags.HICCUP,
    SenseTags.LAUGHTER,
    SenseTags.MALE_LAUGHTER,
    SenseTags.MOAN,
    SenseTags.SIGH,
    SenseTags.SNEEZE,
    SenseTags.SNORE,
    SenseTags.THROAT_CLEAR,
    SenseTags.YAWN,
  ],
  [SenseCategory.HUMAN_ACTION]: [
    SenseTags.APPLAUSE,
    SenseTags.BURP,
    SenseTags.CHEWING,
    SenseTags.CLAP,
    SenseTags.FART,
    SenseTags.FINGER_SNAP,
    SenseTags.FOOTSTEP,
    SenseTags.KISS,
    SenseTags.TONGUE_CLICK,
    SenseTags.TUT_TUT,
    SenseTags.WHISTLE,
  ],
  [SenseCategory.HUMAN_SPEECH]: [
    SenseTags.CROWD,
    SenseTags.CROWD_YELL,
    SenseTags.FEMALE_SPEECH,
    SenseTags.FEMALE_WHISPER,
    SenseTags.FEMALE_YELL,
    SenseTags.MALE_SPEECH,
    SenseTags.MALE_WHISPER,
    SenseTags.MALE_YELL,
    SenseTags.PHONE_FILTER_SPEECH,
    SenseTags.SPEECH,
    SenseTags.WHISPER,
    SenseTags.YELL,
  ],
  [SenseCategory.ANIMAL]: [
    SenseTags.BIRD_CHIRP,
    SenseTags.BIRD_HONK,
    SenseTags.CAT_MEOW,
    SenseTags.CHICKEN_ROOSTER,
    SenseTags.COW,
    SenseTags.DOG_BARK,
    SenseTags.DOG_WHINE,
    SenseTags.DUCK,
    SenseTags.GOAT,
    SenseTags.PIG,
    SenseTags.SHEEP,
  ],
  [SenseCategory.MUSIC]: [
    SenseTags.BOWED_STRING_INSTRUMENT,
    SenseTags.CHILD_SING,
    SenseTags.DRUM,
    SenseTags.FEMALE_RAP,
    SenseTags.FEMALE_SING,
    SenseTags.GUITAR,
    SenseTags.HUMMING,
    SenseTags.INSTRUMENT,
    SenseTags.MALE_RAP,
    SenseTags.MALE_SING,
    SenseTags.MUSIC,
    SenseTags.PIANO,
    SenseTags.RAP,
    SenseTags.SING,
  ],
  [SenseCategory.EMERGENCY]: [
    SenseTags.BREAK,
    SenseTags.CIVIL_DEFENSE_SIREN,
    SenseTags.EMERGENCY_VEHICLE_SIREN,
    SenseTags.FEMALE_SCREAM,
    SenseTags.GLASS_BREAK,
    SenseTags.GUNSHOT,
    SenseTags.GUNSHOT_AUTO,
    SenseTags.GUNSHOT_SINGLE,
    SenseTags.MALE_SCREAM,
    SenseTags.SCREAM,
    SenseTags.SIREN,
  ],
};

export const PRODUCT_TYPE_UI_LABELS: {
  [key in ProjectType]: string;
} = {
  [ProjectType.ALL]: "All",
  [ProjectType.CLOUD_API]: "Cloud API",
  [ProjectType.EDGE_SDK]: "Edge SDK",
};

export const PLAN_META = {
  [PlanType.FREE]: {
    subtitle: "For demo users",
    chooseButtonLabel: "Get started",
    plansModalFeatures: [
      "20 minutes free trial",
      "No credit card required",
      "Organization role: Viewer",
      "Ticketed support",
    ],
    featuresWithKey: [
      {
        key: "Cloud API",
        value: "20 minutes free trial",
      },
      {
        key: "Edge SDK",
        value: "Not available",
      },
    ],
  },
  [PlanType.STANDARD]: {
    subtitle: "For personal users",
    chooseButtonLabel: "Get started",
    plansModalFeatures: [
      "Simple pay-as-you-go plan",
      "Analytics functionality available",
      "Organization: Owner / Viewer",
      "Ticketed support",
      "Post action support",
    ],
    featuresWithKey: [
      {
        key: "Cloud API",
        value: "$0.012 /min",
      },
      {
        key: "Edge SDK",
        value: "various pricing",
      },
    ],
  },
  [PlanType.ENTERPRISE]: {
    subtitle: "For business users",
    chooseButtonLabel: "Contact sales",
    plansModalFeatures: [
      "All features from standard plan",
      "Edge SDK, 14-days free trial available + custom target sound selection",
      "New sound category inquiry with direct contact",
      "Custom API/SDK prices with official B2B contract",
      "24/7 priority support",
      "Post action support",
    ],
    featuresWithKey: [],
  },
};

export const STRIPE_ERROR_CODE_MESSAGES: {
  [key: string]: string;
} = {
  invalid_number: "The card number is not a valid credit card number.",
  invalid_expiry_month: "The card's expiration month is invalid.",
  invalid_expiry_year: "The card's expiration year is invalid.",
  invalid_cvc: "The card's security code is invalid.",
  incorrect_number: "The card number is incorrect.",
  expired_card: "The card has expired.",
  incorrect_cvc: "The card's security code is incorrect.",
  incorrect_zip: "The card's zip code failed validation.",
  card_declined: "The card was declined.",
  missing: "There is no card on a customer that is being charged.",
  processing_error: "An error occurred while processing the card.",
  setup_intent_unexpected_state: "You have already added this card.",
};

export const ANALYTICS_DATE_FILTER_TYPE = [
  {
    dateBadge: "3d",
    label: "Past 3 days",
    value: 60 * 60 * 24 * 2,
  },
  {
    dateBadge: "1w",
    label: "Past 1 week",
    value: 60 * 60 * 24 * 6,
  },
  {
    dateBadge: "1m",
    label: "Past 1 month",
    value: 1,
  },
  {
    dateBadge: "3m",
    label: "Past 3 months",
    value: 3,
  },
  {
    dateBadge: "6m",
    label: "Past 6 months",
    value: 6,
  },
  {
    dateBadge: "calendar",
    label: "Custom Date",
    value: "calendar",
  },
];

export const ANALYTICS_DATE_FILTER_TYPE_HOUR = [
  {
    dateBadge: "3h",
    label: "Past 3 hours",
    value: 60 * 60 * 3,
  },
  {
    dateBadge: "12h",
    label: "Past 12 hours",
    value: 60 * 60 * 12,
  },
  {
    dateBadge: "1d",
    label: "Past 1 day",
    value: 60 * 60 * 24,
  },
  {
    dateBadge: "3d",
    label: "Past 3 days",
    value: 60 * 60 * 24 * 2,
  },
  {
    dateBadge: "1w",
    label: "Past 1 week",
    value: 60 * 60 * 24 * 6,
  },
  {
    dateBadge: "calendar",
    label: "Custom Date",
    value: "calendar",
  },
];

export const PROJECT_FILTER_TYPE = [
  { label: "All", value: ProjectFilterType.ALL },
  { label: "Created by me", value: ProjectFilterType.CREATED_BY_ME },
  { label: "Invited", value: ProjectFilterType.INVITED },
];

export const MEMBER_MORE_TYPE = [
  { label: "Copy email", value: MEMBER_MORE_TYPE_VALUE.COPY_EMAIL },
  { label: "Resend invite", value: MEMBER_MORE_TYPE_VALUE.RESEND_INVITE },
];

export const JOINED_MORE_TYPE = [
  {
    label: "Leave organization",
    value: JOINED_MORE_TYPE_VALUE.LEAVE_ORGANIZATION,
  },
];

export const MEMBER_ROLE_UI_LABELS: {
  [key in MEMBER_ROLE]: string;
} = {
  [MEMBER_ROLE.OWNER]: "Owner",
  [MEMBER_ROLE.VIEWER]: "Viewer",
};

export const PROJECT_FILTER_UI_LABELS: {
  [key in ProjectFilterType]: string;
} = {
  [ProjectFilterType.ALL]: "All",
  [ProjectFilterType.CREATED_BY_ME]: "Created by me",
  [ProjectFilterType.INVITED]: "Invited",
};

export const ANALYTICS_ID_UI_LABELS: {
  [key in SubscriptionProjectType]: string;
} = {
  [ProjectType.EDGE_SDK]: "Channel id",
  [ProjectType.CLOUD_API]: "Session uuid",
};

export const CARD_BRAND_UI_LABELS: {
  [key in CARD_BRAND]: string;
} = {
  [CARD_BRAND.AMEX]: "Amex",
  [CARD_BRAND.DINERS]: "Diners",
  [CARD_BRAND.DISCOVER]: "Discover",
  [CARD_BRAND.JCB]: "JCB",
  [CARD_BRAND.MASTER]: "Master",
  [CARD_BRAND.UNION]: "Unionpay",
  [CARD_BRAND.VISA]: "Visa",
  [CARD_BRAND.UNKNOWN]: "Unknown",
};

export const PAYMENT_MORE_TYPE = [
  { label: "Remove", value: PAYMENT_MORE_TYPE_VALUE.REMOVE },
  { label: "Make default", value: PAYMENT_MORE_TYPE_VALUE.MAKE_DEFAULT },
];

export const INTERVAL_UNIT_UI_LABELS: {
  [key in TAG_INTERVAL_TIME_TYPE_VALUE]: string;
} = {
  [TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS]: "Seconds",
  [TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES]: "Minutes",
};

export const TAG_INTERVAL_TIME_TYPE = [
  {
    label: INTERVAL_UNIT_UI_LABELS[TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS],
    value: TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS,
  },
  {
    label: INTERVAL_UNIT_UI_LABELS[TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES],
    value: TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES,
  },
];

export const WEBHOOK_MORE_TYPE = [
  { label: "Edit webhooks", value: WEBHOOK_MORE_TYPE_VALUE.EDIT_WEBHOOK },
  { label: "Delete webhooks", value: WEBHOOK_MORE_TYPE_VALUE.DELETE_WEBHOOK },
  { label: "View history", value: WEBHOOK_MORE_TYPE_VALUE.VIEW_HISTORY },
];

export const ANALYTICS_TYPE_UI_LABELS: {
  [key in AnalyticsType]: string;
} = {
  [AnalyticsType.TAG_FREQUENCY]: "Tag Frequency",
  [AnalyticsType.REALTIME_VIEW]: "Timeseries View",
  [AnalyticsType.TOP_TAG]: "Top Tags",
  [AnalyticsType.TAG_AMOUNT_BREAKDOWN]: "Tag Source",
};

export const FILTER_TYPE_UI_LABELS: {
  [key in FilterType]: string;
} = {
  [FilterType.TAG]: "Tracked Tags",
  [FilterType.DEVICE]: "Tracked Devices",
  [FilterType.GROUP_BY]: "Group by",
  [FilterType.TOP_TAGS_COUNT]: "Top Tags Shown",
  [FilterType.SCALE]: "Show by",
  [FilterType.FROM_TO]: "Date Range",
  [FilterType.SESSION]: "Select File Source",
};

export const GROUP_BY_CONTENT = [
  { value: "devices", label: "Devices", checked: false },
  { value: "tags", label: "Tags", checked: false },
];

export const BASIC_SCALE_CONTENT = [
  { value: "hour", label: "Hour", checked: false },
  { value: "day", label: "Day", checked: true },
];

export const SCALE_CONTENT = [
  ...BASIC_SCALE_CONTENT,
  { value: "month", label: "Month", checked: false },
];

//previous analytics
export const ANALYTICS_EVENT_LIST_TABLE_COLUMN_ITEMS: AnalyticsEventListTableColumnItem[] =
  [
    {
      columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DATE,
      label: "Date",
    },
    {
      columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.TAG,
      label: "Tag",
    },
    {
      columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME,
      label: "Device name",
    },
    {
      columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.CONTENT_TYPE,
      label: "ContentType",
    },
    {
      columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.IOTYPE,
      label: "IOType",
    },
  ];

export const SENSE_IOTYPES_UI_LABELS: {
  [key in SenseIOTypes]: string;
} = {
  [SenseIOTypes.FILE]: "File",
  [SenseIOTypes.STREAM]: "Stream",
};

export const SENSE_PLATFORMS_UI_LABELS: {
  [key in SensePlatforms]: string;
} = {
  [SensePlatforms.API]: "API",
  [SensePlatforms.SDK]: "SDK",
};

export const PREVIOUS_ANALYTICS_DATE_FILTER_TYPE = [
  {
    dateBadge: "15m",
    label: "Past 15 minutes",
    value: 60 * 15,
  },
  {
    dateBadge: "30m",
    label: "Past 30 minutes",
    value: 60 * 30,
  },
  {
    dateBadge: "1h",
    label: "Past 1 hour",
    value: 60 * 60 * 1,
  },
  {
    dateBadge: "4h",
    label: "Past 4 hours",
    value: 60 * 60 * 4,
  },
  {
    dateBadge: "1d",
    label: "Past 1 day",
    value: 60 * 60 * 24,
  },
  {
    dateBadge: "2d",
    label: "Past 2 days",
    value: 60 * 60 * 24 * 2,
  },
  {
    dateBadge: "7d",
    label: "Past 7 days",
    value: 60 * 60 * 24 * 7,
  },
  {
    dateBadge: "15d",
    label: "Past 15 days",
    value: 60 * 60 * 24 * 15,
  },
  {
    dateBadge: "1m",
    label: "Past 1 month",
    value: 60 * 60 * 24 * 30,
  },
  {
    dateBadge: "calendar",
    label: "Custom Date",
    value: "calendar",
  },
];

export const MATCHED_PATH = [
  PAGE_ROUTE_PATH_NAME.BILLING,
  PAGE_ROUTE_PATH_NAME.BILLING_INFO,
  PAGE_ROUTE_PATH_NAME.BILLING_INVOICES,
  PAGE_ROUTE_PATH_NAME.USAGE,
  PAGE_ROUTE_PATH_NAME.USAGE_API,
  PAGE_ROUTE_PATH_NAME.USAGE_SDK,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SENSE_SDK,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS_OLD,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_UPLOAD,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_POST_ACTION,
  PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SPEAKER_RECOGNITION,
  PAGE_ROUTE_PATH_NAME.ORGANIZATION,
  PAGE_ROUTE_PATH_NAME.ORGANIZATION_MY_MEMBERS,
  PAGE_ROUTE_PATH_NAME.ORGANIZATION_INVITED,
];

export const VOICE_MORE_TYPE = [
  { label: "View details", value: VOICE_MORE_TYPE_VALUE.VIEW_DETAIL },
  { label: "Delete voice", value: VOICE_MORE_TYPE_VALUE.DELETE_VOICE },
];

export const VOICE_SORT_LABELS: {
  [key in VOICE_SORT_TYPE_VALUE]: string;
} = {
  [VOICE_SORT_TYPE_VALUE.ASCEND]: "Name ascending (A-Z)",
  [VOICE_SORT_TYPE_VALUE.DESCEND]: "Name descending (Z-A)",
  [VOICE_SORT_TYPE_VALUE.CREATED]: "Creation date",
};

export const VOICE_SORT_TYPE = [
  {
    label: VOICE_SORT_LABELS[VOICE_SORT_TYPE_VALUE.ASCEND],
    value: VOICE_SORT_TYPE_VALUE.ASCEND,
  },
  {
    label: VOICE_SORT_LABELS[VOICE_SORT_TYPE_VALUE.DESCEND],
    value: VOICE_SORT_TYPE_VALUE.DESCEND,
  },
  {
    label: VOICE_SORT_LABELS[VOICE_SORT_TYPE_VALUE.CREATED],
    value: VOICE_SORT_TYPE_VALUE.CREATED,
  },
];

export const RECORD_VOICE_SAMPLE_TEXT = [
  {
    title: "Start recording",
    text: "The recording will go in one sequence with no pause in between. Click on the recording button to start",
  },
  {
    title: "Sentence 1/5",
    text: "Call me Ishmael. Some years ago - never mind how long precisely - having little or no money in my purse",
  },
  {
    title: "Sentence 2/5",
    text: "and nothing particular to interest me on shore, I thought I would sail about a little and see the watery part of the world",
  },
  {
    title: "Sentence 3/5",
    text: "It is a way I have of driving off the spleen and regulating the circulation",
  },
  {
    title: "Sentence 4/5",
    text: "Whenever I find myself growing grim about the mouth, whenever it is a damp, drizzly November in my soul",
  },
  {
    title: "Sentence 5/5",
    text: "whenever I find myself involuntarily pausing before coffin warehouses, and bringing up the rear of every funeral I meet",
  },
  {
    title: "Recording complete",
    text: "Click on Create tag to complete the voice tag creation process",
  },
];

export const SR_ONBOARD_TEXT = [
  {
    title: "What’s new🎉",
    text: "Here are 3 new BETA features to help you create better projects with Audio ML",
  },
  {
    title: "Create voice tags for accurate Speaker recognition",
    text: "Build voice tags to enhance speaker recognition, making it easy to identify and distinguish voices in future transcriptions.",
  },
  {
    title: "Convert speech to text with ease",
    text: "Easily convert speech to text with accurate speaker identification and timestamped transcriptions. Perfect for meetings, interviews, and more.",
  },
  {
    title: "Export transcriptions with detailed insights",
    text: "Effortlessly export your transcriptions with in-depth metadata, including speaker confidence, scores, and precise timestamps, all in one JSON file.",
  },
];
