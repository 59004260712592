import { setClientInfo, setServerError } from "~/client/store/modules";
import { useCallback, useEffect, useRef, useState } from "react";

import { APIError } from "~/client/types";
import { PROJECT_NAME_LIMIT } from "~/client/lib/constants";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchUpdateSDKChannelDeviceName } from "~/client/api/senseSdk";
import { useAppDispatch } from "~/client/hooks";
import { useSWRConfig } from "swr";

interface Validation {
  validating: boolean;
  validated: boolean;
  error: string;
}

const initialValidation: Validation = {
  validating: false,
  validated: false,
  error: "",
};

function useUpdateDeviceName({
  deviceName,
  channel_id,
}: {
  deviceName?: string;
  channel_id: string;
}) {
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const isUpdating = useRef<boolean>(false);
  const [name, setName] = useState<string>(deviceName ?? "");
  const [validation, setValidation] = useState<Validation>(initialValidation);

  const validate = useCallback(() => {
    const onlyText = name.split(" ").join("");
    const isError = onlyText === "" || onlyText.length > PROJECT_NAME_LIMIT;
    const isNew = deviceName !== name;

    setValidation({
      validating: isNew,
      validated: !isError,
      error: isError
        ? `Input at least 1 characters, with a maximum of ${PROJECT_NAME_LIMIT} characters`
        : "",
    });
  }, [deviceName, name]);

  const update = useCallback(async () => {
    if (isUpdating.current) return;
    isUpdating.current = true;
    try {
      await fetchUpdateSDKChannelDeviceName({
        params: {
          device_name: name,
          channel_id: channel_id,
        },
      });
      await mutate(SWR_KEY.SENSE_SDK_INITIALIZE_CHANNEL_LIST).finally(() => {
        dispatch(
          setClientInfo({
            text: "Device name edited.",
          }),
        );
      });
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      isUpdating.current = false;
    }
  }, [channel_id, dispatch, mutate, name]);

  useEffect(() => {
    if (!deviceName) return;
    setName(deviceName);
  }, [deviceName]);

  useEffect(() => {
    validate();
  }, [name, validate]);

  return {
    name,
    setName,
    validation,
    update,
    isUpdating: isUpdating.current,
  };
}

export default useUpdateDeviceName;
