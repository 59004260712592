import { PopoverAlign } from "react-tiny-popover";
import { PropsWithChildren, ReactNode } from "react";
import { Z_INDEXES } from "../lib/constants";
import styled from "styled-components";
import { Popover } from "../Popover";
import { BaseStyleProps } from "../types";

const DropdownItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export interface DropdownProps<ItemT> extends BaseStyleProps {
  data: ReadonlyArray<ItemT>;
  renderItem: (data: ItemT, index: number) => ReactNode;
  isOpen: boolean;
  onClickOutside: (e: MouseEvent) => void;
  align?: PopoverAlign;
  dropdownPadding?: number;
  containerStyle?: Partial<CSSStyleDeclaration>;
  contentWidth?: number | string;
}

/**
 * React component used to create a dropdown.
 * @param data        - The data of the dropdown.
 * @param renderItem  - The function to render each item.
 * @param isOpen      - The open state of the dropdown.
 * @param onClickOutside - A callback when clicked outside of the dropdown.
 * @param align       - The alignment of the dropdown.
 * @param dropdownPadding - The padding of the dropdown.
 * @param containerStyle - The style of the container.
 * @param style       - The style of the dropdown.
 * @param className   - The className of the dropdown.
 * @param contentWidth - The width of the dropdown content.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/dropdown--default
 */
export const Dropdown = <ItemT,>({
  className,
  containerStyle,
  align,
  dropdownPadding,
  onClickOutside,
  isOpen,
  contentWidth,
  data,
  renderItem,
  style,
  children,
}: PropsWithChildren<DropdownProps<ItemT>>) => {
  return (
    <Popover
      containerClassName={className}
      containerStyle={{
        zIndex: `${Z_INDEXES.POP_OVER}`,
        maxHeight: "200px",
        overflow: "auto",
        ...containerStyle,
      }}
      align={align ?? "start"}
      positions={["bottom"]}
      padding={dropdownPadding ?? 6}
      onClickOutside={onClickOutside}
      isOpen={isOpen}
      content={({ childRect }) => (
        <DropdownItemContainer
          style={{ width: contentWidth ?? childRect.width }}
        >
          {data.map(renderItem)}
        </DropdownItemContainer>
      )}
    >
      <div style={{ display: "flex", ...style }}>{children}</div>
    </Popover>
  );
};
