import { Card, ProgressIndicator, Typo } from "@cochlearai/ui";
import { FC, useCallback } from "react";
import styled from "styled-components";

import { media } from "@cochlearai/util";
import {
  DetailFilterButton,
  FilterButton,
  PieChart,
} from "~/client/components";
import { useAppSelector } from "~/client/hooks";
import useAnalyticsTopTagBreakdown from "~/client/hooks/useAnalyticsTopTagBreakdown";
import { selectedAnalyticsState } from "~/client/lib/selectors";
import { SidebarType } from "~/client/types";
import { AnalyticsType, FilterType } from "~/client/types/analytics";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CardItem = styled(Card)`
  width: 100%;
  padding: 30px;
`;

const TopArea = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 20px;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const FilterArea = styled(Flex)`
  gap: 10px;

  ${media.query.md} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const Loading = styled(ProgressIndicator)`
  display: flex;
  justify-content: center;
  height: 336px;
  align-items: center;
`;

interface Props {
  openSidebar: (type: SidebarType, filterType?: AnalyticsType) => void;
}

const TagSource: FC<Props> = ({ openSidebar }) => {
  const { tagBreakdown, isValidating } = useAnalyticsTopTagBreakdown();
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const tagFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.TAG_AMOUNT_BREAKDOWN][
        FilterType.TAG
      ]
    )?.filter((filter) => filter.checked) ?? [];
  const deviceFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.TAG_AMOUNT_BREAKDOWN][
        FilterType.DEVICE
      ]
    )?.filter((filter) => filter.checked) ?? [];

  const PiechartTooltipFormatter = useCallback(({ seriesIndex, w }): string => {
    const seriesName = w.globals.labels[seriesIndex];
    return `<div class="tooltip-piechart"><span>${seriesName}</span><div class="tooltip-p"><span>Total</span><span>${w.globals.series[seriesIndex]}</span></div></div>`;
  }, []);

  return (
    <CardItem>
      <TopArea>
        <Typo variant="h3">Tag Source</Typo>
        <FilterArea>
          {tagFilters.length > 0 && (
            <DetailFilterButton text="Tags" type="string" data={tagFilters} />
          )}
          {deviceFilters.length > 0 && (
            <DetailFilterButton
              text="Devices"
              type="string"
              data={deviceFilters}
            />
          )}
          <FilterButton
            onClick={() =>
              openSidebar(
                SidebarType.FILTER,
                AnalyticsType.TAG_AMOUNT_BREAKDOWN,
              )
            }
            text="Filter"
          />
        </FilterArea>
      </TopArea>
      {isValidating ? (
        <Loading size="medium" />
      ) : (
        <PieChart
          labels={tagBreakdown.map(
            (item) => `${item.tag_name} - ${item.device_name}`,
          )}
          data={tagBreakdown.map((item) => item.count)}
          tooltipFormatter={PiechartTooltipFormatter}
        />
      )}
    </CardItem>
  );
};

export default TagSource;
