import styled from "styled-components";
import { components, MultiValueGenericProps } from "react-select";
import { Typo } from "../Typo";

const MultiValueText = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
`;

export const MultiValueLabel = (props: MultiValueGenericProps) => {
  return (
    <components.MultiValueLabel {...props}>
      <MultiValueText variant="caption1">{props.data.label}</MultiValueText>
    </components.MultiValueLabel>
  );
};
