import { selectedAnalyticsState } from "~/client/lib/selectors";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetAPITopTags } from "~/client/api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { AnalyticsType, FilterType, TopTag } from "~/client/types/analytics";

function useAnalyticsTopTag() {
  const dispatch = useAppDispatch();
  const params = useParams<{ projectId?: string }>();
  const [topTag, setTopTag] = useState<TopTag[]>([]);
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const filters = useMemo(
    () =>
      filterParamsWithChecked
        ? filterParamsWithChecked[AnalyticsType.TOP_TAG]
        : {},
    [filterParamsWithChecked],
  );

  const { data, error, isValidating, mutate } = useSWR(
    params.projectId && filterParamsWithChecked
      ? SWR_KEY.ANALYTICS_INITIALIZE_TOPTAG
      : null,
    async () => {
      if (!filterParamsWithChecked || !params.projectId) return null;
      const { tag, session } = filters;
      return (
        await fetchGetAPITopTags({
          params: {
            project_id: params.projectId!,
            tags: tag?.filter((item) => item.checked).map((item) => item.value),
            session_ids: session
              ?.filter((item) => item.checked)
              .map((item) => item.value),
          },
        })
      ).data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const initializeEventList = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    if (!data) return;
    setTopTag(
      data
        .sort((a, b) => b.count - a.count)
        .slice(0, filters[FilterType.TOP_TAGS_COUNT]),
    );
  }, [data, filters]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  useEffect(() => {
    initializeEventList();
  }, [initializeEventList, filters]);

  return {
    topTag,
    isValidating,
  };
}

export default useAnalyticsTopTag;
