import {
  ANALYTICS_TYPE_UI_LABELS,
  FILTER_TYPE_UI_LABELS,
  TIMESERIES_MAX_TAGS,
  TOP_TAGS_MAX_COUNT,
  TOP_TAGS_MIN_COUNT,
} from "~/client/lib/constants";
import { AnalyticsDatePicker, AnalyticsFileSource } from "~/client/components";
import {
  AnalyticsType,
  FilterParamsValue,
  FilterType,
  TopTagSession,
} from "~/client/types/analytics";
import {
  Chip,
  DropdownButton,
  ListDropdown,
  Scroll,
  Typo,
} from "@cochlearai/ui";
import React, { FC, useCallback, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAnalyticsFilter, useCurrentProject } from "~/client/hooks";

import { ProjectType } from "~/client/types/project";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FilterValueListPositionContainer = styled(Scroll)<{ isSDK: boolean }>`
  min-height: fill-content;
`;

const FilterValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FilterChip = styled(Chip)<{ secondary?: boolean; checked?: boolean }>`
  margin-top: 8px;
  margin-right: 8px;
  cursor: pointer;
  height: 24px;
  border-radius: ${(p) => (p.secondary ? "4px" : "100px")};
  background-color: ${(p) =>
    p.secondary
      ? p.theme.colors.white
      : !p.checked
        ? p.theme.colors.grey[10]
        : ""};
  color: ${(p) => (p.checked && !p.secondary ? p.theme.colors.blue[50] : "")};
  border-width: ${(p) => (p.secondary ? "1px" : 0)};
  border-style: solid;
  border-color: ${(p) =>
    p.checked ? p.theme.colors.blue[60] : p.theme.colors.grey[30]};
`;

const SetButtonContainer = styled(Flex)`
  flex-direction: row-reverse;
  margin-top: 40px;
`;

const FilterTypeText = styled(Typo)`
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.grey[50]};
`;

const ScaleDropdownButton = styled(DropdownButton)`
  width: 100%;
  height: 32px;
`;

const TagCountControl = styled(FilterChip)<{ disabled?: boolean }>`
  margin: 0;
  background: ${(p) => p.theme.colors.grey[10]};
  opacity: ${(p) => (p.disabled ? 0.4 : 1)};
  cursor: ${(p) => (p.disabled ? "unset" : "pointer")};
`;

interface Props {
  open: boolean;
  filterType: AnalyticsType;
  close?: () => void;
}

const AnalyticsFilterView: FC<Props> = ({ filterType, open, close }) => {
  const { colors } = useTheme();
  const { currentProject } = useCurrentProject();
  const { filters, updateFilter, clearFilter, onClickFilter, setFilter } =
    useAnalyticsFilter({
      chartType: filterType,
      open,
    });
  const [dateDropdownIsOpen, setDateDropdownIsOpen] = useState<boolean>(false);

  const isAvailableToShow = useCallback((value) => {
    const isArray = Array.isArray(value);
    if (!isArray && value) return true;
    if (isArray && value.length > 0) return true;
    return false;
  }, []);

  const getFilterTitle = useCallback(
    (key) => {
      let text = FILTER_TYPE_UI_LABELS[key as FilterType];

      if (key === FilterType.TAG && filterType === AnalyticsType.REALTIME_VIEW)
        text += ` (Max. ${TIMESERIES_MAX_TAGS} tags)`;
      if (
        key === FilterType.FROM_TO &&
        filterType === AnalyticsType.REALTIME_VIEW
      ) {
        if (
          filters[FilterType.SCALE]?.find((item) => item.checked)?.value ===
          "hour"
        ) {
          text += " (Max. 1 Week)";
        } else {
          text += " (Max. 6 Months)";
        }
      }

      return text;
    },
    [filterType, filters],
  );

  const renderContent = (
    key: FilterType,
    value:
      | FilterParamsValue[]
      | number
      | { from: number; to: number }
      | TopTagSession[],
    isSDK: boolean,
  ) => {
    let selected;
    switch (key) {
      case FilterType.TOP_TAGS_COUNT:
        return (
          <Flex
            style={{
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <TagCountControl
              disabled={value === TOP_TAGS_MIN_COUNT}
              onClick={() =>
                typeof value === "number" &&
                value > TOP_TAGS_MIN_COUNT &&
                onClickFilter(key, +value - 1)
              }
            >
              -
            </TagCountControl>
            <Typo variant="caption1">{String(value)}</Typo>
            <TagCountControl
              disabled={value === TOP_TAGS_MAX_COUNT}
              onClick={() =>
                typeof value === "number" &&
                value < TOP_TAGS_MAX_COUNT &&
                onClickFilter(key, +value + 1)
              }
            >
              +
            </TagCountControl>
          </Flex>
        );
      case FilterType.FROM_TO:
        return (
          <AnalyticsDatePicker
            type={filterType}
            filters={filters}
            onClickFilter={(value) => onClickFilter(key, value)}
          />
        );
      case FilterType.SCALE:
        selected = (value as FilterParamsValue[]).find((item) => item.checked);
        return (
          <ListDropdown
            fitToContents={false}
            align="end"
            data={value as FilterParamsValue[]}
            selected={selected?.value}
            isOpen={dateDropdownIsOpen}
            onClickItem={(selectedItem) =>
              onClickFilter(
                key,
                (value as FilterParamsValue[]).map((item) => ({
                  ...item,
                  checked: selectedItem === item.value ? true : false,
                })),
              )
            }
            onClose={() => setDateDropdownIsOpen(false)}
            onClickOutside={() => setDateDropdownIsOpen(false)}
          >
            <ScaleDropdownButton
              onClick={() => setDateDropdownIsOpen(!dateDropdownIsOpen)}
            >
              <Typo variant="caption1" style={{ color: colors.black }}>
                {selected?.label}
              </Typo>
            </ScaleDropdownButton>
          </ListDropdown>
        );
      case FilterType.SESSION:
        return (
          <AnalyticsFileSource
            list={value as TopTagSession[]}
            setFilter={setFilter}
          />
        );
      default:
        return (
          <FilterValueListPositionContainer isSDK={isSDK}>
            <FilterValueContainer>
              {value &&
                (value as FilterParamsValue[])?.map((item, index) => {
                  return (
                    <FilterChip
                      key={`${item.value}-${index}`}
                      onClick={() => onClickFilter(key, item)}
                      secondary={key === FilterType.GROUP_BY}
                      checked={item.checked}
                    >
                      {item.label}
                    </FilterChip>
                  );
                })}
            </FilterValueContainer>
          </FilterValueListPositionContainer>
        );
    }
  };

  return (
    <>
      <Typo variant="h3">Filter</Typo>
      <Typo
        variant="caption1"
        style={{ color: colors.grey[80], marginTop: "4px" }}
      >
        {ANALYTICS_TYPE_UI_LABELS[filterType]}
      </Typo>
      {Object.entries(filters).map(([key, value]) => {
        if (
          currentProject?.product_type !== ProjectType.EDGE_SDK &&
          key === FilterType.GROUP_BY
        )
          return null;
        return (
          <React.Fragment key={key}>
            {isAvailableToShow(value) && (
              <>
                <FilterTypeText variant="caption1">
                  {getFilterTitle(key)}
                </FilterTypeText>
                {renderContent(
                  key as FilterType,
                  value,
                  currentProject?.product_type === ProjectType.EDGE_SDK,
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
      <SetButtonContainer>
        <Typo
          variant="button"
          style={{
            color: colors.blue[60],
            cursor: "pointer",
            marginLeft: "16px",
          }}
          onClick={() => {
            updateFilter();
            close && close();
          }}
        >
          Confirm
        </Typo>
        <Typo
          variant="button"
          style={{
            color: colors.grey[80],
            cursor: "pointer",
          }}
          onClick={() => {
            clearFilter();
            close && close();
          }}
        >
          Clear all
        </Typo>
      </SetButtonContainer>
    </>
  );
};

export default AnalyticsFilterView;
