import axiosClient, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { API_ERROR_STATUS } from "~/client/types";

axiosClient.defaults.withCredentials = true;
axiosClient.defaults.adapter = require("axios/lib/adapters/http");

axiosRetry(axiosClient, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
  retryCondition: (err: AxiosError) => {
    if (err.response) {
      // for login redirection (401), for not retrying forbidden content http request
      const { status } = err.response;
      if (+status === API_ERROR_STATUS.BAD_REQUEST) {
        return false;
      }
      if (+status === API_ERROR_STATUS.UNAUTHORIZED) {
        return false;
      }
      if (+status === API_ERROR_STATUS.FORBIDDEN) {
        return false;
      }
      return !(+status >= 200 && +status < API_ERROR_STATUS.BAD_REQUEST);
    }
    return true;
  },
});

export default axiosClient;
