import { HyperlinkButton, UserProfile } from "@cochlearai/ui";
import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useTheme from "~/client/hooks/useTheme";
import { getConfigs } from "~/client/lib";
import logout from "~/client/lib/helpers/logout";
import { PAGE_ROUTE_PATH_NAME } from "~/client/types/pageRoute";

const { REACT_APP_ACCOUNT_URL: accountURL } = getConfigs();
const accountSettingsURL = accountURL && `${accountURL}`;

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(p) => p.theme.colors.white};

  border-radius: 4px;
`;

const ListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 30px;
  & + & {
    padding-top: 0px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${(p) => p.theme.colors.grey[70]};
`;

const UserText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  width: 186px;
  text-overflow: ellipsis;
`;

const UsernameText = styled(UserText)`
  color: ${(p) => p.theme.colors.black};
  font-weight: bold;
`;

const UserEmailText = styled(UserText)`
  margin-top: 6px;
`;

const ThemeText = styled(Text)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(p) => p.theme.colors.grey[20]};
  width: 100%;
`;

interface Props {
  name: string;
  email: string;
  onClose?: () => void;
  currentPlanTitle?: string;
  hideUserProfile?: boolean;
}

const ProfilePopoverContent: FC<Props> = ({
  name,
  email,
  onClose,
  currentPlanTitle,
  hideUserProfile,
}) => {
  const { t } = useTranslation();
  const { theme, switchTheme } = useTheme();
  return (
    <Container>
      <ListItem>
        {!hideUserProfile && (
          <UserProfile text={name.length > 0 ? name[0] : "U"} />
        )}
        <TextContainer>
          <UsernameText>{name}</UsernameText>
          <UserEmailText>{email}</UserEmailText>
        </TextContainer>
      </ListItem>
      <Divider />
      <ListItem>
        {currentPlanTitle && <Text>{currentPlanTitle}</Text>}
        <span onClick={onClose} style={{ marginLeft: "auto" }}>
          <Link
            to={{
              pathname: PAGE_ROUTE_PATH_NAME.BILLING_INFO,
              state: { planModalOpen: true },
            }}
          >
            <HyperlinkButton text="Upgrade plan" rightArrow />
          </Link>
        </span>
      </ListItem>
      {accountSettingsURL && (
        <ListItem>
          <Link
            to={{ pathname: accountSettingsURL }}
            target="_blank"
            rel="noopener"
            onClick={onClose}
          >
            <Text>{t("My account", { ns: "common" })}</Text>
          </Link>
        </ListItem>
      )}
      <ListItem>
        <ThemeText>{theme}</ThemeText>
        <Text
          onClick={() => {
            switchTheme();
            onClose && onClose();
          }}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        >
          <HyperlinkButton text="Change Theme" rightArrow />
        </Text>
      </ListItem>
      <Divider />
      <ListItem>
        <Text
          onClick={() => {
            logout();
            onClose && onClose();
          }}
          style={{ cursor: "pointer" }}
        >
          {t("Log out", { ns: "common" })}
        </Text>
      </ListItem>
    </Container>
  );
};

export default ProfilePopoverContent;
