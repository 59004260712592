import React, { FC, SVGProps } from "react";

const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1333 6.125H7.86667C7.45429 6.125 7.12 6.46079 7.12 6.875V18.125C7.12 18.5392 7.45429 18.875 7.86667 18.875H18.1333C18.5457 18.875 18.88 18.5392 18.88 18.125V6.875C18.88 6.46079 18.5457 6.125 18.1333 6.125ZM7.86667 5C6.83574 5 6 5.83947 6 6.875V18.125C6 19.1605 6.83574 20 7.86667 20H18.1333C19.1643 20 20 19.1605 20 18.125V6.875C20 5.83947 19.1643 5 18.1333 5H7.86667Z"
      fill={props.fill || "#B2B2B2"}
    />
    <path
      d="M1.86667 1.125H12.1333C12.5457 1.125 12.88 1.46079 12.88 1.875V3.75H14V1.875C14 0.839466 13.1643 0 12.1333 0H1.86667C0.835735 0 0 0.839466 0 1.875V13.125C0 14.1605 0.835735 15 1.86667 15H4.66667V13.875H1.86667C1.45429 13.875 1.12 13.5392 1.12 13.125V1.875C1.12 1.46079 1.45429 1.125 1.86667 1.125Z"
      fill={props.fill || "#B2B2B2"}
    />
  </svg>
);

export default CopyIcon;
