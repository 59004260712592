import { Card, ProgressIndicator, Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";

import { media } from "@cochlearai/util";
import {
  DetailFilterButton,
  FilterButton,
  LineChart,
} from "~/client/components";
import { useAppSelector } from "~/client/hooks";
import useAnalyticsTimeseries from "~/client/hooks/useAnalyticsTimeseries";
import { selectedAnalyticsState } from "~/client/lib";
import { SidebarType } from "~/client/types";
import { AnalyticsType, FilterType } from "~/client/types/analytics";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CardItem = styled(Card)`
  width: 100%;
  padding: 30px;
  margin: 24px 0;
`;

const TopArea = styled(Flex)`
  justify-content: space-between;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const FilterArea = styled(Flex)`
  gap: 10px;

  ${media.query.md} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const Loading = styled(ProgressIndicator)`
  display: flex;
  justify-content: center;
  height: 400px;
  align-items: center;
`;

interface Props {
  openSidebar: (type: SidebarType, filterType?: AnalyticsType) => void;
}

const RealtimeView: FC<Props> = ({ openSidebar }) => {
  const { timeSeries, labels, isValidating } = useAnalyticsTimeseries();
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const groupByFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.REALTIME_VIEW][FilterType.GROUP_BY]
    )?.filter((filter) => filter.checked) ?? [];
  const tagFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.REALTIME_VIEW][FilterType.TAG]
    )?.filter((filter) => filter.checked) ?? [];
  const deviceFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.REALTIME_VIEW][FilterType.DEVICE]
    )?.filter((filter) => filter.checked) ?? [];
  const scale =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.REALTIME_VIEW][FilterType.SCALE]
    )?.find((filter) => filter.checked)?.value ?? "";

  return (
    <CardItem>
      <TopArea>
        <Typo variant="h3">Timeseries View</Typo>
        <FilterArea>
          {groupByFilters.length > 0 && (
            <DetailFilterButton
              text="Group by"
              type="string"
              data={groupByFilters}
            />
          )}
          {tagFilters.length > 0 && (
            <DetailFilterButton text="Tags" type="string" data={tagFilters} />
          )}
          {deviceFilters.length > 0 && (
            <DetailFilterButton
              text="Devices"
              type="string"
              data={deviceFilters}
            />
          )}
          <FilterButton
            onClick={() =>
              openSidebar(SidebarType.FILTER, AnalyticsType.REALTIME_VIEW)
            }
            text="Filter"
            selected={tagFilters.length > 0}
          />
        </FilterArea>
      </TopArea>
      {isValidating ? (
        <Loading size="medium" />
      ) : (
        <LineChart data={timeSeries} labels={labels} scale={scale} />
      )}
    </CardItem>
  );
};

export default RealtimeView;
