import React, { FC, SVGProps } from "react";

const EditIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25839 10.8711L13.8191 1.31032C14.5428 0.586646 15.7721 0.642633 16.5648 1.43536C17.3575 2.2281 17.4135 3.45738 16.6899 4.18105L7.12911 13.7418L2.86493 15.135L4.25839 10.8711ZM5.15791 11.4693L4.49147 13.5086L6.5309 12.8423L15.941 3.43217C16.2871 3.08607 16.2603 2.49814 15.8812 2.119C15.502 1.73987 14.9141 1.71309 14.568 2.0592L5.15791 11.4693Z"
      fill="#B2B2B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09963 3.60072H1.91991C0.859574 3.60072 0 4.40657 0 5.40063V16.2001C0 17.1942 0.859574 18.0001 1.91991 18.0001H12.4794C13.5398 18.0001 14.3993 17.1942 14.3993 16.2001V9.90043H13.2474V16.2001C13.2474 16.5978 12.9036 16.9201 12.4794 16.9201H1.91991C1.49578 16.9201 1.15195 16.5978 1.15195 16.2001V5.40063C1.15195 5.00301 1.49578 4.68067 1.91991 4.68067H8.09963V3.60072Z"
      fill="#B2B2B3"
    />
  </svg>
);

export default EditIcon;
