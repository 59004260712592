import { Card, Typo } from "@cochlearai/ui";
import { FC, useMemo } from "react";
import { Project, ProjectType } from "~/client/types/project";

import { Link } from "react-router-dom";
import { PRODUCT_TYPE_UI_LABELS } from "~/client/lib/constants";
import { SharedUserProfile } from "~/client/components";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { refineUserName } from "~/client/lib/helpers";
import styled from "styled-components";

const CardContainer = styled(Card)`
  width: 100%;
  height: 220px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
`;

const MarkWithTitle = styled.div`
  display: flex;
  align-items: center;
`;

const CardTitleText = styled(Typo)`
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${(p) => p.theme.colors.black};
`;

const CreatedDateLabel = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[50]};
  margin-bottom: 2px;
`;

const CreatedDateText = styled(Typo)`
  text-align: left;
  color: ${(p) => p.theme.colors.grey[80]};
`;

const ProductTypeText = styled(Typo)<{ type?: ProjectType }>`
  margin-right: 18px;
  margin-bottom: 2px;
  color: ${(p) =>
    p.type
      ? p.theme.colors.product_type_color[p.type]
      : p.theme.colors.black} !important;
`;

interface Props {
  data?: Project;
  onClickItem?: () => void;
  sharedOrgColor?: string;
}

const ProjectItem: FC<Props> = ({ data, onClickItem, sharedOrgColor }) => {
  const ownerName = refineUserName({
    givenName: data?.owner?.given_name,
    familyName: data?.owner?.name,
  });

  const projectItemLink = useMemo(() => {
    const projectDetailUrl = `/project/${data?.id}`;
    if (sharedOrgColor) return projectDetailUrl + "/analytics";
    if (data?.product_type === ProjectType.CLOUD_API)
      return projectDetailUrl + "/upload";

    return projectDetailUrl;
  }, [data?.id, data?.product_type, sharedOrgColor]);

  return (
    <Link to={projectItemLink} onClick={onClickItem}>
      <CardContainer hoverEffect>
        <div>
          <ProductTypeText
            data-testid="project-item-type"
            variant="caption1"
            type={data?.product_type}
          >
            {data ? PRODUCT_TYPE_UI_LABELS[data.product_type] : <Skeleton />}
          </ProductTypeText>
          {data ? (
            <MarkWithTitle>
              <CardTitleText data-testid="project-item-name" variant="h3">
                {data?.name}
              </CardTitleText>
            </MarkWithTitle>
          ) : (
            <Skeleton />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <CreatedDateLabel variant="caption1">Created on</CreatedDateLabel>
            <CreatedDateText data-testid="project-item-date" variant="caption1">
              {data ? (
                moment(new Date(data.created_at)).format("MMM DD, YYYY")
              ) : (
                <Skeleton />
              )}
            </CreatedDateText>
          </div>
          {sharedOrgColor && (
            <SharedUserProfile
              text={ownerName.length > 0 ? ownerName[0] : ""}
              color={sharedOrgColor}
            />
          )}
        </div>
      </CardContainer>
    </Link>
  );
};

export default ProjectItem;
