import { CircleProgress, Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

const Content = styled.div`
  display: flex;
  width: auto;
  min-width: 130px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

interface Props {
  percent?: number;
  title?: string;
  description?: string;
}

const Processing: FC<Props> = ({ percent = 1, title, description }) => {
  const { colors } = useTheme();

  return (
    <>
      <CircleProgress
        percent={percent}
        style={{ width: "50px", height: "50px", marginRight: "20px" }}
      />
      <Content>
        <Typo variant="body">{title}</Typo>
        <Typo
          variant="caption1"
          style={{ color: colors.grey[70], marginTop: "4px" }}
        >
          {description}
        </Typo>
      </Content>
    </>
  );
};

export default Processing;
