import { media } from "@cochlearai/util";
import { CSSProperties, PropsWithChildren } from "react";

import styled from "styled-components";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: ${media.size.lg};
  padding-left: unset;
  padding-right: unset;
  margin-top: 60px;
  margin-bottom: 60px;

  ${media.query.xl} {
    width: ${media.size.lg};
    padding-left: unset;
    padding-right: unset;
  }

  ${media.query.lg} {
    width: calc(100vw);
    padding-left: 60px;
    padding-right: 60px;
  }

  ${media.query.md} {
    width: calc(100vw);
    padding-left: 25px;
    padding-right: 25px;
  }

  ${media.query.sm} {
    width: calc(100vw);
    padding-left: 25px;
    padding-right: 25px;
  }
`;

interface Props {
  style?: CSSProperties;
}

const ContentLayout = ({ children, style }: PropsWithChildren<Props>) => {
  return <Container style={style}>{children}</Container>;
};

export default ContentLayout;
