import {
  APICallPayloads,
  FetchCreateVoiceTagParams,
  FetchCreateVoiceTagResponse,
  FetchDeleteVoiceParams,
  FetchDeleteVoiceResponse,
  FetchGetVoiceDetailParams,
  FetchGetVoiceDetailResponse,
  FetchGetVoiceFileParams,
  FetchGetVoiceFileResponse,
  FetchGetVoiceListParams,
  FetchGetVoiceListResponse,
  FetchRecognizeSpeakerParams,
  FetchRecognizeSpeakerResponse,
} from "~/client/types/api";

import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_SR_URL: speakerRecognitionURL } = getConfigs();

const API_URL_PREFIX = speakerRecognitionURL ? `${speakerRecognitionURL}` : "";

export const fetchGetVoiceList = ({
  params,
  config,
}: APICallPayloads<FetchGetVoiceListParams>): Promise<FetchGetVoiceListResponse> => {
  const url = `${API_URL_PREFIX}/speakers`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchDeleteVoice = ({
  params,
  config,
}: APICallPayloads<FetchDeleteVoiceParams>): Promise<FetchDeleteVoiceResponse> => {
  const { speaker } = params;
  const url = `${API_URL_PREFIX}/speaker?speaker=${speaker}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

export const fetchGetVoiceDetail = ({
  params,
  config,
}: APICallPayloads<FetchGetVoiceDetailParams>): Promise<FetchGetVoiceDetailResponse> => {
  const { speaker } = params;
  const url = `${API_URL_PREFIX}/speaker/voices?speaker=${speaker}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetVoiceFile = ({
  params,
  config,
}: APICallPayloads<FetchGetVoiceFileParams>): Promise<FetchGetVoiceFileResponse> => {
  const { speaker, file_name } = params;
  const url = `${API_URL_PREFIX}/speaker/voice?speaker=${speaker}&filename=${file_name}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchCreateVoiceTag = ({
  params,
  config,
}: APICallPayloads<FetchCreateVoiceTagParams>): Promise<FetchCreateVoiceTagResponse> => {
  const url = `${API_URL_PREFIX}/speaker`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchRecognizeSpeaker = ({
  params,
  config,
}: APICallPayloads<FetchRecognizeSpeakerParams>): Promise<FetchRecognizeSpeakerResponse> => {
  const url = `${API_URL_PREFIX}/speaker/recognition`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};
