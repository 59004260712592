import { PAGE_ROUTE_PATH_NAME } from "~/client/types/pageRoute";

function convertProjectDetailPath(
  path: PAGE_ROUTE_PATH_NAME | string,
  projectId: string,
): string {
  switch (path) {
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL:
      return `/project/${projectId}`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SENSE_SDK:
      return `/project/${projectId}/sdk`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS:
      return `/project/${projectId}/analytics`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS_OLD:
      return `/project/${projectId}/previous_analytics`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS:
      return `/project/${projectId}/settings`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_UPLOAD:
      return `/project/${projectId}/upload`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_POST_ACTION:
      return `/project/${projectId}/post_action`;
    case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SPEAKER_RECOGNITION:
      return `/project/${projectId}/speaker_recognition`;
    default:
      return path;
  }
}

export default convertProjectDetailPath;
