import React, { FC, SVGProps } from "react";

const ArrowLeftIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 -2 12 12">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.908 8 0 4l2.908-4 .89.743L1.87 3.395H12v1.21H1.87l1.928 2.652-.89.743Z"
      fill={props.fill || "#8C8C8C"}
    />
  </svg>
);

export default ArrowLeftIcon;
