import React, { FC, useMemo } from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";
import { HEATMAP_COLOR, Z_INDEXES } from "~/client/lib/constants";
import { TagFrequency } from "~/client/types/analytics";

const ChartContainer = styled(Chart)`
  .tooltip-heatmap {
    padding: 16px;
    text-align: center;
    font-size: 10px;
  }
  .tooltip-p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 18px;
    margin: 8px 0;
    font-size: 12px;
  }
  .tooltip-p:last-child {
    font-weight: bold;
  }
  .apexcharts-title-text {
    display: none;
  }
  .apexcharts-text {
    fill: ${(p) => p.theme.colors.black};
  }
  .apexcharts-tooltip {
    z-index: ${Z_INDEXES.POP_OVER};
  }
`;

type TooltipFormatter = {
  series: string[];
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    globals: {
      labels: string[];
      seriesNames: string[];
    };
  };
};

interface Props {
  data: TagFrequency[];
  title?: string;
  columns: string[];
  highestValue?: number;
  tooltipFormatter?: (tooltipProps: TooltipFormatter) => string;
  onClickHeatmapEvent?: (
    event: unknown,
    chartContext: unknown,
    config: unknown,
  ) => void;
}

const Heatmap: FC<Props> = ({
  data,
  title,
  columns,
  highestValue = 0,
  tooltipFormatter,
  onClickHeatmapEvent,
}) => {
  const colorRange = useMemo(() => {
    const colors = HEATMAP_COLOR.slice(
      highestValue <= 5 ? 3 : highestValue <= 10 ? 2 : 0,
    );
    const numberToDivide = highestValue <= 5 ? 1 : highestValue <= 10 ? 2 : 4;
    return colors.map((color, i) => {
      const dividedNumber = Math.round(highestValue / numberToDivide);
      const from = dividedNumber * i + 1;
      const to =
        i === colors.length - 1 ? highestValue : dividedNumber * (i + 1);
      return {
        from,
        to,
        color,
      };
    });
  }, [highestValue]);
  return (
    <ChartContainer
      type="heatmap"
      height="400"
      width="100%"
      series={data}
      options={{
        dataLabels: {
          enabled: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
          selection: {
            enabled: false,
          },
          events: {
            dataPointSelection: onClickHeatmapEvent,
          },
        },
        stroke: {
          width: 1,
        },
        title: {
          text: title, // to update the tooltip value
        },
        xaxis: {
          type: "category",
          categories: columns,
        },
        yaxis: {
          reversed: true,
        },
        tooltip: {
          theme: "dark",
          custom: tooltipFormatter,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          heatmap: {
            useFillColorAsStroke: false,
            distributed: true,
            enableShades: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: "#E5E5E5",
                },
                ...(highestValue > 0 ? colorRange : []),
              ],
            },
          },
        },
      }}
    />
  );
};

export default Heatmap;
