import {
  useAPIErrorToast as useAPIErrorNotification,
  useAppSelector,
} from "~/client/hooks";

import { APIError } from "~/client/types";

function useAPIError(): {
  errorToast: {
    shouldShowErrorToast: boolean;
    hideErrorToast: () => void;
    errorToastMessage: string;
  };
  serverError: APIError<unknown> | null;
} {
  const serverError = useAppSelector((state) => state.alert.serverError);

  const { shouldShowErrorToast, hideErrorToast, errorToastMessage } =
    useAPIErrorNotification({
      statusCode:
        serverError && serverError.response?.status
          ? serverError.response.status
          : undefined,
      errorMsg:
        serverError && serverError.response?.msg
          ? serverError.response.msg
          : undefined,
    });

  return {
    errorToast: {
      shouldShowErrorToast,
      hideErrorToast,
      errorToastMessage,
    },
    serverError,
  };
}

export default useAPIError;
