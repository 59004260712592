import {
  APICallPayloads,
  FetchGetUserParams,
  FetchGetUserResponse,
} from "~/client/types/api";

import { axiosClient } from "~/client/lib";
import getConfigs from "~/client/lib/getConfigs";

const { REACT_APP_REMOTE_API_BASE_URL: remoteAPIBaseURL } = getConfigs();

export const fetchGetUser = ({
  config,
}: APICallPayloads<FetchGetUserParams>): Promise<FetchGetUserResponse> => {
  // https://api.beta.cochl.ai/kratos/sessions/whoami
  const url = remoteAPIBaseURL
    ? `${remoteAPIBaseURL}/kratos/sessions/whoami`
    : "/kratos/sessions/whoami";
  return axiosClient.get(url, config);
};
