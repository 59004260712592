import { color } from "@cochlearai/util";
import { PropsWithChildren } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";

const Container = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(p) =>
      color.convertHexToRGB({ hex: p.theme.colors.black, alpha: 0.4 })};
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) =>
      color.convertHexToRGB({ hex: p.theme.colors.black, alpha: 0.6 })};
  }
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: ${(p) =>
      color.convertHexToRGB({ hex: p.theme.colors.black, alpha: 0.2 })};
  }
`;

export interface ScrollProps extends BaseStyleProps {}

/**
 * React component used to create a scrollable container.
 * @param style - Style of the container.
 * @param className - Class name of the container.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/scroll--default
 */
export const Scroll = ({
  className,
  style,
  children,
}: PropsWithChildren<ScrollProps>) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  );
};
