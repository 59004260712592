import { ChangeEventHandler } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";
import { Typo } from "../Typo";

const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  margin: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Check = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #e5e5e6;
  border-radius: 2px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  & ${CheckBox}:checked ~ ${Check} {
    background-color: #4b68ff;
    border: none;
  }

  & ${CheckBox}:checked ~ ${Check}:after {
    display: block;
  }

  & ${Check}:after {
    left: 6px;
    top: 3px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(35deg);
  }
`;

const Label = styled.label`
  gap: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export interface CheckBoxProps extends BaseStyleProps {
  selected: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

/**
 * React component used to create a checkbox with an optional label.
 * @param selected   - The selected state of the checkbox.
 * @param label      - The label of the checkbox.
 * @param className  - The className of the checkbox.
 * @param style      - The style of the checkbox.
 * @param onChange   - The onChange handler of the checkbox.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/checkbox--default
 */
export const Checkbox = ({
  selected,
  label,
  className,
  style,
  onChange,
}: CheckBoxProps) => {
  return (
    <Label>
      <CheckBoxWrapper className={className} style={style}>
        <CheckBox
          type="checkbox"
          data-testid="@cochl-ui/checkbox"
          checked={selected}
          onChange={onChange}
          readOnly={onChange ? false : true}
        />
        <Check />
      </CheckBoxWrapper>
      {label && <Typo variant="body">{label}</Typo>}
    </Label>
  );
};
