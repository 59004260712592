import { useCallback, useState } from "react";
import { setClientInfo, setServerError } from "~/client/store/modules";

import { useSWRConfig } from "swr";
import { fetchUpdatePaymentMethodById } from "~/client/api";
import { useAppDispatch } from "~/client/hooks";
import { APIError } from "~/client/types";
import { SWR_KEY } from "~/client/types/swrKey";

function useUpdatePaymentMethod() {
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const [isUpdating, setisUpdating] = useState<boolean>(false);
  const updatePaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      if (isUpdating) return;
      setisUpdating(true);
      try {
        await fetchUpdatePaymentMethodById({
          params: {
            id: paymentMethodId,
          },
        });
        mutate(SWR_KEY.PAYMENT_METHOD_INITIALIZE_LIST);
        dispatch(
          setClientInfo({
            text: "Default card modified",
          }),
        );
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setisUpdating(false);
      }
    },
    [dispatch, isUpdating, mutate],
  );

  return {
    updatePaymentMethod,
    isValidating: isUpdating,
  };
}

export default useUpdatePaymentMethod;
