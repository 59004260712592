import React, { FC, SVGProps } from "react";

const MasterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8859 1.75873H8.09875V12.2414H13.8859V1.75873Z"
      fill="#FF5F00"
    />
    <path
      d="M8.4662 7.00003C8.46529 5.99047 8.69225 4.99394 9.1299 4.08587C9.56756 3.1778 10.2044 2.382 10.9923 1.75871C10.0166 0.985679 8.84487 0.504944 7.61094 0.371449C6.37702 0.237954 5.13074 0.457082 4.01453 1.00379C2.89832 1.55051 1.95723 2.40275 1.29882 3.46309C0.640413 4.52344 0.29126 5.74912 0.29126 7.00003C0.29126 8.25094 0.640413 9.47662 1.29882 10.537C1.95723 11.5973 2.89832 12.4496 4.01453 12.9963C5.13074 13.543 6.37702 13.7621 7.61094 13.6286C8.84487 13.4951 10.0166 13.0144 10.9923 12.2414C10.2044 11.6181 9.56757 10.8222 9.12991 9.91418C8.69226 9.00611 8.4653 8.00958 8.4662 7.00003Z"
      fill="#EB001B"
    />
    <path
      d="M21.693 7.00003C21.6931 8.25092 21.344 9.47659 20.6856 10.5369C20.0272 11.5973 19.0862 12.4495 17.97 12.9962C16.8538 13.543 15.6075 13.7621 14.3736 13.6286C13.1397 13.4951 11.968 13.0144 10.9923 12.2414C11.7795 11.6174 12.4159 10.8215 12.8535 9.91357C13.291 9.00564 13.5184 8.00942 13.5184 7.00003C13.5184 5.99063 13.291 4.99441 12.8535 4.08649C12.4159 3.17856 11.7795 2.38263 10.9923 1.75871C11.968 0.985676 13.1397 0.50494 14.3736 0.371447C15.6075 0.237954 16.8538 0.457091 17.97 1.00381C19.0862 1.55053 20.0272 2.40277 20.6856 3.46312C21.344 4.52346 21.6931 5.74913 21.693 7.00003Z"
      fill="#F79E1B"
    />
    <path
      d="M21.0621 11.1311V10.9165H21.1479V10.8727H20.9293V10.9165H21.0151V11.1311H21.0621ZM21.4865 11.1311V10.8723H21.4195L21.3424 11.0503L21.2653 10.8723H21.1983V11.1311H21.2456V10.9359L21.3179 11.1042H21.367L21.4392 10.9355V11.1311H21.4865Z"
      fill="#F79E1B"
    />
  </svg>
);

export default MasterIcon;
