import * as Converters from "./convertSenseValueToUILabel";

import { SenseTags } from "~/client/types/sense";
import { FilterParamsWithCheckedOld } from "~/client/types/analytics";

function getOfficialSenseValue({
  value,
  type,
}: {
  value: string;
  type: keyof FilterParamsWithCheckedOld;
}) {
  let filteredValue: string | undefined = value;

  switch (type) {
    case "tag":
      filteredValue = Converters.convertSenseTagValueToUILabel(
        value as SenseTags,
      );
      break;
    default:
      break;
  }
  return filteredValue;
}

export default getOfficialSenseValue;
