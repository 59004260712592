import { useEffect, useState } from "react";
import useSWR from "swr";
import { SDKQuota, APIError } from "~/client/types";
import { fetchGetSDKQuota } from "~/client/api";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";

function useSdkQuota() {
  const dispatch = useAppDispatch();
  const [sdkQuota, setSdkQuota] = useState<SDKQuota | null>(null);

  const { data, error } = useSWR(
    SWR_KEY.SENSE_SDK_INITIALIZE_SDK_QUOTA,
    async () => {
      const res = await fetchGetSDKQuota({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setSdkQuota(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    sdkQuota,
  };
}

export default useSdkQuota;
