import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Card,
  ProgressIndicator,
  Typo,
} from "@cochlearai/ui";
import { FC, useCallback } from "react";
import styled, { useTheme } from "styled-components";

import { media } from "@cochlearai/util";
import moment from "moment";
import {
  DetailFilterButton,
  FilterButton,
  Heatmap,
  HeatmapLegend,
} from "~/client/components";
import {
  useAnalyticsHeatmap,
  useAppDispatch,
  useAppSelector,
  useCurrentProject,
} from "~/client/hooks";
import {
  convertSenseTagValueToUILabel,
  selectedAnalyticsState,
} from "~/client/lib";
import { updateFilterParams } from "~/client/store/modules";
import { ProjectType, SenseTags, SidebarType } from "~/client/types";
import { AnalyticsType, FilterType } from "~/client/types/analytics";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CardItem = styled(Card)`
  width: 100%;
  padding: 30px;
`;

const TopArea = styled(Flex)`
  justify-content: space-between;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const FilterArea = styled(Flex)`
  gap: 10px;

  ${media.query.md} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const IconWrapper = styled.div<{ disabled: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  cursor: ${(p) => (p.disabled ? "unset" : "pointer")};
`;

const Loading = styled(ProgressIndicator)`
  display: flex;
  justify-content: center;
  height: 400px;
  align-items: center;
`;

const HEATMAP_TOOLTIP_MAX_TAG = 3;

interface Props {
  openSidebar: (type: SidebarType, filterType?: AnalyticsType) => void;
}

const TagFrequencyHeatmap: FC<Props> = ({ openSidebar }) => {
  const dispatch = useAppDispatch();
  const { colors } = useTheme();
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const { currentProject } = useCurrentProject();
  const {
    tagFrequency,
    tags,
    highestValue,
    updateSelectedEvent,
    isValidating,
  } = useAnalyticsHeatmap();
  const tagFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY][FilterType.TAG]
    )?.filter((filter) => filter.checked) ?? [];
  const deviceFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY][FilterType.DEVICE]
    )?.filter((filter) => filter.checked) ?? [];
  const scale = (
    filterParamsWithChecked &&
    filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY][FilterType.SCALE]
  )?.find((filter) => filter.checked)?.value;

  const getOriginalEventInfo = useCallback(
    (yAxis, xAxis) => {
      if (!tagFrequency) return { date: "", tagCount: [] };
      const {
        data: { lines },
      } = tagFrequency;
      const lineInfo = lines?.find((item) => item.line_name === yAxis);
      const columnInfo = lineInfo?.columns.find(
        (item) => item.column_name === xAxis,
      );
      const date = columnInfo?.date ?? "";
      const tagCount =
        (columnInfo?.counts &&
          Object.entries(columnInfo.counts)
            .map(([key, value]) => ({
              tag: convertSenseTagValueToUILabel(key as SenseTags),
              value,
            }))
            .sort((a, b) => b.value - a.value)) ??
        [];
      return {
        date,
        tagCount,
      };
    },
    [tagFrequency],
  );

  const HeatmapTooltipFormatter = useCallback(
    ({ series, seriesIndex, dataPointIndex, w }) => {
      const { date, tagCount } = getOriginalEventInfo(
        w.globals.seriesNames[seriesIndex],
        w.globals.labels[dataPointIndex],
      );
      const tagCountHTML = tagCount
        ?.slice(0, HEATMAP_TOOLTIP_MAX_TAG)
        .map(
          (tagInfo) =>
            `<div class="tooltip-p"><span>${tagInfo.tag}</span><span>${tagInfo.value}</span></div>`,
        )
        .join("");
      const moreTagsText =
        tagCount.length > HEATMAP_TOOLTIP_MAX_TAG
          ? `<div class="tooltip-p">+${tagCount.length - 3} more tags</div>`
          : "";
      return `<div class="tooltip-heatmap">${moment(date).format(
        scale === "hour"
          ? "YYYY/MM/DD HH:mm:ss"
          : scale === "day"
            ? "YYYY/MM/DD, dddd"
            : "MMMM YYYY",
      )}${tagCountHTML}${moreTagsText}<div class="tooltip-p"><span>Total</span><span>${
        series[seriesIndex][dataPointIndex]
      }</span></div></div>`;
    },
    [getOriginalEventInfo, scale],
  );

  const onClickHeatmapEvent = useCallback(
    (event, chartContext, config) => {
      event.stopPropagation();
      const { date, tagCount } = getOriginalEventInfo(
        chartContext.w.globals.seriesNames[config.seriesIndex],
        chartContext.w.globals.labels[config.dataPointIndex],
      );
      updateSelectedEvent({
        date,
        platform: currentProject?.product_type || ProjectType.CLOUD_API,
        tagCount,
      });
      openSidebar(SidebarType.DETAIL_EVENT, AnalyticsType.TAG_FREQUENCY);
    },
    [
      currentProject?.product_type,
      getOriginalEventInfo,
      openSidebar,
      updateSelectedEvent,
    ],
  );

  const onClickDateArrow = useCallback(
    (type: "prev" | "next") => {
      if (!filterParamsWithChecked || !tagFrequency || !tagFrequency[type])
        return;
      dispatch(
        updateFilterParams({
          type: AnalyticsType.TAG_FREQUENCY,
          value: {
            ...filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY],
            [FilterType.FROM_TO]: {
              ...filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY][
                FilterType.FROM_TO
              ],
              ...tagFrequency[type],
            },
          },
        }),
      );
    },
    [dispatch, filterParamsWithChecked, tagFrequency],
  );

  return (
    <CardItem>
      <TopArea>
        <Typo variant="h3">Tag Frequency</Typo>
        <FilterArea>
          <Flex style={{ gap: "4px" }}>
            <IconWrapper
              onClick={() => onClickDateArrow("prev")}
              disabled={!tagFrequency?.prev}
            >
              <ArrowLeftIcon width={12} height={12} fill={colors.black} />
            </IconWrapper>
            <Typo variant="caption1" style={{ color: colors.grey[80] }}>
              {tagFrequency?.current_page}
            </Typo>
            <IconWrapper
              onClick={() => onClickDateArrow("next")}
              disabled={!tagFrequency?.next}
            >
              <ArrowRightIcon
                width={12}
                height={12}
                fill={!tagFrequency?.next ? colors.grey[40] : colors.black}
              />
            </IconWrapper>
          </Flex>
          {tagFilters.length > 0 && (
            <DetailFilterButton text="Tags" type="string" data={tagFilters} />
          )}
          {deviceFilters.length > 0 && (
            <DetailFilterButton
              text="Devices"
              type="string"
              data={deviceFilters}
            />
          )}
          <FilterButton
            onClick={() =>
              openSidebar(SidebarType.FILTER, AnalyticsType.TAG_FREQUENCY)
            }
            text="Filter"
            selected={tagFilters.length > 0}
          />
        </FilterArea>
      </TopArea>
      {isValidating ? (
        <Loading size="medium" />
      ) : (
        <Heatmap
          data={tags}
          title={tagFrequency?.current_page}
          columns={tagFrequency?.legend.columns ?? []}
          highestValue={highestValue}
          tooltipFormatter={HeatmapTooltipFormatter}
          onClickHeatmapEvent={onClickHeatmapEvent}
        />
      )}
      <HeatmapLegend highestValue={highestValue} />
    </CardItem>
  );
};

export default TagFrequencyHeatmap;
