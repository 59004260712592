import {
  Popover as ReactTinyPopover,
  PopoverProps as ReactTinyPropoverProps,
} from "react-tiny-popover";

import { useTheme } from "styled-components";

export interface PopoverProps extends ReactTinyPropoverProps {}

/**
 * React component used to create a popover.
 * @param containerStyle - CSS style of the popover container.
 *
 * rest props are forwarded to the react-tiny-popover component.
 *
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/popover--default
 */
export const Popover = ({
  children,
  containerStyle,
  ...popoverProps
}: PopoverProps) => {
  const { colors } = useTheme();
  return (
    <ReactTinyPopover
      containerStyle={{
        backgroundColor: colors.white,
        boxShadow: "0px 0px 5px rgba(152, 152, 152, 0.3)",
        borderRadius: "4px",
        ...containerStyle,
      }}
      {...popoverProps}
    >
      {children}
    </ReactTinyPopover>
  );
};
