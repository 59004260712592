import { FC, ReactNode } from "react";
import {
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
} from "@cochlearai/ui";
import {
  WEBHOOK_HISTORY_TABLE_COLUMN_TYPE,
  WebhookHistory,
} from "~/client/types/postAction";
import styled, { useTheme } from "styled-components";

import { SenseServices } from "~/client/types";
import { WEBHOOK_HISTORY_TABLE_COLUMN_ITEMS } from "~/client/lib/constants";
import { getWebhookHistoryTableColumnWidth } from "~/client/lib/helpers";
import moment from "moment";

const Container = styled(Table)`
  width: 100%;
`;

const TableHeadItem = styled(TableCell)<{
  columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getWebhookHistoryTableColumnWidth(p.columnType)};
  padding: 11px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
  vertical-align: middle;
`;

const HeaderItemTypo = styled(Typo)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: left;
`;

const TableRowContainer = styled(TableRow)`
  user-select: none;
  background-color: ${(p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE;
}>`
  max-width: ${(p) => getWebhookHistoryTableColumnWidth(p.columnType)};
  padding: 20px;
  vertical-align: middle;
  word-break: break-all;
`;

const TableBodyItemText = styled(Typo)`
  width: 100%;
`;

interface Props {
  data: WebhookHistory[];
  EmptyComponent?: ReactNode;
  onClickViewMsg?: (item: WebhookHistory) => void;
}

const WebhookHistoryTable: FC<Props> = ({
  data,
  EmptyComponent,
  onClickViewMsg,
}) => {
  const { colors } = useTheme();
  return (
    <Scroll style={{ maxHeight: "800px" }}>
      <Container>
        <TableHead>
          <TableRow>
            {WEBHOOK_HISTORY_TABLE_COLUMN_ITEMS.map((item) => {
              return (
                <TableHeadItem
                  key={item.columnType}
                  component="th"
                  columnType={item.columnType}
                >
                  <HeaderItemTypo variant="button">{item.label}</HeaderItemTypo>
                </TableHeadItem>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            const isError = item.result >= 400;
            return (
              <TableRowContainer key={i}>
                <TableBodyItem
                  columnType={WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.DATE}
                >
                  <TableBodyItemText variant="body">
                    {moment(new Date(item.created_at))
                      .format("MM.DD.YYYY HH:mm:ss")
                      .toString()}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.HTTP_ADDRESS}
                >
                  <TableBodyItemText variant="body">
                    {item.url}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.RESPONSE_CODE}
                >
                  <TableBodyItemText variant="body">
                    {item.response_status_code}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.HTTP_ADDRESS}
                >
                  <TableBodyItemText
                    variant="body"
                    style={{
                      color: isError
                        ? colors.red
                        : colors.service_color[SenseServices.HUMAN_INTERACTION],
                    }}
                  >
                    {isError ? "Fail" : "Success"}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.HTTP_ADDRESS}
                >
                  <TableBodyItemText
                    variant="body"
                    onClick={() => onClickViewMsg && onClickViewMsg(item)}
                    style={{
                      color: colors.blue[60],
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    View message
                  </TableBodyItemText>
                </TableBodyItem>
              </TableRowContainer>
            );
          })}
        </TableBody>
      </Container>
      {data.length === 0 && EmptyComponent}
    </Scroll>
  );
};

export default WebhookHistoryTable;
