import React, { FC } from "react";
import { Scroll, Typo } from "@cochlearai/ui";
import { SenseTag, SenseTags } from "~/client/types";
import { convertSenseTagValueToUILabel, timeToString } from "~/client/lib";
import styled, { useTheme } from "styled-components";

import { color } from "@cochlearai/util";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogHeader = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: start;
  padding: 10px 0;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey[20]};
  color: ${(p) =>
    p.disabled ? p.theme.colors.grey[50] : p.theme.colors.black};
`;

const LogContent = styled(Scroll)`
  width: 100%;
  height: 380px;
`;

const LogTextSection = styled(Flex)`
  flex-direction: column;
  align-items: start;
`;

const TagCountBadge = styled(Typo)<{ selected?: boolean }>`
  border-radius: 100px;
  background: ${(p) =>
    p.selected
      ? color.convertHexToRGB({
          hex: p.theme.colors.blue[60],
          alpha: 0.05,
        })
      : p.theme.colors.grey[10]};
  color: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : p.theme.colors.black};
  height: 24px;
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

const Accordion = styled.div`
  background: ${(p) => p.theme.colors.grey[10]};
  padding: 10px 0;
  padding-left: 100px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey[20]};
`;

const TagName = styled(Typo)`
  text-overflow: wrap;
`;

interface Props {
  headerText: string[];
  descriptionText: string[];
  result?: {
    start_time: number;
    end_time: number;
    tags: (string | SenseTag)[];
    transcript?: string;
  }[];
  onClick: (time: number) => void;
  selectedTime?: number;
}

const LogTable: FC<Props> = ({
  headerText,
  descriptionText,
  result,
  onClick,
  selectedTime,
}) => {
  const { colors } = useTheme();

  return (
    <div style={{ width: "100%" }}>
      <LogHeader>
        <div style={{ width: "100px" }}>
          <Typo variant="caption2">{headerText[0]}</Typo>
        </div>
        <Typo variant="caption2">{headerText[1]}</Typo>
      </LogHeader>
      <LogHeader>
        <div style={{ width: "100px" }}>
          <Typo variant="caption2" style={{ color: colors.grey[60] }}>
            {descriptionText[0]}
          </Typo>
        </div>
        <Typo variant="caption2" style={{ color: colors.grey[60] }}>
          {descriptionText[1]}
        </Typo>
      </LogHeader>
      <LogContent>
        {result?.map((log, i) => {
          const tagCount = log.tags.length;
          const isSelected = selectedTime === log.start_time;
          const name =
            typeof log.tags[0] === "string" ? log.tags[0] : log.tags[0].name;
          return (
            <React.Fragment key={i}>
              <LogHeader
                style={{ cursor: "pointer" }}
                disabled={name === SenseTags.OTHERS}
                onClick={() => onClick(log.start_time)}
              >
                <Typo variant="body" style={{ minWidth: "100px" }}>
                  {timeToString(log.start_time)}
                </Typo>
                <LogTextSection>
                  <Flex>
                    <TagName variant="body">
                      {convertSenseTagValueToUILabel(name)}
                    </TagName>
                    {tagCount > 1 && (
                      <TagCountBadge variant="caption1" selected={isSelected}>
                        +{tagCount - 1}
                      </TagCountBadge>
                    )}
                  </Flex>
                  {log.transcript && (
                    <TagName variant="body">{log.transcript}</TagName>
                  )}
                </LogTextSection>
              </LogHeader>
              {isSelected && tagCount > 1 && (
                <Accordion>
                  {log.tags.slice(1, tagCount).map((tag, i) => (
                    <Typo
                      key={i}
                      variant="caption1"
                      style={{ marginBottom: "4px" }}
                    >
                      {typeof tag === "string" ? tag : tag.name}
                    </Typo>
                  ))}
                </Accordion>
              )}
            </React.Fragment>
          );
        })}
      </LogContent>
    </div>
  );
};

export default LogTable;
