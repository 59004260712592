import { useCallback, useRef, useState } from "react";
import { useAppDispatch, useOrganizationMember } from "~/client/hooks";
import {
  setClientError,
  setClientInfo,
  setServerError,
} from "~/client/store/modules";
import { APIError, APIErrorData, API_ERROR_STATUS } from "~/client/types";

import { useSWRConfig } from "swr";
import { fetchInviteToOrganization } from "~/client/api/organization";
import { EMAIL_SEND_LIMIT } from "~/client/lib/constants";
import { checkEmail } from "~/client/lib/helpers";
import { SWR_KEY } from "~/client/types/swrKey";
import useAuthenticate from "./useAuthenticate";

interface Validation {
  validating: boolean;
  validated: boolean;
  error: string;
}

const initialValidation: Validation = {
  validating: false,
  validated: false,
  error: "",
};

function useAddMember() {
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const { customer, formattedUserInfo } = useAuthenticate();
  const { memberList: currentOrganizationMember } = useOrganizationMember();
  const isAdding = useRef<boolean>(false);
  const [memberList, setMemberList] = useState<string[]>([]);
  const [validation, setValidation] = useState<Validation>(initialValidation);

  const validate = useCallback(
    (email) => {
      let result = true;
      if (!email) return false;

      if (memberList.length >= EMAIL_SEND_LIMIT) {
        setValidation({
          validating: true,
          validated: false,
          error: "You have exceeded the limit of invitations",
        });
        result = false;
      }

      if (email === formattedUserInfo?.email) {
        setValidation({
          validating: true,
          validated: false,
          error: "You cannot invite yourself",
        });
        result = false;
      }

      if (memberList.includes(email)) {
        setValidation({
          validating: true,
          validated: false,
          error: "The email is duplicated",
        });
        result = false;
      }

      if (
        currentOrganizationMember.find(
          (data) => !data.pending && data.email === email,
        )
      ) {
        setValidation({
          validating: true,
          validated: false,
          error: `You already have invited ${email}`,
        });
        result = false;
      }

      if (
        currentOrganizationMember.find(
          (data) => data.pending && data.email === email,
        )
      ) {
        setValidation({
          validating: true,
          validated: false,
          error: `You already have sent an invitation mail to ${email}`,
        });
        result = false;
      }

      if (checkEmail(email)) {
        setValidation({
          validating: false,
          validated: true,
          error: "",
        });
      } else {
        setValidation({
          validating: true,
          validated: false,
          error: "Invalid email address",
        });
        result = false;
      }

      return result;
    },
    [memberList, formattedUserInfo, currentOrganizationMember],
  );

  const addEmail = useCallback(
    (email: string) => {
      setMemberList([...memberList, ...[email]]);
    },
    [memberList],
  );

  const deleteEmail = useCallback(
    (member) => {
      setMemberList(memberList.filter((d) => d !== member));
    },
    [memberList, setMemberList],
  );

  const resetEmail = useCallback(() => {
    setMemberList([]);
  }, []);

  const invite = useCallback(
    async (email?: string) => {
      const finalMembers = email ? [...memberList, email] : memberList;
      if (isAdding.current || finalMembers.length === 0) return;

      isAdding.current = true;
      try {
        const res = await fetchInviteToOrganization({
          params: {
            id: customer!.organization_id,
            emails: finalMembers,
          },
        });
        const { data } = res;
        if (data.failed) {
          dispatch(
            setClientError({
              text: `Email send failed. Check the email address. (${data.failed.join(
                ", ",
              )})`,
            }),
          );
        } else {
          dispatch(
            setClientInfo({
              text: "Email send success.",
            }),
          );
          mutate(SWR_KEY.ORGANIZATION_INITIALIZE_MEMBER_LIST);
        }
      } catch (e) {
        const error = e as APIError<unknown>;
        if (error.response?.status === API_ERROR_STATUS.BAD_REQUEST) {
          const errorData = error.response?.data as APIErrorData;
          const message =
            typeof errorData.details === "object"
              ? errorData.details.message
              : errorData.details;

          if (errorData && message) {
            dispatch(
              setClientError({
                text: message,
              }),
            );
          } else {
            throw e;
          }
        } else {
          dispatch(setServerError(error));
        }
      } finally {
        isAdding.current = false;
      }
    },
    [customer, dispatch, memberList, mutate],
  );

  return {
    memberList,
    validation,
    invite,
    addEmail,
    deleteEmail,
    resetEmail,
    validate,
    setValidation,
    isValidating: isAdding.current,
  };
}

export default useAddMember;
