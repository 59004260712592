import { Card, Skeleton } from "@cochlearai/ui";
import { color, media } from "@cochlearai/util";

import { FC } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
  grid-gap: 24px;
  margin-top: 20px;

  ${media.query.md} {
    grid-template-columns: 1fr;
  }
`;

const CardItem = styled(Card)`
  width: 100%;
  height: 220px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  border-radius: 4px;
  padding: 30px;
`;

const Dim = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% / 2);
  background: linear-gradient(
    180deg,
    ${(p) =>
        color.convertHexToRGB({
          hex: p.theme.colors.grey[10],
          alpha: 0,
        })}
      0%,
    ${(p) => p.theme.colors.grey[10]} 69.49%
  );
`;

const CardSkeleton: FC = () => {
  return (
    <Container>
      {Array.from({ length: 8 }).map((_, index) => {
        return (
          <CardItem key={index}>
            <Skeleton width={60} height={15} />
            <Skeleton
              width={121}
              height={19}
              style={{ marginTop: "10px", marginBottom: "85px" }}
            />
            <Skeleton width={60} height={12} style={{ marginBottom: "7px" }} />
            <Skeleton width={79} height={12} />
          </CardItem>
        );
      })}
      <Dim />
    </Container>
  );
};

export default CardSkeleton;
