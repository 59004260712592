import { useCallback, useEffect, useState } from "react";

import { API_ERROR_STATUS } from "~/client/types";

function useAPIErrorToast({
  statusCode,
  errorMsg,
}: {
  statusCode?: API_ERROR_STATUS;
  errorMsg?: string;
}): {
  shouldShowErrorToast: boolean;
  hideErrorToast: () => void;
  errorToastMessage: string;
} {
  const [shouldShowErrorToast, setShouldShowErrorToast] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState("");

  const hideErrorToast = () => {
    setShouldShowErrorToast(false);
  };

  const showErrorToastMessage = (errorMessage: string) => {
    setErrorToastMessage(errorMessage);
    setShouldShowErrorToast(true);
  };

  const handleError = useCallback(
    ({
      status,
      errorMsg,
    }: {
      status: API_ERROR_STATUS | number | undefined;
      errorMsg?: string;
    }): void => {
      switch (status) {
        case API_ERROR_STATUS.BAD_REQUEST:
          showErrorToastMessage(errorMsg ?? "Bad request");
          break;
        case API_ERROR_STATUS.UNAUTHORIZED:
          showErrorToastMessage(errorMsg ?? "Unauthorized");
          break;
        case API_ERROR_STATUS.FORBIDDEN:
          showErrorToastMessage(errorMsg ?? "Forbidden");
          break;
        case API_ERROR_STATUS.INTERNAL_SERVER_ERROR:
        default:
          showErrorToastMessage(errorMsg ?? "Oops! Something went wrong :(");
          break;
      }
    },
    [],
  );

  useEffect(() => {
    if (statusCode) {
      handleError({ status: statusCode, errorMsg });
    }
  }, [statusCode, errorMsg, handleError]);

  return {
    shouldShowErrorToast,
    hideErrorToast,
    errorToastMessage,
  };
}

export default useAPIErrorToast;
