import { ProgressIndicator, Typo } from "@cochlearai/ui";
import React, { FC, Suspense, useState } from "react";
import styled, { useTheme } from "styled-components";

import AllTagButton from "./AllTagButton";
import AllTagPopover from "./AllTagPopover";
import AllTagPopoverContent from "./AllTagPopoverContent";
import LogTable from "./LogTable";
import { SenseEvent } from "~/client/types";
import { media } from "@cochlearai/util";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const TagContainer = styled(Flex)`
  flex-direction: column;
  min-width: 324px;
  height: auto;
  background-color: ${(p) => p.theme.colors.white};

  border-radius: 4px;
  padding: 20px;

  ${media.query.lg} {
    min-width: auto;
  }
`;

const Loading = styled(ProgressIndicator)`
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: center;
`;

interface Props {
  result?: SenseEvent[];
  onClick: (time: number) => void;
  selectedTime?: number;
}

const SenseLog: FC<Props> = ({ result, onClick, selectedTime }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  return (
    <Container>
      <Flex style={{ marginBottom: "13px", justifyContent: "space-between" }}>
        <Typo variant="h3">Detection log</Typo>
        <AllTagPopover
          isOpen={dropdownIsOpen}
          onClickOutside={() => setDropdownIsOpen(false)}
          content={
            <TagContainer>
              <Suspense fallback={<Loading size="medium" />}>
                <AllTagPopoverContent
                  detectedTags={result}
                  onClose={() => setDropdownIsOpen(false)}
                />
              </Suspense>
            </TagContainer>
          }
        >
          <AllTagButton onClick={() => setDropdownIsOpen(!dropdownIsOpen)} />
        </AllTagPopover>
      </Flex>
      <LogTable
        result={result}
        onClick={onClick}
        selectedTime={selectedTime}
        headerText={["Start time", "Detected tags"]}
        descriptionText={["mm:ss:ms", "Tag name"]}
      />
    </Container>
  );
};

export default SenseLog;
