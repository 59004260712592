import React, { FC, SVGProps } from "react";

const CloudAPIIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_89_435)">
      <path d="M24.1451 16.0715L23.9889 17.3013L25.2239 17.1938C25.3148 17.1859 25.4069 17.1818 25.5 17.1818C27.3529 17.1818 29 18.8483 29 21.0909C29 23.3335 27.3529 25 25.5 25H6.6C5.24419 25 4 23.7731 4 22.0727C4 20.5554 5.00375 19.3986 6.18021 19.1834L7.00803 19.0319L7.00774 19H4.35782C3.53032 19.7196 3 20.8286 3 22.0727C3 24.2417 4.61177 26 6.6 26H25.5C27.9853 26 30 23.8021 30 21.0909C30 18.6169 28.3224 16.5703 26.1404 16.2312C25.9313 16.1986 25.7174 16.1818 25.5 16.1818C25.3779 16.1818 25.2569 16.1871 25.1372 16.1975C25.1786 15.8715 25.2 15.5385 25.2 15.2L25.2 15.1905C25.1953 11.2184 22.2422 8 18.6 8C15.9318 8 13.6334 9.72729 12.5931 12.2128C12.4656 12.5174 12.357 12.8333 12.269 13.1588C12.0661 13.0878 11.8573 13.031 11.6436 12.9897C11.3697 12.9367 11.0878 12.9091 10.8 12.9091C8.84981 12.9091 7.17125 14.1778 6.42009 16H7.52063C8.19433 14.7277 9.44364 13.9091 10.8 13.9091C11.1974 13.9091 11.5794 13.9769 11.9384 14.1026L12.9535 14.4582L13.2343 13.42C13.9402 10.8102 16.1191 9 18.6 9C21.6127 9 24.2 11.6921 24.2 15.2C24.2 15.4963 24.1813 15.7872 24.1451 16.0715Z" />
      <path d="M15.05 17C15.2816 15.8589 16.2905 15 17.5 15C18.8807 15 20 16.1193 20 17.5C20 18.8807 18.8807 20 17.5 20C16.2905 20 15.2816 19.1411 15.05 18H0V17H15.05Z" />
      <path d="M13 5H1V17H0V4H14V8.96379C13.6288 9.32401 13.2933 9.7258 13 10.1623V5Z" />
    </g>
    <defs>
      <clipPath id="clip0_89_435">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CloudAPIIcon;
