import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@cochlearai/ui";
import { color, media } from "@cochlearai/util";
import { FC } from "react";

import styled from "styled-components";

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const TableContainer = styled(Table)`
  min-width: ${media.size.lg};
`;

const TableHeadItem = styled(TableCell)`
  height: 40px;
  background-color: ${(p) => p.theme.colors.grey[20]};
`;

const TableRowContainer = styled(TableRow)`
  background: ${(p) => p.theme.colors.white};

  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.grey[20]};
  }
`;

const TableItem = styled(TableCell)`
  padding: 20px;
`;

const TableItemSkeleton = styled(Skeleton)`
  height: 14px;
  width: 100%;
`;

const Dim = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% / 2);
  background: linear-gradient(
    180deg,
    ${(p) =>
        color.convertHexToRGB({
          hex: p.theme.colors.grey[10],
          alpha: 0,
        })}
      0%,
    ${(p) => p.theme.colors.grey[10]} 69.49%
  );
`;

const TableSkeleton: FC = () => {
  return (
    <Container>
      <TableContainer>
        <TableHead>
          <TableRow>
            <TableHeadItem
              component="th"
              style={{
                width: "200px",
              }}
            />
            <TableHeadItem
              component="th"
              style={{
                width: "300px",
              }}
            />
            <TableHeadItem
              component="th"
              style={{
                width: "300px",
              }}
            />
            <TableHeadItem
              component="th"
              style={{
                width: "200px",
              }}
            />
            <TableHeadItem
              component="th"
              style={{
                width: "200px",
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 6 }).map((_, index) => {
            return (
              <TableRowContainer key={index}>
                <TableItem
                  style={{
                    width: "200px",
                  }}
                >
                  <TableItemSkeleton variant="text" />
                </TableItem>
                <TableItem
                  style={{
                    width: "300px",
                  }}
                >
                  <TableItemSkeleton variant="text" />
                </TableItem>
                <TableItem
                  style={{
                    width: "300px",
                  }}
                >
                  <TableItemSkeleton variant="text" />
                </TableItem>
                <TableItem
                  style={{
                    width: "200px",
                  }}
                >
                  <TableItemSkeleton variant="text" />
                </TableItem>
                <TableItem
                  style={{
                    width: "200px",
                  }}
                >
                  <TableItemSkeleton variant="text" />
                </TableItem>
              </TableRowContainer>
            );
          })}
        </TableBody>
      </TableContainer>
      <Dim />
    </Container>
  );
};

export default TableSkeleton;
