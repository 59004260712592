import { SetupIntentResult, Stripe, StripeElements } from "@stripe/stripe-js";
import { setClientError, setServerError } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useState } from "react";

import { APIError } from "~/client/types";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { STRIPE_ERROR_CODE_MESSAGES } from "~/client/lib/constants";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchPaymentFingerprint } from "~/client/api";
import { selectedPaymentState } from "~/client/lib";
import { useSWRConfig } from "swr";

function useCreatePaymentMethod() {
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const { paymentMethodList } = useAppSelector(selectedPaymentState);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const setError = useCallback(
    (message?: string) => {
      dispatch(
        setClientError({
          text: message ?? "Please try again.",
        }),
      );
    },
    [dispatch],
  );

  const createPaymentMethod = useCallback(
    async ({
      stripe,
      elements,
      clientSecret,
    }: {
      stripe: Stripe | null;
      elements: StripeElements | null;
      clientSecret: string;
    }) => {
      if (isCreating) return;
      setIsCreating(true);
      try {
        if (!stripe || !elements) {
          setError();
          return;
        }

        const Card = elements.getElement(CardNumberElement);
        if (!Card) {
          setError();
          return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: Card,
        });
        if (!paymentMethod || error) {
          setError();
          return;
        }

        const { data } = await fetchPaymentFingerprint({
          params: {
            id: paymentMethod.id,
          },
        });

        const duplicated = paymentMethodList.find(
          (payment) => payment.card.fingerprint === data?.fingerprint,
        );

        if (duplicated) {
          setError(STRIPE_ERROR_CODE_MESSAGES.setup_intent_unexpected_state);
          return;
        }

        const setupIntentResult = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: Card,
          },
          return_url: `${window.location.href}`,
        });

        if (setupIntentResult.error) {
          setError(
            setupIntentResult.error.code
              ? STRIPE_ERROR_CODE_MESSAGES[setupIntentResult.error.code]
              : setupIntentResult.error.message,
          );
          return;
        }
        mutate(SWR_KEY.PAYMENT_METHOD_INITIALIZE_LIST);

        return setupIntentResult as SetupIntentResult;
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setIsCreating(false);
      }
    },
    [dispatch, isCreating, mutate, paymentMethodList, setError],
  );

  return {
    createPaymentMethod,
    isValidating: isCreating,
  };
}

export default useCreatePaymentMethod;
