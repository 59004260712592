import { Card, Typo } from "@cochlearai/ui";
import { FC } from "react";

import styled from "styled-components";

const Container = styled(Card)`
  box-shadow: unset;
  width: 230px;
  min-width: 230px;
  height: 96px;
  background-color: ${(p) => p.theme.colors.white};
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 30px;
  padding-right: 30px;

  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
`;

const TagText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};
`;

const CountText = styled(Typo)`
  margin-top: 2px;
`;

const Divider = styled.div`
  width: 1px;
  height: 38px;
  background: ${(p) => p.theme.colors.grey[30]};
  position: absolute;
  right: 0;
  top: calc(50% - 19px);
`;

interface Props {
  tag: string;
  formattedCount: string;
}

const AnalyticsInsightTagCard: FC<Props> = ({ tag, formattedCount }) => {
  return (
    <Container>
      <TagText variant="button">{tag}</TagText>
      <CountText variant="h2">{formattedCount}</CountText>
      <Divider />
    </Container>
  );
};

export default AnalyticsInsightTagCard;
