import { useCallback, useState } from "react";

function useMultiDropdown() {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<
    Array<{ index: number; open: boolean }>
  >([]);

  const openDropdown = useCallback(
    (index) => {
      if (dropdownIsOpen.find((item) => item.index === index)) {
        setDropdownIsOpen(
          dropdownIsOpen.map((item) =>
            item.index === index ? { index, open: !item.open } : item,
          ),
        );
      } else {
        setDropdownIsOpen([...dropdownIsOpen, { index, open: true }]);
      }
    },
    [dropdownIsOpen],
  );

  const closeDropdown = useCallback(
    (index) => {
      setDropdownIsOpen(
        dropdownIsOpen.map((item) =>
          item.index === index ? { index, open: false } : item,
        ),
      );
    },
    [dropdownIsOpen],
  );

  const isDropdownOpen = useCallback(
    (index) => {
      return dropdownIsOpen.find((item) => item.index === index)?.open ?? false;
    },
    [dropdownIsOpen],
  );

  return {
    dropdownIsOpen,
    openDropdown,
    closeDropdown,
    isDropdownOpen,
  };
}

export default useMultiDropdown;
