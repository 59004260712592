import { PropsWithChildren } from "react";
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { GlobalStyle } from "./globalStyle";

interface Props {
  theme: DefaultTheme;
}

export const BaseTheme = ({
  children,
  theme: appTheme,
}: PropsWithChildren<Props>) => {
  return (
    <StyledThemeProvider theme={appTheme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};

export default BaseTheme;
