import React, { FC, SVGProps } from "react";

const ToastCheckIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill={props.fill || "#4B68FF"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4.44378L6.43345 10L4 7.47266L4.649 6.89833L6.29201 8.60473L9.24779 4L10 4.44378Z"
      fill="white"
    />
  </svg>
);

export default ToastCheckIcon;
