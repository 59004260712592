import {
  AccessBlock,
  CardSkeleton,
  EmptyProjectItem,
  ProjectItem,
  ShowMoreButton,
} from "~/client/components";
import { GAEventAction, GAEventCategory, ProjectType } from "~/client/types";
import React, { FC, useCallback, useMemo } from "react";
import { getConfigs, sendEvent } from "~/client/lib";
import { useProjectList, useSubscription } from "~/client/hooks";

import { SHOW_MORE_LIMIT } from "~/client/lib/constants";
import { media } from "@cochlearai/util";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const { REACT_APP_OFFICIAL_WEB_URL: webSiteURL } = getConfigs();

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
  grid-gap: 24px;
  margin-top: 20px;

  ${media.query.md} {
    grid-template-columns: 1fr;
  }
`;

interface Props {
  onClickAddProjectButton: () => void;
}

const ProjectItemList: FC<Props> = ({ onClickAddProjectButton }) => {
  const { t } = useTranslation();
  const {
    filteredProjectList,
    selectedProjectTab,
    organizationOwnerList,
    visibleCount,
    changeVisibleCount,
  } = useProjectList();
  const { currentSubscription } = useSubscription();

  const list = useMemo(
    () => filteredProjectList?.slice(0, visibleCount),
    [filteredProjectList, visibleCount],
  );
  const showLoadMoreButton = useMemo(
    () => (filteredProjectList?.length || 0) > visibleCount,
    [filteredProjectList, visibleCount],
  );
  const showMoreProjects = useCallback(() => {
    changeVisibleCount(visibleCount + SHOW_MORE_LIMIT.PROJECT);
  }, [changeVisibleCount, visibleCount]);

  if (
    !filteredProjectList?.some(
      (item) => item.product_type === ProjectType.EDGE_SDK,
    ) &&
    selectedProjectTab === ProjectType.EDGE_SDK &&
    !currentSubscription.product_types.includes(selectedProjectTab)
  ) {
    return (
      <AccessBlock
        content={<CardSkeleton />}
        modal={{
          title: "Contact us for Edge SDK use",
          subtitle: "Plan for Edge SDK projects : Enterprise",
          description:
            "Supports the analysis of serverless audio feature through the application.",
          buttonLabel: "Contact us",
          onClickButton: () => {
            window.open(`${webSiteURL}/contact-us`, "_blank");
          },
        }}
      />
    );
  }

  return (
    <div>
      <Container data-testid="project-item-list">
        {list && list.length > 0 ? (
          list.map((item, index) => {
            return (
              <ProjectItem
                key={index}
                data={item}
                onClickItem={() => {
                  sendEvent({
                    category: GAEventCategory.PROJECT,
                    action: GAEventAction.CLICK_PROJECT_CARD,
                  });
                }}
                sharedOrgColor={
                  item.organization_id &&
                  organizationOwnerList.find(
                    (org) => org.organization_id === item.organization_id,
                  )?.color
                }
              />
            );
          })
        ) : (
          <EmptyProjectItem onClick={onClickAddProjectButton} />
        )}
      </Container>
      {showLoadMoreButton && (
        <ShowMoreButton
          onClick={showMoreProjects}
          label={t("Show more", { ns: "common" })}
        />
      )}
    </div>
  );
};

export default ProjectItemList;
