import { Button, RefreshIcon } from "@cochlearai/ui";
import { FC, MouseEventHandler, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

const RefreshButton = styled(Button)`
  border-color: ${(p) => p.theme.colors.grey[30]};
  width: 30px;
  padding: 8px;
  background-color: transparent;

  &:hover {
    border-color: ${(p) => p.theme.colors.grey[80]};
  }
`;

const RefreshIconWithAnimation = styled(RefreshIcon)`
  &.analyticsRefreshButtonIconRotateStart {
    animation-name: analyticsRefreshButtonIconRotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
  }

  @keyframes analyticsRefreshButtonIconRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const AnalyticsRefreshButtonWithAnimation: FC<Props> = ({
  onClick,
  className,
}) => {
  const { colors } = useTheme();
  const [refreshIconClassName, setRefreshIconClassName] = useState<
    "" | "analyticsRefreshButtonIconRotateStart"
  >("");
  const refreshIconAnimationTimeoutId: { current: NodeJS.Timeout | null } =
    useRef(null);

  const handleClickRefreshIconWithAnimation: MouseEventHandler<
    HTMLButtonElement
  > = (e) => {
    setRefreshIconClassName("analyticsRefreshButtonIconRotateStart");
    if (onClick) {
      onClick(e);
    }
  };

  useEffect(() => {
    if (refreshIconAnimationTimeoutId.current) {
      clearTimeout(refreshIconAnimationTimeoutId.current);
    }
    if (refreshIconClassName === "analyticsRefreshButtonIconRotateStart") {
      refreshIconAnimationTimeoutId.current = setTimeout(() => {
        setRefreshIconClassName("");
        if (refreshIconAnimationTimeoutId.current) {
          clearTimeout(refreshIconAnimationTimeoutId.current);
        }
      }, 950);
    }
  }, [refreshIconClassName]);

  return (
    <RefreshButton
      className={className}
      color="secondary"
      onClick={handleClickRefreshIconWithAnimation}
      rightIcon={
        <RefreshIconWithAnimation
          width={14}
          height={14}
          fill={colors.black}
          className={refreshIconClassName}
        />
      }
    />
  );
};

export default AnalyticsRefreshButtonWithAnimation;
