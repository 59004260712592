/**
 * individual media size
 */
export const size = {
  sm: "320px",
  md: "960px",
  lg: "1200px",
  xl: "1920px",
};

/**
 * css media query generator function
 */
const mediaQuery = (maxWidth: string) => `
    @media (max-width: ${maxWidth})
`;

/**
 * use this to make media query by size inside of styled-compnents
 */
export const media = {
  sm: mediaQuery(size.sm),
  md: mediaQuery(size.md),
  lg: mediaQuery(size.lg),
  xl: mediaQuery(size.xl),
};
