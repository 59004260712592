import {
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
} from "@cochlearai/ui";
import { FC, ReactNode } from "react";
import styled from "styled-components";

import { color, media } from "@cochlearai/util";
import moment from "moment";
import { ShowMoreButton } from "~/client/components";
import { INVOICE_HISTORY_TABLE_COLUMN_ITEMS } from "~/client/lib/constants";
import {
  getInvoiceHistoryTableColumnWidth,
  numberWithCommas,
} from "~/client/lib/helpers";
import {
  INVOICE_HISTORY_TABLE_COLUMN_TYPE,
  InvoiceHistory,
} from "~/client/types/invoice";

const Container = styled(Table)`
  min-width: ${media.size.md};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getInvoiceHistoryTableColumnWidth(p.columnType)};
  padding: 12.5px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
`;

const HeaderItemTypo = styled(Typo)<{ right?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: ${(p) => (p.right ? "end" : "left")};
`;

const TableRowContainer = styled(TableRow)<{ selected: boolean }>`
  cursor: pointer;
  background-color: ${(p) =>
    p.selected
      ? (p) => p.theme.colors.semantic.grey[10]
      : (p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
  &:hover {
    background-color: ${(p) => p.theme.colors.semantic.grey[10]};
  }

  &:last-child {
    border-bottom: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getInvoiceHistoryTableColumnWidth(p.columnType)};
  max-width: ${(p) => getInvoiceHistoryTableColumnWidth(p.columnType)};
  padding: 0 20px;
  height: 40px;
  vertical-align: middle;
`;

const TableBodyItemText = styled(Typo)<{ right?: boolean }>`
  text-align: ${(p) => (p.right ? "end" : "left")};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StatusBadge = styled(Typo)<{ pending: boolean }>`
  padding: 4px 10px;
  background-color: ${(p) =>
    color.convertHexToRGB({
      hex: p.pending ? p.theme.colors.red : p.theme.colors.blue[60],
      alpha: 0.1,
    })};
  color: ${(p) => (p.pending ? p.theme.colors.red : p.theme.colors.blue[60])};
  width: fit-content;
  border-radius: 100px;
`;

interface Props {
  data: InvoiceHistory[];
  EmptyComponent?: ReactNode;
  canLoadMore?: boolean;
  onClickLoadMore?: () => void;
  onClickItem: (item: InvoiceHistory) => void;
}

const InvoiceHistoryTable: FC<Props> = ({
  data,
  EmptyComponent,
  canLoadMore,
  onClickLoadMore,
  onClickItem,
}) => {
  return (
    <>
      <Scroll>
        <Container>
          <TableHead>
            <TableRow>
              {INVOICE_HISTORY_TABLE_COLUMN_ITEMS.map((item, i) => {
                return (
                  <TableHeadItem
                    key={item.columnType}
                    component="th"
                    columnType={item.columnType}
                  >
                    <HeaderItemTypo variant="body" right={i === 2}>
                      {item.label}
                    </HeaderItemTypo>
                  </TableHeadItem>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((invoice, i) => (
              <TableRowContainer
                selected={false}
                key={i}
                onClick={() => onClickItem(invoice)}
              >
                <TableBodyItem
                  columnType={INVOICE_HISTORY_TABLE_COLUMN_TYPE.DATE}
                >
                  <TableBodyItemText variant="button">
                    {invoice.date
                      ? moment(invoice.date).format("MMM DD, YYYY")
                      : ""}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={INVOICE_HISTORY_TABLE_COLUMN_TYPE.INVOICE_NUMBER}
                >
                  <TableBodyItemText variant="body">
                    {invoice.invoice_number}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={INVOICE_HISTORY_TABLE_COLUMN_TYPE.AMOUNT}
                >
                  <TableBodyItemText variant="button" right={true}>
                    {numberWithCommas({
                      value: invoice.amount,
                      decimal: 2,
                      currency: "usd",
                    })}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={INVOICE_HISTORY_TABLE_COLUMN_TYPE.STATUS}
                >
                  <StatusBadge variant="caption1" pending={!invoice.paid_date}>
                    {invoice.paid_date ? "Paid" : "Pending"}
                  </StatusBadge>
                </TableBodyItem>
              </TableRowContainer>
            ))}
          </TableBody>
        </Container>
        {data.length === 0 && EmptyComponent}
      </Scroll>
      {canLoadMore && <ShowMoreButton onClick={onClickLoadMore} />}
    </>
  );
};

export default InvoiceHistoryTable;
