import { FC, useCallback, useMemo, useState } from "react";

import { Typo } from "@cochlearai/ui";
import styled from "styled-components";
import { SHOW_MORE_LIMIT } from "~/client/lib/constants";
import { UploadHistory } from "~/client/types";
import { UploadHistoryTable } from "..";

const UploadHistoryContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${(p) => p.theme.colors.white};
  margin-top: 24px;
  border-radius: 4px;
`;

const UploadHistoryTitle = styled.div`
  padding: 30px;
  align-items: center;
  display: flex;
`;

interface Props {
  list: UploadHistory[];
  onViewClick?: (history: UploadHistory) => void;
}

const Hisotry: FC<Props> = ({ list, onViewClick }) => {
  const [visibleCount, setVisibleCount] = useState<number>(
    SHOW_MORE_LIMIT.UPLOAD_HISTORY,
  );

  const showLoadMoreButton = useMemo(
    () => list.length > visibleCount,
    [list, visibleCount],
  );

  const showMoreHistory = useCallback(() => {
    setVisibleCount((preValue) => preValue + SHOW_MORE_LIMIT.UPLOAD_HISTORY);
  }, []);

  return (
    <UploadHistoryContainer>
      <UploadHistoryTitle>
        <Typo variant="h3">Upload history</Typo>
      </UploadHistoryTitle>
      <UploadHistoryTable
        data={list.slice(0, visibleCount)}
        onClickBtn={onViewClick}
        canLoadMore={showLoadMoreButton}
        onClickLoadMore={showMoreHistory}
      />
    </UploadHistoryContainer>
  );
};

export default Hisotry;
