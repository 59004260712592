import { BaseStyleProps } from "../types/style";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const TableHeadCellContainer = styled.th`
  display: table-cell;
`;

const TableDataCellContainer = styled.td`
  display: table-cell;
`;

export interface TableCellProps extends BaseStyleProps {
  component?: "th" | "td";
  colSpan?: number;
}

/**
 * React component used to create a table cell.
 * @param style - Style of the table cell.
 * @param className - Class name of the table cell.
 * @param component - Component of the table cell. `th` or `td` is expected. Defaults to `td`.
 * @param colSpan - Colspan of the table cell.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/table--default
 */
export const TableCell = ({
  children,
  component = "td",
  colSpan,
  className,
  style,
}: PropsWithChildren<TableCellProps>) => {
  return component === "th" ? (
    <TableHeadCellContainer className={className} style={style}>
      {children}
    </TableHeadCellContainer>
  ) : (
    <TableDataCellContainer
      className={className}
      style={style}
      colSpan={colSpan}
    >
      {children}
    </TableDataCellContainer>
  );
};
