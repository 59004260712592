import styled from "styled-components";
import { components, OptionProps } from "react-select";
import { Checkbox } from "../Checkbox";
import { Typo } from "../Typo";

const OptionText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Option = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          gap: "10px",
        }}
      >
        <Checkbox selected={props.isSelected} />
        <OptionText variant="body">{props.label}</OptionText>
      </div>
    </components.Option>
  );
};
