import { ProgressIndicator } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";
import { Z_INDEXES } from "~/client/lib/constants";

const Container = styled.div<{ $dim?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(p) => (p.$dim ? "rgba(0, 0, 0, 0.3)" : "")};
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: ${Z_INDEXES.FULL_CENTER_LOADING};
`;

interface Props {
  dim?: boolean;
}
const FullCenterLoading: FC<Props> = ({ dim = true }) => {
  return (
    <Container data-testid="full-center-loading" $dim={dim}>
      <ProgressIndicator size="large" />
    </Container>
  );
};

export default FullCenterLoading;
