import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import { Scroll } from "@cochlearai/ui";
import { color } from "@cochlearai/util";
import styled from "styled-components";
import useSideBarAnimation from "~/client/hooks/useSideBarAnimation";
import { Z_INDEXES } from "~/client/lib/constants";

const Container = styled.div<{ $elementWidth: number; $topPosition: number }>`
  width: auto;

  background-color: ${(p) => p.theme.colors.white};

  position: fixed;
  top: ${(p) => `${p.$topPosition}px`};
  right: 0px;
  bottom: 0px;

  box-shadow: -2px 0px 20px
    ${(p) =>
      color.convertHexToRGB({
        hex: p.theme.colors.shadow,
        alpha: 0.2,
      })};

  z-index: ${Z_INDEXES.SIDE_BAR};

  &.slideIn {
    animation-duration: 0.5s;
    animation-name: slideIn;
  }

  &.slideOut {
    animation-duration: 0.5s;
    animation-name: slideOut;
  }

  &.visible {
    display: flex;
    flex-direction: column;
  }

  &.invisible {
    visibility: hidden;
  }

  @keyframes slideOut {
    from {
      margin-right: 0px;
    }
    to {
      margin-right: ${(p) => `-${p.$elementWidth}px`};
    }
  }

  @keyframes slideIn {
    from {
      margin-right: ${(p) => `-${p.$elementWidth}px`};
    }
    to {
      margin-right: 0px;
    }
  }
`;

interface Props {
  visible: boolean;
  onClose?: () => void;
  onAfterSlideout?: () => void;
  className?: string;
  onClickOutsideExceptionRefs?: React.RefObject<HTMLButtonElement>[];
  fixedTopPosition?: number;
}

const SideBar = ({
  children,
  onClose,
  onAfterSlideout,
  visible,
  className,
  onClickOutsideExceptionRefs,
  fixedTopPosition,
}: PropsWithChildren<Props>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [topPosition, setTopPosition] = useState<number>(
    fixedTopPosition ? fixedTopPosition : 0,
  );
  const { sideBarAnimation, sideBarVisibility, openSideBar, closeSideBar } =
    useSideBarAnimation({
      onSlideOut: onAfterSlideout,
    });

  const onClickOutsideListener = (event: MouseEvent | TouchEvent) => {
    if (!visible) {
      return;
    }
    const nodeTarget = event.target as Element;
    if (
      !containerRef.current ||
      containerRef.current.contains(nodeTarget) ||
      nodeTarget.closest("#react-tiny-popover-container")
    ) {
      return;
    }
    const modal = document.querySelector("[data-testid=modal-background]");
    if (modal && modal.contains(nodeTarget)) {
      return;
    }
    if (onClickOutsideExceptionRefs) {
      if (
        onClickOutsideExceptionRefs.find((item) =>
          item.current?.contains(nodeTarget),
        )
      ) {
        return;
      }
    }
    onClose && onClose();
  };

  useEffect(() => {
    if (visible) {
      openSideBar();
    } else {
      closeSideBar();
    }
  }, [closeSideBar, openSideBar, visible]);

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutsideListener);
    document.addEventListener("touchstart", onClickOutsideListener);
    return () => {
      document.removeEventListener("mousedown", onClickOutsideListener);
      document.removeEventListener("touchstart", onClickOutsideListener);
    };
  }, [visible]);

  useEffect(() => {
    if (!fixedTopPosition) return;
    const syncFilterSideBarPosition = () => {
      if (!visible) {
        return;
      }
      const doc = document.documentElement;
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      setTopPosition(fixedTopPosition - top > 0 ? fixedTopPosition - top : 0);
    };
    if (visible) {
      syncFilterSideBarPosition();
    }
    document.addEventListener("scroll", syncFilterSideBarPosition);
    document.addEventListener("resize", syncFilterSideBarPosition);

    const clear = () => {
      document.removeEventListener("scroll", syncFilterSideBarPosition);
      document.removeEventListener("resize", syncFilterSideBarPosition);
    };
    return () => clear();
  }, [visible, topPosition, fixedTopPosition]);

  const baseClassNames = [sideBarAnimation ?? "", sideBarVisibility ?? ""];

  return (
    <Container
      ref={containerRef}
      $elementWidth={containerRef.current?.clientWidth ?? 0}
      className={
        className
          ? baseClassNames.concat(className).join(" ")
          : baseClassNames.join(" ")
      }
      $topPosition={topPosition}
    >
      <Scroll style={{ padding: "38px 30px", margin: 0 }}>{children}</Scroll>
    </Container>
  );
};

export default SideBar;
