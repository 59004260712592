import {
  Button,
  Card,
  HookIcon,
  HyperlinkButton,
  ListDropdown,
  Typo,
  VerticalMoreIcon,
} from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import { media } from "@cochlearai/util";
import { useTranslation } from "react-i18next";
import useCurrentProject from "~/client/hooks/useCurrentProject";
import useMultiDropdown from "~/client/hooks/useMultiDropdown";
import useWebhookList from "~/client/hooks/useWebhookList";
import { WEBHOOK_MORE_TYPE } from "~/client/lib/constants";
import getConfigs from "~/client/lib/getConfigs";
import { convertSenseTagValueToUILabel } from "~/client/lib/helpers";
import { NotificationConfig } from "~/client/types";

const { REACT_APP_COCHL_SENSE_DOCS_URL: docsURL } = getConfigs();

const Flex = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SettingContainer = styled(Flex)`
  gap: 30px;
  align-items: start;
  flex-direction: column;
  margin-top: 10px;

  ${media.query.md} {
    gap: 10px;
  }
`;

const WebhookCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  color: ${(p) => p.theme.colors.grey[70]};
`;

const TagContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 6px;
  margin-right: 30px;

  ${media.query.md} {
    margin-right: 0px;
  }
`;

const TagBadge = styled.div`
  border-radius: 100px;
  background: ${(p) => p.theme.colors.grey[10]};
  color: ${(p) => p.theme.colors.black};
  height: 24px;
  padding: 4px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AddWebhookBtn = styled(Button)`
  padding: 11px 16px;
  margin-left: 24px;
  min-width: 140px;
  position: absolute;
  right: 0;
  top: -60px;
`;

const MoreButton = styled(VerticalMoreIcon)`
  cursor: pointer;
  width: 20px;
`;

const Section = styled(Flex)`
  align-items: start;
  gap: 10px;

  ${media.query.md} {
    flex-direction: column;
    word-break: break-all;
  }
`;

interface Props {
  onClickMoreItem: (type: string, hook: NotificationConfig) => void;
  onClickAddWebhook: (hooks?: NotificationConfig[]) => void;
}

const WebhookList: FC<Props> = ({ onClickMoreItem, onClickAddWebhook }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { isDropdownOpen, openDropdown, closeDropdown } = useMultiDropdown();
  const { currentProject } = useCurrentProject();
  const { webhookList } = useWebhookList({ currentProject });

  return (
    <>
      <Flex>
        <Typo
          variant="body"
          style={{ color: colors.grey[70], margin: "14px 0" }}
        >
          Set up a webhook and get your IoT devices connected to Cochl!{" "}
          <a
            href={`${docsURL ? docsURL + "/sense/postaction/webhook" : ""}`}
            target="_blank"
            rel="noopener"
          >
            <HyperlinkButton text="Go to document" rightArrow />
          </a>
        </Typo>
        <AddWebhookBtn
          color="primary"
          onClick={() => onClickAddWebhook(webhookList)}
        >
          {t("Add webhook", { ns: "common" })}
        </AddWebhookBtn>
      </Flex>
      {webhookList?.length === 0 ? (
        <Flex
          style={{
            height: "400px",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <HookIcon style={{ marginBottom: "20px" }} />
          <Typo variant="body" style={{ color: colors.grey[70] }}>
            Webhooks are event notifications sent to URLs of your choice.
            <br />
            They can be used to integrate with third-party services which
            support them.
          </Typo>
        </Flex>
      ) : (
        <SettingContainer>
          <Typo variant="h3">Settings</Typo>
          {webhookList?.map((hook, i) => (
            <WebhookCard key={i}>
              <Flex
                style={{
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "10px",
                }}
              >
                <Section>
                  <Typo variant="caption1">Endpoint:</Typo>
                  <Typo variant="caption1">{hook.medium_values[0]}</Typo>
                </Section>
                <Section>
                  <Typo variant="caption1" style={{ marginTop: "4px" }}>
                    Tags:
                  </Typo>
                  <TagContainer>
                    {hook.trigger_conditions
                      ?.sort((a, b) => a.tag_name.localeCompare(b.tag_name))
                      .map((tag, index) => (
                        <TagBadge key={`${tag.id}_${index}`}>
                          <Typo variant="caption1">
                            {convertSenseTagValueToUILabel(tag.tag_name)}
                          </Typo>
                        </TagBadge>
                      ))}
                  </TagContainer>
                </Section>
              </Flex>
              {onClickMoreItem && (
                <ListDropdown
                  align="end"
                  data={WEBHOOK_MORE_TYPE}
                  isOpen={isDropdownOpen(i)}
                  onClickItem={(type) => onClickMoreItem(type, hook)}
                  onClose={() => closeDropdown(i)}
                  onClickOutside={() => closeDropdown(i)}
                >
                  <MoreButton onClick={() => openDropdown(i)} />
                </ListDropdown>
              )}
            </WebhookCard>
          ))}
        </SettingContainer>
      )}
    </>
  );
};

export default WebhookList;
