import { FC, useState } from "react";
import { ProfilePopover, ProfilePopoverContent } from "~/client/components";

import { UserProfile } from "@cochlearai/ui";
import { useSubscription } from "~/client/hooks";
import useAuthenticate from "~/client/hooks/useAuthenticate";

const HeaderProfile: FC = () => {
  const { formattedUserInfo } = useAuthenticate();
  const { formattedCurrentSubscriptionInfo } = useSubscription();

  const userName = formattedUserInfo.username;
  const userEmail = formattedUserInfo.email;
  const [profilePopOverOpen, setProfilePopOverOpen] = useState(false);
  const handleClickProfileIcon = () => {
    setProfilePopOverOpen(true);
  };
  const onClickOutsideProfilePopover = () => {
    setProfilePopOverOpen(false);
  };

  return (
    <ProfilePopover
      isOpen={profilePopOverOpen}
      onClickOutside={onClickOutsideProfilePopover}
      content={
        <ProfilePopoverContent
          name={userName}
          email={userEmail}
          onClose={() => {
            setProfilePopOverOpen(false);
          }}
          currentPlanTitle={formattedCurrentSubscriptionInfo.title}
        />
      }
    >
      <UserProfile
        onClick={handleClickProfileIcon}
        text={userName.length > 0 ? userName[0] : "U"}
        size="small"
      />
    </ProfilePopover>
  );
};

export default HeaderProfile;
