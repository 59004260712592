export enum ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE {
  NAME = "NAME",
  EMAIL = "EMAIL",
  ROLE = "ROLE",
  INVITED_DATE = "INVITED_DATE",
}

export interface OrganizationMemberListTableColumnItem {
  columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE;
  label: string;
}

export interface OrganizationMember {
  user_id: string;
  email: string;
  name: string;
  given_name: string;
  role: MEMBER_ROLE;
  pending: boolean;
  joined_date: string;
}

export interface Organization {
  id: string;
  user_id: string;
  owner_name: string;
  owner_given_name: string;
  owner_email: string;
  is_admin: boolean;
  joined_date: string;
}

export enum MEMBER_MORE_TYPE_VALUE {
  COPY_EMAIL = "COPY_EMAIL",
  RESEND_INVITE = "RESEND_INVITE",
}

export enum JOINED_MORE_TYPE_VALUE {
  LEAVE_ORGANIZATION = "LEAVE_ORGANIZATION",
}

export enum MEMBER_ROLE {
  OWNER = "organization_admin",
  VIEWER = "organization_user",
}
