export enum SenseServices {
  HOME_CONTEXT = "home-context",
  EMERGENCY_DETECTION = "emergency",
  HUMAN_INTERACTION = "human-interaction",
  HUMAN_STATUS = "human-status",
  VEHICLE_MONITOR = "vehicle-monitor",
}

export enum SenseCategory {
  ALL = "All",
  EMERGENCY = "Emergency",
  HUMAN_ACTION = "Human action",
  HUMAN_STATUS = "Human status",
  HUMAN_SPEECH = "Human speech",
  MUSIC = "Music",
  HOME_CONTEXT = "Home context",
  VEHICLE = "Vehicle",
  ALARM = "Alarm",
  ANIMAL = "Animal",
}

export enum SenseTags {
  AIRCRAFT_NOISE = "Aircraft_noise",
  ALARM = "Alarm",
  APPLAUSE = "Applause",
  APPLIANCE_ALARM = "Appliance_alarm",
  BABY_CRY = "Baby_cry",
  BABY_LAUGHTER = "Baby_laughter",
  BELL = "Bell",
  BIRD_CHIRP = "Bird_chirp",
  BIRD_HONK = "Bird_honk",
  BOILING = "Boiling",
  BOWED_STRING_INSTRUMENT = "Bowed_string_instrument",
  BREAK = "Break",
  BURP = "Burp",
  CAR_ALARM = "Car_alarm",
  CAR_BACKFIRE = "Car_backfire",
  CAR_HORN = "Car_horn",
  CAT_MEOW = "Cat_meow",
  CHEWING = "Chewing",
  CHICKEN_ROOSTER = "Chicken_Rooster",
  CHILD_LAUGHTER = "Child_laughter",
  CHILD_SING = "Child_sing",
  CHOPPING = "Chopping",
  CIVIL_DEFENSE_SIREN = "Civil_defense_siren",
  CLAP = "Clap",
  COOKING = "Cooking",
  COOKING_TOOLS = "Cooking_tools",
  COUGH = "Cough",
  COW = "Cow",
  CROWD = "Crowd",
  CROWD_YELL = "Crowd_yell",
  DINING_TOOLS = "Dining_tools",
  DOG_BARK = "Dog_bark",
  DOG_WHINE = "Dog_whine",
  DOOR_OPEN_CLOSE = "Door_open_close",
  DOORBELL = "Doorbell",
  DRUM = "Drum",
  DUCK = "Duck",
  EMERGENCY_VEHICLE_SIREN = "Emergency_vehicle_siren",
  FART = "Fart",
  FEMALE_LAUGHTER = "Female_laughter",
  FEMALE_RAP = "Female_rap",
  FEMALE_SCREAM = "Female_scream",
  FEMALE_SING = "Female_sing",
  FEMALE_SPEECH = "Female_speech",
  FEMALE_WHISPER = "Female_whisper",
  FEMALE_YELL = "Female_yell",
  FINGER_SNAP = "Finger_snap",
  FIRE_SMOKE_ALARM = "Fire_smoke_alarm",
  FOOTSTEP = "Footstep",
  FRYING = "Frying",
  GLASS_BREAK = "Glass_break",
  GOAT = "Goat",
  GUITAR = "Guitar",
  GUNSHOT = "Gunshot",
  GUNSHOT_AUTO = "Gunshot_auto",
  GUNSHOT_SINGLE = "Gunshot_single",
  HAIR_DRYER = "Hair_dryer",
  HICCUP = "Hiccup",
  HUMMING = "Humming",
  INSTRUMENT = "Instrument",
  KISS = "Kiss",
  KNOCK = "Knock",
  LAUGHTER = "Laughter",
  LOUD_ENGINE_ACCELERATION = "Loud_engine_acceleration",
  MALE_LAUGHTER = "Male_laughter",
  MALE_RAP = "Male_rap",
  MALE_SCREAM = "Male_scream",
  MALE_SING = "Male_sing",
  MALE_SPEECH = "Male_speech",
  MALE_WHISPER = "Male_whisper",
  MALE_YELL = "Male_yell",
  MOAN = "Moan",
  MUSIC = "Music",
  PHONE_FILTER_SPEECH = "Phone_filter_speech",
  PHONE_VIBRATION = "Phone_vibration",
  PIANO = "Piano",
  PIG = "Pig",
  RAP = "Rap",
  REVERSING_BEEP = "Reversing_beep",
  SCREAM = "Scream",
  SHEEP = "Sheep",
  SIGH = "Sigh",
  SING = "Sing",
  SIREN = "Siren",
  SNEEZE = "Sneeze",
  SNORE = "Snore",
  SPEECH = "Speech",
  THROAT_CLEAR = "Throat_clear",
  THUD = "Thud",
  TIRE_SQUEAL = "Tire_squeal",
  TOILET_FLUSH = "Toilet_flush",
  TONGUE_CLICK = "Tongue_click",
  TOOTHBRUSH = "Toothbrush",
  TUT_TUT = "Tut_tut",
  VACUUM_CLEANER = "Vacuum_cleaner",
  WATER_POUR = "Water_pour",
  WATER_RUN = "Water_run",
  WATER_TAP = "Water_tap",
  WHISPER = "Whisper",
  WHISTLE = "Whistle",
  WIND_NOISE = "Wind_noise",
  YAWN = "Yawn",
  YELL = "Yell",
  OTHERS = "Others",
}

export enum SensePlatforms {
  API = "api",
  SDK = "sdk",
}

export interface SDKTag {
  value: SenseTags;
  label: string;
}

export interface SenseTagObject {
  id: SenseTags;
  name: string;
}

export enum SenseIOTypes {
  FILE = "file",
  STREAM = "stream",
}
