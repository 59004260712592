export interface PaymentMethod {
  id: string;
  is_default: boolean;
  card: {
    brand: CARD_BRAND;
    last4: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
  };
}

export interface Customer {
  created_at: string;
  customer_id: string;
  organization_id: string;
  organization_name: string;
  email: string;
  balance: number;
  currency: string;
}

export enum CARD_ELEMENT {
  NUMBER = "cardNumber",
  EXPIRY = "cardExpiry",
  CVC = "cardCvc",
}

export interface CardInfo {
  id: string;
  card: {
    brand: CARD_BRAND;
    last4: string;
  };
}

export enum CARD_BRAND {
  AMEX = "amex",
  DINERS = "diners",
  DISCOVER = "discover",
  JCB = "jcb",
  MASTER = "mastercard",
  UNION = "unionpay",
  VISA = "visa",
  UNKNOWN = "unknown",
}

export enum PAYMENT_MORE_TYPE_VALUE {
  REMOVE = "REMOVE",
  MAKE_DEFAULT = "MAKE_DEFAULT",
}
