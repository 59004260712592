import { BaseTheme, theme as themeColor } from "@cochlearai/ui";
import { PropsWithChildren } from "react";

import { useTheme } from "~/client/hooks";

const BaseThemeProvider = ({ children }: PropsWithChildren) => {
  const { theme } = useTheme();
  return (
    <BaseTheme
      theme={{
        theme: theme,
        colors: themeColor[theme],
      }}
    >
      {children}
    </BaseTheme>
  );
};

export default BaseThemeProvider;
