import {
  APIError,
  FetchReadStatusResponse,
  Project,
  ReadStatus,
  SenseEvent,
  UploadStatus,
} from "~/client/types";
import {
  FILE_UPLOAD_CHUNK_SIZE,
  FILE_UPLOAD_LIMIT_LENGTH,
  FILE_UPLOAD_LIMIT_SIZE,
  ONE_MINUTE_SECOND,
  UPLOAD_MAX_TIME,
  UPLOAD_OFFSET,
} from "~/client/lib/constants";
import {
  convertFileToBase64,
  selectedAudioState,
  selectedUploadState,
} from "~/client/lib";
import {
  fetchCreateSession,
  fetchDeleteSession,
  fetchReadStatus,
  fetchUploadChunk,
} from "~/client/api/senseApi";
import { resetUploadInfo, setUploadInfo } from "~/client/store/modules/upload";
import { setClientError, setServerError } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { fetchCreateUploadHistory } from "~/client/api/analytics";
import { fetchRecognizeSpeaker } from "../api";
import { getUniqueSenseTag } from "~/client/lib/helpers";
import { useSWRConfig } from "swr";

let cancelFlag: boolean;

interface Props {
  currentProject: Project | null;
}

function useUpload({ currentProject }: Props) {
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const isBrowserFileUploading = useRef<boolean>(false);
  const { audioInfo } = useAppSelector(selectedAudioState); // audio redux state
  const { uploadInfo } = useAppSelector(selectedUploadState); // upload redux state
  const uploadInfoByProject = useMemo(
    () => uploadInfo.find((data) => data.projectId === currentProject?.id),
    [uploadInfo, currentProject?.id],
  );
  const duration = useMemo(
    () =>
      audioInfo.find((data) => data.projectId === currentProject?.id)?.duration,
    [audioInfo, currentProject?.id],
  );

  /**
   * reset upload date
   */
  const reset = useCallback(async () => {
    currentProject &&
      dispatch(resetUploadInfo({ projectId: currentProject?.id }));
    setFile(null);
  }, [currentProject, dispatch]);

  /**
   * create session
   * https://docs.cochl.ai/sense/api/openapi/
   * @returns session id
   */
  const createSession = useCallback(async () => {
    if (!file || !currentProject) return;

    try {
      const res = await fetchCreateSession({
        config: {
          headers: {
            "x-api-key": currentProject.key,
          },
        },
        params: {
          type: "file",
          content_type: "audio/" + file.name.split(".").pop(),
          total_size: file.size,
          file_name: file.name,
          file_length: duration,
        },
      });
      const { data } = res;
      return data.session_id;
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    }
  }, [currentProject, dispatch, duration, file]);

  /**
   * create upload history
   * https://cochl.atlassian.net/l/cp/R0AtpTaA
   * @param {string} id session id
   */
  const addUploadHistory = useCallback(
    async (id: string) => {
      if (!(file && currentProject)) return;
      try {
        await fetchCreateUploadHistory({
          params: {
            project_id: currentProject.id,
            session_id: id,
            file_name: file.name,
            file_size: file.size,
            file_length: Math.ceil(duration || 0),
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      }
    },
    [currentProject, dispatch, duration, file],
  );

  /**
   * handle 'upload chunk' progress
   * @param {Event} e progress event
   * @param {number} uploadedChunk  uploaded chunk size
   */
  const handleOnProgress = useCallback(
    (e, uploadedChunk) => {
      if (!currentProject) return;
      let percent;
      const totalSize = file?.size || 0;
      if (totalSize < FILE_UPLOAD_CHUNK_SIZE) {
        percent = Math.round((e.loaded / e.total) * 100);
      } else {
        percent = Math.round((uploadedChunk / totalSize) * 100);
      }
      dispatch(
        setUploadInfo({
          projectId: currentProject.id,
          uploadProgress: percent,
        }),
      );
    },
    [currentProject, dispatch, file],
  );

  /**
   * upload chunk
   * @param {string} id session id
   */
  const upload = useCallback(
    async (id: string) => {
      if (!file) return;

      for (
        let sequence = 0;
        sequence * FILE_UPLOAD_CHUNK_SIZE < file.size;
        sequence++
      ) {
        try {
          if (cancelFlag) break;

          const chunk = file.slice(
            sequence * FILE_UPLOAD_CHUNK_SIZE,
            (sequence + 1) * FILE_UPLOAD_CHUNK_SIZE,
          );
          const base64 = String(await convertFileToBase64(chunk))
            .replace("data:", "")
            .replace(/^.+,/, "");
          const uploadedChunk = chunk.size + sequence * FILE_UPLOAD_CHUNK_SIZE;

          await fetchUploadChunk({
            config: {
              onUploadProgress: (e) => handleOnProgress(e, uploadedChunk),
            },
            params: {
              sessionId: id,
              chunkData: base64,
              chunkSequence: sequence,
            },
          });
        } catch (e) {
          console.error(e);
          const error = e as APIError<unknown>;
          dispatch(setServerError(error));
        }
      }
    },
    [dispatch, file, handleOnProgress],
  );

  /**
   * delete session
   */
  const deleteSession = useCallback(async () => {
    if (!uploadInfoByProject?.sessionId || !currentProject) return;

    try {
      await fetchDeleteSession({
        params: {
          sessionId: uploadInfoByProject.sessionId,
        },
      });
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      dispatch(
        setUploadInfo({
          projectId: currentProject.id,
          sessionId: undefined,
        }),
      );
    }
  }, [currentProject, dispatch, uploadInfoByProject?.sessionId]);

  /**
   * read status (inference)
   */
  const readStatus = useCallback(async () => {
    if (!uploadInfoByProject?.sessionId || !currentProject) return;
    const startTime = Date.now();
    let state,
      hasMore,
      offset = 0,
      retryCount = 0,
      result = [] as SenseEvent[];

    while (Date.now() - startTime < UPLOAD_MAX_TIME) {
      try {
        const res = (await fetchReadStatus({
          params: {
            sessionId: uploadInfoByProject.sessionId,
            ...(offset && { offset }),
          },
        })) as FetchReadStatusResponse;
        const { data: responseData } = res;

        state = responseData.state;
        hasMore = responseData.has_more;
        result = [...result, ...responseData.data];

        if (state === ReadStatus.DONE) {
          dispatch(
            setUploadInfo({
              projectId: currentProject.id,
              sessionResult: {
                result: result,
                uniqueTag: getUniqueSenseTag(result),
              },
            }),
          );
          if (!hasMore) {
            break;
          } else {
            offset += UPLOAD_OFFSET;
          }
        } else if (
          state === ReadStatus.PENDING ||
          state === ReadStatus.IN_PROGRESS
        ) {
          await waitCallInterval(retryCount);
        } else if (state === ReadStatus.ERROR) {
          dispatch(
            setClientError({
              text: `Inference failed`,
            }),
          );
          break;
        } else if (!state) {
          dispatch(
            setClientError({
              text: `Invalid response: ${JSON.stringify(responseData)}`,
            }),
          );
          break;
        }

        ++retryCount;
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
        // break;
      }
    }
  }, [currentProject, dispatch, uploadInfoByProject?.sessionId]);

  const waitCallInterval = (retryCount: number) => {
    return new Promise((resolve) =>
      setTimeout(
        resolve,
        Math.min(!retryCount ? 1000 : 1100 * retryCount, 4000),
      ),
    );
  };

  /**
   * cancel 'upload chunk'
   */
  const cancelUpload = useCallback(async () => {
    cancelFlag = true;
    await deleteSession();
    reset();
  }, [deleteSession, reset]);

  /**
   * start upload
   * 1. create session
   */
  const startUpload = useCallback(async () => {
    if (!currentProject) return;
    const id = await createSession();
    if (id) {
      dispatch(
        setUploadInfo({
          projectId: currentProject.id,
          status: UploadStatus.INITIATING,
          sessionId: id,
        }),
      );
    }
  }, [createSession, currentProject, dispatch]);

  /**
   * analyze
   * 1. create upload history
   * 2. upload chunk
   * 3. read status (inference)
   * 4. delete session
   */
  const analyze = useCallback(async () => {
    if (!currentProject || !uploadInfoByProject?.sessionId) return;
    dispatch(
      setUploadInfo({
        projectId: currentProject.id,
        status: UploadStatus.UPLOADING,
      }),
    );
    await addUploadHistory(uploadInfoByProject?.sessionId);
    await upload(uploadInfoByProject?.sessionId);

    dispatch(
      setUploadInfo({
        projectId: currentProject.id,
        status: UploadStatus.ANALYZING,
      }),
    );
    await readStatus();
    await deleteSession();

    dispatch(
      setUploadInfo({
        projectId: currentProject.id,
        status: UploadStatus.FINISHED,
      }),
    );
    mutate(SWR_KEY.SENSE_API_INITIALIZE_USAGE);
  }, [
    addUploadHistory,
    deleteSession,
    dispatch,
    mutate,
    readStatus,
    upload,
    currentProject,
    uploadInfoByProject,
  ]);

  /**
   * speaker recognition
   */
  const detectSpeaker = useCallback(async () => {
    if (!file || !currentProject) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data } = await fetchRecognizeSpeaker({
        config: {
          headers: {
            "x-api-key": currentProject.key,
          },
        },
        params: formData,
      });

      if (data.error) {
        dispatch(
          setClientError({
            text: data.error,
          }),
        );
        return;
      }
      dispatch(
        setUploadInfo({
          projectId: currentProject.id,
          transcription: data.data ?? [],
        }),
      );
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    }
  }, [currentProject, uploadInfoByProject]);

  useEffect(() => {
    if (!currentProject) return;
    if (file) {
      isBrowserFileUploading.current = true;
      if (!duration) return;
      isBrowserFileUploading.current = false;

      if (Math.ceil(file.size / 1024 / 1024) > FILE_UPLOAD_LIMIT_SIZE) {
        dispatch(
          setClientError({
            text: `Maximum size of file uploading is ${FILE_UPLOAD_LIMIT_SIZE}mb.`,
          }),
        );
        return;
      }
      if (Math.ceil(duration / ONE_MINUTE_SECOND) > FILE_UPLOAD_LIMIT_LENGTH) {
        dispatch(
          setClientError({
            text: `Maximum length of file uploading is ${FILE_UPLOAD_LIMIT_LENGTH}m.`,
          }),
        );
        return;
      }

      cancelFlag = false;
      dispatch(
        setUploadInfo({
          projectId: currentProject.id,
          fileInfo: {
            name: file.name,
            size: file.size,
            duration,
          },
        }),
      );
      startUpload();
    }
  }, [currentProject, dispatch, duration, file, startUpload]);

  return {
    ...uploadInfoByProject,
    isBrowserFileUploading: isBrowserFileUploading.current,
    analyze,
    detectSpeaker,
    setFile,
    reset,
    cancelUpload,
  };
}

export default useUpload;
