import * as Converters from "./convertSenseValueToUILabel";

import { SenseTags } from "~/client/types/sense";

function isOfficialSenseValue({
  value,
  type,
}: {
  value: string;
  type: "tag";
}): boolean {
  let filteredValue: string | undefined;

  switch (type) {
    case "tag":
      filteredValue = Converters.convertSenseTagValueToUILabel(
        value as SenseTags,
      );
      break;
    default:
      break;
  }
  return typeof filteredValue !== "undefined";
}

export default isOfficialSenseValue;
