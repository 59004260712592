import { selectedAnalyticsState } from "~/client/lib/selectors";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetTimeseries } from "~/client/api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { convertSenseTagValueToUILabel } from "~/client/lib/helpers";
import { AnalyticsType } from "~/client/types/analytics";
import useSdkChannelList from "./useSdkChannelList";
import useCurrentProject from "./useCurrentProject";

function useAnalyticsTimeseries() {
  const dispatch = useAppDispatch();
  const params = useParams<{ projectId?: string }>();
  const { currentProject } = useCurrentProject();
  const { channelList } = useSdkChannelList({ currentProject });
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const [timeSeries, setTimeSeries] = useState<
    { name: string; data: (number | null)[] }[]
  >([]);
  const [labels, setLabels] = useState<string[]>([]);
  const filters = useMemo(
    () =>
      filterParamsWithChecked
        ? filterParamsWithChecked[AnalyticsType.REALTIME_VIEW]
        : {},
    [filterParamsWithChecked],
  );

  const { data, error, isValidating, mutate } = useSWR(
    params.projectId && filters
      ? SWR_KEY.ANALYTICS_INITIALIZE_TIMESERIES
      : null,
    async () => {
      if (!filters || !params.projectId) return null;
      const { tag, device, fromTo, scale, groupBy } = filters;
      return (
        await fetchGetTimeseries({
          params: {
            project_id: params.projectId!,
            ...fromTo!,
            tags: tag?.filter((item) => item.checked).map((item) => item.value),
            devices: device
              ?.filter((item) => item.checked)
              .map((item) => item.value),
            scale: scale?.find((item) => item.checked)?.value,
            tags_merge: groupBy?.find((item) => item.value === "tags")?.checked,
            devices_merge: groupBy?.find((item) => item.value === "devices")
              ?.checked,
          },
        })
      ).data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const initializeEventList = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    let tempLabels: string[] = [];
    const tempTimeseries: { name: string; data: { x: string; y: number }[] }[] =
      [];

    if (data) {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].data?.length; j++) {
          tempLabels = Array.from(
            new Set(tempLabels.concat([data[i].data[j].date])),
          );
          const tagName = data[i].tags
            ?.map((tag) => convertSenseTagValueToUILabel(tag))
            ?.join(", ");
          const deviceName = data[i].devices
            ?.map(
              (device) =>
                channelList.find((channel) => channel.device_id === device)
                  ?.device_name,
            )
            ?.join(", ");
          const targetString = `${tagName}${
            deviceName ? " - " + deviceName : ""
          }`;
          const isExistIdx = tempTimeseries.findIndex(
            (item) => item.name === targetString,
          );
          if (isExistIdx > -1) {
            tempTimeseries[isExistIdx].data.push({
              x: data[i].data[j].date,
              y: data[i].data[j].count,
            });
          } else {
            tempTimeseries.push({
              name: targetString,
              data: [
                {
                  x: data[i].data[j].date,
                  y: data[i].data[j].count,
                },
              ],
            });
          }
        }
      }
    }
    tempLabels.sort((a, b) => +new Date(a) - +new Date(b));
    setTimeSeries(
      tempTimeseries.map((series) => ({
        ...series,
        data: tempLabels.map(
          (label) => series.data.find((item) => item.x === label)?.y ?? 0,
        ),
      })),
    );
    setLabels(tempLabels);
  }, [channelList, data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  useEffect(() => {
    initializeEventList();
  }, [initializeEventList, filters]);

  return {
    timeSeries,
    labels,
    isValidating,
  };
}

export default useAnalyticsTimeseries;
