import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { media } from "@cochlearai/util";
import { Z_INDEXES } from "~/client/lib/constants";
import QuestionTemplate from "./QuestionTemplate";
import IntroStep from "./IntroStep";
import CompleteStep from "./CompleteStep";
import useSurveyQuestion from "~/client/hooks/useSurveyQuestion";
import useAuthenticate from "~/client/hooks/useAuthenticate";
import useSurveyCompleted from "~/client/hooks/useSurveyCompleted";

const Container = styled.div`
  position: fixed;
  bottom: 86px;
  right: 60px;
  height: auto;
  width: 340px;
  border-radius: 24px;
  background: ${(p) => p.theme.colors.blue[60]};
  padding: 20px;
  z-index: ${Z_INDEXES.POP_OVER};

  ${media.query.md} {
    width: 90%;
    max-width: 340px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 5%;
  }
`;

const PopupSurvey: FC = () => {
  const { user } = useAuthenticate();
  const { completed } = useSurveyCompleted({ user });
  const {
    questions,
    order,
    answers,
    setSurveyOrder,
    setSurveyAnswer,
    sendSurvey,
  } = useSurveyQuestion();

  const onChange = useCallback(
    (e, question, isTextArea, removeText) => {
      if (!user) return;

      const value = e.target.value;
      let temp_answers;
      const found = answers.find(
        (answer) =>
          answer.question_id === question.question_id &&
          answer.isTextArea === isTextArea,
      );

      if (found) {
        temp_answers = answers.map((item) =>
          item === found ? { ...item, answer: value } : item,
        );
      } else {
        temp_answers = [
          ...answers,
          {
            user_id: user.identity.id,
            question_id: question.question_id,
            answer: value,
            ...(isTextArea && { isTextArea }),
          },
        ];
      }

      if (removeText || !value) {
        temp_answers = temp_answers.filter(
          (item) =>
            !(item.question_id === question.question_id && item.isTextArea),
        );
      }

      setSurveyAnswer(temp_answers);
    },
    [answers, user, setSurveyAnswer],
  );

  if (typeof order !== "number" || !questions || completed) {
    return null;
  }

  return (
    <Container>
      {order === 0 ? (
        <IntroStep
          onClose={() => setSurveyOrder(null)}
          onClickStart={() => setSurveyOrder(order + 1)}
        />
      ) : order === 5 ? (
        <CompleteStep
          onClose={() => setSurveyOrder(null)}
          onClickStart={() => {
            setSurveyAnswer([]);
            setSurveyOrder(1);
          }}
        />
      ) : (
        <QuestionTemplate
          order={order}
          questions={questions}
          answers={answers}
          onClickPrevious={() => setSurveyOrder(order - 1)}
          onClickNext={() => {
            if (order === questions.length) sendSurvey();
            setSurveyOrder(order + 1);
          }}
          onChange={onChange}
        />
      )}
    </Container>
  );
};

export default PopupSurvey;
