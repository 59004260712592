import { APIError, ClientError } from "~/client/types/error";
import { useAPIError, useClientError } from ".";

import { initializeErrors } from "~/client/store/modules/alert";
import { useAppDispatch } from "..";
import { useCallback } from "react";

function useError(): {
  clientError: ClientError | null;
  setClientError: (clientError: ClientError | null) => void;
  serverError: APIError<unknown> | null;
  shouldShowErrorToast: boolean;
  hideErrorToast: () => void;
  errorToastMessage: string;
  initializeError: () => void;
} {
  const {
    errorToast: { shouldShowErrorToast, hideErrorToast, errorToastMessage },
    serverError,
  } = useAPIError();
  const { clientError, setClientError } = useClientError();
  const dispatch = useAppDispatch();

  const initializeError = useCallback(() => {
    dispatch(initializeErrors());
  }, [dispatch]);

  return {
    clientError,
    setClientError,
    serverError,
    shouldShowErrorToast,
    hideErrorToast,
    errorToastMessage,
    initializeError,
  };
}

export default useError;
