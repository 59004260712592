import { media as mediaUtil, size } from "./media";

import { convertHexToRGB } from "./convertHexToRGB";

export const media = {
  query: mediaUtil,
  size,
};

export const color = {
  convertHexToRGB: convertHexToRGB,
};
