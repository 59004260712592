import moment from "moment";
moment.relativeTimeThreshold("ss", 1); // a few seconds
moment.relativeTimeThreshold("s", 60); // seconds
moment.relativeTimeThreshold("m", 60); // minutes
moment.relativeTimeThreshold("h", 24); // hours
moment.relativeTimeThreshold("d", 30); // days
// moment.relativeTimeThreshold('w', 1); // weeks
moment.relativeTimeThreshold("M", 12); // months
moment.updateLocale("en", {
  relativeTime: {
    s: "%ds", // a few seconds
    ss: "%ds", // xx seconds
    m: "%dm", // xx minutes
    mm: "%dm", // xx minutes
    h: "%dh", // xx hour
    hh: "%dh", // xx hours
    d: "%dd", // day
    dd: "%dd", // days
    //     w: "%dd", // week
    //     ww: "%dd", // weeks
    M: "%dmo", // month
    MM: "%dmo", // months
    y: "%dy", // year
    yy: "%dy", // years
  },
});

// if (typeof window !== "undefined") {
//   window.moment = moment;
// }
