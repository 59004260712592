import { MODAL_WIDTH_BY_SIZE, Z_INDEXES } from "../lib/constants";
import { PropsWithChildren, useEffect, useRef } from "react";

import { BaseStyleProps } from "../types";
import { color } from "@cochlearai/util";
import styled from "styled-components";

const Background = styled.div<{ $hasDim: boolean }>`
  position: ${(p) => (p.$hasDim ? "fixed" : "relative")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${(p) => p.$hasDim && "rgba(19, 19, 19, 0.4)"};

  z-index: ${(p) => (p.$hasDim ? Z_INDEXES.MODAL_BACKGROUND : "inherit")};
`;

const Container = styled.div<{
  size: "small" | "medium" | "large";
  $hasDim: boolean;
}>`
  overflow: auto;
  max-height: 95vh;
  max-width: 90vw;

  z-index: ${Z_INDEXES.MODAL};

  width: ${(p) => MODAL_WIDTH_BY_SIZE[p.size]};
  height: auto;

  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 0px 20px
    ${(p) =>
      color.convertHexToRGB({
        hex: p.theme.colors.shadow,
        alpha: 0.1,
      })};

  position: ${(p) => (p.$hasDim ? "absolute" : "fixed")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 4px;
`;

export interface ModalProps extends BaseStyleProps {
  size?: "small" | "medium" | "large";
  onClose?: () => void;
  open: boolean;
  dim?: boolean;
}

/**
 * React component used to create a modal.
 * @param open - Indicates whether the modal is open or not.
 * @param onClose - Callback function that will be called when user closes the modal.
 * @param size - Size of the modal. `small`, `medium` or `large`.
 * @param dim - Indicates whether the modal is dimmed or not.
 * @param className - The class name of the modal.
 * @param style - CSS properties.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/modal--default
 */
export const Modal = ({
  size = "medium",
  children,
  open,
  onClose,
  className,
  style,
  dim = true,
}: PropsWithChildren<ModalProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  // const disableScrollingMobile = useCallback((e: TouchEvent) => {
  //   e.preventDefault();
  // }, []);

  // const disableScrolling = useCallback(() => {
  //   if (typeof document !== "undefined") {
  //     document.body.setAttribute("style", "height: 100%; overflow: hidden;");
  //   }
  //   // mobile
  //   document.body.addEventListener("touchmove", disableScrollingMobile);
  // }, []);

  // const enableScrolling = useCallback(() => {
  //   if (typeof document !== "undefined") {
  //     document.body.setAttribute(
  //       "style",
  //       "height: inherit; overflow: inherit;"
  //     );
  //   }
  //   // mobile
  //   document.body.removeEventListener("touchmove", disableScrollingMobile);
  // }, []);

  // useEffect(() => {
  //   if (open) {
  //     disableScrolling();
  //   } else {
  //     enableScrolling();
  //   }
  // }, [open]);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent | TouchEvent) => {
      const target = e.target as Element;
      if (
        !ref.current ||
        ref.current.contains(target) ||
        target.closest("#react-tiny-popover-container")
      ) {
        return;
      }
      onClose && onClose();
    };
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose && onClose();
      }
    };
    document.addEventListener("mousedown", onClickOutside);
    document.addEventListener("touchstart", onClickOutside);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
      document.removeEventListener("touchstart", onClickOutside);
      document.removeEventListener("keydown", onKeyDown);
      // enableScrolling();
    };
  }, [onClose]);

  if (!open) {
    return null;
  }
  return (
    <Background data-testid="modal-background" $hasDim={dim}>
      <Container
        data-testid="modal-container"
        className={className}
        ref={ref}
        size={size}
        $hasDim={dim}
        style={style}
      >
        {children}
      </Container>
    </Background>
  );
};
