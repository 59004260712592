import moment, { Moment } from "moment";

function checkDateLimit(
  startDate: string | Moment,
  endDate: string | Moment,
  dateLimit: { min: number; max: number; unit: "days" | "months" },
) {
  let isValid = true;
  const startDateMoment =
    typeof startDate === "string" ? moment(new Date(startDate)) : startDate;
  const endDateMoment =
    typeof endDate === "string" ? moment(new Date(endDate)) : endDate;
  const diff = endDateMoment.diff(startDateMoment, dateLimit.unit);

  if (!(diff >= dateLimit.min && diff <= dateLimit.max)) {
    isValid = false;
  }
  return isValid;
}

export default checkDateLimit;
