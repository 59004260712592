import { ThemeColor } from "./colors";

export enum ThemeType {
  LIGHT = "light",
  DARK = "dark",
}

export type BaseThemeColor = Record<ThemeType, string>;

type ButtonType = "default" | "primary" | "secondary" | "danger";

export type ButtonTypeColor = {
  [key in ButtonType]?: BaseThemeColor;
};

export interface ColorSet extends ButtonTypeColor {
  hover: ButtonTypeColor;
  disabled: ButtonTypeColor;
}

export interface ComponentThemeColor {
  button: {
    border: ColorSet;
    backgroundColor: ColorSet;
    color: ColorSet;
  };
}

type CustomType = {
  theme: ThemeType;
  colors: ThemeColor;
};

declare module "styled-components" {
  export interface DefaultTheme extends CustomType {} // eslint-disable-line
}
