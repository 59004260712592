import { numberWithCommas } from ".";

function convertUsageTimeFormat({
  seconds,
  showMin = true,
}: {
  seconds: number;
  showMin?: boolean;
}) {
  const minutes = ~~(seconds / 60);
  const remainingSeconds = seconds % 60;
  const minText =
    !showMin && minutes === 0
      ? ""
      : numberWithCommas({
          value: minutes,
          maxDecimal: 2,
        }) + " min";
  const secondText =
    numberWithCommas({
      value: remainingSeconds,
      maxDecimal: 2,
    }) + " sec";

  return {
    minutes,
    seconds: remainingSeconds,
    formattedString: `${minText} ${secondText}`,
  };
}

export default convertUsageTimeFormat;
