import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Audio {
  projectId: string;
  sense?: {
    isPlay?: boolean;
    curTime?: number;
  };
  transcript?: {
    isPlay?: boolean;
    curTime?: number;
  };
  duration?: number;
  channel?: Float32Array;
}

interface AudioState {
  audioInfo: Audio[];
}

const initialState: AudioState = {
  audioInfo: [],
};

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    setAudioInfo: (state, action: PayloadAction<Audio>) => {
      const { projectId, sense, duration, channel, transcript } =
        action.payload;

      const foundIdx = state.audioInfo.findIndex(
        (data) => data.projectId === projectId,
      );
      const newInfo = {
        projectId,
        ...(sense && { sense }),
        ...(transcript && { transcript }),
        ...(typeof duration === "number" && { duration }),
        ...(channel && { channel }),
      };
      if (foundIdx === -1) {
        state.audioInfo = [...state.audioInfo, newInfo];
      } else {
        state.audioInfo[foundIdx] = {
          ...state.audioInfo[foundIdx],
          ...newInfo,
        };
      }
    },
    resetAudioInfo: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload;
      const foundIdx = state.audioInfo.findIndex(
        (data) => data.projectId === projectId,
      );
      if (foundIdx !== -1) {
        state.audioInfo = state.audioInfo.filter(
          (data) => data.projectId !== projectId,
        );
      }
    },
  },
  extraReducers: () => {
    //
  },
});

export const { setAudioInfo, resetAudioInfo } = audioSlice.actions;
export const { reducer: audioReducer } = audioSlice;
