import React, { FC, SVGProps } from "react";

const ArrowRightIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.092 2 12 6l-2.908 4-.89-.743 1.928-2.652H0v-1.21h10.13L8.202 2.743 9.092 2Z"
      fill={props.fill || "#8C8C8C"}
    />
  </svg>
);

export default ArrowRightIcon;
