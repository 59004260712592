const logoStyle = [
  "color: #ffffff",
  "text-shadow: 2px 2px #ffffff",
  "background: #21AEFF",
  "font-size: 5em",
  "border: 1px solid #ffffff",
  "padding: 20px",
  "font-family: fantasy",
].join(";");
const joinUsStyle = [
  "color: #000000",
  "text-shadow: 2px 2px #000000",
  "background: #ffffff",
  "font-size: 2em",
  "border: 1px solid #ffffff",
  "padding: 20px",
  "text-decoration: none",
].join(";");
function cochlLogging(): void {
  console.log("%c                     COCHL.                     ", logoStyle);
  console.log("%cjoin us >> https://cochl.oopy.io/", joinUsStyle);
}

export default cochlLogging;
