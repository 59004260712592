import { CALENDAR_INPUT_NAMES } from "@cochlearai/calendar";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { isValidCalendarInputDate } from "~/client/lib/helpers";

function useReactDatesOld() {
  const [calendarDatesUI, setCalendarDatesUI] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: moment(),
    endDate: moment(),
  });

  const [inputValues, setInputValues] = useState<{
    startDate: {
      date: string;
      month: string;
      year: string;
      time?: string;
    };
    endDate: {
      date: string;
      month: string;
      year: string;
      time?: string;
    };
  }>({
    startDate: {
      date: calendarDatesUI.startDate
        ? `${calendarDatesUI.startDate.toDate().getDate()}`
        : "",
      month: calendarDatesUI.startDate
        ? `${calendarDatesUI.startDate.toDate().getMonth() + 1}`
        : "",
      year: calendarDatesUI.startDate
        ? `${calendarDatesUI.startDate.toDate().getFullYear()}`
        : "",
      time: calendarDatesUI.startDate
        ? `${calendarDatesUI.startDate
            .toDate()
            .getHours()}:${calendarDatesUI.startDate.toDate().getMinutes()}`
        : "",
    },
    endDate: {
      date: calendarDatesUI.endDate
        ? `${calendarDatesUI.endDate.toDate().getDate()}`
        : "",
      month: calendarDatesUI.endDate
        ? `${calendarDatesUI.endDate.toDate().getMonth() + 1}`
        : "",
      year: calendarDatesUI.endDate
        ? `${calendarDatesUI.endDate.toDate().getFullYear()}`
        : "",
      time: calendarDatesUI.endDate
        ? `${calendarDatesUI.endDate
            .toDate()
            .getHours()}:${calendarDatesUI.endDate.toDate().getMinutes()}`
        : "",
    },
  });

  const [calendarInputValidation, setCalendarInputValidation] = useState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: true,
    endDate: true,
  });

  const onStartDateInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value, name } = e.target;
    if (name !== CALENDAR_INPUT_NAMES.STARTDATE_TIME && isNaN(+value)) {
      return;
    }
    setInputValues({
      ...inputValues,
      startDate: {
        ...inputValues.startDate,
        date:
          name === CALENDAR_INPUT_NAMES.STARTDATE_DATE
            ? value
            : inputValues.startDate.date,
        month:
          name === CALENDAR_INPUT_NAMES.STARTDATE_MONTH
            ? value
            : inputValues.startDate.month,
        year:
          name === CALENDAR_INPUT_NAMES.STARTDATE_YEAR
            ? value
            : inputValues.startDate.year,
        time:
          name === CALENDAR_INPUT_NAMES.STARTDATE_TIME
            ? value
            : inputValues.startDate.time,
      },
    });
  };

  const onEndDateInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value, name } = e.target;
    // prevent character value change
    if (name !== CALENDAR_INPUT_NAMES.ENDDATE_TIME && isNaN(+value)) {
      return;
    }
    setInputValues({
      ...inputValues,
      endDate: {
        ...inputValues.endDate,
        date:
          name === CALENDAR_INPUT_NAMES.ENDDATE_DATE
            ? value
            : inputValues.endDate.date,
        month:
          name === CALENDAR_INPUT_NAMES.ENDDATE_MONTH
            ? value
            : inputValues.endDate.month,
        year:
          name === CALENDAR_INPUT_NAMES.ENDDATE_YEAR
            ? value
            : inputValues.endDate.year,
        time:
          name === CALENDAR_INPUT_NAMES.ENDDATE_TIME
            ? value
            : inputValues.endDate.time,
      },
    });
  };

  const onStartDateInputBlur = () => {
    const {
      startDate: { date, month, year, time },
    } = inputValues;
    const startDate = new Date(`${year}-${month}-${date} ${time}`);
    const momentDate = moment(startDate);
    const isValidDate = isValidCalendarInputDate(startDate);

    if (
      !isValidDate ||
      (calendarDatesUI.endDate &&
        momentDate.unix() >= calendarDatesUI.endDate.unix())
    ) {
      setCalendarInputValidation({
        ...calendarInputValidation,
        startDate: false,
      });
      return;
    }
    setCalendarInputValidation({
      ...calendarInputValidation,
      startDate: isValidDate,
    });
    const [formattedYear, formattedMonth, formattedDate, formattedTime] =
      momentDate.format("YYYY-MM-DD-HH:mm").split("-");
    setInputValues({
      ...inputValues,
      startDate: {
        ...inputValues.startDate,
        year: formattedYear,
        month: formattedMonth,
        date: formattedDate,
        time: formattedTime,
      },
    });
    setCalendarDatesUI({
      ...calendarDatesUI,
      startDate: momentDate,
      endDate: calendarDatesUI.endDate,
    });
  };

  const onEndDateInputBlur = () => {
    const {
      endDate: { date, month, year, time },
    } = inputValues;
    const endDate = new Date(`${year}-${month}-${date} ${time}`);
    const momentDate = moment(endDate);
    const isValidDate = isValidCalendarInputDate(endDate);

    if (
      !isValidDate ||
      (calendarDatesUI.startDate &&
        momentDate.unix() <= calendarDatesUI.startDate.unix())
    ) {
      setCalendarInputValidation({
        ...calendarInputValidation,
        endDate: false,
      });
      return;
    }
    setCalendarInputValidation({
      ...calendarInputValidation,
      endDate: isValidDate,
    });
    const [formattedYear, formattedMonth, formattedDate, formattedTime] =
      momentDate.format("YYYY-MM-DD-HH:mm").split("-");
    setInputValues({
      ...inputValues,
      endDate: {
        ...inputValues.endDate,
        year: formattedYear,
        month: formattedMonth,
        date: formattedDate,
        time: formattedTime,
      },
    });
    setCalendarDatesUI({
      ...calendarDatesUI,
      endDate: momentDate,
      startDate: calendarDatesUI.startDate,
    });
  };

  const updateCalendarDatesUI = useCallback(
    ({
      startDate,
      endDate,
    }: {
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
    }) => {
      setCalendarDatesUI({
        startDate,
        endDate,
      });
    },
    [],
  );

  const updateCalendarInputValidation = useCallback(
    ({ startDate, endDate }: { startDate: boolean; endDate: boolean }) => {
      setCalendarInputValidation({
        startDate,
        endDate,
      });
    },
    [],
  );

  useEffect(() => {
    const { startDate, endDate } = calendarDatesUI;
    if (startDate) {
      const formattedValidStartDate = startDate.format("MM/DD/YYYY/HH:mm");
      const [
        startDateMonthValue,
        startDateDateValue,
        startDateYearValue,
        startDateTimeValue,
      ] = formattedValidStartDate.split("/");
      setInputValues((prevState) => ({
        ...prevState,
        startDate: {
          ...prevState.startDate,
          date: startDateDateValue,
          month: startDateMonthValue,
          year: startDateYearValue,
          time: startDateTimeValue,
        },
      }));
    }
    if (endDate) {
      const formattedValidEndDate = endDate.format("MM/DD/YYYY/HH:mm");
      const [
        endDateMonthValue,
        endDateDateValue,
        endDateYearValue,
        endDateTimeValue,
      ] = formattedValidEndDate.split("/");
      setInputValues((prevState) => ({
        ...prevState,
        endDate: {
          ...prevState.endDate,
          date: endDateDateValue,
          month: endDateMonthValue,
          year: endDateYearValue,
          time: endDateTimeValue,
        },
      }));
    }
  }, [calendarDatesUI]);

  return {
    calendarDatesUI,
    updateCalendarDatesUI,
    calendarInputValidation,
    updateCalendarInputValidation,
    inputValues,
    onStartDateInputChange,
    onStartDateInputBlur,
    onEndDateInputChange,
    onEndDateInputBlur,
  };
}

export default useReactDatesOld;
