import {
  Checkbox,
  Chip,
  ListDropdown,
  MoreIcon,
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
  UserProfile,
} from "@cochlearai/ui";
import React, { FC, ReactNode } from "react";
import {
  MEMBER_ROLE_UI_LABELS,
  ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_ITEMS,
} from "~/client/lib/constants";
import {
  getOrganizationMemberListTableColumnWidth,
  refineUserName,
} from "~/client/lib/helpers";
import {
  MEMBER_MORE_TYPE_VALUE,
  MEMBER_ROLE,
  ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE,
  OrganizationMember,
} from "~/client/types/organization";

import { media } from "@cochlearai/util";
import moment from "moment";
import styled, { useTheme } from "styled-components";
import useMultiDropdown from "~/client/hooks/useMultiDropdown";

const Container = styled(Table)`
  min-width: ${media.size.lg};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getOrganizationMemberListTableColumnWidth(p.columnType)};
  padding: 11px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
  vertical-align: middle;
`;

const HeaderItemTypo = styled(Typo)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: left;
`;

const TableRowContainer = styled(TableRow)`
  user-select: none;
  background-color: ${(p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE;
}>`
  max-width: ${(p) => getOrganizationMemberListTableColumnWidth(p.columnType)};
  padding: 20px;
  vertical-align: middle;
  word-break: break-all;
`;

const TableBodyItemText = styled(Typo)`
  width: 100%;
`;

const UserRoundProfile = styled(UserProfile)<{
  role: string;
  pending: boolean;
}>`
  margin-right: 8px;
  min-width: 25px;
  background: ${(p) =>
    p.pending
      ? p.theme.colors.white
      : p.role === MEMBER_ROLE.OWNER
        ? p.theme.colors.black
        : p.theme.colors.grey[60]};
  border: ${(p) => p.pending && `1px dashed ${p.theme.colors.black}`};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const PendingChip = styled(Chip)`
  margin-left: 8px;
  background: ${(p) => p.theme.colors.grey[10]};
`;

interface Props {
  data: OrganizationMember[];
  EmptyComponent?: ReactNode;
  onClickItem: (item: OrganizationMember) => void;
  moreItemType: Array<{ label: string; value: string }>;
  onClickMoreItem: (type: string, member: OrganizationMember) => void;
  selectedItem: OrganizationMember[];
  selectAll: React.ChangeEventHandler<HTMLInputElement>;
  isSelectAll: boolean;
}

const OrganizationMembersTable: FC<Props> = ({
  data,
  EmptyComponent,
  onClickItem,
  moreItemType,
  onClickMoreItem,
  selectedItem,
  isSelectAll,
  selectAll,
}) => {
  const { colors } = useTheme();
  const { isDropdownOpen, openDropdown, closeDropdown } = useMultiDropdown();

  return (
    <Scroll>
      <Container>
        <TableHead>
          <TableRow>
            {ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_ITEMS.map((item, i) => {
              return (
                <TableHeadItem
                  key={item.columnType}
                  component="th"
                  columnType={item.columnType}
                >
                  <Flex>
                    {i === 0 && (
                      <Checkbox
                        selected={isSelectAll}
                        onChange={selectAll}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    <HeaderItemTypo variant="button">
                      {item.label}
                    </HeaderItemTypo>
                  </Flex>
                </TableHeadItem>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            const userName = refineUserName({
              givenName: item.given_name,
              familyName: item.name,
            });
            return (
              <TableRowContainer key={i}>
                <TableBodyItem
                  columnType={ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.NAME}
                >
                  <Flex>
                    <Checkbox
                      selected={selectedItem.includes(item)}
                      onChange={() => onClickItem(item)}
                      style={{
                        visibility:
                          item.role === MEMBER_ROLE.OWNER
                            ? "hidden"
                            : "visible",
                        marginRight: "10px",
                      }}
                    />
                    <UserRoundProfile
                      text={userName.length > 0 ? userName[0] : "U"}
                      size="small"
                      role={item.role}
                      pending={item.pending}
                    />
                    <TableBodyItemText
                      variant="button"
                      style={{ width: "auto" }}
                    >
                      {userName || "User"}
                    </TableBodyItemText>
                    {item.pending && <PendingChip>Pending</PendingChip>}
                  </Flex>
                </TableBodyItem>
                <TableBodyItem
                  columnType={ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.EMAIL}
                >
                  <TableBodyItemText variant="body">
                    {item.email}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.ROLE}
                >
                  <TableBodyItemText variant="body">
                    {MEMBER_ROLE_UI_LABELS[item.role]}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={
                    ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.INVITED_DATE
                  }
                >
                  {item.role === MEMBER_ROLE.VIEWER && (
                    <Flex>
                      <TableBodyItemText variant="body">
                        {!item.pending &&
                          moment(item.joined_date).format("MMM DD, YYYY")}
                      </TableBodyItemText>
                      <ListDropdown
                        align="end"
                        data={
                          item.pending
                            ? moreItemType
                            : moreItemType.filter(
                                (item) =>
                                  item.value !==
                                  MEMBER_MORE_TYPE_VALUE.RESEND_INVITE,
                              )
                        }
                        isOpen={isDropdownOpen(i)}
                        onClickItem={(type) => onClickMoreItem(type, item)}
                        onClose={() => closeDropdown(i)}
                        onClickOutside={() => closeDropdown(i)}
                      >
                        <Flex
                          style={{ height: "20px", cursor: "pointer" }}
                          onClick={() => openDropdown(i)}
                        >
                          <MoreIcon fill={colors.black} />
                        </Flex>
                      </ListDropdown>
                    </Flex>
                  )}
                </TableBodyItem>
              </TableRowContainer>
            );
          })}
        </TableBody>
      </Container>
      {data.length === 0 && EmptyComponent}
    </Scroll>
  );
};

export default OrganizationMembersTable;
