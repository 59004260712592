import { MouseEventHandler, PropsWithChildren, ReactNode } from "react";

import { media } from "@cochlearai/util";
import { ArrowContainer } from "react-tiny-popover";
import styled, { useTheme } from "styled-components";
import { InfoIcon } from "../Icons";
import { Popover } from "../Popover";
import { Typo } from "../Typo";
import { Z_INDEXES } from "../lib/constants";
import { BaseStyleProps } from "../types";

const Flex = styled.div``;

const CustomInfoIcon = styled(InfoIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: ${(p) => p.theme.colors.grey[50]};

  ${media.query.md} {
    margin-left: unset;
  }
`;

const InfoPopoverContent = styled.div`
  background-color: ${(p) => p.theme.colors.grey[90]};
  padding: 16px;
  border-radius: 4px;
  color: ${(p) => p.theme.colors.white};

  ${media.query.md} {
    max-width: 250px;
  }
`;

export interface InfoTooltipProps extends BaseStyleProps {
  open: boolean;
  text: string | ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseOut?: MouseEventHandler<HTMLDivElement>;
}

/**
 * React component used to create a info tooltip.
 * @param style - The style of the tooltip.
 * @param className - The class name of the tooltip.
 * @param open - Whether the tooltip is open or not.
 * @param text - The text of the tooltip.
 * @param position - The position of the tooltip. `top`, `bottom`, `left` or `right`.
 * @param onMouseOver - A callback when the tooltip is opened.
 * @param onMouseOut - A callback when the tooltip is closed.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/info-tooltip--default
 */
export const InfoTooltip = ({
  style,
  className,
  open,
  onMouseOver,
  onMouseOut,
  text,
  position = "top",
  children,
}: PropsWithChildren<InfoTooltipProps>) => {
  const { colors } = useTheme();
  return (
    <Popover
      isOpen={open}
      boundaryInset={0}
      content={({ childRect, popoverRect, position }) => {
        return (
          <ArrowContainer
            childRect={childRect}
            popoverRect={popoverRect}
            position={position}
            arrowSize={6}
            arrowStyle={{ left: "unset" }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            arrowColor={colors.grey[90]}
          >
            <InfoPopoverContent>
              <Typo variant="body">{text}</Typo>
            </InfoPopoverContent>
          </ArrowContainer>
        );
      }}
      align="center"
      positions={[position]}
      reposition
      padding={6}
      containerStyle={{
        backgroundColor: "transparent",
        boxShadow: "unset",
        zIndex: `${Z_INDEXES.POP_OVER}`,
      }}
    >
      <Flex
        style={style}
        className={className}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {children ?? <CustomInfoIcon />}
      </Flex>
    </Popover>
  );
};
