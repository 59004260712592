import getConfigs from "~/client/lib/getConfigs";

function logout() {
  const configs = getConfigs();
  const { REACT_APP_ACCOUNT_LOGOUT_URL: logoutURL } = configs;
  if (!logoutURL) {
    return;
  }
  window.location.href = logoutURL;
}

export default logout;
