import {
  initializeSearchHistory,
  addSearchHistory as dispatchAddSearchHistory,
  removeSearchHistory as dispatchRemoveSearchHistory,
} from "~/client/store/modules/search";
import { useAppDispatch, useAppSelector } from ".";
import { useCallback, useEffect } from "react";

import { STORAGE_KEYS } from "~/client/types/storage";
import { selectedSearchState, storage } from "~/client/lib";
import { Search } from "~/client/types/search";

function useSearch() {
  const { history } = useAppSelector(selectedSearchState);
  const dispatch = useAppDispatch();

  const addSearchHistory = useCallback(
    (history: Search) => {
      dispatch(dispatchAddSearchHistory(history));
    },
    [dispatch],
  );
  const removeSearchHistory = useCallback(
    (searchId: string) => {
      dispatch(dispatchRemoveSearchHistory({ searchId }));
    },
    [dispatch],
  );

  useEffect(() => {
    const searchHistoryStorage = storage.get<string>(
      STORAGE_KEYS.SEARCH_HISTORY,
    );
    if (searchHistoryStorage)
      dispatch(initializeSearchHistory(JSON.parse(searchHistoryStorage)));
  }, [dispatch]);

  return {
    history,
    addSearchHistory,
    removeSearchHistory,
  };
}

export default useSearch;
