import {
  ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE,
  AnalyticsEvent,
} from "~/client/types/analytics";
import {
  Chip,
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
} from "@cochlearai/ui";
import { FC, ReactNode } from "react";
import { Project, ProjectType } from "~/client/types/project";
import { convertSenseTagValueToUILabel, getConfigs } from "~/client/lib";
import styled, { useTheme } from "styled-components";

import { ANALYTICS_EVENT_LIST_TABLE_COLUMN_ITEMS } from "~/client/lib/constants";
import { SenseTags } from "~/client/types/sense";
import { ShowMoreButton } from "~/client/components";
import { getAnalyticsEventListTableColumnWidth } from "~/client/lib/helpers";
import { media } from "@cochlearai/util";
import moment from "moment";

const { REACT_APP_COCHL_WEB_STAGE: stage } = getConfigs();

const Container = styled(Table)`
  min-width: ${media.size.lg};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE;
  isSDK: boolean;
}>`
  width: ${(p) => getAnalyticsEventListTableColumnWidth(p.columnType, p.isSDK)};
  padding: 12.5px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
`;

const HeaderItemTypo = styled(Typo)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: left;
`;

const TableRowContainer = styled(TableRow)<{ selected: boolean }>`
  cursor: pointer;
  background-color: ${(p) =>
    p.selected
      ? (p) => p.theme.colors.semantic.grey[10]
      : (p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
  &:hover {
    background-color: ${(p) => p.theme.colors.semantic.grey[10]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE;
  isSDK: boolean;
}>`
  max-width: ${(p) =>
    getAnalyticsEventListTableColumnWidth(p.columnType, p.isSDK)};
  padding: 20px;
  vertical-align: middle;
`;

const TableBodyItemText = styled(Typo)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const IOTypeText = styled(TableBodyItemText)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

interface Props {
  data?: AnalyticsEvent[];
  currentProject: Project | null;
  selectedEventId?: number;
  EmptyComponent?: ReactNode;
  onClickItem: (item: AnalyticsEvent) => void;
  canLoadMore?: boolean;
  onClickLoadMore?: () => void;
}

const AnalyticsEventTable: FC<Props> = ({
  data,
  currentProject,
  selectedEventId,
  EmptyComponent,
  canLoadMore,
  onClickItem,
  onClickLoadMore,
}) => {
  const { colors } = useTheme();
  const isSDK = currentProject?.product_type === ProjectType.EDGE_SDK;
  const tableHeadItems = isSDK
    ? ANALYTICS_EVENT_LIST_TABLE_COLUMN_ITEMS
    : ANALYTICS_EVENT_LIST_TABLE_COLUMN_ITEMS.filter(
        (item) =>
          item.columnType !==
          ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME,
      );
  return (
    <>
      <Scroll>
        <Container>
          <TableHead>
            <TableRow>
              {tableHeadItems.map((item) => {
                return (
                  <TableHeadItem
                    key={item.columnType}
                    component="th"
                    columnType={item.columnType}
                    isSDK={isSDK}
                  >
                    <HeaderItemTypo variant="body">{item.label}</HeaderItemTypo>
                  </TableHeadItem>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, i) => {
              const { timestamp, tag, io_type, content_type, device_name } =
                item;
              const date = new Date(timestamp * 1000);
              const formattedDate = moment(date).format("MM.DD.YYYY HH:mm:ss");
              return (
                <TableRowContainer
                  selected={selectedEventId === item.event_id}
                  onClick={() => onClickItem(item)}
                  key={`${item.event_id}_${i}`}
                >
                  <TableBodyItem
                    columnType={ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DATE}
                    isSDK={isSDK}
                  >
                    {stage !== "test" && (
                      <TableBodyItemText variant="body">
                        {formattedDate.toString()}
                      </TableBodyItemText>
                    )}
                  </TableBodyItem>
                  <TableBodyItem
                    columnType={ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.TAG}
                    isSDK={isSDK}
                  >
                    <TableBodyItemText variant="body">
                      <Chip>
                        {convertSenseTagValueToUILabel(tag as SenseTags)}
                      </Chip>
                    </TableBodyItemText>
                  </TableBodyItem>
                  {isSDK && (
                    <TableBodyItem
                      columnType={
                        ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME
                      }
                      isSDK={isSDK}
                    >
                      <TableBodyItemText
                        variant="body"
                        style={{ color: colors.grey[70] }}
                      >
                        {device_name}
                      </TableBodyItemText>
                    </TableBodyItem>
                  )}
                  <TableBodyItem
                    columnType={
                      ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.CONTENT_TYPE
                    }
                    isSDK={isSDK}
                  >
                    <TableBodyItemText
                      variant="body"
                      style={{ color: colors.grey[70] }}
                    >
                      {content_type}
                    </TableBodyItemText>
                  </TableBodyItem>
                  <TableBodyItem
                    columnType={ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.IOTYPE}
                    isSDK={isSDK}
                  >
                    <IOTypeText
                      variant="body"
                      style={{ color: colors.grey[70] }}
                    >
                      {io_type}
                    </IOTypeText>
                  </TableBodyItem>
                </TableRowContainer>
              );
            })}
          </TableBody>
        </Container>
        {data?.length === 0 && EmptyComponent}
      </Scroll>
      {canLoadMore && <ShowMoreButton onClick={onClickLoadMore} />}
    </>
  );
};

export default AnalyticsEventTable;
