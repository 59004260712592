import React, { FC } from "react";

import { HeaderMenuItemType } from "~/client/types/header";
import { Link } from "react-router-dom";
import { ProfilePopoverContent } from "~/client/components";
import { SideBar } from "../SideBar";
import styled from "styled-components";
import useAuthenticate from "~/client/hooks/useAuthenticate";
import { useSubscription } from "~/client/hooks";

const MenuSideBar = styled(SideBar)`
  width: calc(80vw);
`;

const MenuSideBarContentContainer = styled.div`
  width: 100%;
`;

const ListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 30px;
  & + & {
    padding-top: 0px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-size: 15.5px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${(p) => p.theme.colors.black};
  font-weight: 500;
`;

interface Props {
  menus: HeaderMenuItemType[];
  visible: boolean;
  onClose: () => void;
}

const HeaderSidebar: FC<Props> = ({ menus, visible, onClose }) => {
  const { formattedUserInfo } = useAuthenticate();
  const { formattedCurrentSubscriptionInfo } = useSubscription();

  const userName = formattedUserInfo.username;
  const userEmail = formattedUserInfo.email;

  return (
    <MenuSideBar visible={visible} onClose={onClose}>
      <MenuSideBarContentContainer>
        <ProfilePopoverContent
          name={userName}
          email={userEmail}
          currentPlanTitle={formattedCurrentSubscriptionInfo.title}
          onClose={onClose}
        />
        {menus.map((menuItem, i) => (
          <ListItem key={i}>
            <Link onClick={onClose} to={menuItem.to}>
              <Text>{menuItem.label}</Text>
            </Link>
          </ListItem>
        ))}
      </MenuSideBarContentContainer>
    </MenuSideBar>
  );
};

export default HeaderSidebar;
