import { PropsWithChildren } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";

type SkeletonVariant = "rectangle" | "circle" | "text";

const Container = styled.div<{
  variant?: SkeletonVariant;
  width?: number;
  height?: number;
}>`
  width: ${(p) => (p.width ? `${p.width}px` : "0px")};
  height: ${(p) => (p.height ? `${p.height}px` : "0px")};

  background: ${(p) => p.theme.colors.grey[20]};

  border-radius: ${(p) => {
    switch (p.variant) {
      case "circle":
        return "50%";
      case "rectangle":
        return "0px";
      case "text":
        return "4px";
      default:
        return "0px";
    }
  }};
`;

export interface SkeletonProps extends BaseStyleProps {
  variant?: SkeletonVariant;
  width?: number;
  height?: number;
}
/**
 * React component used to create a skeleton.
 * @param variant - Variant of the skeleton.`reactangle` | `circle` | `text`.
 * @param width - Width of the skeleton.
 * @param height - Height of the skeleton.
 * @param style - Style of the skeleton.
 * @param className - Class name of the skeleton.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/skeleton--default
 */
export const Skeleton = ({
  variant = "text",
  width,
  height,
  className,
  style,
  children,
}: PropsWithChildren<SkeletonProps>) => {
  return (
    <Container
      data-testid="@cochl-ui/SkeletonContainer"
      className={className}
      width={width}
      height={height}
      variant={variant}
      style={style}
    >
      {children}
    </Container>
  );
};
