import { setServerError } from "~/client/store/modules";
import { useCallback, useState } from "react";

import { APIError } from "~/client/types";
import { fetchRemoveMediumSetting } from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";

function useRemoveMedium() {
  const dispatch = useAppDispatch();
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const remove = useCallback(
    async (id) => {
      if (isRemoving || !id) return;
      setIsRemoving(true);
      try {
        await fetchRemoveMediumSetting({
          params: {
            id,
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setIsRemoving(false);
      }
    },
    [dispatch, isRemoving],
  );

  return {
    remove,
    isValidating: isRemoving,
  };
}

export default useRemoveMedium;
