import { useCallback, useEffect, useRef, useState } from "react";

function useSideBarAnimation({ onSlideOut }: { onSlideOut?: () => void }) {
  const [sideBarAnimation, setSideBarAnimation] = useState<
    "slideIn" | "slideOut" | undefined
  >(undefined);
  const [sideBarVisibility, setSideBarVisibility] = useState<
    "visible" | "invisible"
  >("invisible");

  const animationTimeoutIdRef: { current: NodeJS.Timeout | null } =
    useRef(null);

  const toggleSideBar = () => {
    switch (sideBarAnimation) {
      case "slideIn":
        setSideBarAnimation("slideOut");
        break;
      case "slideOut":
        setSideBarAnimation("slideIn");
        break;
      default:
        setSideBarAnimation("slideIn");
        break;
    }
  };

  const openSideBar = useCallback(() => {
    setSideBarAnimation("slideIn");
  }, []);

  const closeSideBar = useCallback(() => {
    setSideBarAnimation("slideOut");
  }, []);

  useEffect(() => {
    if (animationTimeoutIdRef.current) {
      clearTimeout(animationTimeoutIdRef.current);
    }
    if (sideBarAnimation === "slideOut") {
      animationTimeoutIdRef.current = setTimeout(() => {
        setSideBarVisibility("invisible");
        if (onSlideOut) {
          onSlideOut();
        }
        if (animationTimeoutIdRef.current) {
          clearTimeout(animationTimeoutIdRef.current);
        }
      }, 400);
    }
    if (sideBarAnimation === "slideIn") {
      setSideBarVisibility("visible");
    }
  }, [sideBarAnimation]);

  return {
    sideBarAnimation,
    sideBarVisibility,
    toggleSideBar,
    openSideBar,
    closeSideBar,
  };
}

export default useSideBarAnimation;
