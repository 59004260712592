import { useCallback, useEffect, useState } from "react";
import { fetchCreateMediumSetting } from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";
import {
  APIError,
  APIErrorData,
  API_ERROR_STATUS,
  MediumType,
  TagInterval,
  TAG_INTERVAL_TIME_TYPE_VALUE,
} from "~/client/types";
import { setClientError, setClientInfo } from "~/client/store/modules";
import { checkURL } from "~/client/lib/helpers";
import { useParams } from "react-router-dom";

interface Validation {
  validating: boolean;
  validated: boolean;
  error: string;
}

const initialValidation: Validation = {
  validating: false,
  validated: false,
  error: "",
};

function useWebhook({
  triggerCondition,
}: {
  triggerCondition?: TagInterval[];
}) {
  const params = useParams<{ projectId?: string }>();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validation, setValidation] = useState<Validation>(initialValidation);
  const [url, setUrl] = useState<string>("");

  const validate = useCallback(() => {
    const onlyText = url.split(" ").join("");
    if (onlyText === "") {
      setValidation({
        validating: url.length > 0,
        validated: false,
        error: `Input at least 1 characters`,
      });
      return;
    }

    if (checkURL(onlyText)) {
      setValidation({
        validating: true,
        validated: true,
        error: "",
      });
    } else {
      setValidation({
        validating: true,
        validated: false,
        error: "Invalid url address",
      });
    }
  }, [url]);

  const addWebhook = useCallback(async () => {
    if (isLoading) return;
    if (!validation.validating) {
      setValidation({
        ...validation,
        validating: true,
      });
    }
    if (
      !validation.validated ||
      triggerCondition?.length === 0 ||
      !params.projectId
    )
      return;

    setIsLoading(true);
    try {
      await fetchCreateMediumSetting({
        params: {
          medium: {
            medium_type: MediumType.WEBHOOK,
            urls: [url],
          },
          "notification-configuration": {
            project_id: params.projectId,
          },
          "trigger-conditions": triggerCondition?.map((data) => ({
            tag_name: data.value,
            ...(data.interval && {
              interval:
                data.interval *
                (data.interval_unit === TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES
                  ? 60
                  : 1),
            }),
          })),
        },
      });

      dispatch(setClientInfo({ text: "Webhook has been created" }));
      setValidation(initialValidation);
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      if (error.response?.status === API_ERROR_STATUS.BAD_REQUEST) {
        const errorData = error.response?.data as APIErrorData;
        const message =
          typeof errorData.details === "object"
            ? errorData.details.message
            : errorData.details;

        if (errorData && message) {
          dispatch(
            setClientError({
              text: message,
            }),
          );
        } else {
          throw e;
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    isLoading,
    validation,
    triggerCondition,
    params.projectId,
    url,
    dispatch,
  ]);

  useEffect(() => {
    validate();
  }, [url, validate]);

  return {
    addWebhook,
    url,
    setUrl,
    validation,
    setValidation,
    isLoading,
  };
}

export default useWebhook;
