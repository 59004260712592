import React, { FC, SVGProps } from "react";

const EdgeSDKIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20 10L20 21L9 21L9 17H8V22L21 22L21 9H8V14H9L9 10L20 10Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.5C1 3.70948 1.85888 4.71836 3 4.94999L3 15L3 16H4H14V15L4 15L4 4.94999C5.14112 4.71836 6 3.70948 6 2.5C6 1.11929 4.88071 0 3.5 0C2.11929 0 1 1.11929 1 2.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 16V15H26H22V16L26 16L26 25.05C24.8589 25.2816 24 26.2905 24 27.5C24 28.8807 25.1193 30 26.5 30C27.8807 30 29 28.8807 29 27.5C29 26.2905 28.1411 25.2816 27 25.05L27 16Z"
    />
  </svg>
);

export default EdgeSDKIcon;
