import React, { FC, SVGProps } from "react";

const CardAddIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="#4B68FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5 24.5V15H25.5V24.5H35V25.5H25.5V35H24.5V25.5H15V24.5H24.5Z"
      fill="white"
    />
  </svg>
);

export default CardAddIcon;
