import { PropsWithChildren } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";

const Container = styled.div<{ percent?: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: move 1s ease-in-out infinite;
  background: ${(p) =>
    p.percent
      ? `conic-gradient(${p.theme.colors.blue[60]} ${p.percent}%, 0, #ecf0f1 ${(
          100 - p.percent
        ).toString()}%)`
      : ""};
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.white};
  height: 85%;
  width: 85%;
  border-radius: 50%;
`;

export interface CircleProgressProps extends BaseStyleProps {
  percent: number;
}

/**
 * React component used to create a circle progress layout.
 * @param style      - The style of the circle progress.
 * @param percent    - The percent of the circle progress.
 * @param className  - The className of the circle progress.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/circle-progress--default
 */
export const CircleProgress = ({
  children,
  style,
  percent,
  className,
}: PropsWithChildren<CircleProgressProps>) => {
  return (
    <Container percent={percent} style={style} className={className}>
      <Inner>{children}</Inner>
    </Container>
  );
};
