export interface Voice {
  speaker: string;
  added_time: string;
  uploading?: boolean;
}

export interface VoiceFile {
  file: File;
  length?: number;
}

export enum VOICE_MORE_TYPE_VALUE {
  DELETE_VOICE = "DELETE_VOICE",
  VIEW_DETAIL = "VIEW_DETAIL",
}

export enum VOICE_SORT_TYPE_VALUE {
  ASCEND = "ASCEND",
  DESCEND = "DESCEND",
  CREATED = "CREATED",
}

export enum UPLOAD_VOICE_TABLE_COLUMN_TYPE {
  FILE_NAME = "FILE_NAME",
  FILE_SIZE = "FILE_SIZE",
  FILE_LENGTH = "FILE_LENGTH",
  EXTRA = "EXTRA",
}

export interface UploadVoiceTableColumnItem {
  columnType: UPLOAD_VOICE_TABLE_COLUMN_TYPE;
  label: string;
}

export interface Transcription {
  confidence: number;
  first_word_start_msec: number;
  first_word_start_time: string;
  idx: number;
  last_word_end_msec: number;
  last_word_end_time: string;
  org_pub_id: string;
  speaker_name: string;
  speaker_score: number;
  speaker_tag: number;
  transcript: string;
}

export enum RECORD_PHASE {
  BEFORE_START = 0,
  PHASE_1 = 1,
  PHASE_2 = 2,
  PHASE_3 = 3,
  PHASE_4 = 4,
  PHASE_5 = 5,
  COMPLETE = 6,
}

export enum ONBOARD_STATUS {
  STATUS_1 = 1,
  STATUS_2 = 2,
  STATUS_3 = 3,
  STATUS_4 = 4,
  VIEWED = 5,
}
