import { components, MultiValueRemoveProps } from "react-select";
import { useTheme } from "styled-components";
import { ToastCloseIcon } from "../Icons";

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  const { colors } = useTheme();
  return (
    <components.MultiValueRemove {...props}>
      <ToastCloseIcon
        style={{
          cursor: "pointer",
          pointerEvents: "none",
        }}
        width={6.7}
        height={6.7}
        fill={colors.black}
      />
    </components.MultiValueRemove>
  );
};
