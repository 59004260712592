import React, { FC, SVGProps } from "react";

const ToastErrorIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={props.fill || "#E02E2E"} />
    <path
      d="M9.45455 4L10 4.54545L4.54549 9.99996L4.00004 9.45451L9.45455 4Z"
      fill="white"
    />
    <path
      d="M9.99996 9.45461L9.45451 10.0001L4 4.54555L4.54545 4.0001L9.99996 9.45461Z"
      fill="white"
    />
  </svg>
);

export default ToastErrorIcon;
