import React, { FC } from "react";

import { CustomDayPickerRangeControllerShape } from "../types/calendar";
import { DayPickerRangeController } from "../DayPickerRangeController";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const CustomWrapper = styled.div`
  position: relative !important;

  .DayPicker {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 4px;
    margin: auto;
    min-height: 352px;

    td {
      padding: 0;
    }
  }

  .CalendarMonth,
  .CalendarMonthGrid {
    background-color: ${(p) => p.theme.colors.white};
  }

  .CalendarDay {
    font-size: 12px !important;
    line-height: 15px !important;
  }

  .CalendarDay__default {
    vertical-align: middle;
    border: none !important;
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.black};

    &:hover {
      background-color: ${(p) => p.theme.colors.white} !important;
      color: ${(p) => p.theme.colors.blue[60]};
    }
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    color: ${(p) => p.theme.colors.grey[50]};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    color: ${(p) => p.theme.colors.white};
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    &:hover {
      & > div {
        background-color: ${(p) => p.theme.colors.grey[10]} !important;
        color: ${(p) => p.theme.colors.white} !important;
      }
    }
    & > div {
      background-color: ${(p) => p.theme.colors.grey[10]} !important;
      position: relative;
      width: 32px !important;
      margin: auto;
    }
  }

  .CalendarDay__selected_start {
    & > div {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    &:not(.CalendarDay__selected_end) > div {
      &::after {
        content: "";
        background-color: ${(p) => p.theme.colors.grey[10]};
        width: 80%;
        height: 100%;
        right: -10px;
        position: absolute;
      }
    }
  }

  .CalendarDay__selected_end {
    & > div {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    &:not(.CalendarDay__selected_start) > div {
      &::after {
        content: "";
        background-color: ${(p) => p.theme.colors.grey[10]};
        width: 80%;
        height: 100%;
        left: -10px;
        position: absolute;
      }
    }
  }

  .CalendarDay__selected_span {
    & > div {
      background-color: ${(p) => p.theme.colors.grey[10]} !important;
      color: ${(p) => p.theme.colors.blue[60]} !important;
    }
  }

  .CalendarMonth_caption {
    padding-top: 25px !important;
    padding-bottom: 50px !important;

    & > strong {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: ${(p) => p.theme.colors.black} !important;
    }
  }

  .DayPicker_weekHeader {
    top: 80px !important;

    small {
      font-size: 12px !important;
      line-height: 15px !important;
      color: ${(p) => p.theme.colors.grey[30]} !important;
    }
  }

  .CalendarDay__today > div > div {
    background-color: ${(p) => p.theme.colors.grey[30]};
    border-radius: 50% !important;
  }

  .CalendarDay__outside {
    color: ${(p) => p.theme.colors.grey[30]};
  }

  ${media.query.md} {
    width: calc(100%) !important;
  }
`;

const CustomBottomWrapper = styled.div`
  display: flex !important;
  align-items: center !important;
  padding: 16px !important;
  border-top: 1px solid ${(p) => p.theme.colors.grey[20]};

  ${media.query.md} {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`;

const InputWrapper = styled.div`
  display: flex !important;
  align-items: center !important;
  flex: 1 !important;

  ${media.query.md} {
    width: 100% !important;
    justify-content: space-between !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex !important;
  align-items: center !important;
  flex-direction: row-reverse !important;

  ${media.query.md} {
    margin-top: 14px !important;
    margin-left: auto !important;
  }
`;

const Calendar: FC<CustomDayPickerRangeControllerShape> = ({
  renderInputComponents,
  renderButtonComonents,
  style,
  className,
  ...reactDatesProps
}) => {
  return (
    <CustomWrapper style={style} className={className}>
      <DayPickerRangeController {...reactDatesProps} />
      <CustomBottomWrapper>
        {renderInputComponents && (
          <InputWrapper>{renderInputComponents()}</InputWrapper>
        )}
        {renderButtonComonents && (
          <ButtonWrapper>{renderButtonComonents()}</ButtonWrapper>
        )}
      </CustomBottomWrapper>
    </CustomWrapper>
  );
};

export default Calendar;
