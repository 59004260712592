import React, { FC } from "react";
import {
  DayPickerRangeControllerShape as ReactDatesDayPickerControllerShape,
  DayPickerRangeController as ReactDatesDayPickerRangeController,
} from "react-dates";

const DayPickerRangeController: FC<ReactDatesDayPickerControllerShape> = (
  props,
) => {
  return <ReactDatesDayPickerRangeController {...props} />;
};

export default DayPickerRangeController;
