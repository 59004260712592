import {
  CLOUD_API_TIME_FOR_PRICE,
  CLOUD_API_USD_PRICE,
  FREE_USAGE_MIN,
} from "~/client/lib/constants";
import { PlanType, Subscription } from "~/client/types";
import { convertUsageTimeFormat, numberWithCommas } from ".";

import React from "react";

interface Props {
  usage: number;
  fileLength: number;
  subscription: Subscription;
}

interface PriceInfo {
  fileLengthText: string;
  originalPrice: string;
  discountedPrice: string;
  description: React.ReactNode;
  isOverUsed: boolean;
}

function getUploadPriceInfo({
  usage,
  fileLength,
  subscription,
}: Props): PriceInfo {
  let description, preDescription;
  let discountedPrice = "";
  const unitPriceVal =
    subscription.prices?.api.base_price ?? CLOUD_API_USD_PRICE;
  const fileLengthText = convertUsageTimeFormat({
    seconds: fileLength,
    showMin: false,
  }).formattedString;
  const originalPrice = numberWithCommas({
    value: Math.ceil(fileLength / CLOUD_API_TIME_FOR_PRICE) * unitPriceVal,
    maxDecimal: 3,
    currency: "usd",
  });
  const totalUsage = (usage + fileLength) / 60;
  const isOverUsed = FREE_USAGE_MIN < totalUsage;

  switch (subscription.plan_id) {
    case PlanType.FREE:
      if (!isOverUsed) {
        discountedPrice = "$0";
        description = `You may use API upto ${FREE_USAGE_MIN} minutes for free.`;
      } else {
        description = `Your total usage is over ${FREE_USAGE_MIN}min. Upgrade plan for
        analyzing.`;
      }
      break;
    case PlanType.ENTERPRISE:
      description = (
        <span>
          {`(${fileLengthText}) x ($${unitPriceVal} per ${CLOUD_API_TIME_FOR_PRICE} sec) = ${originalPrice}`}
          <br />
          {`(the amount less than ${CLOUD_API_TIME_FOR_PRICE}-second unit is rounded up)`}
        </span>
      );
      break;
    case PlanType.STANDARD:
      if (totalUsage <= FREE_USAGE_MIN) {
        discountedPrice = "$0";
        preDescription = (
          <span>
            {`You may use API upto ${FREE_USAGE_MIN} minutes for free.`}
            <br />
            <br />
          </span>
        );
      } else {
        const restOfFreeUsage = FREE_USAGE_MIN * 60 - usage;
        if (restOfFreeUsage > 0) {
          discountedPrice = numberWithCommas({
            value:
              Math.ceil(
                (fileLength - restOfFreeUsage) / CLOUD_API_TIME_FOR_PRICE,
              ) * unitPriceVal,
            maxDecimal: 3,
            currency: "usd",
          });
          preDescription = (
            <span>
              {`You may use API upto ${FREE_USAGE_MIN} minutes for free (${
                convertUsageTimeFormat({
                  seconds: restOfFreeUsage,
                  showMin: false,
                }).formattedString
              } of your file gets discounted)`}
              <br />
              <br />
            </span>
          );
        }
      }

      description = (
        <>
          {preDescription}
          <span>
            {`(${fileLengthText}) x ($${unitPriceVal} per ${CLOUD_API_TIME_FOR_PRICE} sec) = ${originalPrice}`}
            <br />
            {`(the amount less than ${CLOUD_API_TIME_FOR_PRICE}-second unit is rounded up)`}
          </span>
        </>
      );
      break;
    default:
      break;
  }

  return {
    fileLengthText,
    originalPrice,
    discountedPrice,
    description,
    isOverUsed,
  };
}

export default getUploadPriceInfo;
