import { fetchGetUser, fetchGetWhoami } from "~/client/lib/api";
import {
  initializeAuthentication,
  setServerError,
} from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect } from "react";

import { APIError } from "~/client/types";
import { SWR_KEY } from "~/client/types/swrKey";
import { selectedAuthState } from "~/client/lib";
import useSWR from "swr";

function useAuthenticate() {
  const dispatch = useAppDispatch();
  const { user, customer } = useAppSelector(selectedAuthState);

  const { data, error } = useSWR(
    user || customer ? null : SWR_KEY.AUTH_INITIALIZE,
    async () => {
      const [responseUser, responseCustomer] = await Promise.all([
        fetchGetUser({ params: {} }),
        fetchGetWhoami({ params: {} }),
      ]);
      const { data: dataUser } = responseUser;
      const { data: dataCustomer } = responseCustomer;
      return {
        user: dataUser,
        customer: dataCustomer,
      };
    },
    {
      suspense: true,
    },
  );

  const getUserName = useCallback(() => {
    let userName = "";
    if (!user) return userName;

    const { given_name, family_name } = user.identity.traits;
    userName = `${given_name}${
      given_name && family_name ? " " : ""
    }${family_name}`;

    return userName;
  }, [user]);

  useEffect(() => {
    if (!data) return;
    dispatch(initializeAuthentication(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    user,
    customer,
    formattedUserInfo: {
      username: getUserName(),
      email: user ? user.identity.traits.email : "",
    },
  };
}

export default useAuthenticate;
