import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { STORAGE_KEYS } from "~/client/types/storage";
import { Search } from "~/client/types/search";
import storage from "~/client/lib/storage";

interface SearchState {
  history: Search[];
  searchText: string | null;
}

const initialState: SearchState = {
  history: [],
  searchText: null,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    initializeSearchHistory: (state, action: PayloadAction<Search[]>) => {
      state.history = action.payload;
    },
    addSearchHistory: (state, action: PayloadAction<Search>) => {
      if (state.history) {
        if (!state.history.find((item) => item.text === action.payload.text)) {
          state.history = [action.payload, ...state.history];
        }
      } else {
        state.history = [action.payload];
      }
      storage.set(STORAGE_KEYS.SEARCH_HISTORY, state.history);
    },
    removeSearchHistory: (
      state,
      action: PayloadAction<{ searchId: string }>,
    ) => {
      if (state.history) {
        state.history = state.history.filter(
          (item) => item.id !== action.payload.searchId,
        );
        storage.set(STORAGE_KEYS.SEARCH_HISTORY, state.history);
      }
    },
    resetSearchHistory: (state) => {
      state.history = initialState.history;
    },
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});

export const {
  initializeSearchHistory,
  addSearchHistory,
  removeSearchHistory,
  resetSearchHistory,
  updateSearchText,
} = searchSlice.actions;

export const { reducer: searchReducer } = searchSlice;
