import detector from "i18next-browser-languagedetector";
import enCommon from "./locales/en/common.json";
import frCommon from "./locales/fr/common.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import koCommon from "./locales/ko/common.json";
// import senseTranslationJSON from "./locales/sense.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    // sense: senseTranslationJSON,
    common: enCommon,
  },
  fr: {
    // sense: senseTranslationJSON,
    common: frCommon,
  },
  ko: {
    // sense: senseTranslationJSON,
    common: koCommon,
  },
};

void i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // supportedLngs: ["en", "fr", "ko"],
    supportedLngs: ["en"], // temporarily rollback to English only
    keySeparator: false,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
