import React, { FC, SVGProps } from "react";

const PauseIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={props.fill ?? "#4B68FF"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="5" y="3" width="8.87421" height="23.6646" rx="1.875" />
      <rect x="16.8322" y="3" width="8.87421" height="23.6646" rx="1.875" />
    </svg>
  );
};

export default PauseIcon;
