import { useEffect, useState } from "react";

import { APIError } from "~/client/types";
import { Organization } from "~/client/types/organization";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetJoinedOrganization } from "~/client/api";
import { refineUserName } from "~/client/lib";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useSWR from "swr";

function useOrganization() {
  const dispatch = useAppDispatch();
  const [organization, setOrganization] = useState<Organization[]>([]);
  const { data, error, isValidating } = useSWR(
    SWR_KEY.ORGANIZATION_INITIALIZE_ORGANIZATION_LIST,
    async () => {
      const res = await fetchGetJoinedOrganization({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setOrganization(
      data
        .filter((data) => !data.is_admin)
        .sort((a, b) => {
          const prevName = refineUserName({
            givenName: a.owner_given_name,
            familyName: a.owner_name,
          });
          const nextName = refineUserName({
            givenName: b.owner_given_name,
            familyName: b.owner_name,
          });
          return prevName.toLowerCase().localeCompare(nextName.toLowerCase());
        }),
    );
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return { organization, isValidating };
}

export default useOrganization;
