import { Checkbox, Scroll, Typo } from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import React, { CSSProperties, FC } from "react";
import styled, { useTheme } from "styled-components";
import { SDKTag, SenseCategory } from "~/client/types";
import { TagCategoryTab, TagCategoryTabItem } from ".";

const TagContainer = styled(Scroll)`
  margin: 2px auto;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 16px;
  width: 100%;
  max-height: 320px;

  ${media.query.lg} {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
`;

const SelectAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  margin: 20px;
`;

interface Props {
  tags: SDKTag[];
  onClickItem?: (item: SDKTag) => void;
  selectedItems?: SDKTag[];
  style?: CSSProperties;
  selectedCategory: SenseCategory;
  onClickCategory: (item: SenseCategory) => void;
  isSelectAll?: boolean;
  onClickSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TagCategory: FC<Props> = ({
  tags,
  onClickItem,
  selectedItems,
  style,
  selectedCategory,
  onClickCategory,
  isSelectAll,
  onClickSelectAll,
}) => {
  const { colors } = useTheme();
  const isSelectable = !!(onClickItem && selectedItems);

  return (
    <div>
      <TagCategoryTab
        data={Object.values(SenseCategory) as SenseCategory[]}
        renderItem={(item, index) => (
          <TagCategoryTabItem
            key={`${index}_${item}`}
            selected={item === selectedCategory}
            onClick={() => onClickCategory(item)}
            title={item}
          />
        )}
      />
      {onClickSelectAll && (
        <SelectAll>
          <Checkbox
            selected={isSelectAll ?? false}
            onChange={onClickSelectAll}
            label="Select All in Category"
          />
        </SelectAll>
      )}
      <TagContainer style={style}>
        {tags
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((tag, index) => (
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={`${tag.value}_${index}`}
            >
              {isSelectable ? (
                <Checkbox
                  selected={
                    !!selectedItems.find((data) => data.value === tag.value)
                  }
                  onChange={() => onClickItem(tag)}
                  label={tag.label}
                />
              ) : (
                <Typo
                  variant="body"
                  style={{
                    color: colors.grey[90],
                    display: "list-item",
                    listStylePosition: "inside",
                  }}
                >
                  {tag.label}
                </Typo>
              )}
            </div>
          ))}
      </TagContainer>
    </div>
  );
};

export default TagCategory;
