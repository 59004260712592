import { Card, HyperlinkButton, Typo } from "@cochlearai/ui";
import React, { FC } from "react";
import styled, { useTheme } from "styled-components";

import { media } from "@cochlearai/util";
import { Link } from "react-router-dom";

const Container = styled(Card)`
  border: 1px solid ${(p) => p.theme.colors.grey[20]};
  box-shadow: none;
  padding: 40px;
  & + & {
    margin-top: 30px;
  }
`;

const SenseUsageInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${media.query.lg} {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
`;

const CaptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: auto;

  ${media.query.lg} {
    margin: 0;
  }
`;

interface Props {
  className?: string;
  title: string;
  caption?: string;
  description: string;
  children?: React.ReactNode;
  linkTo?: string;
  linkText?: string;
}

const ProjectOverviewUsageCard: FC<Props> = ({
  className,
  title,
  caption,
  description,
  children,
  linkTo,
  linkText,
}) => {
  const { colors } = useTheme();
  return (
    <Container className={className}>
      <SenseUsageInfo>
        <Typo variant="h3">{title}</Typo>
        <CaptionWrapper>
          {caption && (
            <Typo variant="h5" style={{ color: colors.grey[70] }}>
              {caption}
            </Typo>
          )}
          {linkTo && linkText && (
            <Link to={linkTo}>
              <HyperlinkButton text={linkText} rightArrow />
            </Link>
          )}
        </CaptionWrapper>
        <Typo variant="h5">{description}</Typo>
      </SenseUsageInfo>
      {children}
    </Container>
  );
};

export default ProjectOverviewUsageCard;
