import React, { FC } from "react";

import { Z_INDEXES } from "~/client/lib/constants";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 66px;
  background-color: ${(p) => p.theme.colors.white};
  z-index: ${Z_INDEXES.FOOTER};
`;

const Inner = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: ${media.size.lg};
  padding-left: unset;
  padding-right: unset;
  color: ${(p) => p.theme.colors.black};

  ${media.query.xl} {
    width: ${media.size.lg};
    padding-left: unset;
    padding-right: unset;
  }

  ${media.query.lg} {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }

  ${media.query.md} {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  ${media.query.sm} {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

interface Props {
  copyrightText: string;
}

const Footer: FC<Props> = ({ copyrightText }) => {
  return (
    <FooterContainer>
      <Inner>{copyrightText}</Inner>
    </FooterContainer>
  );
};

export default Footer;
