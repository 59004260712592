import { PropsWithChildren, ReactNode } from "react";
import styled, { useTheme } from "styled-components";
import { Button } from "../Button";
import { ModalCloseIcon } from "../Icons";
import { Modal } from "../Modal";
import { Typo } from "../Typo";
import { BaseStyleProps } from "../types";

const ModalWithPadding = styled(Modal)`
  padding: 50px;
`;

const TitleAndCloseContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TitleText = styled(Typo)``;

const SubTitleText = styled(Typo)<{ warning?: boolean }>`
  margin-top: 15px;
  color: ${(p) => (p.warning ? p.theme.colors.red : p.theme.colors.grey[70])};
  white-space: initial;
  max-width: 100%;
`;

const ChildrenContainer = styled.div`
  margin-top: 30px;
`;

const ButtonsContainer = styled.div<{ $hasContent?: boolean }>`
  margin-top: ${(p) => (p.$hasContent ? "30px" : "80px")};
  display: flex;
  align-items: center;
`;

const BottomButton = styled(Button)`
  flex: 1;
  height: 50px;
`;

export interface ConfirmModalProps extends BaseStyleProps {
  open: boolean;
  onClose: () => void;
  onAbort?: () => void;
  onConfirm?: () => void;
  title: string;
  subTitle?: string | ReactNode;
  confirmText?: string;
  abortText?: string;
  warning?: boolean;
  confirmDisabled?: boolean;
  size?: "small" | "medium" | "large";
}

/**
 * React component used to create a confirm modal.
 * @param open       - The open state of the modal.
 * @param onClose    - The function to call when the modal is closed.
 * @param onAbort    - The function to call when the modal is aborted.
 * @param onConfirm  - The function to call when the modal is confirmed.
 * @param title      - The title of the modal.
 * @param subTitle   - The sub title of the modal.
 * @param confirmText - The text of the confirm button.
 * @param abortText   - The text of the abort button.
 * @param warning    - Whether the modal is a warning modal.
 * @param confirmDisabled - Whether the confirm button is disabled.
 * @param size       - The size of the modal. `small`, `medium` or `large`.
 * @param style      - The style of the modal.
 * @param className  - The className of the modal.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/confirm-modal--default
 */
export const ConfirmModal = ({
  open,
  onClose,
  onAbort,
  onConfirm,
  title,
  subTitle,
  children,
  confirmText,
  abortText,
  warning,
  confirmDisabled,
  size = "medium",
  style,
  className,
}: PropsWithChildren<ConfirmModalProps>) => {
  const { colors } = useTheme();
  return (
    <ModalWithPadding
      open={open}
      onClose={onClose}
      size={size}
      style={style}
      className={className}
    >
      <TitleAndCloseContainer>
        <TitleText variant="h1">{title}</TitleText>
        <ModalCloseIcon
          data-testid="modal-close"
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            minWidth: "16px",
            minHeight: "16px",
          }}
          fill={colors.black}
          onClick={onClose}
        />
      </TitleAndCloseContainer>
      {subTitle && typeof subTitle === "string" && (
        <SubTitleText variant="h5" warning={warning}>
          {subTitle}
        </SubTitleText>
      )}
      {subTitle && typeof subTitle === "object" && subTitle}
      <ChildrenContainer>{children}</ChildrenContainer>
      {(onAbort || onConfirm) && (
        <ButtonsContainer $hasContent={typeof children !== "undefined"}>
          {onAbort && (
            <BottomButton color="secondary" onClick={onAbort}>
              {abortText ? abortText : "Cancel"}
            </BottomButton>
          )}
          {onConfirm && (
            <BottomButton
              onClick={onConfirm}
              disabled={confirmDisabled}
              color={warning ? "danger" : "primary"}
              style={{ marginLeft: onAbort ? "21px" : "unset" }}
            >
              {confirmText ? confirmText : "Continue"}
            </BottomButton>
          )}
        </ButtonsContainer>
      )}
    </ModalWithPadding>
  );
};
