import { Skeleton } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";

const FilterContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
`;

const FilterSessionIdInputSkeleton = styled(Skeleton)`
  width: 588px;
  height: 30px;
  border-radius: 4px;
`;

const FilterRightInnerContainer = styled.div`
  display: flex;
`;

const FilterRightSkeleton = styled(Skeleton)<{ size: "lg" | "md" | "sm" }>`
  height: 30px;
  width: ${(p) => {
    switch (p.size) {
      case "lg":
        return "280px";
      case "md":
        return "84px";
      case "sm":
      default:
        return "30px";
    }
  }};
  border-radius: 4px;

  & + & {
    margin-left: 11px;
  }
`;

const AnalyticsFilterSkeleton: FC = () => {
  return (
    <FilterContainer>
      <FilterSessionIdInputSkeleton variant="rectangle" />
      <FilterRightInnerContainer>
        <FilterRightSkeleton variant="rectangle" size="lg" />
        <FilterRightSkeleton variant="rectangle" size="md" />
        <FilterRightSkeleton variant="rectangle" size="sm" />
      </FilterRightInnerContainer>
    </FilterContainer>
  );
};

export default AnalyticsFilterSkeleton;
