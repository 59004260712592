import React, { FC } from "react";
import styled from "styled-components";
import { AnalyticsInsightTagCardSkeleton } from "~/client/components";

const InsightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

const AnalyticsInsightTagCardListSkeleton: FC = () => {
  return (
    <InsightContainer>
      {Array.from({ length: 5 }).map((_, index) => {
        return <AnalyticsInsightTagCardSkeleton key={index} />;
      })}
    </InsightContainer>
  );
};

export default AnalyticsInsightTagCardListSkeleton;
