import { ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE } from "~/client/types/analytics";

function getAnalyticsEventListTableColumnWidth(
  columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE,
  isSDK: boolean,
) {
  switch (columnType) {
    case ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.TAG:
      return isSDK ? "300px" : "460px";
    case ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME:
      return "200px";
    case ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.DATE:
    case ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.CONTENT_TYPE:
      return "270px";
    case ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE.IOTYPE:
      return isSDK ? "160px" : "200px";
    default:
      return "0px";
  }
}

export default getAnalyticsEventListTableColumnWidth;
