import { ProjectType } from "./project";

export enum PlanType {
  FREE = "free",
  STANDARD = "standard",
  ENTERPRISE = "enterprise",
}

export interface Plan {
  id: PlanType;
  public: boolean;
  default_plan: boolean;
}

export type SubscriptionProjectType =
  | ProjectType.CLOUD_API
  | ProjectType.EDGE_SDK;

export type ProductPrice = {
  tag_unit_price?: number;
  base_price?: number;
};

export interface Subscription {
  id: number;
  plan_id: PlanType;
  created_at: string;
  active: boolean;
  product_types: SubscriptionProjectType[];
  prices?: {
    [ProjectType.CLOUD_API]: ProductPrice;
    [ProjectType.EDGE_SDK]: ProductPrice;
  };
  current_costs?: SubscriptionCurrentCosts[];
}

export interface SubscriptionCurrentCosts {
  product: "sense-api" | "sense-sdk";
  amount: number;
  currency: "usd" | string;
}
