import React, { FC } from "react";

import { DropdownButton } from "@cochlearai/ui";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  onClick?: () => void;
  className?: string;
}

const AllTagButton: FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ onClick, className }, ref) => {
    return (
      <div
        ref={ref}
        className={className}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "unset" }}
      >
        <DropdownButton>All tags</DropdownButton>
      </div>
    );
  },
);

export default AllTagButton;
