import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import getConfigs from "../getConfigs";

const {
  REACT_APP_GA_TRACKING_ID,
  REACT_APP_COCHL_WEB_STAGE,
  REACT_APP_GA4_MEASUREMENT_ID,
} = getConfigs();

// init ga universal
if (REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(REACT_APP_GA_TRACKING_ID, {
    debug: REACT_APP_COCHL_WEB_STAGE !== "prod",
  });
}

// init ga4
if (REACT_APP_GA4_MEASUREMENT_ID) {
  ReactGA4.initialize(REACT_APP_GA4_MEASUREMENT_ID);
}
