import { APIError, APIErrorData, API_ERROR_STATUS } from "~/client/types";
import {
  setClientError,
  setClientInfo,
  setServerError,
} from "~/client/store/modules";
import { useCallback, useRef } from "react";

import { OrganizationMember } from "~/client/types/organization";
import { fetchReinviteToOrganization } from "~/client/api/organization";
import { useAppDispatch } from "~/client/hooks";
import useAuthenticate from "./useAuthenticate";

function useReinviteMember() {
  const isReinviting = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { customer } = useAuthenticate();

  const reinvite = useCallback(
    async (memberToInvite?: OrganizationMember) => {
      if (isReinviting.current || !memberToInvite) return;

      isReinviting.current = true;

      try {
        const res = await fetchReinviteToOrganization({
          params: {
            id: customer!.organization_id,
            email: memberToInvite.email,
          },
        });
        const { data } = res;

        if (data && data.invitations_left_today >= 0) {
          dispatch(
            setClientInfo({
              text: `Invite resended. You have ${data.invitations_left_today} more available.`,
            }),
          );
        }
      } catch (e) {
        const error = e as APIError<unknown>;
        if (error.response?.status === API_ERROR_STATUS.BAD_REQUEST) {
          const errorData = error.response?.data as APIErrorData;
          const message =
            typeof errorData.details === "object"
              ? errorData.details.message
              : errorData.details;

          if (errorData && message) {
            dispatch(
              setClientError({
                text: message,
              }),
            );
          } else {
            throw e;
          }
        } else {
          dispatch(setServerError(error));
        }
      } finally {
        isReinviting.current = false;
      }
    },
    [customer, dispatch],
  );

  return {
    reinvite,
    isValidating: isReinviting.current,
  };
}

export default useReinviteMember;
