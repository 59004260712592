import moment from "moment";

function getDateRangeByScale(scale: string, date = "") {
  let startDate, endDate, dateBadge;

  switch (scale) {
    case "day":
      startDate = moment(date ? new Date(date) : new Date()).startOf("month");
      endDate = moment(date ? new Date(date) : new Date()).endOf("month");
      dateBadge = "1m";
      break;
    case "month":
      startDate = moment(date ? new Date(date) : new Date()).startOf("year");
      endDate = moment(date ? new Date(date) : new Date()).endOf("year");
      dateBadge = "1y";
      break;
    default:
      endDate = moment(date ? new Date(date) : new Date()).endOf("day");
      startDate = moment(date ? new Date(date) : new Date())
        .subtract(6, "day")
        .startOf("day");
      dateBadge = "1w";
      break;
  }
  return {
    startDate,
    endDate,
    dateBadge,
  };
}

export default getDateRangeByScale;
