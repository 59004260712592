import { Modal, ModalCloseIcon, ToastCheckIcon, Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import { formatPlanTitle } from "~/client/lib/helpers";
import { PlanType } from "~/client/types";

const ConfirmModal = styled(Modal)`
  width: 540px;
  height: 250px;
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  open: boolean;
  onClose: () => void;
  newSubscriptionId?: PlanType;
}

const PricePlanResultModal: FC<Props> = ({
  open,
  onClose,
  newSubscriptionId,
}) => {
  const { colors } = useTheme();

  const newSubscriptionColor = newSubscriptionId
    ? colors.plan_color[newSubscriptionId]
    : "";

  return (
    <ConfirmModal open={open} onClose={onClose}>
      <ModalCloseIcon
        style={{
          position: "absolute",
          right: "30px",
          top: "30px",
          cursor: "pointer",
        }}
        fill={colors.black}
        onClick={onClose}
      />
      <ToastCheckIcon
        style={{
          width: "60px",
          height: "60px",
          marginTop: "50px",
        }}
        fill={newSubscriptionColor}
      />
      <Typo variant="h2" style={{ marginTop: "20px" }}>
        Plan changed to{" "}
        <span style={{ color: newSubscriptionColor }}>
          {newSubscriptionId && formatPlanTitle(newSubscriptionId)}
        </span>{" "}
        plan.
      </Typo>
      <Typo variant="h5" style={{ color: colors.grey[70], marginTop: "10px" }}>
        Changed plan is used from now on.
      </Typo>
    </ConfirmModal>
  );
};

export default PricePlanResultModal;
