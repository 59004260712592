import { Scroll } from "@cochlearai/ui";
import { FC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ProjectTabItem } from "~/client/components";
import useProjectList from "~/client/hooks/useProjectList";
import { PRODUCT_TYPE_UI_LABELS } from "~/client/lib/constants";
import { ProjectType } from "~/client/types";

const TabsContainer = styled(Scroll)`
  white-space: nowrap;
  border-bottom: 2px solid ${(p) => p.theme.colors.grey[20]};
`;

const ProjectTab: FC = () => {
  const { t } = useTranslation();
  const { projectCount, selectedProjectTab, changeProjectTab } =
    useProjectList();

  return (
    <TabsContainer>
      <ul>
        {Object.values(ProjectType).map((item, index) => (
          <ProjectTabItem
            key={`${index}_${item}`}
            selected={item === selectedProjectTab}
            onClick={() => changeProjectTab(item)}
            title={t(PRODUCT_TYPE_UI_LABELS[item], { ns: "common" })}
            count={projectCount[item] || 0}
          />
        ))}
      </ul>
    </TabsContainer>
  );
};

export default ProjectTab;
