import { ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE } from "~/client/types/organization";

function getOrganizationInvitedTableColumnWidth(
  columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE,
) {
  switch (columnType) {
    case ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.NAME:
      return "400px";
    case ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.EMAIL:
      return "500px";
    case ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.INVITED_DATE:
      return "300px";
    default:
      return "0px";
  }
}

export default getOrganizationInvitedTableColumnWidth;
