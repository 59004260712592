import React, { FC, Suspense } from "react";

import SubHeaderContent from "./SubHeaderContent";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  z-index: 18;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
  display: flex;
  flex-direction: column;
`;

const SubHeader: FC = () => {
  return (
    <Container>
      <Suspense fallback="">
        <SubHeaderContent />
      </Suspense>
    </Container>
  );
};

export default SubHeader;
