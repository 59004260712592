import { APIError, Project, ProjectType, SDKChannel } from "~/client/types";
import { useCallback, useEffect, useState } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetSDKChannelList } from "~/client/api";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useSWR from "swr";

function useSdkChannelList({
  limit,
  currentProject,
}: {
  limit?: number;
  currentProject: Project | null;
}) {
  const dispatch = useAppDispatch();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [channelList, setChannelList] = useState<SDKChannel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedChannel, setSelectedChannel] = useState<SDKChannel | null>(
    null,
  );
  const { data, error, isValidating } = useSWR(
    currentProject && currentProject?.product_type === ProjectType.EDGE_SDK
      ? SWR_KEY.SENSE_SDK_INITIALIZE_CHANNEL_LIST
      : null,
    async () => {
      const res = await fetchGetSDKChannelList({
        params: {
          with_project: currentProject!.id,
          ...(limit !== undefined && { limit }),
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const loadMoreChannelList = useCallback(async () => {
    if (isLoadingMore) return;
    if (channelList.length === 0) return;
    setIsLoadingMore(true);
    try {
      const res = await fetchGetSDKChannelList({
        params: {
          with_project: currentProject!.id,
          after_id: channelList[channelList.length - 1].id,
          ...(limit !== undefined && { limit }),
        },
      });
      const { data } = res;
      setChannelList(channelList.concat(data.list));
      setTotalCount(data.total_count);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingMore(false);
    }
  }, [channelList, currentProject, isLoadingMore, limit]);

  const selectChannel = useCallback((channel: SDKChannel) => {
    setSelectedChannel(channel);
  }, []);
  const unselectChannel = useCallback(() => {
    setSelectedChannel(null);
  }, []);

  useEffect(() => {
    if (!data) return;
    setChannelList(data.list);
    setTotalCount(data.total_count);

    if (selectedChannel) {
      const updatedInfo = data.list.find(
        (item) => item.id === selectedChannel.id,
      );
      updatedInfo && selectChannel(updatedInfo);
    }
  }, [data, selectChannel, selectedChannel]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    isValidating,
    channelList,
    loadMoreChannelList,
    totalCount,
    selectedChannel,
    selectChannel,
    unselectChannel,
  };
}

export default useSdkChannelList;
