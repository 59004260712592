import { PropsWithChildren, useState } from "react";
import styled, { useTheme } from "styled-components";

import { ArrowDownIcon } from "../Icons";
import { BaseStyleProps } from "../types";

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  padding: 11px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ArrowIcon = styled(ArrowDownIcon)<{ isRotate?: boolean }>`
  transform: ${(p) => (p.isRotate ? "rotate(180deg)" : "")};
  transition: transform 0.3s ease-in-out;
`;

const Panel = styled.div`
  padding: 11px 0;
`;

export interface AccordianProps extends BaseStyleProps {
  panelContent: React.ReactNode;
}

/**
 * React component used to create a Accordian layout.
 * @param className  - The className of the accordian.
 * @param style      - The style of the accordian.
 * @param onClose    - The onClose handler of the accordian.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/accordian--default
 */
export const Accordian = ({
  children,
  className,
  style,
  panelContent,
}: PropsWithChildren<AccordianProps>) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const { colors } = useTheme();
  return (
    <Container className={className} style={style}>
      <Content onClick={() => setIsPanelOpen(!isPanelOpen)}>
        {children}
        <ArrowIcon
          width={6}
          height={4}
          isRotate={isPanelOpen}
          fill={colors.black}
        />
      </Content>
      {isPanelOpen && <Panel>{panelContent}</Panel>}
    </Container>
  );
};
