import React, { FC, SVGProps } from "react";

const RecordIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={props.fill ?? "#727273"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1023_22284)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2401 9.61408V9.36001H13.6801V9.61408C13.6801 12.1531 11.8026 14.2536 9.36021 14.603V16.4174H11.5202V17.8574H5.7602V16.4174H7.92021V14.6031C5.4777 14.2537 3.6001 12.1532 3.6001 9.61408V9.36001H5.0401V9.61408C5.0401 11.6023 6.65187 13.2141 8.6401 13.2141C10.6283 13.2141 12.2401 11.6023 12.2401 9.61408ZM13.6801 -0.719986H12.2401V-0.935968C12.2401 -2.92419 10.6283 -4.53597 8.6401 -4.53597C6.65187 -4.53597 5.0401 -2.92419 5.0401 -0.935972V-0.719986H3.6001V-0.935972C3.6001 -3.71949 5.85658 -5.97597 8.6401 -5.97597C11.4236 -5.97597 13.6801 -3.71948 13.6801 -0.935968V-0.719986ZM10.0802 3.48343V9.51203C10.0802 10.3073 9.4355 10.952 8.64021 10.952C7.84492 10.952 7.20021 10.3073 7.20021 9.51202V3.48342C7.20021 2.68814 7.84491 2.04343 8.64021 2.04343C9.4355 2.04343 10.0802 2.68814 10.0802 3.48343ZM5.76021 3.48342C5.76021 1.89284 7.04963 0.603427 8.64021 0.603427C10.2308 0.603427 11.5202 1.89285 11.5202 3.48343V9.51203C11.5202 11.1026 10.2308 12.392 8.64021 12.392C7.04963 12.392 5.76021 11.1026 5.76021 9.51202V3.48342Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1023_22284">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RecordIcon;
