import { TAG_INTERVAL_TIME_TYPE_VALUE } from "../../types";
import { ONE_MINUTE_SECOND } from "../constants";

function getIntervalTimeError(
  interval?: number | string,
  unit?: string,
  min = 0,
  max = Infinity,
): string | undefined {
  const seconds =
    Number(interval) *
    (unit === TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES ? ONE_MINUTE_SECOND : 1);

  return isNaN(seconds) || seconds < min || seconds > max
    ? `Input numbers between ${min}s and ${max}s`
    : undefined;
}

export default getIntervalTimeError;
