import { Typo } from "@cochlearai/ui";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
const ProjectFilterButtonSkeleton: FC = () => {
  const { colors } = useTheme();
  return (
    <Container>
      <Typo
        variant="caption1"
        style={{ color: colors.grey[70], marginRight: "10px" }}
      >
        Filter By
      </Typo>
      <Skeleton height={27} width={127} />
    </Container>
  );
};

export default ProjectFilterButtonSkeleton;
