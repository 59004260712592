import { BaseStyleProps } from "../types/style";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const TableContainer = styled.table`
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0px;
`;

export interface TableProps extends BaseStyleProps {}
/**
 * React component used to create a table.
 * @param style - Style of the table.
 * @param className - Class name of the table.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/table--default
 */
export const Table = ({
  children,
  className,
  style,
}: PropsWithChildren<TableProps>) => {
  return (
    <TableContainer
      data-testid="@cochl-ui/TableContainer"
      className={className}
      style={style}
    >
      {children}
    </TableContainer>
  );
};
