import { FC } from "react";

import { InvitationIcon } from "@cochlearai/ui";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Notification } from "~/client/types/notification";
import { PAGE_ROUTE_PATH_NAME } from "~/client/types/pageRoute";

const Flex = styled.div`
  display: flex;
`;

const Container = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
`;

const TextContainer = styled(Flex)`
  flex-direction: column;
  margin-left: 10px;
  max-width: 194px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${(p) => p.theme.colors.black};
  margin-top: 7px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const TimeText = styled(Text)`
  color: ${(p) => p.theme.colors.grey[70]};
`;

const NotConfirmed = styled.div`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background: ${(p) => p.theme.colors.red};
`;

const Divider = styled.div`
  background: ${(p) => p.theme.colors.grey[20]};
  height: 1px;
  margin: 0 30px;
`;

interface Props {
  notification: Notification;
  onClick?: () => void;
  isLast?: boolean;
}

const NotificationMessage: FC<Props> = ({ notification, onClick, isLast }) => {
  const { message, created_at, read } = notification;
  const linkTo = message.includes("invited")
    ? PAGE_ROUTE_PATH_NAME.ORGANIZATION_INVITED
    : PAGE_ROUTE_PATH_NAME.ORGANIZATION_MY_MEMBERS;

  return (
    <Link to={linkTo} onClick={onClick}>
      <Container>
        <Flex>
          <InvitationIcon />
          <TextContainer>
            <Text>{message || "You have got an invitation"}</Text>
            <TimeText>{moment(created_at).fromNow()}</TimeText>
          </TextContainer>
        </Flex>
        {!read && <NotConfirmed />}
      </Container>
      {!isLast && <Divider />}
    </Link>
  );
};

export default NotificationMessage;
