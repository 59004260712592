import { resetAudioInfo, setAudioInfo } from "~/client/store/modules/audio";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Project } from "~/client/types";
import { selectedAudioState } from "~/client/lib";

let audioList: {
  projectId: string;
  senseAudioEl: HTMLAudioElement;
  transcriptAudioEl: HTMLAudioElement;
}[] = [];

interface Props {
  currentProject: Project | null;
}

function useAudio({ currentProject }: Props) {
  const dispatch = useAppDispatch();
  const [audioFile, setAudioFile] = useState<Blob | null>(null);
  const { audioInfo } = useAppSelector(selectedAudioState);
  const audioInfoByProject = useMemo(
    () => audioInfo.find((data) => data.projectId === currentProject?.id),
    [currentProject?.id, audioInfo],
  );

  /**
   * update 'isPlay' state
   */
  const updateIsPlay = useCallback(
    (type, value) => {
      currentProject &&
        dispatch(
          setAudioInfo({
            projectId: currentProject?.id,
            [type]: { ...audioInfoByProject?.[type], isPlay: value },
          }),
        );
    },
    [currentProject, audioInfoByProject],
  );

  /**
   * handle 'ended' event
   */
  const handleEnded = useCallback(async (type) => {
    updateIsPlay(type, false);
  }, []);

  /**
   * reset audio redux state
   */
  const reset = useCallback(async () => {
    if (!currentProject) return;
    const foundAudio = audioList.find(
      (data) => data.projectId === currentProject.id,
    );
    foundAudio?.senseAudioEl.pause();
    foundAudio?.transcriptAudioEl.pause();
    updateIsPlay("sense", false);
    updateIsPlay("transcript", false);
    dispatch(resetAudioInfo({ projectId: currentProject?.id }));
  }, [currentProject]);

  /**
   * toggle audio (play/pause)
   */
  const toggleAudio = useCallback(
    (type) => {
      if (!currentProject) return;
      const foundAudio = audioList.find(
        (data) => data.projectId === currentProject?.id,
      );

      if (!audioInfoByProject || !foundAudio) return;
      const targetAudio =
        type === "transcript"
          ? foundAudio.transcriptAudioEl
          : foundAudio.senseAudioEl;
      const otherAudio =
        type === "transcript"
          ? foundAudio.senseAudioEl
          : foundAudio.transcriptAudioEl;
      const otherType = type === "transcript" ? "sense" : "transcript";

      if (!audioInfoByProject[type]?.isPlay) {
        if (!otherAudio.paused) {
          otherAudio.pause();
          updateIsPlay(otherType, false);
        }

        targetAudio.addEventListener("ended", () => handleEnded(type));
        targetAudio.play();
        updateIsPlay(type, true);
      } else {
        targetAudio.pause();
        updateIsPlay(type, false);
      }
    },
    [audioInfoByProject, currentProject?.id],
  );

  /**
   * get audio current time (play time)
   * @returns audio current time
   */
  const getAudioCurTime = useCallback(
    (type) => {
      const foundAudio = audioList.find(
        (data) => data.projectId === currentProject?.id,
      );
      if (!foundAudio) return 0;

      const targetAudio =
        type === "transcript"
          ? foundAudio.transcriptAudioEl
          : foundAudio.senseAudioEl;
      return targetAudio.currentTime || 0;
    },
    [currentProject],
  );

  /**
   * set audio current time
   */
  const setCurrentTime = useCallback(
    (type, pos) => {
      if (!currentProject) return;
      const foundAudio = audioList.find(
        (data) => data.projectId === currentProject.id,
      );
      if (isNaN(pos) || !foundAudio) return;
      const targetAudio =
        type === "transcript"
          ? foundAudio.transcriptAudioEl
          : foundAudio.senseAudioEl;

      targetAudio.currentTime = pos;
      dispatch(
        setAudioInfo({
          projectId: currentProject.id,
          [type]: { ...audioInfoByProject?.[type], curTime: pos },
        }),
      );
    },
    [currentProject, audioInfoByProject],
  );

  /**
   * handle 'canplaythrough' event (available to play)
   */
  const handleCanPlayThrough = useCallback(async () => {
    if (!audioFile || !currentProject) return;

    const audioContext = new window.AudioContext();

    const soundBuffer = await audioFile.arrayBuffer();
    const sampleBuffer = await audioContext.decodeAudioData(soundBuffer);
    const channel = sampleBuffer.getChannelData(0);
    const foundAudio = audioList.find(
      (data) => data.projectId === currentProject?.id,
    );

    if (channel && foundAudio) {
      dispatch(
        setAudioInfo({
          projectId: currentProject.id,
          duration: foundAudio.senseAudioEl.duration,
          channel: channel,
        }),
      );
    }
  }, [audioFile, currentProject]);

  useEffect(() => {
    if (!audioFile || !currentProject) return;

    const senseAudioEl = new Audio(URL.createObjectURL(audioFile));
    const transcriptAudioEl = new Audio(URL.createObjectURL(audioFile));
    const newAudioInfo = {
      projectId: currentProject.id,
      senseAudioEl,
      transcriptAudioEl,
    };
    const foundIdx = audioList.findIndex(
      (data) => data.projectId === currentProject?.id,
    );

    if (foundIdx === -1) {
      audioList = [...audioList, newAudioInfo];
    } else {
      audioList[foundIdx] = newAudioInfo;
    }

    // error: canPlayThrough event is fired when changing current audio time
    senseAudioEl.addEventListener("loadeddata", handleCanPlayThrough);

    return () => {
      senseAudioEl.removeEventListener("loadeddata", handleCanPlayThrough);
      senseAudioEl.removeEventListener("ended", () => handleEnded("sense"));
      transcriptAudioEl.removeEventListener("ended", () =>
        handleEnded("transcript"),
      );
    };
  }, [audioFile, currentProject]);

  return {
    ...audioInfoByProject,
    setAudioFile,
    toggleAudio,
    setCurrentTime,
    getAudioCurTime,
    reset,
  };
}

export default useAudio;
