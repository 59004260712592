import {
  CANVAS_HEIGHT,
  CANVAS_PADDING,
  CANVAS_WIDTH,
} from "~/client/lib/constants";
import { MouseEvent, RefObject, useCallback, useEffect, useRef } from "react";

import { Project } from "~/client/types";
import { useAudio } from "~/client/hooks";
import { useTheme } from "styled-components";

const animationFrameId = {
  sense: 0,
  transcript: 0,
};

interface Props {
  width?: number;
  height?: number;
  padding?: number;
  currentProject: Project | null;
  audioType: "sense" | "transcript";
}

function useCanvas({
  width = CANVAS_WIDTH,
  height = CANVAS_HEIGHT,
  padding = CANVAS_PADDING,
  currentProject,
  audioType,
}: Props) {
  const canvasRef: RefObject<HTMLCanvasElement> =
    useRef<HTMLCanvasElement>(null);
  const backgroundRef: RefObject<HTMLCanvasElement> =
    useRef<HTMLCanvasElement>(null);
  const {
    duration = 0,
    channel,
    sense,
    transcript,
    setCurrentTime,
    getAudioCurTime,
  } = useAudio({ currentProject });
  const isPlay = audioType === "sense" ? sense?.isPlay : transcript?.isPlay;
  const curTime = audioType === "sense" ? sense?.curTime : transcript?.curTime;
  const { colors } = useTheme();

  /**
   * set canvas scale
   */
  const setCanvas = useCallback(
    ({
      canvas,
      ctx,
      backgroundCanvas,
      backgroundCtx,
    }: {
      canvas: HTMLCanvasElement;
      ctx: CanvasRenderingContext2D;
      backgroundCanvas: HTMLCanvasElement;
      backgroundCtx: CanvasRenderingContext2D;
    }) => {
      const devicePixelRatio = window.devicePixelRatio ?? 1;

      backgroundCanvas.style.width = width + "px";
      backgroundCanvas.style.height = height + "px";
      backgroundCanvas.width = width * devicePixelRatio;
      backgroundCanvas.height = (height + padding * 2) * devicePixelRatio;

      backgroundCtx.scale(devicePixelRatio, devicePixelRatio);
      backgroundCtx.translate(0, padding);

      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      canvas.width = width * devicePixelRatio;
      canvas.height = (height + padding * 2) * devicePixelRatio;

      ctx.scale(devicePixelRatio, devicePixelRatio);
      ctx.translate(0, padding);
    },
    [height, width, padding],
  );

  /**
   * draw animation (audio sound wave)
   * @param {CanvasRenderingContext2D} ctx  canvas context
   */
  const animate = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (!ctx || !channel) return;
      const curTime = getAudioCurTime(audioType);
      const cursorPos = (width * curTime) / duration;
      const step = Math.ceil(channel.length / width);
      const amp = CANVAS_HEIGHT / 2;

      // delete previous painting
      ctx.clearRect(0, -padding, CANVAS_WIDTH, CANVAS_HEIGHT + padding * 2);

      // cursor
      ctx.beginPath();
      ctx.strokeStyle = colors.red;
      ctx.lineWidth = 1;
      ctx.moveTo(cursorPos, -padding);
      ctx.lineTo(cursorPos, CANVAS_HEIGHT + padding);
      ctx.stroke();

      // process audio wave
      ctx.fillStyle = colors.grey[90];
      for (let i = 0; i < cursorPos; i++) {
        let min = 1.0;
        let max = -1.0;
        for (let j = 0; j < step; j++) {
          const datum = channel[i * step + j] * (step < 300 ? 100 : 1);
          if (datum < min) min = datum;
          if (datum > max) max = datum;
        }
        ctx.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
      }
    },
    [channel, colors, duration, getAudioCurTime, padding, width, audioType],
  );

  /**
   * draw background
   * @param {CanvasRenderingContext2D} ctx  canvas context
   */
  const background = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (!channel) return;
      const step = Math.ceil(channel.length / width);
      const amp = CANVAS_HEIGHT / 2;
      ctx.clearRect(0, -padding, CANVAS_WIDTH, CANVAS_HEIGHT + padding * 2);

      // background
      ctx.fillStyle = colors.grey[60];
      for (let i = 0; i < width; i++) {
        let min = 1.0;
        let max = -1.0;
        for (let j = 0; j < step; j++) {
          const datum = channel[i * step + j] * (step < 300 ? 100 : 1);
          if (datum < min) min = datum;
          if (datum > max) max = datum;
        }
        ctx.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
      }
    },
    [channel, colors, padding, width],
  );

  /**
   * handle 'click' event on canvas
   * @param {MouseEvent} e  mouse event
   */
  const handleClick = useCallback(
    (e: MouseEvent) => {
      const canvas = canvasRef.current;
      const rect = canvas?.getBoundingClientRect();
      const ctx = canvas?.getContext("2d");
      if (ctx && rect) {
        const canvasMouseX = e.clientX - rect.left;
        const curTime = (canvasMouseX * duration) / width;
        setCurrentTime(audioType, curTime);
        animate(ctx);
      }
    },
    [animate, duration, setCurrentTime, width, audioType],
  );

  const render = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!ctx) return;

    animate(ctx);
    animationFrameId[audioType] = requestAnimationFrame(render);
  }, [isPlay, audioType]);

  useEffect(() => {
    if (isPlay) {
      render();
    } else {
      cancelAnimationFrame(animationFrameId[audioType]);
    }

    return () => {
      cancelAnimationFrame(animationFrameId[audioType]);
    };
  }, [animate, isPlay, audioType]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const backgroundCanvas = backgroundRef.current;
    const backgroundCtx = backgroundCanvas?.getContext("2d");

    if (!(canvas && ctx && backgroundCanvas && backgroundCtx)) return;

    setCanvas({ canvas, ctx, backgroundCanvas, backgroundCtx });
    background(backgroundCtx);
    animate(ctx);
  }, [animate, background, channel, curTime, setCanvas]);

  return {
    canvasRef,
    backgroundRef,
    handleClick,
  };
}

export default useCanvas;
