import {
  FetchCancelDeleteSDKChannelParams,
  FetchCancelDeleteSDKChannelResponse,
  FetchDeleteSDKChannelParams,
  FetchDeleteSDKChannelResponse,
  FetchGetSDKChannelListParams,
  FetchGetSDKChannelListResponse,
  FetchGetSDKQuotaParams,
  FetchGetSDKQuotaResponse,
  FetchUpdateSDKChannelDeviceNameParams,
  FetchUpdateSDKChannelDeviceNameResponse,
  FetchGetSDKTagsParams,
  FetchGetSDKTagsResponse,
  FetchGetProjectSDKTagsParams,
  FetchGetProjectSDKTagsResponse,
  FetchGetProjectSDKUsageParams,
  FetchGetProjectSDKUsageResponse,
} from "~/client/types/api/senseSdk";

import { APICallPayloads } from "~/client/types/api";
import getConfigs from "~/client/lib/getConfigs";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_SENSE_SDK_API_BASE_URL: senseSdkApiBaseURL } = getConfigs();

const API_URL_PREFIX = senseSdkApiBaseURL ? `${senseSdkApiBaseURL}` : "";

export const fetchGetSDKQuota = ({
  config,
}: APICallPayloads<FetchGetSDKQuotaParams>): Promise<FetchGetSDKQuotaResponse> => {
  const url = `${API_URL_PREFIX}/quota`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetSDKChannelList = ({
  config,
  params,
}: APICallPayloads<FetchGetSDKChannelListParams>): Promise<FetchGetSDKChannelListResponse> => {
  let url = `${API_URL_PREFIX}/channels?active_devices=true&`;
  const { with_project, after_id, limit } = params;
  if (with_project) {
    url += `with_project=${with_project}&`;
  }
  if (after_id) {
    url += `after_id=${after_id}&`;
  }
  if (typeof limit === "number") {
    url += `limit=${limit}&`;
  }
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchUpdateSDKChannelDeviceName = ({
  params,
  config,
}: APICallPayloads<FetchUpdateSDKChannelDeviceNameParams>): Promise<FetchUpdateSDKChannelDeviceNameResponse> => {
  const url = `${API_URL_PREFIX}/channels/${params.channel_id}/web`;
  return makeAPIRequest({
    ...config,
    url,
    data: {
      device_name: params.device_name,
    },
    method: "PATCH",
  });
};

export const fetchDeleteSDKChannel = ({
  params,
  config,
}: APICallPayloads<FetchDeleteSDKChannelParams>): Promise<FetchDeleteSDKChannelResponse> => {
  const url = `${API_URL_PREFIX}/channels/${params.channel_id}/web`;
  return makeAPIRequest({
    ...config,
    url,
    data: {
      delete_pending: true,
    },
    method: "PATCH",
  });
};

export const fetchCancelDeleteSDKChannel = ({
  params,
  config,
}: APICallPayloads<FetchCancelDeleteSDKChannelParams>): Promise<FetchCancelDeleteSDKChannelResponse> => {
  const url = `${API_URL_PREFIX}/channels/${params.channel_id}/web`;
  return makeAPIRequest({
    ...config,
    url,
    data: {
      delete_pending: false,
    },
    method: "PATCH",
  });
};

export const fetchGetSDKTags = ({
  config,
}: APICallPayloads<FetchGetSDKTagsParams>): Promise<FetchGetSDKTagsResponse> => {
  const url = `${API_URL_PREFIX}/tags`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetProjectSDKTags = ({
  config,
  params,
}: APICallPayloads<FetchGetProjectSDKTagsParams>): Promise<FetchGetProjectSDKTagsResponse> => {
  const url = `${API_URL_PREFIX}/projects/${params.project_id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetProjectSDKUsage = ({
  config,
  params,
}: APICallPayloads<FetchGetProjectSDKUsageParams>): Promise<FetchGetProjectSDKUsageResponse> => {
  const url = `${API_URL_PREFIX}/projects/${params.project_id}/usage`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};
