import {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  forwardRef,
} from "react";

import { BaseStyleProps } from "../types";
import { ThemeColors } from "../lib/ThemeColors";
import { ThemeType } from "../types/theme";
import { Z_INDEXES } from "../lib/constants";
import { styled } from "styled-components";

export type ButtonType = "default" | "primary" | "secondary" | "danger";

const Container = styled.button<{
  color: ButtonType;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  text-align: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${(p) =>
    ThemeColors.button.border[p.color]?.[
      (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
    ] ?? "transparent"};
  background-color: ${(p) =>
    ThemeColors.button.backgroundColor[p.color]?.[
      (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
    ] ?? "transparent"};
  color: ${(p) =>
    ThemeColors.button.color[p.color]?.[
      (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
    ] ?? "transparent"};

  &:hover {
    color: ${(p) =>
      ThemeColors.button.color.hover[p.color]?.[
        (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
      ]};
    border-color: ${(p) =>
      ThemeColors.button.border.hover[p.color]?.[
        (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
      ]};
    background-color: ${(p) =>
      ThemeColors.button.backgroundColor.hover[p.color]?.[
        (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
      ]};
    & > svg {
      fill: ${(p) =>
        ThemeColors.button.color.hover[p.color]?.[
          (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
        ]};
    }
  }

  &:disabled {
    opacity: 0.4;

    border-color: ${(p) =>
      ThemeColors.button.border.disabled[p.color]?.[
        (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
      ]};
    color: ${(p) =>
      ThemeColors.button.color.disabled[p.color]?.[
        (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
      ]};
    background-color: ${(p) =>
      ThemeColors.button.backgroundColor[p.color]?.[
        (p.theme.theme ?? ThemeType.LIGHT) as ThemeType
      ]};
  }
`;

const RightIconWrapper = styled.div`
  z-index: ${Z_INDEXES.BUTTON + 1};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: unset;
  margin-left: auto;
  padding: unset;
`;

export interface ButtonProps extends BaseStyleProps {
  disabled?: boolean;
  color?: ButtonType;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ["data-testid"]?: string;
}

/**
 * React component used to create a button layout.
 *
 * It renders a `button` and
 * comes with helpful style shorthand, properties.
 *
 * @param color - The color of the button. `default`, `primary`, `secondary`, `danger`.
 * @param leftIcon - The left icon of the button.
 * @param rightIcon - The right icon of the button.
 * @param onClick - The onClick handler of the button.
 * @param disabled   - If true, disables the button.
 * @param className - The className of the button.
 * @param style      - The style of the button.
 *
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/button--default
 */
export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      children,
      style,
      disabled,
      color = "default",
      leftIcon,
      rightIcon,
      onClick,
      className,
      ...others
    },
    ref,
  ) => {
    return (
      <Container
        className={className}
        onClick={onClick}
        disabled={disabled}
        color={color}
        style={{
          zIndex: Z_INDEXES.BUTTON,
          ...style,
        }}
        data-testid={others["data-testid"]}
        ref={ref}
      >
        {leftIcon && leftIcon}
        {children}
        {rightIcon && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
      </Container>
    );
  },
);
