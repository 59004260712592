import { MouseEventHandler } from "react";
import { BaseStyleProps } from "../types";

export type TypoVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "body"
  | "caption1"
  | "caption2"
  | "caption3"
  | "button";

export interface TypoProps extends BaseStyleProps {
  children?: React.ReactNode;
  ["data-testid"]?: string;
  variant: TypoVariant;
  onClick?: MouseEventHandler<HTMLHeadingElement | HTMLParagraphElement>;
}

const provideTypoElement = ({
  variant,
  children,
  className,
  style,
  onClick,
  ...rest
}: TypoProps) => {
  switch (variant) {
    case "h1":
      return (
        <h1
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </h5>
      );
    case "caption1":
    case "caption2":
    case "caption3":
    case "button":
      return (
        <p
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={[variant, className].join(" ")}
          style={style}
        >
          {children}
        </p>
      );
    case "body":
    default:
      return (
        <p
          data-testid={rest["data-testid"]}
          onClick={onClick}
          className={className}
          style={style}
        >
          {children}
        </p>
      );
  }
};

/**
 * React component used to create a typo.
 * @param variant - Variant of the typo. `h1`, `h2`, `h3`, `h4`, `h5`, `body`, `caption1`, `caption2`, `caption3`, `button`.
 * @param onClick - Event fired when the user clicks on the typo.
 * @param className - Class name added to the typo.
 * @param style - Style added to the typo.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/typo--default
 */
export const Typo = (props: TypoProps) => provideTypoElement(props);
