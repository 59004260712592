import { MouseEventHandler } from "react";
import styled from "styled-components";

const Container = styled.div<{
  width: number;
  color?: string;
  isFirst?: boolean;
  isLast?: boolean;
  $borderRadius: number;
}>`
  width: ${(p) => `${isNaN(p.width) ? 0 : p.width}%`};
  height: 100%;
  background-color: ${(p) => p.color ?? p.theme.colors.blue[60]};
  border-top-left-radius: ${(p) => (p.isFirst ? p.$borderRadius : 0)}px;
  border-bottom-left-radius: ${(p) => (p.isFirst ? p.$borderRadius : 0)}px;
  border-top-right-radius: ${(p) => (p.isLast ? p.$borderRadius : 0)}px;
  border-bottom-right-radius: ${(p) => (p.isLast ? p.$borderRadius : 0)}px;
  cursor: pointer;
`;

export interface UsageBarContentProps {
  className?: string;
  isFirst?: boolean;
  isLast?: boolean;
  color?: string;
  width: number;
  borderRadius?: number;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseOut?: MouseEventHandler<HTMLDivElement>;
}

/**
 * React component used to create a usage bar content.
 * @param className - Class name added to the usage bar content.
 * @param isFirst - Whether the usage bar content is the first one.
 * @param isLast - Whether the usage bar content is the last one.
 * @param color - The color of the usage bar content.
 * @param width - Width of the usage bar content.
 * @param borderRadius - Border radius of the usage bar content.
 * @param onMouseOver - Mouse over handler.
 * @param onMouseOut - Mouse out handler.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/usagebar--default
 */
export const UsageBarContent = ({
  className,
  isFirst,
  isLast,
  color,
  width,
  borderRadius = 4,
  onMouseOver,
  onMouseOut,
}: UsageBarContentProps) => {
  return (
    <Container
      className={className}
      data-testid="@cochl-ui/consumedUsageBar"
      isFirst={isFirst}
      isLast={isLast}
      color={color}
      width={width || 0}
      $borderRadius={borderRadius}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    ></Container>
  );
};
