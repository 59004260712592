export enum SenseServices {
  HOME_CONTEXT = "home-context",
  EMERGENCY_DETECTION = "emergency",
  HUMAN_INTERACTION = "human-interaction",
  HUMAN_STATUS = "human-status",
  VEHICLE_MONITOR = "vehicle-monitor",
}

export enum ProjectType {
  ALL = "all",
  CLOUD_API = "api",
  EDGE_SDK = "sdk",
}

export enum PlanType {
  FREE = "free",
  STANDARD = "standard",
  ENTERPRISE = "enterprise",
}

export interface PaletteThemeColor {
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
}

export interface ThemeColor {
  blue: PaletteThemeColor;
  grey: PaletteThemeColor;
  black: string;
  white: string;
  red: string;
  red_stroke: string;
  body: string;
  shadow: string;
  code: string;
  semantic: {
    grey: {
      10: string;
      20: string;
    };
  };
  plan_color: {
    [key in PlanType]: string;
  };
  product_type_color: {
    [key in ProjectType]: string;
  };
  service_color: {
    [key in SenseServices]: string;
  };
}
