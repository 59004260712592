import {
  Checkbox,
  Chip,
  Input,
  Popover,
  Scroll,
  SearchIcon,
  Typo,
} from "@cochlearai/ui";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Z_INDEXES } from "~/client/lib/constants";
import { FilterType, TopTagSession } from "~/client/types/analytics";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FileSource = styled(Scroll)`
  padding: 12px;
  height: 200px;
`;

const SearchInput = styled(Input)`
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey[20]};

  & > input {
    padding: 8px;

    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    &::placeholder {
      color: ${(p) => p.theme.colors.grey[70]};
    }
  }
`;

const FileSourceButton = styled(Flex)`
  margin-top: 12px;
  margin-bottom: 240px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  padding: 0 16px;
  cursor: pointer;
  height: 36px;
  overflow: hidden;
`;

const TagsCountBadge = styled(Flex)`
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  margin-left: 8px;
  position: absolute;
  top: -30px;
  left: 100px;
`;

const FileNameText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface Props {
  list: TopTagSession[];
  setFilter: (key: FilterType, value: TopTagSession[]) => void;
}

const AnalyticsFileSource: FC<Props> = ({ list, setFilter }) => {
  const { colors } = useTheme();
  const [fileSourcePopoverIsOpen, setFileSourcePopoverIsOpen] =
    useState<boolean>(false);
  const [files, setFiles] = useState<TopTagSession[]>(list);
  const [searchText, setSearchText] = useState<string>("");
  const [selectAllFiles, setSelectAllFiles] = useState<boolean>(false);
  const fileList = useMemo(() => {
    return files.filter(
      (item) =>
        item.file_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
    );
  }, [files, searchText]);
  const selectedFileList = useMemo(() => {
    return files.filter((item) => item.checked);
  }, [files]);

  const onClickFilter = useCallback(
    (file) => {
      const newFiles = files.map((item) => {
        if (item === file) {
          return {
            ...item,
            checked: !item.checked,
          };
        } else {
          return item;
        }
      });
      setFiles(newFiles);
      setFilter(FilterType.SESSION, newFiles);
    },
    [files, setFilter],
  );

  const onClickSelectAll = useCallback(
    (e) => {
      const newFiles = files.map((file) => ({
        ...file,
        checked: e.target.checked ? true : false,
      }));
      setFiles(newFiles);
      setFilter(FilterType.SESSION, newFiles);
      setSelectAllFiles(e.target.checked);
    },
    [files, setFilter],
  );

  useEffect(() => {
    if (selectedFileList.length === files.length) {
      setSelectAllFiles(true);
    } else {
      setSelectAllFiles(false);
    }
  }, [selectedFileList, files]);

  useEffect(() => {
    setFiles(list);
  }, [list]);

  return (
    <Popover
      isOpen={fileSourcePopoverIsOpen}
      positions={["bottom"]}
      align="end"
      containerStyle={{
        zIndex: `${Z_INDEXES.POP_OVER}`,
      }}
      onClickOutside={() => {
        setSearchText("");
        setFileSourcePopoverIsOpen(false);
      }}
      content={({ childRect }) => {
        return (
          <FileSource style={{ width: childRect.width }}>
            <SearchInput
              leftIcon={
                <SearchIcon width={12} height={12} fill={colors.grey[70]} />
              }
              placeholder="Search Tags"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Flex style={{ marginTop: "10px", gap: "10px" }}>
              <Checkbox selected={selectAllFiles} onChange={onClickSelectAll} />
              <FileNameText variant="body">All Files</FileNameText>
            </Flex>
            {fileList.map((item, i) => (
              <Flex
                style={{
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
                key={i}
              >
                <Flex style={{ gap: "10px" }}>
                  <Checkbox
                    selected={item.checked}
                    onChange={() => onClickFilter?.(item)}
                  />
                  <FileNameText variant="body">{item.file_name}</FileNameText>
                </Flex>
                <Typo variant="body" style={{ color: colors.grey[40] }}>
                  {moment(new Date(item.date * 1000)).format(
                    "DD/MM/YYYY HH:mm",
                  )}
                </Typo>
              </Flex>
            ))}
          </FileSource>
        );
      }}
      padding={5}
      reposition={false}
    >
      <div style={{ position: "relative" }}>
        <FileSourceButton
          onClick={() => setFileSourcePopoverIsOpen(!fileSourcePopoverIsOpen)}
        >
          {selectedFileList.length === 0 ? (
            <Typo variant="caption1" style={{ color: colors.grey[70] }}>
              Select files
            </Typo>
          ) : (
            <Flex style={{ gap: "8px" }}>
              {selectedFileList.map((item, i) => (
                <Chip key={i} style={{ cursor: "pointer" }}>
                  {item.file_name}
                </Chip>
              ))}
            </Flex>
          )}
        </FileSourceButton>
        <TagsCountBadge>
          <Typo variant="caption3">{selectedFileList.length}</Typo>
        </TagsCountBadge>
      </div>
    </Popover>
  );
};

export default AnalyticsFileSource;
