import { setClientInfo } from "~/client/store/modules/alert";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from ".";
import { COPY_TEXT_AREA_ID } from "../constants";

function useCopyClipboard() {
  const dispatch = useAppDispatch();
  const copyEventListener = useCallback(
    (e: ClipboardEvent) => {
      const target = e.target as HTMLTextAreaElement | null;
      if (target && target.id) {
        const { id } = target;
        if (id === COPY_TEXT_AREA_ID) {
          dispatch(setClientInfo({ text: "Copied text to clipboard" }));
        }
      }
    },
    [dispatch],
  );

  useEffect(() => {
    document.addEventListener("copy", copyEventListener);
    return () => {
      document.removeEventListener("copy", copyEventListener);
    };
  }, [copyEventListener]);
}

export default useCopyClipboard;
