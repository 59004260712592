import { INVOICE_TABLE_COLUMN_TYPE } from "../../types";

function getInvoiceTableColumnWidth(columnType: INVOICE_TABLE_COLUMN_TYPE) {
  switch (columnType) {
    case INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION:
      return "720px";
    case INVOICE_TABLE_COLUMN_TYPE.QTY:
      return "120px";
    case INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE:
      return "110px";
    case INVOICE_TABLE_COLUMN_TYPE.AMOUNT:
      return "170px";
    default:
      return "0px";
  }
}

export default getInvoiceTableColumnWidth;
