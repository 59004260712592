import {
  ChromeIcon,
  HeaderLogoIcon,
  HyperlinkButton,
  Typo,
} from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ICON_LIST: {
  browser: "Chrome" | "Firefox" | "Opera" | "Safari";
  downloadURL: string;
  iconComponent: JSX.Element;
  subText?: string;
}[] = [
  {
    browser: "Chrome",
    downloadURL: "https://www.google.com/chrome/",
    iconComponent: <ChromeIcon width={100} height={100} />,
    subText: "Recommended",
  },
  // {
  //   browser: "Firefox",
  //   downloadURL: "https://www.mozilla.org/en-US/firefox/new/",
  //   iconComponent: <Firefox width={100} height={100} />,
  // },
  // {
  //   browser: "Opera",
  //   downloadURL: "https://www.opera.com/download",
  //   iconComponent: <Opera width={100} height={100} />,
  // },
  // {
  //   browser: "Safari",
  //   downloadURL: "https://support.apple.com/downloads/safari",
  //   iconComponent: <Safari width={100} height={100} />,
  // },
];

const Container = styled.div`
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled(Typo)`
  margin-bottom: 20px;
`;
const SubText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[70]};
`;
const IconListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;
const IconContainerWrapper = styled.div`
  height: 277px;
  & + & {
    margin-left: 40px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 252px;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: ${(p) => p.theme.colors.grey[20]};
  border-radius: 4px;
`;
const IconText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[70]};
`;

const BrowserSupport: FC = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <Container>
      <TitleText variant="h1">
        {t("Browser not supported", { ns: "common" })}
      </TitleText>
      <SubText variant="h3">
        <span style={{ marginRight: "4px" }}>
          <HeaderLogoIcon width={76} height={16} fill={colors.black} />
        </span>
        {t("Dashboard is not supported in your browser.", { ns: "common" })}
      </SubText>
      <SubText variant="h3">
        {t(
          "For better use of the site, we recommend you to use browsers below.",
          { ns: "common" },
        )}
      </SubText>
      <IconListContainer>
        {ICON_LIST.map((item) => (
          <IconContainerWrapper key={item.browser}>
            <IconContainer>
              {item.iconComponent}
              <IconText variant="h4">{item.browser}</IconText>
              <Link
                to={{ pathname: item.downloadURL }}
                target="_blank"
                rel="noopener"
              >
                <HyperlinkButton text="download" />
              </Link>
            </IconContainer>
            {item.subText && (
              <Typo
                variant="caption1"
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  color: colors.grey[70],
                  fontWeight: 600,
                }}
              >
                {item.subText}
              </Typo>
            )}
          </IconContainerWrapper>
        ))}
      </IconListContainer>
    </Container>
  );
};

export default BrowserSupport;
