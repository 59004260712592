/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  FetchCreateProjectParams,
  FetchCreateProjectResponse,
  FetchGetProjectByIdParams,
  FetchGetProjectByIdResponse,
  FetchGetProjectListParams,
  FetchGetProjectListResponse,
  FetchRemoveProjectParams,
  FetchRemoveProjectResponse,
  FetchUpdateProjectParams,
  FetchUpdateProjectResponse,
} from "~/client/types/api/project";

import { APICallPayloads } from "~/client/types/api";
import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_PROJECT_API_BASE_URL: projectAPIBaseURL } = getConfigs();

const API_URL_PREFIX = projectAPIBaseURL ? `${projectAPIBaseURL}` : "";

export const fetchGetProjectList = ({
  config,
}: APICallPayloads<FetchGetProjectListParams>): Promise<FetchGetProjectListResponse> => {
  const url = `${API_URL_PREFIX}/`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetProjectById = ({
  params,
  config,
}: APICallPayloads<FetchGetProjectByIdParams>): Promise<FetchGetProjectByIdResponse> => {
  const url = `${API_URL_PREFIX}/${params.projectId}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchCreateProject = ({
  config,
  params,
}: APICallPayloads<FetchCreateProjectParams>): Promise<FetchCreateProjectResponse> => {
  const url = `${API_URL_PREFIX}/`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchUpdateProject = ({
  params,
  config,
}: APICallPayloads<FetchUpdateProjectParams>): Promise<FetchUpdateProjectResponse> => {
  const url = `${API_URL_PREFIX}/${params.id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
    data: {
      name: params.name,
      reset_key: params.reset_key,
    },
  });
};

export const fetchRemoveProject = ({
  params,
  config,
}: APICallPayloads<FetchRemoveProjectParams>): Promise<FetchRemoveProjectResponse> => {
  const url = `${API_URL_PREFIX}/${params.id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};
