import React, { FC, SVGProps } from "react";

const UploadIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.40039 11.865V14.64C2.40039 15.1702 2.8302 15.6 3.36039 15.6H14.6404C15.1706 15.6 15.6004 15.1702 15.6004 14.64V11.865H14.1004V14.1H3.90039V11.865H2.40039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.08887 5.41159L9.003 1.83954L13.9171 5.41159L13.004 6.5052L9.74599 4.137L9.74599 12.75H8.26014L8.26014 4.13691L5.00204 6.5052L4.08887 5.41159Z"
      fill="white"
    />
  </svg>
);

export default UploadIcon;
