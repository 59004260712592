import { useEffect, useState } from "react";
import useSWR from "swr";
import { Notification } from "~/client/types/notification";
import { useAppDispatch } from "~/client/hooks";
import { APIError } from "~/client/types";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetNotifications } from "~/client/api";

function useNotification() {
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { data, error, isValidating } = useSWR(
    SWR_KEY.NOTIFICATION_INITIALIZE_NOTIFICATION_LIST,
    async () => {
      const res = await fetchGetNotifications({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setNotifications(
      data.sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at)),
    );
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return { notifications, isValidating };
}

export default useNotification;
