import { Button, Card, Typo } from "@cochlearai/ui";
import { PropsWithChildren, ReactNode } from "react";
import styled, { useTheme } from "styled-components";

const Container = styled(Card)<{ warning?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 288px;
  border: ${(p) => (p.warning ? "1px solid #f03e3e" : "unset")};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-bottom: 80px;
`;
const TitleText = styled(Typo)`
  margin-bottom: 18px;
`;

const SubTitleText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[70]};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 30px;
  height: 100px;
`;

const ActionButton = styled(Button)<{ warning?: boolean }>`
  background-color: ${(p) =>
    p.warning ? "#f03e3e" : (p) => p.theme.colors.blue[60]};
  padding: 11px 16px;
`;

interface Props {
  title: string;
  subTitle?: string | ReactNode;
  actionButton: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
  };
  warning?: boolean;
  className?: string;
}

const SettingItem = ({
  title,
  subTitle,
  children,
  actionButton,
  warning,
  className,
}: PropsWithChildren<Props>) => {
  const { colors } = useTheme();
  return (
    <Container className={className} warning={warning}>
      <ContentContainer>
        <TitleText variant="h3">{title}</TitleText>
        {typeof subTitle === "string" ? (
          <SubTitleText variant="body">{subTitle}</SubTitleText>
        ) : (
          subTitle
        )}
        {children}
      </ContentContainer>
      <div>
        <div style={{ height: "2px", backgroundColor: colors.grey[10] }} />
        <ActionContainer>
          <ActionButton
            color="primary"
            onClick={actionButton.onClick}
            disabled={actionButton.disabled}
            warning={warning}
          >
            {actionButton.title}
          </ActionButton>
        </ActionContainer>
      </div>
    </Container>
  );
};

export default SettingItem;
