import {
  AmexIcon,
  DinersIcon,
  DiscoverIcon,
  JCBIcon,
  ListDropdown,
  MasterIcon,
  MoreIcon,
  Typo,
  UnionpayIcon,
  VisaIcon,
} from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";
import {
  CARD_BRAND_UI_LABELS,
  PAYMENT_MORE_TYPE,
} from "~/client/lib/constants";
import { CARD_BRAND, PaymentMethod } from "~/client/types/payment";

import { media } from "@cochlearai/util";

const ItemContainerRow = styled.div<{ $isDefault: boolean }>`
  padding: 15px 20px;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid ${(p) => p.theme.colors.grey[20]};
  border-radius: 4px;
  background: ${(p) => (p.$isDefault ? p.theme.colors.grey[10] : "")};

  & + & {
    margin-top: 10px;
  }
`;

const ItemContainerItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 176px;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
    min-width: unset;
  }
`;

const CardBrandWrapper = styled.div`
  min-width: 50px;
  height: 30px;
  border: 1px solid ${(p) => p.theme.colors.grey[20]};
  border-radius: 4px;
  background: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  data: PaymentMethod;
  isMulti?: boolean;
  openMorePopover: (id: string) => void;
  closeMorePopover: (id: string) => void;
  isPopoverOpen: (id: string) => boolean;
  onClickMoreItem: (type: string, item: PaymentMethod) => void;
}

const PaymentMethodItem: FC<Props> = ({
  data,
  isMulti,
  openMorePopover,
  closeMorePopover,
  isPopoverOpen,
  onClickMoreItem,
}) => {
  const { colors } = useTheme();
  if (!data.card) {
    return null;
  }
  const { brand, last4, exp_month: expMonth, exp_year: expYear } = data.card;

  const renderCardBrandImage = (brand: CARD_BRAND) => {
    switch (brand) {
      case CARD_BRAND.AMEX:
        return <AmexIcon />;
      case CARD_BRAND.DINERS:
        return <DinersIcon />;
      case CARD_BRAND.DISCOVER:
        return <DiscoverIcon />;
      case CARD_BRAND.JCB:
        return <JCBIcon />;
      case CARD_BRAND.MASTER:
        return <MasterIcon />;
      case CARD_BRAND.UNION:
        return <UnionpayIcon />;
      case CARD_BRAND.VISA:
        return <VisaIcon />;
      default:
        return <></>;
    }
  };

  return (
    <ItemContainerRow $isDefault={data.is_default}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CardBrandWrapper>{renderCardBrandImage(brand)}</CardBrandWrapper>
        <ItemContainerItem>
          <Typo variant="button">{CARD_BRAND_UI_LABELS[brand]}</Typo>
          <Typo variant="button">(**** {last4})</Typo>
        </ItemContainerItem>
        <Typo variant="body">
          Expires {`${expMonth}`.length === 1 ? `0${expMonth}` : `${expMonth}`}/
          {expYear}
        </Typo>
      </div>
      {isMulti && data.is_default ? (
        <Typo variant="body" style={{ color: colors.grey[70] }}>
          Default
        </Typo>
      ) : (
        <ListDropdown
          align="end"
          data={!isMulti ? PAYMENT_MORE_TYPE.slice(0, 1) : PAYMENT_MORE_TYPE}
          isOpen={isPopoverOpen(data.id)}
          onClickItem={(type) => onClickMoreItem(type, data)}
          onClose={() => closeMorePopover(data.id)}
          onClickOutside={() => closeMorePopover(data.id)}
        >
          <Flex
            style={{ height: "20px", cursor: "pointer" }}
            onClick={() => openMorePopover(data.id)}
          >
            <MoreIcon fill={colors.black} />
          </Flex>
        </ListDropdown>
      )}
    </ItemContainerRow>
  );
};

export default PaymentMethodItem;
