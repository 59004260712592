import { getConfigs, emptyCache } from "~/client/lib";

const { REACT_APP_DASHBOARD_URL: dashboardURL } = getConfigs();

export default function checkAppVersion() {
  if (process.env.NODE_ENV === "production") {
    if (dashboardURL) {
      fetch(`${dashboardURL}/buildMeta.json`).then((res) => {
        res.json().then((val: { APP_VERSION: string }) => {
          const { APP_VERSION: appVersion } = val;
          const storedAppVersion = localStorage.getItem("@cochl/app-version");
          if (storedAppVersion) {
            if (storedAppVersion !== appVersion) {
              localStorage.setItem("@cochl/app-version", appVersion);
              emptyCache();
            }
          } else {
            localStorage.setItem("@cochl/app-version", appVersion);
          }
        });
      });
    }
  }
}
