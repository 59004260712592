import { Button, Card, Typo } from "@cochlearai/ui";
import { FC } from "react";

import { media } from "@cochlearai/util";
import styled from "styled-components";

const Container = styled(Card)`
  border: 1px solid ${(p) => p.theme.colors.red_stroke};
  box-shadow: none;
  padding: 40px;

  display: flex;
  align-items: center;

  ${media.query.lg} {
    flex-direction: column;
    align-items: unset;
  }
`;

const TitleText = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
  width: 266px;
  ${media.query.lg} {
    width: 100%;
  }
`;

const SubText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};
  width: 690px;
  white-space: pre;

  ${media.query.lg} {
    width: 100%;
    margin-top: 14px;
    white-space: normal;
  }
`;

const DeleteButton = styled(Button)`
  margin-left: auto;
  width: 95px;
  height: 40px;

  ${media.query.lg} {
    margin-top: 14px;
  }
`;

interface Props {
  onClick?: () => void;
  titleLabel: string;
  subTextLabel: string;
  deleteButtonLabel: string;
}

const DeleteProjectItem: FC<Props> = ({
  titleLabel,
  subTextLabel,
  deleteButtonLabel,
  onClick,
}) => {
  return (
    <Container>
      <TitleText variant="h3">{titleLabel}</TitleText>
      <SubText variant="body">{subTextLabel}</SubText>
      <DeleteButton
        color="danger"
        onClick={() => {
          onClick && onClick();
        }}
      >
        {deleteButtonLabel}
      </DeleteButton>
    </Container>
  );
};

export default DeleteProjectItem;
