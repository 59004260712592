import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { emptyCache } from "~/client/lib";
import { pageView } from "../ga/utils";

function usePageReload() {
  const location = useLocation();
  const pageShowHandler = useCallback(
    (event: PageTransitionEvent) => {
      if (event.persisted) {
        const { pathname } = location;
        emptyCache();
        pageView(pathname);
      }
    },
    [location],
  );

  useEffect(() => {
    window.addEventListener("pageshow", pageShowHandler);
    return () => {
      window.removeEventListener("pageshow", pageShowHandler);
    };
  }, [pageShowHandler]);
}

export default usePageReload;
