import React, { FC, SVGProps } from "react";

const PlusIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.4 0H6.6V12H5.4V0Z" fill="#fff" />
    <path d="M12 5.4V6.6L0 6.6L5.24533e-08 5.4L12 5.4Z" fill="#fff" />
  </svg>
);

export default PlusIcon;
