import { PropsWithChildren } from "react";

import { color } from "@cochlearai/util";
import styled from "styled-components";
import { BaseStyleProps } from "../types";

const CardContainer = styled.div<{ $hoverEffect?: boolean }>`
  background: ${(p) => p.theme.colors.white};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.grey[20]};

  &:hover {
    box-shadow: ${(p) =>
      p.$hoverEffect &&
      `0px 0px 20px ${color.convertHexToRGB({
        hex: p.theme.colors.blue[60],
        alpha: 0.1,
      })}`};
    border: ${(p) => p.$hoverEffect && `1px solid ${p.theme.colors.blue[60]}`};
    & p:not(.caption) {
      color: ${(p) => p.$hoverEffect && p.theme.colors.grey[90]};
    }
    & .caption:last-child {
      color: ${(p) => p.$hoverEffect && p.theme.colors.grey[70]};
    }
  }
`;

export interface CardProps extends BaseStyleProps {
  hoverEffect?: boolean;
}

/**
 * React component used to create a card layout with optional hover effect and custom styles.
 * @param style      - The style of the card.
 * @param hoverEffect - Whether the card should have a hover effect.
 * @param className  - The className of the card.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/card--default
 */
export const Card = ({
  children,
  style,
  hoverEffect,
  className,
}: PropsWithChildren<CardProps>) => {
  return (
    <CardContainer
      className={className}
      $hoverEffect={hoverEffect}
      style={style}
    >
      {children}
    </CardContainer>
  );
};
