import { selectedAnalyticsState } from "~/client/lib/selectors";
import { updateFilterParams } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useState } from "react";
import {
  AnalyticsType,
  FilterParams,
  FilterParamsValue,
  FilterType,
} from "~/client/types/analytics";
import { getDateRangeByScale } from "~/client/lib/helpers";
import {
  TIMESERIES_MAX_TAGS,
  TOP_TAGS_MIN_COUNT,
} from "~/client/lib/constants";

function useAnalyticsFilter({
  chartType,
  open,
}: {
  chartType: AnalyticsType;
  open: boolean;
}) {
  const dispatch = useAppDispatch();
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const [filters, setFilters] = useState<FilterParams>(
    filterParamsWithChecked ? filterParamsWithChecked[chartType] : {},
  );

  const resetFilter = useCallback(() => {
    if (!filterParamsWithChecked) return;
    setFilters(filterParamsWithChecked[chartType]);
  }, [filterParamsWithChecked, chartType]);

  const onClickFilter = useCallback(
    (key: FilterType, target) => {
      const filterValues = filters[key] as FilterParamsValue[];
      let filterValue;
      const isScale = key === FilterType.SCALE;
      const dateRange = isScale
        ? getDateRangeByScale(
            target.find((item: { checked: boolean }) => item.checked).value,
          )
        : undefined;

      if (
        [
          FilterType.TOP_TAGS_COUNT,
          FilterType.SCALE,
          FilterType.FROM_TO,
        ].indexOf(key) !== -1
      ) {
        filterValue = target;
      } else {
        const checkedValues = filterValues.filter((item) => item.checked);
        filterValue = filterValues.map((item) => {
          if (target.value === item.value) {
            if (
              !item.checked &&
              key === FilterType.TAG &&
              chartType === AnalyticsType.REALTIME_VIEW &&
              checkedValues.length === TIMESERIES_MAX_TAGS
            ) {
              return {
                ...item,
              };
            }
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return {
            ...item,
          };
        });
      }
      setFilters({
        ...filters,
        ...(dateRange && {
          [FilterType.FROM_TO]: {
            from: dateRange.startDate.format("YYYY-MM-DDTHH:mm:ssZ"),
            to: dateRange.endDate.format("YYYY-MM-DDTHH:mm:ssZ"),
            badge: dateRange.dateBadge,
          },
        }),
        [key]: filterValue,
      });
    },
    [chartType, filters],
  );

  const setFilter = useCallback(
    (key: FilterType, value) => {
      setFilters({
        ...filters,
        [key]: value,
      });
    },
    [filters],
  );

  const updateFilter = useCallback(() => {
    dispatch(
      updateFilterParams({
        type: chartType,
        value: filters,
      }),
    );
  }, [dispatch, chartType, filters]);

  const clearFilter = useCallback(() => {
    const newFilter = Object.entries(filters)
      .map(([key, value]) => {
        const values =
          key === FilterType.TOP_TAGS_COUNT
            ? TOP_TAGS_MIN_COUNT
            : key === FilterType.FROM_TO
              ? value
              : key === FilterType.SCALE
                ? (value as FilterParamsValue[]).map((filter) => ({
                    ...filter,
                    checked: filter.value === "day" ? true : false,
                  }))
                : (value as FilterParamsValue[]).map((filter) => ({
                    ...filter,
                    checked: false,
                  }));
        return {
          [key]: values,
        };
      })
      .reduce((acc, cv) => ({ ...acc, ...cv }), {});
    dispatch(
      updateFilterParams({
        type: chartType,
        value: newFilter as FilterParams,
      }),
    );
  }, [filters, dispatch, chartType]);

  useEffect(() => {
    resetFilter();
  }, [open, chartType, resetFilter]);

  return {
    filters,
    updateFilter,
    onClickFilter,
    resetFilter,
    clearFilter,
    setFilter,
  };
}

export default useAnalyticsFilter;
