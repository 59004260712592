import {
  InfoTooltip,
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
} from "@cochlearai/ui";
import { ThemeType } from "@cochlearai/ui/src/types/theme";
import React, { FC, ReactNode, memo, useCallback, useState } from "react";
import styled, { useTheme } from "styled-components";

import { color, media } from "@cochlearai/util";
import moment from "moment";
import {
  INVOICE_TABLE_COLUMN_ITEMS,
  PRODUCT_TYPE_UI_LABELS,
} from "~/client/lib/constants";
import {
  getInvoiceTableColumnWidth,
  numberWithCommas,
} from "~/client/lib/helpers";
import { INVOICE_TABLE_COLUMN_TYPE, InvoiceInfo } from "~/client/types/invoice";
import { PlanType } from "~/client/types/priceplan";
import { ProjectType } from "~/client/types/project";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled(Table)`
  min-width: ${media.size.md};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: INVOICE_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getInvoiceTableColumnWidth(p.columnType)};
  padding: 12.5px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
`;

const HeaderItemTypo = styled(Typo)<{ left?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: ${(p) => (p.left ? "left" : "end")};
`;

const TableRowContainer = styled(TableRow)<{ selected: boolean }>`
  background-color: ${(p) =>
    p.selected ? (p) => p.theme.colors.grey[20] : (p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
`;

const PlanGroupRowContainer = styled(TableRowContainer)`
  background-color: ${(p) => p.theme.colors.grey[10]};
`;

const PriceRowContainer = styled(TableRowContainer)`
  & + & {
    border-top: unset;
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: INVOICE_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getInvoiceTableColumnWidth(p.columnType)};
  padding: 0 20px;
  height: 40px;
  vertical-align: middle;
`;

const PriceTableBodyItem = styled(TableBodyItem)`
  height: 30px;
`;

const TableBodyItemText = styled(Typo)<{ left?: boolean }>`
  text-align: ${(p) => (p.left ? "left" : "end")};
`;

const PlanBadge = styled(Typo)<{ plan: PlanType }>`
  padding: 2px 6px;
  background-color: ${(p) =>
    p.theme.theme === ThemeType.LIGHT
      ? color.convertHexToRGB({
          hex: p.theme.colors.plan_color[p.plan],
          alpha: 0.1,
        })
      : "#ffffff"};
  color: ${(p) => p.theme.colors.plan_color[p.plan]};
  width: fit-content;
  border-radius: 2px;
  margin-left: 8px;
`;

const ProjectTypeText = styled(Typo)<{ type: ProjectType }>`
  color: ${(p) => p.theme.colors.product_type_color[p.type]};
  margin-right: 8px;
`;

interface Props {
  data: InvoiceInfo;
  EmptyComponent?: ReactNode;
}

const InvoiceTable: FC<Props> = ({ data, EmptyComponent }) => {
  const { colors } = useTheme();
  const [invoicePopoverIsOpen, setInvoicePopoverIsOpen] = useState<
    Array<{ id: string; open: boolean }>
  >([]);

  const openPopover = useCallback(
    (id) => {
      const tempArr = invoicePopoverIsOpen.map((item) =>
        item.id === id ? { id, open: true } : { ...item, open: false },
      );

      if (invoicePopoverIsOpen.find((item) => item.id === id)) {
        setInvoicePopoverIsOpen(tempArr);
      } else {
        setInvoicePopoverIsOpen([...tempArr, { id, open: true }]);
      }
    },
    [invoicePopoverIsOpen],
  );

  const closePopover = useCallback(
    (id) => {
      setInvoicePopoverIsOpen(
        invoicePopoverIsOpen.map((item) =>
          item.id === id ? { id, open: false } : item,
        ),
      );
    },
    [invoicePopoverIsOpen],
  );

  const isPopoverOpen = useCallback(
    (id) => {
      return invoicePopoverIsOpen.find((item) => item.id === id)?.open ?? false;
    },
    [invoicePopoverIsOpen],
  );

  return (
    <Scroll>
      <Container>
        <TableHead>
          <TableRow>
            {INVOICE_TABLE_COLUMN_ITEMS.map((item, i) => {
              return (
                <TableHeadItem
                  key={item.columnType}
                  component="th"
                  columnType={item.columnType}
                >
                  <HeaderItemTypo variant="body" left={i === 0}>
                    {item.label}
                  </HeaderItemTypo>
                </TableHeadItem>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.plan_groups.map((group, i) => (
            <React.Fragment key={i}>
              <PlanGroupRowContainer selected={false}>
                <TableBodyItem
                  columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
                  colSpan={4}
                >
                  <Flex>
                    <TableBodyItemText
                      variant="caption1"
                      style={{ color: colors.grey[60] }}
                    >
                      {`${moment
                        .unix(group.period.start)
                        .format("MMM DD, YYYY")} - ${moment
                        .unix(group.period.end)
                        .format("MMM DD, YYYY")}`}
                    </TableBodyItemText>
                    <PlanBadge
                      variant="caption3"
                      plan={group.plan_type as PlanType}
                    >
                      {group.plan_type}
                    </PlanBadge>
                  </Flex>
                </TableBodyItem>
              </PlanGroupRowContainer>
              {group.items.map((plan, item_index) => (
                <React.Fragment key={item_index}>
                  <TableRowContainer selected={false}>
                    <TableBodyItem
                      columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
                    >
                      <Flex>
                        <ProjectTypeText
                          variant="caption3"
                          type={plan.product_type as ProjectType}
                        >
                          {PRODUCT_TYPE_UI_LABELS[plan.product_type]}
                        </ProjectTypeText>
                        <TableBodyItemText variant="button">
                          {plan.description}
                        </TableBodyItemText>
                      </Flex>
                    </TableBodyItem>
                    <TableBodyItem columnType={INVOICE_TABLE_COLUMN_TYPE.QTY}>
                      <TableBodyItemText variant="body">
                        {numberWithCommas({ value: plan.quantity })}
                      </TableBodyItemText>
                    </TableBodyItem>
                    <TableBodyItem
                      columnType={INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE}
                    >
                      <TableBodyItemText variant="body">
                        {numberWithCommas({
                          value: plan.unit_price,
                          decimal: 2,
                          currency: "usd",
                        })}
                      </TableBodyItemText>
                    </TableBodyItem>
                    <TableBodyItem
                      columnType={INVOICE_TABLE_COLUMN_TYPE.AMOUNT}
                    >
                      <TableBodyItemText variant="button">
                        {numberWithCommas({
                          value: plan.amount,
                          decimal: 3,
                          currency: "usd",
                        })}
                      </TableBodyItemText>
                    </TableBodyItem>
                  </TableRowContainer>
                  {plan.sub_items &&
                    plan.sub_items.map((sub, sub_index) => (
                      <TableRowContainer key={sub_index} selected={false}>
                        <TableBodyItem
                          columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
                        >
                          <Flex>
                            <TableBodyItemText
                              variant="body"
                              style={{
                                color: colors.grey[80],
                                marginLeft: "50px",
                              }}
                            >
                              {sub.description}
                            </TableBodyItemText>
                            <TableBodyItemText
                              variant="button"
                              style={{
                                color: colors.grey[50],
                                margin: "0 8px",
                              }}
                            >
                              {`${moment
                                .unix(sub.period.start)
                                .format("MM/DD")} ~ ${moment
                                .unix(sub.period.end)
                                .format("MM/DD")}`}
                            </TableBodyItemText>
                            <InfoTooltip
                              open={isPopoverOpen(
                                `${i}_${item_index}_${sub_index}`,
                              )}
                              text="Unused dates will be charged counting 1 month as 30 days default"
                              onMouseOver={() =>
                                openPopover(`${i}_${item_index}_${sub_index}`)
                              }
                              onMouseOut={() =>
                                closePopover(`${i}_${item_index}_${sub_index}`)
                              }
                              position="bottom"
                            />
                          </Flex>
                        </TableBodyItem>
                        <TableBodyItem
                          columnType={INVOICE_TABLE_COLUMN_TYPE.QTY}
                        >
                          <TableBodyItemText variant="body">
                            {numberWithCommas({ value: sub.quantity })}
                          </TableBodyItemText>
                        </TableBodyItem>
                        <TableBodyItem
                          columnType={INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE}
                        >
                          <TableBodyItemText variant="body">
                            {numberWithCommas({
                              value: sub.unit_price,
                              decimal: 2,
                              currency: "usd",
                            })}
                          </TableBodyItemText>
                        </TableBodyItem>
                        <TableBodyItem
                          columnType={INVOICE_TABLE_COLUMN_TYPE.AMOUNT}
                        >
                          <TableBodyItemText variant="button">
                            {numberWithCommas({
                              value: sub.amount,
                              decimal: 3,
                              currency: "usd",
                            })}
                          </TableBodyItemText>
                        </TableBodyItem>
                      </TableRowContainer>
                    ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <PriceRowContainer selected={false}>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
              colSpan={4}
              style={{ height: "20px" }}
            ></PriceTableBodyItem>
          </PriceRowContainer>
          <PriceRowContainer selected={false}>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
            ></PriceTableBodyItem>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE}
              colSpan={2}
            >
              <TableBodyItemText variant="button">Subtotal</TableBodyItemText>
            </PriceTableBodyItem>
            <PriceTableBodyItem columnType={INVOICE_TABLE_COLUMN_TYPE.AMOUNT}>
              <TableBodyItemText variant="button">
                {numberWithCommas({
                  value: data?.sub_total,
                  decimal: 2,
                  currency: "usd",
                })}
              </TableBodyItemText>
            </PriceTableBodyItem>
          </PriceRowContainer>
          <PriceRowContainer selected={false}>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
            ></PriceTableBodyItem>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE}
              colSpan={2}
            >
              <TableBodyItemText
                variant="button"
                style={{ color: colors.grey[60], fontStyle: "italic" }}
              >
                Applied balance
              </TableBodyItemText>
            </PriceTableBodyItem>
            <PriceTableBodyItem columnType={INVOICE_TABLE_COLUMN_TYPE.AMOUNT}>
              <TableBodyItemText
                variant="button"
                style={{ color: colors.grey[60] }}
              >
                {numberWithCommas({
                  value: data?.applied_balance,
                  decimal: 2,
                  currency: "usd",
                })}
              </TableBodyItemText>
            </PriceTableBodyItem>
          </PriceRowContainer>
          <PriceRowContainer selected={false}>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.DESCRIPTION}
            ></PriceTableBodyItem>
            <PriceTableBodyItem
              columnType={INVOICE_TABLE_COLUMN_TYPE.UNIT_PRICE}
              colSpan={2}
            >
              <TableBodyItemText variant="button">Total</TableBodyItemText>
            </PriceTableBodyItem>
            <PriceTableBodyItem columnType={INVOICE_TABLE_COLUMN_TYPE.AMOUNT}>
              <TableBodyItemText variant="button">
                {numberWithCommas({
                  value: data?.total,
                  decimal: 2,
                  currency: "usd",
                })}
              </TableBodyItemText>
            </PriceTableBodyItem>
          </PriceRowContainer>
        </TableBody>
      </Container>
      {data?.plan_groups.length === 0 && EmptyComponent}
    </Scroll>
  );
};

export default memo(InvoiceTable, (prev, next) => prev.data === next.data);
