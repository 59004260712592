import { Card, Skeleton } from "@cochlearai/ui";
import { FC } from "react";

import styled from "styled-components";

const InsightCardSkeletonContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1;
  box-shadow: unset;
  background-color: transparent;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  border-radius: 4px;
  height: 80px;
  & + & {
    margin-left: 12px;
  }
`;

const InsightCardTextSkeleton = styled(Skeleton)`
  width: 140px;
  height: 8px;
`;

const InsightCardRectangleSkeleton = styled(Skeleton)`
  width: 84px;
  height: 30px;
  margin-top: 10px;
  border-radius: 4px;
`;

const AnalyticsInsightTagCardSkeleton: FC = () => {
  return (
    <InsightCardSkeletonContainer>
      <InsightCardTextSkeleton variant="text" />
      <InsightCardRectangleSkeleton variant="rectangle" />
    </InsightCardSkeletonContainer>
  );
};

export default AnalyticsInsightTagCardSkeleton;
