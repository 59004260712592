import { Project, ProjectType, SDKChannel } from ".";
import { SenseIOTypes, SensePlatforms, SenseTags } from "./sense";

export enum AnalyticsType {
  TAG_FREQUENCY = "TAG_FREQUENCY",
  REALTIME_VIEW = "REALTIME_VIEW",
  TOP_TAG = "TOP_TAG",
  TAG_AMOUNT_BREAKDOWN = "TAG_AMOUNT_BREAKDOWN",
}

export enum FilterType {
  TAG = "tag",
  DEVICE = "device",
  GROUP_BY = "groupBy",
  TOP_TAGS_COUNT = "topTagsCount",
  SCALE = "scale",
  FROM_TO = "fromTo",
  SESSION = "session",
}

export interface FilterParamsValue {
  value: SenseTags | string;
  label: SenseTags | string;
  checked: boolean;
}

export type FilterParamsWithChecked = {
  [T in AnalyticsType]: FilterParams;
};

export type TopTagSession = {
  value: string;
  file_name: string;
  date: number;
  checked: boolean;
};

export interface FilterParams {
  [FilterType.TAG]?: FilterParamsValue[];
  [FilterType.DEVICE]?: FilterParamsValue[];
  [FilterType.GROUP_BY]?: FilterParamsValue[];
  [FilterType.TOP_TAGS_COUNT]?: number;
  [FilterType.SCALE]?: FilterParamsValue[];
  [FilterType.FROM_TO]?: {
    from: string;
    to: string;
    badge?: string;
  };
  [FilterType.SESSION]?: TopTagSession[];
}

export interface SelectedAnalyticsEvent {
  date?: string;
  platform?: ProjectType;
  tagCount?: { tag: string; value: number }[];
}

export interface FilterItemUI {
  label: string;
  value: string;
}

export type AnalyticsFilterItems = FilterItemUI[] | null;

export interface HeatmapEvent {
  data: {
    lines: {
      line_name: string;
      columns: {
        column_name: string;
        date: string;
        total_count: number;
        counts?: { [key in SenseTags]: number };
      }[];
    }[];
  };
  legend: {
    lines: string[];
    columns: string[];
  };
  current_page: string;
  prev: {
    from: string;
    to: string;
  };
  next: {
    from: string;
    to: string;
  };
}

export interface TimeseriesEvent {
  devices: string[];
  tags: SenseTags[];
  data: { date: string; count: number }[];
}

export interface TopTag {
  tag_name: SenseTags;
  count: number;
}

export interface SDKTopTag extends TopTag {
  device: string;
}

export interface TagFrequency {
  name: string;
  data: { x: string; y: number }[];
}

//previous analytics

export enum ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE {
  DATE = "DATE",
  TAG = "TAG",
  DEVICE_NAME = "DEVICE_NAME",
  CONTENT_TYPE = "CONTENT_TYPE",
  IOTYPE = "IO_TYPE",
}

export interface AnalyticsEventListTableColumnItem {
  columnType: ANALYTICS_EVENT_LIST_TABLE_COLUMN_TYPE;
  label: string;
}

export interface AnalyticsEvent {
  event_id: number;
  project_id: string;
  session_id: string;
  tag: string;
  content_type: string;
  io_type: string;
  platform: string;
  timestamp: number;
  device_id?: string;
  device_name?: string;
  channel_id?: string;
}

export enum ANALYTICS_SORT {
  TIMESTAMP_DESC = "timestamp desc",
  TIMESTAMP_ASC = "timestamp asc",
  EVENT_ID_DESC = "event_id desc",
  EVENT_ID_ASC = "event_id asc",
}

export enum CONTENT_TYPE {
  AUDIO_MP3 = "audio/mp3",
  AUDIO_WAV = "audio/wav",
  AUDIO_X_RAW = "audio/x-raw",
  AUDIO_OGG = "audio/ogg",
}

export interface FilterParamsValueOld {
  value: SenseIOTypes | CONTENT_TYPE | SenseTags | SensePlatforms | string;
  checked: boolean;
}

export interface FilterParamsWithCheckedOld {
  ioType: FilterParamsValueOld[];
  contentType: FilterParamsValueOld[];
  tag: FilterParamsValueOld[];
  deviceName?: FilterParamsValueOld[];
  platform?: FilterParamsValueOld[];
}

export type FilterParamsKeys = keyof FilterParamsWithCheckedOld;
export type FilterParamsValues = FilterParamsWithCheckedOld[FilterParamsKeys];

export interface FetchEventQueryParams {
  filterParamsWithChecked: FilterParamsWithCheckedOld;
  currentProject: Project;
  page?: number;
  limit?: number;
  sort?: ANALYTICS_SORT;
  from?: number;
  to?: number;
  searchText?: string | null;
  channelList?: SDKChannel[];
}

export interface AnalyticsMetaParams {
  group: string[];
  io_type: string[];
  content_type: string[];
  tag_name: string[];
  platform: string[];
  device_id?: string[];
}

export interface FetchEventListParams {
  filter: AnalyticsMetaParams;
  project_id: string[];
  channel_id?: string;
  session_id?: string;
  initialize?: boolean;
  from?: number;
  to?: number;
  page?: number;
  limit?: number;
  sort?: ANALYTICS_SORT;
}

export type TagCount = {
  tag_name: string;
  count: number;
};

export interface FilterParamsOld {
  ioType: SenseIOTypes[];
  contentType: CONTENT_TYPE[];
  tag: {
    TagId: number;
    Name: SenseTags;
  }[];
  platform: SensePlatforms[];
}
