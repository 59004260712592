import { media } from "@cochlearai/util";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import { NotiIcon } from "../Icons";

const Container = styled.div<{ $confirmed?: boolean }>`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    & path:last-child {
      fill: ${(p) =>
        p.$confirmed ? p.theme.colors.black : p.theme.colors.red};
    }
  }

  ${media.query.md} {
    margin-left: auto;
  }
`;

export interface NotificationProps extends ComponentPropsWithoutRef<"div"> {
  onClick?: () => void;
  className?: string;
  confirmed?: boolean;
}

/**
 * React component used to create a notification icon.
 * @param className - CSS class name.
 * @param confirmed - Indicates whether the notification is confirmed or not.
 * @param onClick - Callback function that will be called when user clicks on the notification icon.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/notification--default
 */
export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  ({ onClick, className, confirmed }, ref) => {
    const { colors } = useTheme();
    return (
      <Container
        ref={ref}
        className={className}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "unset" }}
        $confirmed={confirmed}
      >
        <NotiIcon fill={colors.black} />
      </Container>
    );
  },
);
