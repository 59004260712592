import React, { FC, SVGProps } from "react";

const ModalCloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.546 0 16 1.455 1.455 16 0 14.546 14.546 0Z"
      fill={props.fill || "#131313"}
    />
    <path
      d="m16 14.545-1.455 1.454L0 1.454 1.455 0 16 14.545Z"
      fill={props.fill || "#131313"}
    />
  </svg>
);

export default ModalCloseIcon;
