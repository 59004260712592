import { BaseStyleProps } from "../types";
import { CopyIcon } from "../Icons";
import { PropsWithChildren } from "react";
import { Scroll } from "../Scroll";
import { Typo } from "../Typo";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const Container = styled(Scroll)`
  position: relative;
  width: 100%;
  height: 556px;
  padding: 30px;
  border-radius: 4px;
  background: ${(p) => p.theme.colors.code};
  border: 2px solid transparent;
  color: #ffffff;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
  }

  ${media.query.md} {
    height: 450px;
  }
`;

const CopyButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  gap: 6px;
  top: 20px;
  right: 20px;
  color: #e5e5e5;
`;

export interface CodeViewProps extends BaseStyleProps {
  onClickCopy?: () => void;
}

/**
 * React component used to create a code view layout.
 * @param className  - The className of the code view.
 * @param style      - The style of the code view.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/codeview--default
 */
export const CodeView = ({
  children,
  className,
  style,
  onClickCopy,
}: PropsWithChildren<CodeViewProps>) => {
  return (
    <Container className={className} style={style}>
      <pre>{children}</pre>
      {onClickCopy && (
        <CopyButton onClick={onClickCopy}>
          <Typo variant="button">Copy</Typo>
          <CopyIcon width={20} height={20} fill="#E5E5E5" />
        </CopyButton>
      )}
    </Container>
  );
};
