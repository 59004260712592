import React, { FC, SVGProps } from "react";

const CalendarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill={props.fill || "#131313"} d="M2 6h2v2H2z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0h1v1h4V0h1v1h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h2V0Zm5 2v1h1V2h2a1 1 0 0 1 1 1v1H1V3a1 1 0 0 1 1-1h2v1h1V2h4ZM1 5v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H1Z"
      fill={props.fill || "#131313"}
    />
    <path fill={props.fill || "#131313"} d="M6 6h2v2H6zM10 6h2v2h-2z" />
  </svg>
);

export default CalendarIcon;
