import { useCallback, useMemo, useState } from "react";
import {
  fetchCreateMediumSetting,
  fetchUpdateNotiConfig,
} from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";
import {
  APIError,
  APIErrorData,
  API_ERROR_STATUS,
  MediumType,
  Project,
  TagInterval,
  TAG_INTERVAL_TIME_TYPE_VALUE,
} from "~/client/types";
import {
  setClientError,
  setClientInfo,
  setServerError,
} from "~/client/store/modules";
import { ONE_MINUTE_SECOND } from "~/client/lib/constants";

function useCreateEmailNC({
  currentProject,
  triggerCondition,
}: {
  currentProject: Project | null;
  triggerCondition?: TagInterval[];
}) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emails, setEmails] = useState<{ value: string; label: string }[]>([]);
  const [interval, setInterval] = useState<string>("");
  const [unit, setUnit] = useState<TAG_INTERVAL_TIME_TYPE_VALUE>(
    TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS,
  );
  const intervalTimeSecond = useMemo(
    () =>
      interval
        ? +interval *
          (unit === TAG_INTERVAL_TIME_TYPE_VALUE.MINUTES
            ? ONE_MINUTE_SECOND
            : 1)
        : 0,
    [interval, unit],
  );

  const addEmail = useCallback(async () => {
    if (isLoading || !currentProject) return;
    setIsLoading(true);
    try {
      await fetchCreateMediumSetting({
        params: {
          medium: {
            medium_type: MediumType.EMAIL,
            emails: emails.map((mail) => mail.value),
          },
          "notification-configuration": {
            project_id: currentProject.id,
            interval: intervalTimeSecond,
          },
          "trigger-conditions": triggerCondition?.map((data) => ({
            tag_name: data.value,
          })),
        },
      });

      dispatch(setClientInfo({ text: "Email notification has been created" }));
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      if (error.response?.status === API_ERROR_STATUS.BAD_REQUEST) {
        const errorData = error.response?.data as APIErrorData;
        const message =
          typeof errorData.details === "object"
            ? errorData.details.message
            : errorData.details;

        if (errorData && message) {
          dispatch(
            setClientError({
              text: message,
            }),
          );
        } else {
          throw e;
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    isLoading,
    currentProject,
    emails,
    intervalTimeSecond,
    triggerCondition,
    dispatch,
  ]);

  const updateNC = useCallback(
    async (config_id: string) => {
      if (isLoading || !config_id) return;
      setIsLoading(true);

      try {
        await fetchUpdateNotiConfig({
          params: {
            config_id,
            interval: intervalTimeSecond,
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, intervalTimeSecond, isLoading],
  );

  return {
    emails,
    setEmails,
    interval,
    setInterval,
    unit,
    setUnit,
    intervalTimeSecond,
    addEmail,
    updateNC,
    isLoading,
  };
}

export default useCreateEmailNC;
