import { SenseEvent, SenseUniqueTag } from "~/client/types/senseAPI";

import { SenseTags } from "~/client/types/sense";
import { findLastIndex } from ".";

function getUniqueSenseTag(tagArr: SenseEvent[]) {
  const detectionTags = [] as SenseUniqueTag[];

  for (let i = 0; i < tagArr.length; i++) {
    const beforeTags = tagArr[i - 1]?.tags;
    const afterTags = tagArr[i + 1]?.tags;
    const currentTags = tagArr[i].tags;

    for (let j = 0; j < currentTags.length; j++) {
      if (currentTags[j].name === SenseTags.OTHERS) continue;

      if (!beforeTags?.find((tag) => tag.name === currentTags[j].name)) {
        const duplicatedIdx = findLastIndex(
          detectionTags,
          (tag) => tag.name === currentTags[j].name,
        );
        if (detectionTags[duplicatedIdx]?.end === tagArr[i].start_time) {
          detectionTags[duplicatedIdx].started = true;
        } else {
          detectionTags.push({
            start: tagArr[i].start_time,
            end: tagArr[i].end_time,
            name: currentTags[j].name,
            started: true,
          });
        }
      } else if (!afterTags?.find((tag) => tag.name === currentTags[j].name)) {
        const foundIdx = findLastIndex(
          detectionTags,
          (tag) => tag.name === currentTags[j].name && tag.started,
        );

        detectionTags[foundIdx].started = false;
        detectionTags[foundIdx].end = tagArr[i].end_time;
      }
    }
  }

  return detectionTags;
}

export default getUniqueSenseTag;
