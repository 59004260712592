import React, { FC, SVGProps } from "react";

const HeaderLogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 160 33"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="2.9048"
          y1="20.108"
          x2="28.7261"
          y2="20.108"
        >
          <stop offset="0" style={{ stopColor: "#9327FF" }} />
          <stop offset="0.5027" style={{ stopColor: "#4B7EFF" }} />
          <stop offset="1" style={{ stopColor: "#00D7FF" }} />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M28.7,20.2c0,7.1-5.8,12.8-13,12.8c-7,0-12.8-5.7-12.8-12.8c0-7.2,5.8-13,12.8-13C22.9,7.2,28.7,13,28.7,20.2z
		"
        />
        <g>
          <g>
            <g>
              <path
                fill={props.fill || ""}
                d="M75.5,8.6c-3.5,0-6.5,1.3-8.8,3.9c-0.5,0.5-0.9,1.1-1.3,1.7c-2.7,3.5-9.8,12.8-10.2,13c-1.8,2-4.3,3-7.2,3
					c-1.9,0-3.5-0.4-5-1.3c-1.5-0.7-2.6-2-3.5-3.4c-0.8-1.6-1.4-3-1.4-4.7c0-2.6,1-4.9,2.9-6.6c1.8-1.8,4.1-2.7,7-2.7
					c3.5,0,6.3,1.3,8.3,4l2.3-1.5c-0.6-1.2-1.6-2.2-2.5-2.9c-1-0.7-2.2-1.3-3.6-1.8c-1.5-0.5-2.8-0.7-4.3-0.7
					c-2.5,0-4.7,0.5-6.6,1.5c-2,1.2-3.5,2.7-4.6,4.5c-1,1.9-1.7,4.1-1.7,6.2c0,3.3,1.3,6.3,3.7,8.6c2.3,2.3,5.1,3.6,8.8,3.6
					c2.3,0,4.7-0.6,6.6-1.6c1.1-0.6,2.2-1.3,3.1-2.3c0.2-0.2,3.4-4.3,6.2-7.9c0.1,3.1,1.2,5.6,3.3,7.8c2.3,2.4,5.1,3.9,8.6,3.9
					c3.5,0,6.5-1.5,8.7-3.9c2.1-2.3,3.3-5.1,3.3-8.4c0-3.3-1.1-6-3.2-8.4C82,9.9,79,8.6,75.5,8.6z M83.3,25.5
					c-0.8,1.4-2,2.6-3.3,3.3c-1.3,0.7-2.9,1.3-4.5,1.3c-1.6,0-3.1-0.6-4.5-1.3c-1.3-0.7-2.4-2-3.3-3.3c-0.7-1.4-1.2-3-1.2-4.7
					c0-2.6,0.8-4.7,2.6-6.7c1.8-1.7,3.9-2.7,6.3-2.7c2.5,0,4.5,1,6.3,2.7c1.8,1.9,2.7,4,2.7,6.7C84.5,22.5,84.1,24.1,83.3,25.5z
					 M142.9,33h3L146,0.2h-3L142.9,33z M135,9.9c-1.3-0.9-2.8-1.3-4.5-1.3c-1.7,0-3.3,0.4-4.7,1.2c-1.4,0.7-2.8,2-4,3.5v-13h-3
					l0,16.7c-3.3,4-8.3,10-8.6,10.3c-2,2-4.3,3-7.2,3c-1.8,0-3.5-0.4-5-1.3c-1.5-0.7-2.7-2-3.5-3.4c-0.8-1.6-1.3-3-1.3-4.7
					c0-2.6,0.9-4.9,2.8-6.6c1.9-1.8,4.2-2.7,7-2.7c3.5,0,6.3,1.3,8.3,4l2.4-1.5c-0.7-1.2-1.6-2.2-2.5-2.9c-1-0.7-2.3-1.3-3.6-1.8
					c-1.4-0.5-2.8-0.7-4.4-0.7c-2.5,0-4.7,0.5-6.6,1.5c-2,1.2-3.5,2.7-4.7,4.5c-1,1.9-1.7,4.1-1.7,6.2c0,3.3,1.2,6.3,3.6,8.6
					c2.4,2.3,5.3,3.6,9,3.6c2.3,0,4.5-0.6,6.3-1.6c1.2-0.6,2.2-1.3,3.2-2.3c0.3-0.2,3.8-4.5,6.5-8l0,11.8h2.9l0-9.2
					c0-3,0.1-5,0.3-6.3c0.5-1.7,1.5-3.2,2.8-4.4c1.5-1.2,3-1.8,4.8-1.8c1.7,0,2.9,0.5,3.9,1.2c0.9,0.8,1.6,2,2,3.4
					c0.2,1,0.3,2.7,0.3,5.3L135.9,33h2.9l0-12.6c0-3.3-0.2-5.6-0.9-7C137.3,11.9,136.3,10.8,135,9.9z M155.9,27.7
					c-0.5-0.6-1.3-1-2.2-1c-0.9,0-1.5,0.3-2.2,1c-0.5,0.6-0.8,1.3-0.8,2.3c0,0.7,0.3,1.4,0.8,2.1c0.7,0.7,1.3,1,2.2,1
					c0.8,0,1.7-0.3,2.2-1c0.6-0.7,1-1.3,1-2.1C156.9,29,156.6,28.3,155.9,27.7z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HeaderLogoIcon;
