import { useCallback, useEffect, useState } from "react";
import { fetchUpdateProject } from "~/client/api/project";
import { Project } from "~/client/types/project";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import {
  initializeCurrentProject,
  setClientInfo,
  setLoading,
  setServerError,
} from "~/client/store/modules";
import { APIError } from "~/client/types";
import { useSWRConfig } from "swr";
import { SWR_KEY } from "~/client/types/swrKey";
import { selectedProjectState } from "~/client/lib";

function useUpdateProjectName({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectedProjectState);
  const [name, setName] = useState<string>(currentProject?.name ?? "");

  const update = useCallback(async () => {
    if (isLoading) return;
    if (!currentProject) return;
    const { id } = currentProject;
    dispatch(setLoading(true));
    try {
      const res = await fetchUpdateProject({
        params: {
          id,
          name: name.trim(),
          reset_key: false,
        },
      });
      const { data } = res;
      dispatch(initializeCurrentProject(data));
      mutate(SWR_KEY.PROJECT_LIST_INITIALIZE);
      dispatch(
        setClientInfo({
          text: "Project name change saved.",
        }),
      );
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      dispatch(setLoading(false));
    }
  }, [currentProject, dispatch, isLoading, mutate, name]);

  useEffect(() => {
    if (!currentProject) return;
    const { name } = currentProject;
    setName(name);
  }, [currentProject]);

  return {
    name,
    setName,
    update,
  };
}

export default useUpdateProjectName;
