import { FC, useMemo, useState } from "react";

import {
  Input,
  ModalCloseIcon,
  Scroll,
  SearchIcon,
  Typo,
} from "@cochlearai/ui";
import { color } from "@cochlearai/util";
import styled, { useTheme } from "styled-components";
import useSdkTags from "~/client/hooks/useSdkTags";
import { SenseEvent } from "../../types";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TitleArea = styled(Flex)`
  width: 100%;
  position: relative;
`;

const NoTags = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[60]};
`;

const TagList = styled(Scroll)`
  height: 245px;
  width: 100%;
`;

const Tag = styled.div<{ isDetected: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey[20]};
  color: ${(p) =>
    p.isDetected ? p.theme.colors.black : p.theme.colors.grey[70]};
`;

const CountBadge = styled(Typo)`
  background: ${(p) =>
    color.convertHexToRGB({ hex: p.theme.colors.blue[60], alpha: 0.05 })};
  color: ${(p) => p.theme.colors.blue[60]};
  border-radius: 100px;
  padding: 4px 10px;
`;

const SearchInput = styled(Input)`
  width: 100%;
  height: 30px;
  padding: 0 20px;
  margin-top: 14px;

  & > input {
    padding: 8px;

    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    &::placeholder {
      color: ${(p) => p.theme.colors.grey[70]};
    }
  }
`;

interface Props {
  onClose?: () => void;
  detectedTags?: SenseEvent[];
}

interface TagCountList {
  tag: string;
  count: number;
}

const AllTagPopoverContent: FC<Props> = ({ onClose, detectedTags }) => {
  const { colors } = useTheme();
  const { sdkTags } = useSdkTags();
  const [searchText, setSearchText] = useState<string>("");

  const detected = useMemo(() => {
    if (!detectedTags) return [];
    const result = [] as TagCountList[];

    for (let i = 0; i < detectedTags.length; i++) {
      for (let j = 0; j < detectedTags[i].tags.length; j++) {
        const name = detectedTags[i].tags[j].name;
        if (name !== "Others") {
          const foundIdx = result.findIndex((item) => item.tag === name);
          if (foundIdx !== -1) {
            result[foundIdx].count += 1;
          } else {
            result.push({ tag: name, count: 1 });
          }
        }
      }
    }
    return result;
  }, [detectedTags]);

  const tagList = useMemo(() => {
    return (sdkTags ?? [])
      .map((tag) => {
        const found = detected.find((item) => item.tag === tag.value);
        return { ...tag, count: found ? found.count : 0 };
      })
      .sort((a, b) => b.count - a.count)
      .filter(
        (item) =>
          item.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
      );
  }, [detected, sdkTags, searchText]);

  return (
    <>
      <TitleArea>
        <Typo variant="body" style={{ fontWeight: 500 }}>
          All Tags
        </Typo>
        <ModalCloseIcon
          fill={colors.grey[80]}
          style={{ position: "absolute", right: 0, cursor: "pointer" }}
          onClick={onClose}
        />
      </TitleArea>
      <SearchInput
        leftIcon={<SearchIcon width={12} height={12} fill={colors.grey[70]} />}
        placeholder="Search Tags"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Typo
        variant="caption3"
        style={{
          color: colors.grey[80],
          marginTop: "4px",
          marginBottom: "13px",
          marginLeft: "auto",
        }}
      >
        {detected.length} out of {sdkTags?.length} tags detected
      </Typo>
      {tagList.length > 0 ? (
        <TagList>
          {tagList.map((tag, index) => (
            <Tag key={index} isDetected={tag.count > 0}>
              <Typo variant="body">{tag.label}</Typo>
              {tag.count > 0 && (
                <CountBadge variant="caption1">{`${tag.count > 1 ? "+" : ""}${
                  tag.count
                }`}</CountBadge>
              )}
            </Tag>
          ))}
        </TagList>
      ) : (
        <Flex style={{ height: "245px" }}>
          <NoTags variant="body">No Tags Found</NoTags>
        </Flex>
      )}
    </>
  );
};

export default AllTagPopoverContent;
