import { BaseListProps, Plan } from "~/client/types";
import React, { FC } from "react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
  overflow-x: auto;
`;

const Divider = styled.div`
  min-width: 1px;
  background-color: ${(p) => p.theme.colors.grey[30]};
  margin-bottom: 50px;
  z-index: 2;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -50px;
    width: 1px;
    height: 50px;
    background-color: ${(p) => p.theme.colors.grey[10]};
  }
`;

type Props = BaseListProps<{
  plan: Plan;
  disabled: boolean;
  onClickItem: (clickedPlan: Plan) => void;
}>;

const PricePlanItemList: FC<Props> = ({ data, renderItem }) => {
  return (
    <>
      <Container>
        {data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Divider />}
              {renderItem(item)}
            </React.Fragment>
          );
        })}
      </Container>
    </>
  );
};

export default PricePlanItemList;
