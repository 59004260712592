import { PAGE_ROUTE_PATH_NAME, PAGE_TITLE, PageRoute } from "~/client/types";

import { retryLazy } from "~/client/lib/lazyUtil";

const routes: PageRoute[] = [
  {
    title: PAGE_TITLE.INDEX,
    pathname: PAGE_ROUTE_PATH_NAME.INDEX,
    exact: true,
    component: retryLazy(() => import("~/client/pages/IndexPage")),
  },
  {
    title: PAGE_TITLE.PROJECT,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT,
    exact: true,
    component: retryLazy(() => import("~/client/pages/ProjectOverviewPage")),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailOverviewPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL_UPLOAD,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_UPLOAD,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailUploadPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL_SENSE_SDK,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SENSE_SDK,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailSenseSDKPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL_ANALYTICS,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailAnalyticsPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL_ANALYTICS_OLD,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS_OLD,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailOldAnalyticsPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL_POST_ACTION,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_POST_ACTION,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailPostActionPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailSettingsPage"),
    ),
  },
  {
    title: PAGE_TITLE.PROJECT_DETAIL_SPEAKER_RECOGNITION,
    pathname: PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SPEAKER_RECOGNITION,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/ProjectDetailSpeakerRecognitionPage"),
    ),
  },
  {
    title: PAGE_TITLE.USAGE,
    pathname: PAGE_ROUTE_PATH_NAME.USAGE,
    exact: true,
    component: retryLazy(() => import("~/client/pages/UsagePage")),
  },
  {
    title: PAGE_TITLE.USAGE,
    pathname: PAGE_ROUTE_PATH_NAME.USAGE_API,
    exact: true,
    component: retryLazy(() => import("~/client/pages/UsageAPIPage")),
  },
  {
    title: PAGE_TITLE.USAGE,
    pathname: PAGE_ROUTE_PATH_NAME.USAGE_SDK,
    exact: true,
    component: retryLazy(() => import("~/client/pages/UsageSDKPage")),
  },
  {
    title: PAGE_TITLE.BILLING,
    pathname: PAGE_ROUTE_PATH_NAME.BILLING,
    exact: true,
    component: retryLazy(() => import("~/client/pages/SettingsPage")),
  },
  {
    title: PAGE_TITLE.BILLING,
    pathname: PAGE_ROUTE_PATH_NAME.BILLING_INFO,
    exact: true,
    component: retryLazy(() => import("~/client/pages/SettingsBillingPage")),
  },
  {
    title: PAGE_TITLE.BILLING,
    pathname: PAGE_ROUTE_PATH_NAME.BILLING_INVOICES,
    exact: true,
    component: retryLazy(() => import("~/client/pages/SettingsInvoicesPage")),
  },
  {
    title: PAGE_TITLE.ORGANIZATION,
    pathname: PAGE_ROUTE_PATH_NAME.ORGANIZATION,
    exact: true,
    component: retryLazy(() => import("~/client/pages/OrganizationPage")),
  },
  {
    title: PAGE_TITLE.ORGANIZATION,
    pathname: PAGE_ROUTE_PATH_NAME.ORGANIZATION_MY_MEMBERS,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/OrganizationMyMembersPage"),
    ),
  },
  {
    title: PAGE_TITLE.ORGANIZATION,
    pathname: PAGE_ROUTE_PATH_NAME.ORGANIZATION_INVITED,
    exact: true,
    component: retryLazy(
      () => import("~/client/pages/OrganizationInvitedPage"),
    ),
  },
  {
    title: PAGE_TITLE.NOTFOUND,
    pathname: PAGE_ROUTE_PATH_NAME.NOT_FOUND,
    component: retryLazy(() => import("~/client/pages/NotFoundPage")),
  },
];

export default routes;
