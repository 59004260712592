import { Chip, ThemeType, Typo } from "@cochlearai/ui";

import { FC } from "react";
import { Link } from "react-router-dom";
import { color } from "@cochlearai/util";
import styled from "styled-components";

const Wrapper = styled.li`
  display: inline-block;
  padding: unset;
  & + & {
    margin-left: 50px;
  }
`;

const Linker = styled(Link)<{ disabled?: boolean }>`
  display: inline-block;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  pointer-events: ${(p) => (p.disabled ? "none" : "")};
  user-select: none;
`;

const ItemText = styled(Typo)<{ selected?: boolean; disabled?: boolean }>`
  display: inline-flex;
  padding-bottom: 10px;

  border-bottom: 2px solid
    ${(p) => (p.selected ? p.theme.colors.blue[60] : "transparent")};
  &:hover {
    border-bottom-color: ${(p) =>
      !p.disabled ? p.theme.colors.blue[60] : "transparent"};
  }
  opacity: ${(p) => (p.disabled ? 0.4 : 1)};
`;

const BETAChip = styled(Chip)<{ selected?: boolean }>`
  color: ${(p) =>
    p.selected ? p.theme.colors.blue[70] : p.theme.colors.black};
  background-color: ${(p) =>
    color.convertHexToRGB({
      hex: p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[10],
      alpha: p.selected ? 0.05 : 1,
    })};
  margin-left: 4px;
  cursor: inherit;
`;

interface Props {
  to: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  isBETA?: boolean;
}

const SubHeaderLinkItem: FC<Props> = ({
  to,
  selected,
  label,
  disabled,
  isBETA,
}) => {
  return (
    <Wrapper>
      <Linker to={to} disabled={disabled}>
        <ItemText variant="h4" selected={selected} disabled={disabled}>
          {label}
          {isBETA && <BETAChip selected={selected}>BETA</BETAChip>}
        </ItemText>
      </Linker>
    </Wrapper>
  );
};

export default SubHeaderLinkItem;
