import { Typo, WhiteLogoIcon } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 20px;
  justify-content: center;
`;
const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background: #d9d9d9;
`;

interface Props {
  onClose?: () => void;
  onClickStart?: () => void;
}

const IntroStep: FC<Props> = ({ onClose, onClickStart }) => {
  const { colors } = useTheme();
  return (
    <div style={{ textAlign: "center" }}>
      <WhiteLogoIcon />
      <Typo variant="h4" style={{ margin: "20px 0", color: "#ffffff" }}>
        Congratulations! You’ve been selected for a special survey.
      </Typo>
      <Typo variant="body" style={{ margin: "20px 0", color: "#ffffff" }}>
        The survey will only take a minute and any feedback you have is
        extremely valuable to us.
      </Typo>
      <Divider />
      <ButtonWrapper>
        <Typo
          variant="button"
          style={{ padding: "10px", cursor: "pointer", color: "#ffffff" }}
          onClick={onClose}
        >
          Close
        </Typo>
        <Typo
          variant="button"
          style={{
            padding: "10px 14px",
            cursor: "pointer",
            background: "#ffffff",
            color: colors.blue[60],
            borderRadius: "100000px",
          }}
          onClick={onClickStart}
        >
          Take part in survey
        </Typo>
      </ButtonWrapper>
    </div>
  );
};

export default IntroStep;
