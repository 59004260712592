function formatPlanTitle(title: string): string {
  if (title.length === 0) {
    return "";
  }
  return title
    .split("")
    .map((value, index) => {
      if (index === 0) {
        return value.toUpperCase();
      }
      return value;
    })
    .join("");
}

export default formatPlanTitle;
