import React, { FC, SVGProps } from "react";

const InvitationIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="15" cy="15" r="15" fill="#F1F1F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3988 10.8333C19.7788 10.8333 19.956 11.3099 19.6701 11.5634L15.1751 15.5489L11.0625 12.5L10.5507 13.1529L15.2013 16.6336L20.2129 12.1901C21.0704 11.4298 20.539 10 19.3988 10H8V20H22V13.3333H21.1765V19.1667H8.82353V10.8333H19.3988Z"
      fill="#727273"
    />
  </svg>
);

export default InvitationIcon;
