function isBlockedChrome(): boolean {
  // is Chrome browser 122 version
  const ua = navigator.userAgent;
  const isChrome = ua.includes("Chrome/");
  if (!isChrome) return false;
  const version = ua.split("Chrome/")[1]?.split(".")[0];
  return version === "122";
}

export default isBlockedChrome;
