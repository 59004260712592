import { selectedAnalyticsState } from "~/client/lib/selectors";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetSDKTopTags } from "~/client/api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { convertSenseTagValueToUILabel } from "~/client/lib/helpers";
import { AnalyticsType } from "~/client/types/analytics";
import useSdkChannelList from "./useSdkChannelList";
import moment from "moment";
import { TAG_SOURCE_MAX_COUNT } from "~/client/lib/constants";
import useCurrentProject from "./useCurrentProject";

interface TagBreakdown {
  device: string;
  device_name: string;
  tag_name: string;
  count: number;
}

function useAnalyticsTopTagBreakdown() {
  const dispatch = useAppDispatch();
  const params = useParams<{ projectId?: string }>();
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const [tagBreakdown, setTagBreakdown] = useState<TagBreakdown[]>([]);
  const { currentProject } = useCurrentProject();
  const { channelList } = useSdkChannelList({ currentProject });
  const filters = useMemo(
    () =>
      filterParamsWithChecked
        ? filterParamsWithChecked[AnalyticsType.TAG_AMOUNT_BREAKDOWN]
        : {},
    [filterParamsWithChecked],
  );

  const { data, error, isValidating, mutate } = useSWR(
    params.projectId && filterParamsWithChecked
      ? SWR_KEY.ANALYTICS_INITIALIZE_TOPTAG_BREAKDOWN
      : null,
    async () => {
      if (!filterParamsWithChecked || !params.projectId) return null;
      const { tag, device, fromTo } = filters;
      return (
        await fetchGetSDKTopTags({
          params: {
            project_id: params.projectId,
            from: moment(fromTo?.from).unix(),
            to: moment(fromTo?.to).unix(),
            tags: tag?.filter((item) => item.checked).map((item) => item.value),
            devices: device
              ?.filter((item) => item.checked)
              .map((item) => item.value),
          },
        })
      ).data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const initializeEventList = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    setTagBreakdown(
      (data ?? [])
        .map((item) => {
          const foundDevice = channelList.find(
            (channel) => channel.device_id === item.device,
          );
          return {
            ...item,
            tag_name: convertSenseTagValueToUILabel(item.tag_name),
            device_name: foundDevice?.device_name || "",
          };
        })
        .slice(0, TAG_SOURCE_MAX_COUNT),
    );
  }, [data, channelList]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  useEffect(() => {
    initializeEventList();
  }, [initializeEventList, filters]);

  return {
    data,
    tagBreakdown,
    isValidating,
  };
}

export default useAnalyticsTopTagBreakdown;
