import { CSSProperties, PropsWithChildren } from "react";
import { PopoverAlign } from "react-tiny-popover";
import styled from "styled-components";
import { Dropdown } from "../Dropdown";
import { DropdownItem } from "../DropdownItem";
import { Typo } from "../Typo";

const DropdownListItem = styled(DropdownItem)<{
  $disable?: boolean;
  selected?: boolean;
}>`
  cursor: ${(p) => (p.$disable ? "default" : "pointer")};
  opacity: ${(p) => (p.$disable ? 0.4 : 1)};
  background-color: ${(p) => (p.selected ? p.theme.colors.grey[20] : "unset")};
  &:hover {
    background-color: ${(p) =>
      p.$disable ? p.theme.colors.white : p.theme.colors.grey[20]};
  }
`;

export interface ListDropdownProps {
  isOpen: boolean;
  onClose: () => void;
  onClickOutside: (e: MouseEvent) => void;
  data: {
    label: string;
    value: string;
    disable?: boolean;
  }[];
  onClickItem?: (value: string) => void;
  align?: PopoverAlign;
  selected?: string;
  style?: Partial<CSSStyleDeclaration>;
  boxStyle?: CSSProperties;
  className?: string;
  fitToContents?: boolean;
}

/**
 * React component used to create a list dropdown.
 * @param isOpen - Indicates whether the dropdown is open or not.
 * @param onClose - Callback function that will be called when user closes the dropdown.
 * @param onClickOutside - Click event handler for outside of the dropdown element.
 * @param data - Data array containing labels and values for each item in the dropdown.
 * @param onClickItem - Callback function that will be called when user selects an item in the dropdown.
 * @param align - Alignment of the dropdown box relative to its target.
 * @param selected - Selected value of the dropdown.
 * @param style - The style of the dropdown.
 * @param boxStyle - The style of the dropdown box.
 * @param className - The class name of the dropdown.
 * @param fitToContents - Indicates whether the dropdown box should fit to its target.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/list-dropdown--default
 */
export const ListDropdown = ({
  isOpen,
  onClickItem,
  data,
  children,
  onClickOutside,
  onClose,
  align,
  selected,
  className,
  style,
  boxStyle,
  fitToContents = true,
}: PropsWithChildren<ListDropdownProps>) => {
  return (
    <Dropdown
      isOpen={isOpen}
      align={align}
      data={data}
      onClickOutside={onClickOutside}
      className={className}
      containerStyle={style}
      style={boxStyle}
      contentWidth={fitToContents ? "fit-content" : undefined}
      renderItem={({ label, value, disable }, index) => {
        return (
          <DropdownListItem
            key={`${label}-${index}`}
            $disable={disable}
            onClick={(e) => {
              e.stopPropagation();
              if (disable) return;
              onClickItem && onClickItem(value);
              onClose();
            }}
            selected={selected === value}
          >
            <Typo variant="body">{label}</Typo>
          </DropdownListItem>
        );
      }}
    >
      {children}
    </Dropdown>
  );
};
