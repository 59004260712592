import React, { PropsWithChildren } from "react";

import styled from "styled-components";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRightWrapper = styled.div`
  margin-left: auto;
`;

const ChildrenWrapper = styled.div``;

interface Props {
  title?: string;
  titleRight?: React.ReactNode;
  subTitle?: string;
  onClickNext?: () => void;
  nextButtonTitle?: string;
  nextButtonDisabled?: boolean;
}

const PageWithTitleLayout = ({
  title,
  titleRight,
  subTitle,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <ContentContainer>
      <ContentHeader>
        <TitleRow>
          {title && <h2 style={{ fontWeight: "bold" }}>{title}</h2>}
          {titleRight && <TitleRightWrapper>{titleRight}</TitleRightWrapper>}
        </TitleRow>
        {subTitle &&
          subTitle.split("\n").map((subT) => <p key={subT}>{subT}</p>)}
      </ContentHeader>
      <ChildrenWrapper style={{ marginTop: title ? "3.5rem" : "unset" }}>
        {children}
      </ChildrenWrapper>
    </ContentContainer>
  );
};

export default PageWithTitleLayout;
