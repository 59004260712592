import React, { FC, SVGProps } from "react";

const HistoryIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="#B2B2B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3H7.5V6.9169L10.2572 8.57125L9.74275 9.42875L6.5 7.4831V3Z"
      fill="#B2B2B3"
    />
  </svg>
);

export default HistoryIcon;
