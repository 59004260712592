function downloadJSON(
  jsonString: string,
  fileName: string,
  contentType = "text/plain",
) {
  const a = document.createElement("a");
  const file = new Blob([jsonString], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName + ".json";
  a.click();
}

export default downloadJSON;
