import getConfigs from "~/client/lib/getConfigs";

const configs = getConfigs();

const {
  REACT_APP_ACCOUNT_LOGIN_URL: accountLoginURL,
  REACT_APP_ACCOUNT_URL: accountURL,
} = configs;

// unauthorized
export const handleUnauthenticated = (): void => {
  if (typeof accountLoginURL === "undefined") {
    return;
  }
  if (typeof window === "undefined") {
    return;
  }
  const returnURL = encodeURIComponent(window.location.href);
  const accountAutoRedirectLoginURL = `${accountLoginURL}${returnURL}`;
  window.location.href = accountAutoRedirectLoginURL;
};

// unverified email
export const handleUnverified = (): void => {
  if (typeof accountURL === "undefined") {
    return;
  }
  window.location.href = accountURL;
};
