import { ANALYTICS_SORT, AnalyticsEvent } from "~/client/types/analytics";
import { Project, ProjectType } from "~/client/types/project";
import {
  convertSenseTagValueToUILabel,
  getAnalyticsParams,
} from "~/client/lib/helpers";
import {
  selectedAnalyticsState,
  selectedSearchState,
} from "~/client/lib/selectors";
import { setServerError, updateSearchText } from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useState } from "react";

import { APIError } from "~/client/types/error";
import { SHOW_MORE_LIMIT } from "~/client/lib/constants";
import { SWR_KEY } from "~/client/types/swrKey";
import { SenseTags } from "~/client/types/sense";
import { fetchGetAnalyticsEvents } from "~/client/api";
import useSWR from "swr";
import useSdkChannelList from "./useSdkChannelList";

function useAnalyticsEventList({
  currentProject,
  isFreePlan,
  fromTo,
}: {
  currentProject: Project | null;
  isFreePlan: boolean;
  fromTo: {
    from: number;
    to: number;
  };
}) {
  const dispatch = useAppDispatch();
  const { searchText } = useAppSelector(selectedSearchState);
  const { filterParamsWithCheckedOld } = useAppSelector(selectedAnalyticsState);
  const { channelList } = useSdkChannelList({ currentProject });
  const [count, setCount] = useState<number>(SHOW_MORE_LIMIT.ANALYTICS);
  const [selectedEvent, setSelectedEvent] = useState<AnalyticsEvent | null>(
    null,
  );

  const { data, error, isValidating, mutate } = useSWR(
    currentProject && filterParamsWithCheckedOld && !isFreePlan
      ? SWR_KEY.ANALYTICS_INITIALIZE_EVENT_LIST
      : null,
    async () => {
      const params = getAnalyticsParams({
        currentProject: currentProject!,
        filterParamsWithChecked: filterParamsWithCheckedOld!,
        page: 1,
        limit: count,
        sort: ANALYTICS_SORT.TIMESTAMP_DESC,
        ...fromTo,
        searchText,
        channelList,
      });
      return (await fetchGetAnalyticsEvents({ params })).data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const loadMoreEventList = useCallback(() => {
    setCount((prev) => prev + SHOW_MORE_LIMIT.ANALYTICS);
  }, []);

  const selectEvent = useCallback((event: AnalyticsEvent) => {
    setSelectedEvent({
      ...event,
      tag: convertSenseTagValueToUILabel(event.tag as SenseTags),
    });
  }, []);

  const unselectEvent = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  const setSessionUuidSearchValue = useCallback(
    (value) => dispatch(updateSearchText(value)),
    [dispatch],
  );

  const initializeEventList = useCallback(() => {
    setCount(SHOW_MORE_LIMIT.ANALYTICS);
    mutate();
  }, [mutate]);

  const getFilteredEvents = useCallback(
    (events) =>
      currentProject?.product_type === ProjectType.EDGE_SDK
        ? events.map((event: AnalyticsEvent) => ({
            ...event,
            device_name: channelList.find(
              (channel) => channel.device_id === event.device_id,
            )?.device_name,
          }))
        : events,
    [channelList, currentProject?.product_type],
  );

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  useEffect(() => {
    initializeEventList();
  }, [initializeEventList, searchText, fromTo]);

  useEffect(() => {
    mutate();
  }, [count, mutate]);

  useEffect(() => {
    return () => {
      setSessionUuidSearchValue(null);
    };
  }, [setSessionUuidSearchValue]);

  return {
    eventList: getFilteredEvents(data?.events),
    tagCount: (data?.summary?.by_tag ?? [])
      .filter(({ tag_name, count }) => tag_name !== "Others" && count > 0)
      .sort((a, b) => b.count - a.count),
    totalCount: data?.summary?.total_count,
    listCount: count,
    selectedEvent,
    sessionUuidSearchValue: searchText,
    isValidating,
    initializeEventList,
    loadMoreEventList,
    selectEvent,
    unselectEvent,
    setSessionUuidSearchValue,
  };
}

export default useAnalyticsEventList;
