import React, { FC, SVGProps } from "react";

const HamburgerIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 63 63"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    fill={props.fill ? props.fill : "#000"}
    {...props}
  >
    <g>
      <g transform="translate(330.000000, 232.000000)">
        <polygon points="-321.8,-219 -274.3,-219 -274.3,-212.7 -321.8,-212.7    " />
        <polygon points="-321.8,-203.2 -274.3,-203.2 -274.3,-196.8 -321.8,-196.8    " />
        <polygon points="-321.8,-187.3 -274.3,-187.3 -274.3,-181 -321.8,-181    " />
      </g>
    </g>
  </svg>
);

export default HamburgerIcon;
