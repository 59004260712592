/* eslint-disable @typescript-eslint/no-empty-interface */
import { MouseEventHandler, PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";
import { Button } from "../Button";

import { ArrowDownIcon } from "../Icons";
import { BaseStyleProps } from "../types/style";

const ButtonContainer = styled(Button)`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: ${(p) => p.theme.colors.grey[30]};
  & + & {
    margin-left: 14px;
  }
  &:hover {
    border-color: ${(p) => p.theme.colors.grey[80]};
    color: ${(p) => p.theme.colors.black};
  }
`;

export interface DropdownButtonProps extends BaseStyleProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * React component used to create a dropdown button.
 * @param onClick - A callback when clicked.
 * @param style - The style of the button.
 * @param className - The className of the button.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/dropdown--default
 */

export const DropdownButton = ({
  children,
  onClick,
  className,
  style,
}: PropsWithChildren<DropdownButtonProps>) => {
  const { colors } = useTheme();
  return (
    <ButtonContainer
      className={className}
      color="secondary"
      rightIcon={
        <ArrowDownIcon
          width={6}
          height={4}
          fill={colors.black}
          style={{ marginLeft: "8px" }}
        />
      }
      onClick={onClick}
      style={style}
    >
      {children}
    </ButtonContainer>
  );
};
