import React, { FC } from "react";

import { Scroll } from "@cochlearai/ui";
import styled from "styled-components";
import { BaseListProps } from "~/client/types/list";

const Container = styled(Scroll)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  position: relative;
  overflow-y: hidden;
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.white};
`;

const Space = styled.div`
  width: 1px;
  min-width: 1px;
  background-color: ${(p) => p.theme.colors.grey[30]};
  height: 38px;
`;

type Props = BaseListProps<{
  tag: string;
  formattedCount: string;
}>;

const AnalyticsInsightTagCardList: FC<Props> = ({
  renderItem,
  data,
  EmptyComponent,
}) => {
  return (
    <Container>
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && index < data.length && <Space />}
            {renderItem(item)}
          </React.Fragment>
        );
      })}
      {data.length === 0 && EmptyComponent}
    </Container>
  );
};

export default AnalyticsInsightTagCardList;
