import { INVOICE_HISTORY_TABLE_COLUMN_TYPE } from "../../types";

function getInvoiceHistoryTableColumnWidth(
  columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE,
) {
  switch (columnType) {
    case INVOICE_HISTORY_TABLE_COLUMN_TYPE.DATE:
      return "260px";
    case INVOICE_HISTORY_TABLE_COLUMN_TYPE.INVOICE_NUMBER:
      return "270px";
    case INVOICE_HISTORY_TABLE_COLUMN_TYPE.AMOUNT:
      return "440px";
    case INVOICE_HISTORY_TABLE_COLUMN_TYPE.STATUS:
      return "160px";
    default:
      return "0px";
  }
}

export default getInvoiceHistoryTableColumnWidth;
