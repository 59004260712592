import {
  Button,
  Checkbox,
  DropdownButton,
  Input,
  ListDropdown,
  MultiSelectDropdown,
  Typo,
} from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useSWRConfig } from "swr";
import { FullCenterLoading, TagCategory } from "~/client/components";
import {
  useAppDispatch,
  useCreateEmailNC,
  useCurrentProject,
  useEmailList,
  useOrganizationMember,
  useProjectTags,
  useSdkTags,
  useTriggerCondition,
  useUpdateMedium,
} from "~/client/hooks";
import {
  INTERVAL_UNIT_UI_LABELS,
  SENSE_TAGS_BY_CATEGORY,
  TAG_INTERVAL_TIME_TYPE,
} from "~/client/lib/constants";
import {
  convertSenseTagValueToUILabel,
  getIntervalTimeError,
} from "~/client/lib/helpers";
import { setClientInfo } from "~/client/store/modules";
import {
  MediumType,
  NotificationConfig,
  ProjectType,
  SenseCategory,
  TAG_INTERVAL_TIME_TYPE_VALUE,
} from "~/client/types";
import { SWR_KEY } from "~/client/types/swrKey";

const InputContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;
  width: 720px;

  ${media.query.md} {
    width: 100%;
  }
`;

const EmailInputTitle = styled(Typo)`
  margin-top: 30px;
`;

const DateDropdownButton = styled(DropdownButton)`
  margin-left: auto;
  height: 60px;
  width: 100%;
  cursor: pointer;
  border-color: ${(p) => p.theme.colors.grey[30]};
`;

const IntervalTimeInputContainer = styled.div`
  width: 50%;
  height: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 50px;
`;

const TagsCountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  margin-left: 8px;
`;

interface Props {
  onResetItem: (nc: NotificationConfig) => void;
}

const EmailList: FC<Props> = ({ onResetItem }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const { currentProject } = useCurrentProject();
  const { emailList } = useEmailList();
  const { sdkTags } = useSdkTags();
  const { projectTags } = useProjectTags({ currentProject });
  const { memberList } = useOrganizationMember();
  const {
    confirmToUpdate,
    triggerCondition,
    setTriggerCondition,
    isLoading,
    errorInfo,
  } = useTriggerCondition();
  const {
    emails,
    setEmails,
    interval,
    setInterval,
    unit,
    setUnit,
    intervalTimeSecond,
    addEmail,
    updateNC,
    isLoading: isNCLoading,
  } = useCreateEmailNC({ currentProject, triggerCondition });
  const { update, isUpdating, errorInfo: mediumError } = useUpdateMedium();
  const [intervalDropdownIsOpen, setIntervalDropdownIsOpen] =
    useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectedTagCategory, setSelectedTagCategory] = useState<SenseCategory>(
    SenseCategory.ALL,
  );

  const tagList = useMemo(() => {
    return (
      currentProject?.product_type === ProjectType.EDGE_SDK
        ? projectTags.map((tag) => ({ value: tag.id, label: tag.name }))
        : sdkTags
    ).filter(
      (item) =>
        SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(item.value) !== -1,
    );
  }, [currentProject?.product_type, projectTags, sdkTags, selectedTagCategory]);

  const organizationMembers = useMemo(
    () =>
      memberList.map((member) => ({
        value: member.email,
        label: member.email,
      })),
    [memberList],
  );

  const onClickConfirm = useCallback(async () => {
    if (emailList.length > 0) {
      const { medium_id, id, interval } = emailList[0];
      const emailsToUpdate = emails.map((mail) => mail.value);

      if (interval !== intervalTimeSecond) {
        await updateNC(id);
      }
      await update(medium_id, emailsToUpdate, MediumType.EMAIL);
      await confirmToUpdate(emailList[0]);

      if (!errorInfo && !mediumError) {
        dispatch(setClientInfo({ text: "Email notification has been edited" }));
      }
    } else {
      await addEmail();
    }

    mutate(SWR_KEY.POST_ACTION_INITIALIZE_EMAIL_LIST);
  }, [
    addEmail,
    confirmToUpdate,
    dispatch,
    emailList,
    emails,
    errorInfo,
    intervalTimeSecond,
    mediumError,
    mutate,
    update,
    updateNC,
  ]);

  const reset = useCallback(() => {
    setTriggerCondition([]);
    setSelectedTagCategory(SenseCategory.ALL);
    setEmails([]);
    setInterval("");
    setUnit(TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS);
    setError(undefined);
  }, [setEmails, setInterval, setTriggerCondition, setUnit]);

  const onChangeTag = useCallback(
    (item) => {
      if (!triggerCondition.find((data) => data.value === item.value)) {
        setTriggerCondition([...triggerCondition, ...[item]]);
      } else {
        setTriggerCondition(
          triggerCondition.filter((data) => data.value !== item.value),
        );
      }
    },
    [triggerCondition, setTriggerCondition],
  );

  const onClickSelectAll = useCallback(
    (e) => {
      setTriggerCondition(
        e.target.checked
          ? triggerCondition.concat(
              tagList.filter(
                (tag) => !triggerCondition.find((tc) => tc.value === tag.value),
              ),
            )
          : triggerCondition.filter(
              (item) =>
                SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(
                  item.value,
                ) === -1,
            ),
      );
    },
    [selectedTagCategory, setTriggerCondition, tagList, triggerCondition],
  );

  useEffect(() => {
    let isNotSelected = false,
      i = 0;
    do {
      if (!triggerCondition.find((tag) => tag.value === tagList[i].value)) {
        isNotSelected = true;
      }
      i++;
    } while (!isNotSelected && i < tagList.length);

    setIsSelectAll(!isNotSelected);
  }, [tagList, triggerCondition]);

  useEffect(() => {
    if (emailList.length > 0) {
      const { trigger_conditions, medium_values, interval } = emailList[0];
      trigger_conditions &&
        setTriggerCondition(
          trigger_conditions.map((data) => ({
            value: data.tag_name,
            label: convertSenseTagValueToUILabel(data.tag_name),
          })),
        );
      medium_values &&
        setEmails(
          medium_values.map((data) => ({
            value: data,
            label: data,
          })),
        );
      interval && setInterval(String(interval));
      setUnit(TAG_INTERVAL_TIME_TYPE_VALUE.SECONDS);
    } else {
      reset();
    }
  }, [emailList, reset, setEmails, setInterval, setTriggerCondition, setUnit]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "30px 0 4px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typo variant="h5">Tags selection</Typo>
          <TagsCountBadge>
            <Typo variant="caption3">{triggerCondition.length}</Typo>
          </TagsCountBadge>
        </div>
        <Checkbox
          selected={isSelectAll}
          onChange={onClickSelectAll}
          label="Select all"
        />
      </div>
      <TagCategory
        tags={tagList}
        onClickItem={onChangeTag}
        selectedItems={triggerCondition}
        style={{ maxHeight: "250px" }}
        selectedCategory={selectedTagCategory}
        onClickCategory={setSelectedTagCategory}
      />
      <EmailInputTitle variant="h5">Address</EmailInputTitle>
      <InputContainer>
        <MultiSelectDropdown
          value={emails}
          data={organizationMembers ?? []}
          handleChange={(selected) =>
            setEmails(selected as { value: string; label: string }[])
          }
          onClearAll={() => setEmails([])}
          clearAll={emails.length > 0 ?? false}
          placeholder="Name or email with organization members"
          style={{ width: "100%" }}
        />
      </InputContainer>
      <EmailInputTitle variant="h5" style={{ marginBottom: "4px" }}>
        Email Interval
      </EmailInputTitle>
      <InputContainer>
        <IntervalTimeInputContainer>
          <Input
            type="number"
            value={interval}
            onChange={(e) => {
              setError(getIntervalTimeError(e.target.value, unit, 60, 3600));
              setInterval(e.target.value);
            }}
            placeholder="Interval time (60s ~ 3600s)"
            validated={error ? false : undefined}
            error={error}
          />
        </IntervalTimeInputContainer>
        <ListDropdown
          align="end"
          data={TAG_INTERVAL_TIME_TYPE}
          isOpen={intervalDropdownIsOpen}
          onClickItem={(item) => {
            const unit = item as TAG_INTERVAL_TIME_TYPE_VALUE;
            setError(getIntervalTimeError(interval, unit, 60, 3600));
            setUnit(unit);
          }}
          onClose={() => setIntervalDropdownIsOpen(false)}
          onClickOutside={() => setIntervalDropdownIsOpen(false)}
          boxStyle={{ width: "50%" }}
        >
          <DateDropdownButton
            onClick={() => setIntervalDropdownIsOpen(!intervalDropdownIsOpen)}
          >
            <Typo variant="caption1" style={{ color: colors.black }}>
              {INTERVAL_UNIT_UI_LABELS[unit]}
            </Typo>
          </DateDropdownButton>
        </ListDropdown>
      </InputContainer>
      <ButtonContainer>
        <Button
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            emailList[0] ? onResetItem(emailList[0]) : reset();
          }}
          style={{
            padding: "11px 16px",
          }}
        >
          {t("Reset", { ns: "common" })}
        </Button>
        <Button
          color="primary"
          onClick={onClickConfirm}
          disabled={
            triggerCondition.length === 0 ||
            emails.length === 0 ||
            interval === undefined ||
            !unit ||
            !!error
          }
          style={{
            padding: "11px 16px",
          }}
        >
          {t(`${emailList.length > 0 ? "Edit" : "Add"} email`, {
            ns: "common",
          })}
        </Button>
      </ButtonContainer>
      {(isLoading || isNCLoading || isUpdating) && <FullCenterLoading />}
    </>
  );
};

export default EmailList;
