import React, { FC, SVGProps } from "react";

const FilterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.563 1.5a2 2 0 0 1 3.874 0H14v1h-1.563a2 2 0 0 1-3.874 0H0v-1h8.563ZM9.5 2a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM8.563 11.5a2 2 0 0 1 3.874 0H14v1h-1.563a2 2 0 0 1-3.874 0H0v-1h8.563Zm.937.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5.437 7.5a2 2 0 0 1-3.874 0H0v-1h1.563a2 2 0 0 1 3.874 0H14v1H5.437ZM4.5 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill={props.fill || "#131313"}
    />
  </svg>
);

export default FilterIcon;
