export interface APIError<DataT> {
  response?: {
    status?: number;
    statusText?: string;
    data?: DataT;
    msg?: string;
    config?: {
      url?: string;
    };
  };
}

export enum API_ERROR_STATUS {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export interface ClientError {
  text: string;
}

export interface GetUserUnauthorizedErrorData {
  error: {
    code: number;
    message: string;
    reason: string;
    request: string;
    status: string;
  };
}

export type HTTPError = APIError<unknown>;

export interface APIErrorData {
  details?: string | { data?: string; message?: string };
}
