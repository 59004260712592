import { Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";

const ProjectTypeTab = styled.div`
  display: flex;
  align-items: center;
  display: inline-block;
  cursor: pointer;
`;

const ProjectTypeText = styled(Typo)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 25px;
  color: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[70]};

  border-bottom-color: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : "transparent"};
  border-bottom-style: solid;
  border-bottom-width: ${(p) => (p.selected ? "2px" : "1px")};
  &:hover {
    border-bottom-color: ${(p) => p.theme.colors.blue[60]};
    border-bottom-width: 2px;
  }
`;

type Props = {
  selected: boolean;
  onClick: () => void;
  title: string;
};

const TagCategoryTabItem: FC<Props> = ({ selected, onClick, title }) => {
  return (
    <ProjectTypeTab onClick={onClick}>
      <ProjectTypeText variant="body" selected={selected}>
        {title}
      </ProjectTypeText>
    </ProjectTypeTab>
  );
};

export default TagCategoryTabItem;
