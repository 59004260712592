import { ONBOARD_STATUS, VOICE_SORT_TYPE_VALUE, Voice } from "../../types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface VoiceState {
  filter: {
    sort: VOICE_SORT_TYPE_VALUE;
  };
  searchText: string;
  voiceList: Voice[];
  onboardStatus: ONBOARD_STATUS;
}

const initialState: VoiceState = {
  filter: {
    sort: VOICE_SORT_TYPE_VALUE.ASCEND,
  },
  searchText: "",
  voiceList: [],
  onboardStatus: ONBOARD_STATUS.STATUS_1,
};

export const voiceSlice = createSlice({
  name: "voice",
  initialState,
  reducers: {
    updateSortFilter: (state, action: PayloadAction<VOICE_SORT_TYPE_VALUE>) => {
      state.filter.sort = action.payload;
    },
    resetVoiceFilter: (state) => {
      state.filter = initialState.filter;
    },
    updateVoiceSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    updateVoiceList: (state, action: PayloadAction<Voice[]>) => {
      state.voiceList = action.payload;
    },
    addVoice: (state, action: PayloadAction<Voice>) => {
      state.voiceList = [...state.voiceList, action.payload];
    },
    updateOnboardStatus: (state, action: PayloadAction<ONBOARD_STATUS>) => {
      state.onboardStatus = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});
export const {
  updateSortFilter,
  resetVoiceFilter,
  updateVoiceSearchText,
  updateVoiceList,
  addVoice,
  updateOnboardStatus,
} = voiceSlice.actions;
export const { reducer: voiceReducer } = voiceSlice;
