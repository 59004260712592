import { MouseEventHandler, PropsWithChildren } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";

const ItemContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${(p) => p.theme.colors.white};
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  &:hover {
    background-color: ${(p) => p.theme.colors.grey[20]};
  }
`;

export interface DropdownItemProps extends BaseStyleProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

/**
 * React component used to create a dropdown item.
 * @param onClick - A callback when clicked.
 * @param style - The style of the item.
 * @param className - The className of the item.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/dropdown--default
 */
export const DropdownItem = ({
  children,
  onClick,
  className,
  style,
}: PropsWithChildren<DropdownItemProps>) => {
  return (
    <ItemContainer className={className} onClick={onClick} style={style}>
      {children}
    </ItemContainer>
  );
};
