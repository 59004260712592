import React, { FC, SVGProps } from "react";

const ToastCloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.9091 0L12 1.0909L1.09098 11.9999L7.90082e-05 10.909L10.9091 0Z" />
    <path d="M11.9999 10.9092L10.909 12.0001L0 1.09111L1.0909 0.000212402L11.9999 10.9092Z" />
  </svg>
);

export default ToastCloseIcon;
