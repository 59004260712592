import moment from "moment";

function getBetweenYear(
  startDate: string | number | Date,
  endDate: string | number | Date,
) {
  const dateStart =
    typeof startDate === "number" ? moment.unix(startDate) : moment(startDate);
  const dateEnd =
    typeof endDate === "number" ? moment.unix(endDate) : moment(endDate);
  const timeValues: string[] = [];

  while (
    dateEnd > dateStart ||
    dateStart.format("YYYY") === dateEnd.format("YYYY")
  ) {
    const year = dateStart.format("YYYY");
    timeValues.push(year);
    dateStart.add(1, "year");
  }

  return timeValues;
}

export default getBetweenYear;
