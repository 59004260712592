import { FC } from "react";

import { Typo } from "@cochlearai/ui";
import styled from "styled-components";

const ProjectTypeTab = styled.li`
  display: flex;
  align-items: center;
  display: inline-block;
  padding: unset;
  & + & {
    margin-left: 50px;
  }
  cursor: pointer;
`;

const ProjectTypeText = styled(Typo)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
  color: ${(p) =>
    p.selected ? p.theme.colors.black : p.theme.colors.grey[60]};

  border-bottom: 2px solid
    ${(p) => (p.selected ? p.theme.colors.blue[60] : "transparent")};
  &:hover {
    border-bottom-color: ${(p) => p.theme.colors.blue[60]};
  }
`;

const ProjectCountBadge = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 24px;
  border-radius: 74px;
  background: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[30]};
  color: ${(p) => (p.selected ? "#ffffff" : p.theme.colors.grey[80])};
  margin-left: 8px;
`;
type Props = {
  selected?: boolean;
  onClick?: () => void;
  title: string;
  count?: number;
};

const ProjectTabItem: FC<Props> = ({ selected, onClick, title, count }) => {
  return (
    <ProjectTypeTab onClick={onClick}>
      <ProjectTypeText variant="h4" selected={selected}>
        {title}
        <ProjectCountBadge selected={selected}>
          <Typo variant="caption1">{count}</Typo>
        </ProjectCountBadge>
      </ProjectTypeText>
    </ProjectTypeTab>
  );
};

export default ProjectTabItem;
