import { ModalCloseIcon, ProgressIndicator } from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import { FC, Suspense } from "react";
import styled, { useTheme } from "styled-components";
import {
  AnalyticsDetailView,
  AnalyticsFilterView,
  AnalyticsTagSelection,
  SideBar,
} from "~/client/components";
import { STATIC_SIDEBAR_TOP_POSITION } from "~/client/lib/constants";
import { SidebarInfo, SidebarType } from "~/client/types/sidebar";

const CustomSideBar = styled(SideBar)`
  width: 462px;

  ${media.query.md} {
    width: calc(100vw);
  }
`;

const ModalCloseButton = styled(ModalCloseIcon)`
  display: block;
  cursor: pointer;
  margin-left: auto;
  min-height: 16px;
  margin-bottom: 30px;
`;

const SidebarLoading = styled(ProgressIndicator)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

interface Props {
  sidebarInfo: SidebarInfo;
  closeSidebar: () => void;
}

const AnalyticsSidebar: FC<Props> = ({ sidebarInfo, closeSidebar }) => {
  const { colors } = useTheme();

  const renderContent = () => {
    const { open, type, filterType } = sidebarInfo;
    switch (type) {
      case SidebarType.TAG_SELECTION:
        return <AnalyticsTagSelection open={open} close={closeSidebar} />;
      case SidebarType.DETAIL_EVENT:
        return filterType && <AnalyticsDetailView filterType={filterType} />;
      case SidebarType.FILTER:
        return (
          filterType && (
            <AnalyticsFilterView
              open={open}
              filterType={filterType}
              close={closeSidebar}
            />
          )
        );
      default:
        return null;
    }
  };

  return (
    <CustomSideBar
      visible={sidebarInfo.open}
      fixedTopPosition={STATIC_SIDEBAR_TOP_POSITION}
      onClose={closeSidebar}
    >
      <ModalCloseButton
        width={16}
        height={16}
        fill={colors.black}
        onClick={closeSidebar}
      />
      <Suspense fallback={<SidebarLoading size="medium" />}>
        {renderContent()}
      </Suspense>
    </CustomSideBar>
  );
};

export default AnalyticsSidebar;
