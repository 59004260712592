import { Toast } from "@cochlearai/ui";
import { FC } from "react";
import { BaseToastProps } from "~/client/types/toast";

const InfoToast: FC<BaseToastProps> = ({
  open,
  message,
  onClose,
  onClick,
  buttonText,
}) => {
  return (
    <Toast
      open={open}
      onClose={onClose}
      message={message}
      severity="info"
      buttonText={buttonText}
      onClick={onClick}
    />
  );
};

export default InfoToast;
