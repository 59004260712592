import { useCallback, useRef } from "react";
import { Project } from "~/client/types/project";
import { fetchUpdateProject } from "~/client/api/project";
import { useAppDispatch } from "~/client/hooks";
import {
  initializeCurrentProject,
  setServerError,
} from "~/client/store/modules";
import { APIError } from "~/client/types";

function useResetProjectKey({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const isResetting = useRef<boolean>(false);
  const dispatch = useAppDispatch();

  const reset = useCallback(async () => {
    if (isResetting.current) return;
    if (!currentProject) return;
    isResetting.current = true;
    const { id, name } = currentProject;
    try {
      const res = await fetchUpdateProject({
        params: {
          id,
          name,
          reset_key: true,
        },
      });
      const { data } = res;
      dispatch(initializeCurrentProject(data));
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      isResetting.current = false;
    }
  }, [currentProject, dispatch]);

  return {
    reset,
  };
}

export default useResetProjectKey;
