import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PaymentMethod } from "../../types";

export interface PaymentState {
  paymentMethodList: PaymentMethod[];
}

const initialState: PaymentState = {
  paymentMethodList: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    initializePaymentList: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethodList = action.payload;
    },
    unshiftPaymentList: (state, action: PayloadAction<PaymentMethod>) => {
      if (state.paymentMethodList) {
        state.paymentMethodList = [action.payload, ...state.paymentMethodList];
      } else {
        state.paymentMethodList = [action.payload];
      }
    },
    filterPaymentList: (
      state,
      action: PayloadAction<{ paymentId: string }>,
    ) => {
      if (state.paymentMethodList) {
        state.paymentMethodList = state.paymentMethodList.filter(
          (item) => item.id !== action.payload.paymentId,
        );
      }
    },
  },
  extraReducers: () => {
    //
  },
});

export const { initializePaymentList, unshiftPaymentList, filterPaymentList } =
  paymentSlice.actions;
export const { reducer: paymentReducer } = paymentSlice;
