import { RootState } from "~/client/store/store";
import { createSelector } from "@reduxjs/toolkit";

export const userSelector = (state: RootState) => state.auth.user;
export const selectedUser = createSelector(userSelector, (user) => user);
export const customerSelector = (state: RootState) => state.auth.customer;
export const selectedCustomer = createSelector(
  customerSelector,
  (customer) => customer,
);
export const selectedAuthState = createSelector(
  userSelector,
  customerSelector,
  (user, customer) => ({
    user,
    customer,
  }),
);

export const clientErrorSelector = (state: RootState) =>
  state.alert.clientError;
export const selectedClientError = createSelector(
  clientErrorSelector,
  (clientError) => clientError,
);
export const serverErrorSelector = (state: RootState) =>
  state.alert.serverError;
export const selectedServerError = createSelector(
  serverErrorSelector,
  (serverError) => serverError,
);
export const clientInfoSelector = (state: RootState) => state.alert.clientInfo;
export const selectedClientInfo = createSelector(
  clientInfoSelector,
  (clientInfo) => clientInfo,
);
export const selectedAlertState = createSelector(
  clientErrorSelector,
  serverErrorSelector,
  clientInfoSelector,
  (clientError, serverError, clientInfo) => ({
    clientError,
    serverError,
    clientInfo,
  }),
);

export const currentPageRouteSelector = (state: RootState) =>
  state.pageRoute.current;
export const selectedCurrentPageRoute = createSelector(
  currentPageRouteSelector,
  (currentPageRoute) => currentPageRoute,
);
export const hostPageRouteSelector = (state: RootState) =>
  state.pageRoute.hostRoute;
export const selectedHostPageRoute = createSelector(
  hostPageRouteSelector,
  (hostPageRoute) => hostPageRoute,
);
export const subRoutesSelector = (state: RootState) =>
  state.pageRoute.subRoutes;
export const selectedPageRouteState = createSelector(
  currentPageRouteSelector,
  hostPageRouteSelector,
  subRoutesSelector,
  (currentPageRoute, hostPageRoute, subRoutes) => ({
    currentPageRoute,
    hostPageRoute,
    subRoutes,
  }),
);

export const currentSubscriptionSelector = (state: RootState) =>
  state.pricePlan.currentSubscription;
export const selectedCurrentSubscription = createSelector(
  currentSubscriptionSelector,
  (currentSubscription) => currentSubscription,
);

export const projectStateSelector = (state: RootState) => state.project;
export const selectedProjectState = createSelector(
  projectStateSelector,
  (projectState) => projectState,
);

export const paymentStateSelector = (state: RootState) => state.payment;
export const selectedPaymentState = createSelector(
  paymentStateSelector,
  (paymentState) => paymentState,
);

export const audioStateSelector = (state: RootState) => state.audio;
export const selectedAudioState = createSelector(
  audioStateSelector,
  (audioState) => audioState,
);

export const uploadStateSelector = (state: RootState) => state.upload;
export const selectedUploadState = createSelector(
  uploadStateSelector,
  (uploadState) => uploadState,
);

export const analyticsStateSelector = (state: RootState) => state.analytics;
export const selectedAnalyticsState = createSelector(
  analyticsStateSelector,
  (analyticsState) => analyticsState,
);

export const searchStateSelector = (state: RootState) => state.search;
export const selectedSearchState = createSelector(
  searchStateSelector,
  (searchState) => searchState,
);

export const surveyStateSelector = (state: RootState) => state.survey;
export const selectedSurveyState = createSelector(
  surveyStateSelector,
  (surveyState) => surveyState,
);

export const voiceStateSelector = (state: RootState) => state.voice;
export const selectedVoiceState = createSelector(
  voiceStateSelector,
  (voiceState) => voiceState,
);
