import { DropdownButton, ListDropdown, Typo } from "@cochlearai/ui";
import { FC, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useProjectList, useSubscription } from "~/client/hooks";
import {
  PROJECT_FILTER_TYPE,
  PROJECT_FILTER_UI_LABELS,
} from "~/client/lib/constants";
import {
  ProjectFilterType,
  ProjectType,
  SubscriptionProjectType,
} from "~/client/types";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const DateDropdownButton = styled(DropdownButton)<{
  disable: boolean;
  selected: boolean;
}>`
  width: 127px;
  margin-left: auto;
  opacity: ${(p) => (p.disable ? 0.4 : 1)};
  cursor: ${(p) => (p.disable ? "unset" : "pointer")};
  pointer-events: ${(p) => (p.disable ? "none" : "")};
  border-color: ${(p) =>
    p.selected ? p.theme.colors.grey[80] : p.theme.colors.grey[30]};
`;

const ProjectFilterButton: FC = () => {
  const { colors } = useTheme();
  const [filterDropdownIsOpen, setFilterDropdownIsOpen] =
    useState<boolean>(false);

  const {
    filteredProjectList,
    selectedProjectTab,
    organizationOwnerList,
    filterType,
    changeFilterType,
  } = useProjectList();
  const { currentSubscription } = useSubscription();

  const filterData = useMemo(() => {
    if (
      !currentSubscription.product_types.includes(
        selectedProjectTab as SubscriptionProjectType,
      ) &&
      selectedProjectTab !== ProjectType.ALL
    ) {
      return PROJECT_FILTER_TYPE.map((item) => {
        if (item.value === ProjectFilterType.CREATED_BY_ME) {
          return {
            ...item,
            disable: true,
          };
        }
        return item;
      });
    }

    return PROJECT_FILTER_TYPE;
  }, [currentSubscription.product_types, selectedProjectTab]);

  return (
    <Container>
      <Typo
        variant="caption1"
        style={{ color: colors.grey[70], marginRight: "10px" }}
      >
        Filter By
      </Typo>
      <ListDropdown
        align="end"
        data={filterData}
        isOpen={filterDropdownIsOpen}
        onClickItem={(value) => changeFilterType(value as ProjectFilterType)}
        onClose={() => {
          setFilterDropdownIsOpen(false);
        }}
        onClickOutside={() => setFilterDropdownIsOpen(false)}
      >
        <DateDropdownButton
          onClick={() =>
            organizationOwnerList.length !== 0 && setFilterDropdownIsOpen(true)
          }
          disable={
            organizationOwnerList.length === 0 ||
            (!filteredProjectList?.some(
              (item) => item.product_type === ProjectType.EDGE_SDK,
            ) &&
              selectedProjectTab === ProjectType.EDGE_SDK &&
              !currentSubscription.product_types.includes(selectedProjectTab))
          }
          selected={filterType !== ProjectFilterType.ALL}
        >
          <Typo variant="caption1" style={{ color: colors.black }}>
            {PROJECT_FILTER_UI_LABELS[filterType]}
          </Typo>
        </DateDropdownButton>
      </ListDropdown>
    </Container>
  );
};

export default ProjectFilterButton;
