import {
  AnalyticsType,
  FilterParams,
  FilterParamsKeys,
  FilterParamsWithChecked,
  FilterParamsWithCheckedOld,
  FilterType,
  SelectedAnalyticsEvent,
} from "~/client/types/analytics";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  BASIC_SCALE_CONTENT,
  GROUP_BY_CONTENT,
  SCALE_CONTENT,
  TOP_TAGS_MIN_COUNT,
} from "~/client/lib/constants";
import { SenseTags } from "~/client/types/sense";

interface AnalyticsState {
  selectedEvent: SelectedAnalyticsEvent | null;
  filterParamsWithChecked: FilterParamsWithChecked | null;
  sdkTagFilter: { label: string; value: SenseTags; checked: boolean }[];
  filterParamsWithCheckedOld: FilterParamsWithCheckedOld | null;
  disabledFilterParams: FilterParamsKeys[];
}

const lastMonthFromTo = {
  from: moment(new Date()).startOf("month").format("YYYY-MM-DDTHH:mm:ssZ"),
  to: moment(new Date()).endOf("month").format("YYYY-MM-DDTHH:mm:ssZ"),
  badge: "1m",
};

const initialState: AnalyticsState = {
  selectedEvent: null,
  filterParamsWithChecked: {
    [AnalyticsType.TAG_FREQUENCY]: {
      [FilterType.SCALE]: SCALE_CONTENT,
      [FilterType.FROM_TO]: lastMonthFromTo,
    },
    [AnalyticsType.REALTIME_VIEW]: {
      [FilterType.SCALE]: BASIC_SCALE_CONTENT,
      [FilterType.FROM_TO]: lastMonthFromTo,
      [FilterType.GROUP_BY]: GROUP_BY_CONTENT,
    },
    [AnalyticsType.TOP_TAG]: {
      [FilterType.TOP_TAGS_COUNT]: TOP_TAGS_MIN_COUNT,
    },
    [AnalyticsType.TAG_AMOUNT_BREAKDOWN]: {
      [FilterType.FROM_TO]: lastMonthFromTo,
    },
  },
  sdkTagFilter: [],
  filterParamsWithCheckedOld: null,
  disabledFilterParams: [],
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    initializeFilterParams: (
      state,
      action: PayloadAction<{ type?: AnalyticsType; value: FilterParams }>,
    ) => {
      const { filterParamsWithChecked } = state;
      const { type, value } = action.payload;

      const LimitedTags = value[FilterType.TAG]?.map((tag, i) => ({
        ...tag,
        checked: i < 8 ? true : false,
      }));

      if (filterParamsWithChecked) {
        if (type) {
          state.filterParamsWithChecked = {
            ...filterParamsWithChecked,
            [type]: {
              ...filterParamsWithChecked[type],
              ...value,
            },
          };
        } else {
          state.filterParamsWithChecked = {
            [AnalyticsType.TAG_FREQUENCY]: {
              ...filterParamsWithChecked[AnalyticsType.TAG_FREQUENCY],
              ...value,
            },
            [AnalyticsType.REALTIME_VIEW]: {
              ...filterParamsWithChecked[AnalyticsType.REALTIME_VIEW],
              ...value,
              ...(LimitedTags && { [FilterType.TAG]: LimitedTags }),
            },
            [AnalyticsType.TOP_TAG]: {
              ...filterParamsWithChecked[AnalyticsType.TOP_TAG],
              ...value,
            },
            [AnalyticsType.TAG_AMOUNT_BREAKDOWN]: {
              ...filterParamsWithChecked[AnalyticsType.TAG_AMOUNT_BREAKDOWN],
              ...value,
            },
          };
        }
      }
    },
    updateFilterParams: (
      state,
      action: PayloadAction<{
        type: AnalyticsType;
        value: FilterParams;
      }>,
    ) => {
      const { type, value } = action.payload;
      const { filterParamsWithChecked } = state;
      if (!filterParamsWithChecked) return;

      state.filterParamsWithChecked = {
        ...filterParamsWithChecked,
        [type]: value,
      };
    },
    resetFilterParams: (state) => {
      state.filterParamsWithChecked = initialState.filterParamsWithChecked;
    },
    setSelectedEvent: (
      state,
      action: PayloadAction<SelectedAnalyticsEvent>,
    ) => {
      state.selectedEvent = action.payload;
    },
    setSdkTagFilter: (
      state,
      action: PayloadAction<
        { label: string; value: SenseTags; checked: boolean }[]
      >,
    ) => {
      state.sdkTagFilter = action.payload;
    },
    //previous analytics
    initializeFilterParamsOld: (
      state,
      action: PayloadAction<FilterParamsWithCheckedOld | null>,
    ) => {
      state.filterParamsWithCheckedOld = action.payload;
    },
    updateFilterParamsOld: (
      state,
      action: PayloadAction<{
        section: keyof FilterParamsWithCheckedOld;
        value: string;
      }>,
    ) => {
      const { section, value } = action.payload;
      const { filterParamsWithCheckedOld } = state;
      if (!filterParamsWithCheckedOld) return;

      state.filterParamsWithCheckedOld = {
        ...filterParamsWithCheckedOld,
        [section]: filterParamsWithCheckedOld[section]?.map((item) => {
          if (value === item.value) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return {
            ...item,
          };
        }),
      };
    },
    setDisabledFilterParamsOld: (
      state,
      action: PayloadAction<FilterParamsKeys[]>,
    ) => {
      state.disabledFilterParams = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});
export const {
  initializeFilterParams,
  updateFilterParams,
  resetFilterParams,
  setSelectedEvent,
  setSdkTagFilter,
  initializeFilterParamsOld,
  updateFilterParamsOld,
  setDisabledFilterParamsOld,
} = analyticsSlice.actions;
export const { reducer: analyticsReducer } = analyticsSlice;
