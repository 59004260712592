import { ConfirmModal, ToastCloseIcon, Typo } from "@cochlearai/ui";
import { FC, memo, useCallback, useEffect, useRef } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useAddMember } from "~/client/hooks";

const InputWithWarning = styled.div<{
  validated?: boolean;
}>`
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px;

  height: auto;
  cursor: text;

  background-color: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) =>
    typeof p.validated === "boolean"
      ? p.validated === true
        ? p.theme.colors.blue[60]
        : p.theme.colors.red
      : p.theme.colors.grey[30]};

  &[aria-disabled="true"] {
    border-color: transparent;
    opacity: 0.4;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
  overflow: unset;
`;

const AddMemberInput = styled.input`
  min-width: 240px;
  min-height: 100%;
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) => p.theme.colors.white};
  border: unset;
  border-radius: inherit;
  padding: 0;

  &:focus {
    outline: none;
  }
  &:disabled {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &::placeholder {
    color: ${(p) => p.theme.colors.grey[60]};
  }
`;

const ErrorText = styled(Typo)`
  color: ${(p) => p.theme.colors.red};
  margin-top: 8px;
  text-align: left;
`;

const EmailBadge = styled.div`
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.grey[10]};
  border-radius: 74px;
  padding: 4px 10px;
  user-select: none;
`;

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const AddMemberModal: FC<Props> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const FormRef = useRef<HTMLFormElement>(null);
  const { register, handleSubmit, setFocus, watch, setValue } = useForm<{
    email: string;
  }>();
  const email = watch("email");
  const {
    memberList,
    validation,
    invite,
    addEmail,
    deleteEmail,
    resetEmail,
    validate,
    setValidation,
  } = useAddMember();

  const onClickConfirm = useCallback(async () => {
    await invite(email);
    onConfirm && onConfirm();
  }, [email, invite, onConfirm]);

  const onCloseHandler = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Backspace") {
        if (!e.target.value) {
          deleteEmail(memberList[memberList.length - 1]);
          setValidation({
            validating: false,
            validated: true,
            error: "",
          });
        }
      } else if (e.key === "Enter") {
        if (!email) onClickConfirm();
      }
    },
    [deleteEmail, email, memberList, onClickConfirm, setValidation],
  );

  const onSubmit: SubmitHandler<{ email: string }> = (data) => {
    addEmail(data.email);
    setValue("email", "");
  };

  useEffect(() => {
    if (!email) {
      setValidation({
        validating: false,
        validated: true,
        error: "",
      });
      return;
    }
    const lastChar = email[email.length - 1];
    const onlyText = email.replaceAll(/[,;]/g, "");
    const validationResult = validate(onlyText);
    if (lastChar === "," || lastChar === ";") {
      if (validationResult) {
        addEmail(onlyText);
        setValue("email", "");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!open) {
      resetEmail();
      setValue("email", "");
      setValidation({
        validating: false,
        validated: true,
        error: "",
      });
    }
  }, [open, resetEmail, setValidation, setValue]);

  return (
    <ConfirmModal
      open={open}
      onClose={onCloseHandler}
      onAbort={onCloseHandler}
      onConfirm={onClickConfirm}
      title="Add members"
      subTitle="You can add members to your organization."
      confirmText="Send invite"
      abortText={t("Cancel", { ns: "common" })}
      confirmDisabled={
        (validation.validating && !!validation.error) ||
        (email?.length === 0 && memberList?.length === 0)
      }
    >
      <InputWithWarning
        validated={validation.validating ? validation.validated : undefined}
        onClick={() => setFocus("email")}
      >
        <FlexWrapper>
          {memberList.map((member) => (
            <EmailBadge key={member}>
              <Typo variant="caption1">{member}</Typo>
              <ToastCloseIcon
                style={{
                  cursor: "pointer",
                  marginLeft: "8px",
                }}
                width={6.7}
                height={6.7}
                fill={colors.black}
                onClick={() => deleteEmail(member)}
              />
            </EmailBadge>
          ))}
          <form ref={FormRef} onSubmit={handleSubmit(onSubmit)}>
            <AddMemberInput
              {...register("email", {
                validate,
              })}
              type="text"
              placeholder="Email, separated by comma"
              onKeyDown={onKeyDown}
              autoFocus
            ></AddMemberInput>
          </form>
        </FlexWrapper>
      </InputWithWarning>
      {validation.validating && !!validation.error && (
        <ErrorText variant="caption3">{validation.error}</ErrorText>
      )}
    </ConfirmModal>
  );
};

export default memo(AddMemberModal);
