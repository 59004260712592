import React, { FC, SVGProps } from "react";

const ChromeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-26.4 -44 228.8 264"
    {...props}
  >
    <defs>
      <linearGradient
        y2={44.354}
        x2={81.837}
        y1={75.021}
        x1={29.337}
        gradientUnits="userSpaceOnUse"
        id="chromeLogo_svg__f"
        xlinkHref="#chromeLogo_svg__a"
      />
      <linearGradient
        y2={130.33}
        x2={52.538}
        y1={164.5}
        x1={110.87}
        gradientUnits="userSpaceOnUse"
        id="chromeLogo_svg__g"
        xlinkHref="#chromeLogo_svg__b"
      />
      <linearGradient
        y2={114.13}
        x2={136.55}
        y1={49.804}
        x1={121.86}
        gradientUnits="userSpaceOnUse"
        id="chromeLogo_svg__j"
        xlinkHref="#chromeLogo_svg__c"
      />
      <linearGradient
        y2={114.13}
        x2={136.55}
        y1={49.804}
        x1={121.86}
        gradientUnits="userSpaceOnUse"
        id="chromeLogo_svg__k"
        xlinkHref="#chromeLogo_svg__c"
      />
      <linearGradient
        y2={44.354}
        x2={81.837}
        y1={75.021}
        x1={29.337}
        gradientUnits="userSpaceOnUse"
        id="chromeLogo_svg__n"
        xlinkHref="#chromeLogo_svg__a"
      />
      <linearGradient
        y2={130.33}
        x2={52.538}
        y1={164.5}
        x1={110.87}
        gradientUnits="userSpaceOnUse"
        id="chromeLogo_svg__r"
        xlinkHref="#chromeLogo_svg__b"
      />
      <circle r={88} cx={96} cy={96} id="chromeLogo_svg__d" />
    </defs>
    <clipPath id="chromeLogo_svg__e">
      <use
        height="100%"
        xlinkHref="#chromeLogo_svg__d"
        overflow="visible"
        width="100%"
      />
    </clipPath>
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <path fill="#db4437" d="M21.97 8v108h39.39L96 56h88V8z" />
      <linearGradient
        x1={29.337}
        x2={81.837}
        y1={75.021}
        gradientUnits="userSpaceOnUse"
        y2={44.354}
        id="chromeLogo_svg__a"
      >
        <stop offset={0} stopOpacity={0.6} stopColor="#A52714" />
        <stop offset={0.66} stopOpacity={0} stopColor="#A52714" />
      </linearGradient>
      <path fill="url(#chromeLogo_svg__f)" d="M21.97 8v108h39.39L96 56h88V8z" />
    </g>
    <path
      fill="#3e2723"
      transform="translate(-8 -8)"
      d="M62.31 115.65 22.48 47.34l-.58 1 39.54 67.8z"
      fillOpacity={0.15}
      clipPath="url(#chromeLogo_svg__e)"
    />
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <path fill="#0f9d58" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
      <linearGradient
        x1={110.87}
        x2={52.538}
        y1={164.5}
        gradientUnits="userSpaceOnUse"
        y2={130.33}
        id="chromeLogo_svg__b"
      >
        <stop offset={0} stopOpacity={0.4} stopColor="#055524" />
        <stop offset={0.33} stopOpacity={0} stopColor="#055524" />
      </linearGradient>
      <path
        fill="url(#chromeLogo_svg__g)"
        d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z"
      />
    </g>
    <path
      fill="#263238"
      transform="translate(-8 -8)"
      d="m129.84 117.33-.83-.48L90.62 184h1.15l38.1-66.64z"
      fillOpacity={0.15}
      clipPath="url(#chromeLogo_svg__e)"
    />
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <defs>
        <path
          d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z"
          id="chromeLogo_svg__h"
        />
      </defs>
      <clipPath id="chromeLogo_svg__i">
        <use
          height="100%"
          xlinkHref="#chromeLogo_svg__h"
          overflow="visible"
          width="100%"
        />
      </clipPath>
      <g clipPath="url(#chromeLogo_svg__i)">
        <path fill="#ffcd40" d="m96 56 34.65 60-38.88 68H184V56z" />
        <linearGradient
          x1={121.86}
          x2={136.55}
          y1={49.804}
          gradientUnits="userSpaceOnUse"
          y2={114.13}
          id="chromeLogo_svg__c"
        >
          <stop offset={0} stopOpacity={0.3} stopColor="#EA6100" />
          <stop offset={0.66} stopOpacity={0} stopColor="#EA6100" />
        </linearGradient>
        <path
          fill="url(#chromeLogo_svg__j)"
          d="m96 56 34.65 60-38.88 68H184V56z"
        />
      </g>
    </g>
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <path fill="#ffcd40" d="m96 56 34.65 60-38.88 68H184V56z" />
      <path
        fill="url(#chromeLogo_svg__k)"
        d="m96 56 34.65 60-38.88 68H184V56z"
      />
    </g>
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <defs>
        <path d="m96 56 34.65 60-38.88 68H184V56z" id="chromeLogo_svg__l" />
      </defs>
      <clipPath id="chromeLogo_svg__m">
        <use
          height="100%"
          xlinkHref="#chromeLogo_svg__l"
          overflow="visible"
          width="100%"
        />
      </clipPath>
      <g clipPath="url(#chromeLogo_svg__m)">
        <path fill="#db4437" d="M21.97 8v108h39.39L96 56h88V8z" />
        <path
          fill="url(#chromeLogo_svg__n)"
          d="M21.97 8v108h39.39L96 56h88V8z"
        />
      </g>
    </g>
    <radialGradient
      r={84.078}
      gradientTransform="translate(-576)"
      cx={668.18}
      cy={55.948}
      gradientUnits="userSpaceOnUse"
      id="chromeLogo_svg__o"
    >
      <stop offset={0} stopOpacity={0.2} stopColor="#3E2723" />
      <stop offset={1} stopOpacity={0} stopColor="#3E2723" />
    </radialGradient>
    <path
      fill="url(#chromeLogo_svg__o)"
      transform="translate(-8 -8)"
      d="M96 56v20.95L174.4 56z"
      clipPath="url(#chromeLogo_svg__e)"
    />
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <defs>
        <path d="M21.97 8v40.34L61.36 116 96 56h88V8z" id="chromeLogo_svg__p" />
      </defs>
      <clipPath id="chromeLogo_svg__q">
        <use
          height="100%"
          xlinkHref="#chromeLogo_svg__p"
          overflow="visible"
          width="100%"
        />
      </clipPath>
      <g clipPath="url(#chromeLogo_svg__q)">
        <path fill="#0f9d58" d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z" />
        <path
          fill="url(#chromeLogo_svg__r)"
          d="M8 184h83.77l38.88-38.88V116H61.36L8 24.48z"
        />
      </g>
    </g>
    <radialGradient
      r={78.044}
      gradientTransform="translate(-576)"
      cx={597.88}
      cy={48.52}
      gradientUnits="userSpaceOnUse"
      id="chromeLogo_svg__s"
    >
      <stop offset={0} stopOpacity={0.2} stopColor="#3E2723" />
      <stop offset={1} stopOpacity={0} stopColor="#3E2723" />
    </radialGradient>
    <path
      fill="url(#chromeLogo_svg__s)"
      transform="translate(-8 -8)"
      d="m21.97 48.45 57.25 57.24L61.36 116z"
      clipPath="url(#chromeLogo_svg__e)"
    />
    <radialGradient
      r={87.87}
      gradientTransform="translate(-576)"
      cx={671.84}
      cy={96.138}
      gradientUnits="userSpaceOnUse"
      id="chromeLogo_svg__t"
    >
      <stop offset={0} stopOpacity={0.2} stopColor="#263238" />
      <stop offset={1} stopOpacity={0} stopColor="#263238" />
    </radialGradient>
    <path
      fill="url(#chromeLogo_svg__t)"
      transform="translate(-8 -8)"
      d="m91.83 183.89 20.96-78.2L130.65 116z"
      clipPath="url(#chromeLogo_svg__e)"
    />
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <circle fill="#f1f1f1" r={40} cx={96} cy={96} />
      <circle fill="#4285f4" r={32} cx={96} cy={96} />
    </g>
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <path
        fill="#3e2723"
        d="M96 55c-22.09 0-40 17.91-40 40v1c0-22.09 17.91-40 40-40h88v-1z"
        fillOpacity={0.2}
      />
      <path
        fill="#fff"
        d="M130.6 116c-6.92 11.94-19.81 20-34.6 20-14.8 0-27.69-8.06-34.61-20h-.04L8 24.48v1L61.36 117h.04c6.92 11.94 19.81 20 34.61 20 14.79 0 27.68-8.05 34.6-20h.05v-1z"
        fillOpacity={0.1}
      />
      <path
        fill="#3e2723"
        d="M97 56c-.17 0-.33.02-.5.03C118.36 56.3 136 74.08 136 96c0 21.92-17.64 39.7-39.5 39.97.17 0 .33.03.5.03 22.09 0 40-17.91 40-40s-17.91-40-40-40z"
        opacity={0.1}
      />
      <path
        fill="#fff"
        d="M131 117.33c3.4-5.88 5.37-12.68 5.37-19.96 0-4.22-.66-8.28-1.87-12.09.95 3.42 1.5 7.01 1.5 10.73 0 7.28-1.97 14.08-5.37 19.96l.02.04-38.88 68h1.16l38.09-66.64z"
        fillOpacity={0.2}
      />
    </g>
    <g clipPath="url(#chromeLogo_svg__e)" transform="translate(-8 -8)">
      <path
        fill="#fff"
        d="M96 9c48.43 0 87.72 39.13 87.99 87.5 0-.17.01-.33.01-.5 0-48.6-39.4-88-88-88S8 47.4 8 96c0 .17.01.33.01.5C8.28 48.13 47.57 9 96 9z"
        fillOpacity={0.2}
      />
      <path
        fill="#3e2723"
        d="M96 183c48.43 0 87.72-39.13 87.99-87.5 0 .17.01.33.01.5 0 48.6-39.4 88-88 88S8 144.6 8 96c0-.17.01-.33.01-.5C8.28 143.87 47.57 183 96 183z"
        fillOpacity={0.15}
      />
    </g>
    <radialGradient
      r={176.75}
      gradientTransform="translate(-8 -8)"
      cx={34.286}
      cy={32.014}
      gradientUnits="userSpaceOnUse"
      id="chromeLogo_svg__u"
    >
      <stop offset={0} stopOpacity={0.1} stopColor="#fff" />
      <stop offset={1} stopOpacity={0} stopColor="#fff" />
    </radialGradient>
    <circle fill="url(#chromeLogo_svg__u)" r={88} cx={88} cy={88} />
  </svg>
);

export default ChromeIcon;
