import { CopyIcon, EditIcon, Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import moment from "moment";
import { copyToClipboard } from "~/client/lib/helpers";
import { SDKChannel } from "~/client/types/sdk";

const EventDetailInfoItemContainer = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 20px;
  }
`;

const EventDetailInfoItemLabelContainer = styled.div`
  width: 110px;
`;

const EventDetailInfoItemValueContainer = styled.div`
  display: flex;
  flex: 1;
  word-break: break-word;
`;

const EventDetailContentText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[50]};
`;

const TopInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

interface Props {
  data: SDKChannel;
  onEditDeviceName?: () => void;
}

const EdgeSdkDetailView: FC<Props> = ({ data, onEditDeviceName }) => {
  const {
    id,
    device_name,
    device_id,
    sdk_version,
    model_version,
    delete_pending,
    created_at,
    last_seen,
  } = data;
  const { colors } = useTheme();

  return (
    <>
      <Typo
        variant="h3"
        style={{
          paddingBottom: "20px",
          borderBottom: `1px solid ${colors.grey[20]}`,
        }}
      >
        Details
      </Typo>
      <EventDetailContentText variant="caption2" style={{ marginTop: "10px" }}>
        Channel id
      </EventDetailContentText>
      <TopInfoContainer>
        <Typo variant="body">{id}</Typo>
        <CopyIcon
          width={20}
          height={20}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          fill={colors.grey[50]}
          onClick={() => {
            copyToClipboard(id);
          }}
        />
      </TopInfoContainer>
      <div
        style={{
          backgroundColor: colors.grey[20],
          height: "1px",
          width: "100%",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      />
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Device name
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{device_name}</Typo>
          {onEditDeviceName && (
            <EditIcon
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={onEditDeviceName}
            />
          )}
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Device id
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{device_id}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            SDK version
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{sdk_version}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Model version
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{model_version}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Delete pending
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{delete_pending ? "true" : "false"}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Last online
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{moment(new Date(last_seen)).fromNow()}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Installation date
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">
            {moment(new Date(created_at))
              .format("MM.DD.YYYY HH:mm:ss")
              .toString()}
          </Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
    </>
  );
};

export default EdgeSdkDetailView;
