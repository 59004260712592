import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchUpcomingInvoice } from "~/client/api";
import { useAppDispatch } from "~/client/hooks";
import { APIError, InvoiceInfo } from "~/client/types";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";

function useUpcomingInvoice() {
  const dispatch = useAppDispatch();
  const [invoice, setInvoice] = useState<InvoiceInfo | null>(null);
  const { data, error, isValidating } = useSWR(
    SWR_KEY.INVOICE_INITIALIZE_UPCOMING_INVOICE,
    async () => {
      const res = await fetchUpcomingInvoice({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setInvoice(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    invoice,
    isValidating,
  };
}

export default useUpcomingInvoice;
