import React, { FC, useCallback, useState } from "react";
import { SenseEvent, SenseUniqueTag, UploadStatus } from "~/client/types";
import styled, { useTheme } from "styled-components";

import AudioVisualizer from "./AudioVisualizer";
import SenseLog from "./SenseLog";
import { Typo } from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import useAudio from "~/client/hooks/useAudio";
import useCurrentProject from "~/client/hooks/useCurrentProject";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Flex)`
  border-radius: 4px;
  padding: 30px;
  gap: 60px;
  align-items: start;
  background: ${(p) => p.theme.colors.white};

  ${media.query.lg} {
    flex-direction: column;
    align-items: start;
  }
`;

const DetectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface Props {
  status?: UploadStatus;
  uniqueTags?: SenseUniqueTag[];
  log?: SenseEvent[];
}

const SenseDetection: FC<Props> = ({ status, uniqueTags, log }) => {
  const { colors } = useTheme();
  const { currentProject } = useCurrentProject();
  const { sense, duration, toggleAudio, setCurrentTime } = useAudio({
    currentProject,
  });
  const [selectedTime, setSelectedTime] = useState<number | undefined>();

  const onClickLog = useCallback(
    (time: number) => {
      setCurrentTime("sense", time);
      setSelectedTime(selectedTime === time ? undefined : time);
    },
    [selectedTime, setCurrentTime],
  );

  return (
    <Wrapper>
      <DetectionContainer>
        <Typo variant="h3">Cochl.Sense detection</Typo>
        <Typo
          variant="body"
          style={{
            color: colors.grey[70],
            marginTop: "10px",
            marginBottom: "18px",
          }}
        >
          Analyze what sound tags appear in your audio
        </Typo>
        <AudioVisualizer
          audioType="sense"
          duration={duration}
          uniqueTags={uniqueTags}
          selectedTime={selectedTime}
          status={status}
          isPlay={sense?.isPlay}
          toggleAudio={() => toggleAudio("sense")}
        />
      </DetectionContainer>
      <SenseLog result={log} selectedTime={selectedTime} onClick={onClickLog} />
    </Wrapper>
  );
};

export default SenseDetection;
