import React, { FC, SVGProps } from "react";

const SearchIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.72793 8.33986C6.91088 9.01976 5.86034 9.42869 4.71433 9.42869C2.11068 9.42869 0 7.31801 0 4.71435C0 2.11068 2.11068 0 4.71433 0C7.31798 0 9.42865 2.11068 9.42865 4.71435C9.42865 5.86021 9.01985 6.91057 8.34013 7.72757L12 11.3877L11.3877 12L7.72793 8.33986ZM8.5715 4.71435C8.5715 6.84462 6.84459 8.57154 4.71433 8.57154C2.58406 8.57154 0.85715 6.84462 0.85715 4.71435C0.85715 2.58407 2.58406 0.857154 4.71433 0.857154C6.84459 0.857154 8.5715 2.58407 8.5715 4.71435Z"
      fill="#727273"
    />
  </svg>
);

export default SearchIcon;
