import { Popover, PopoverProps } from "@cochlearai/ui";
import { Calendar } from "../Calendar";

import { MonthYearPicker } from "../MonthYearPicker";
import { CustomDayPickerRangeControllerShape } from "../types/calendar";

export interface DayPickerRangeControllerPopoverProps {
  children: JSX.Element;
  popover: Omit<PopoverProps, "children" | "content">;
  calendar: CustomDayPickerRangeControllerShape;
  monthYearProps?: {
    title?: string;
    values?: { value: string; disaled?: boolean }[];
    selected?: string;
    onClickPrev?: () => void;
    onClickNext?: () => void;
    onClickItem: (target: string) => void;
  };
  showCalendar?: boolean;
  width?: number;
}

export const DayPickerRangeControllerPopover = ({
  children,
  calendar: calendarProps,
  monthYearProps,
  showCalendar,
  width,
  popover: popoverProps,
}: DayPickerRangeControllerPopoverProps) => {
  return (
    <Popover
      {...popoverProps}
      content={({ childRect }) => {
        return showCalendar ? (
          <Calendar
            {...calendarProps}
            style={{ width: width ?? childRect.width }}
          />
        ) : (
          <MonthYearPicker
            {...monthYearProps}
            style={{ width: width ?? childRect.width }}
          />
        );
      }}
      padding={6}
    >
      {children}
    </Popover>
  );
};

export default DayPickerRangeControllerPopover;
