import { APIError, API_ERROR_STATUS, ClientError } from "~/client/types/error";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ClientInfo } from "~/client/types/clientInfo";

export interface AlertState {
  clientError: ClientError | null;
  serverError: APIError<unknown> | null;
  clientInfo: ClientInfo | null;
}

const initialState: AlertState = {
  clientError: null,
  serverError: null,
  clientInfo: null,
};

type SetClientInfoPayload = ClientInfo | null;

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setClientInfo: (state, action: PayloadAction<SetClientInfoPayload>) => {
      state.clientInfo = action.payload;
    },
    setClientError: (state, action: PayloadAction<ClientError | null>) => {
      state.clientError = action.payload;
    },
    setServerError: (
      state,
      action: PayloadAction<APIError<unknown> | null>,
    ) => {
      if (action.payload && action.payload.response) {
        state.serverError = action.payload;
      } else {
        state.serverError = {
          response: {
            status: API_ERROR_STATUS.INTERNAL_SERVER_ERROR,
          },
        };
      }
    },
    initializeErrors: (state) => {
      state.clientError = null;
      state.serverError = null;
    },
  },
  extraReducers: () => {
    //
  },
});

export const {
  setClientError,
  setServerError,
  initializeErrors,
  setClientInfo,
} = alertSlice.actions;

export const { reducer: alertReducer } = alertSlice;
