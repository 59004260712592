import { setServerError } from "~/client/store/modules";
import { useCallback, useState } from "react";

import { APIError, MediumType } from "~/client/types";
import { fetchUpdateMedium } from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";

function useUpdateMedium() {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [errorInfo, setErrorInfo] = useState<APIError<unknown> | null>(null);

  const update = useCallback(
    async (id: string, mediumValues: string[], type: MediumType) => {
      if (isUpdating || !id || !mediumValues) return;
      setIsUpdating(true);
      setErrorInfo(null);
      const objectName = type === MediumType.WEBHOOK ? "urls" : "emails";

      try {
        await fetchUpdateMedium({
          params: {
            id,
            [objectName]: mediumValues,
          },
        });
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        setErrorInfo(error);
        dispatch(setServerError(error));
      } finally {
        setIsUpdating(false);
      }
    },
    [dispatch, isUpdating],
  );

  return {
    update,
    isUpdating,
    errorInfo,
  };
}

export default useUpdateMedium;
