import { Customer, User } from "~/client/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  user: User | null;
  customer: Customer | null;
}

const initialState: AuthState = {
  user: null,
  customer: null,
};

export interface InitializeAuthenticationPayload {
  user: User;
  customer: Customer;
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initializeAuthentication: (
      state,
      action: PayloadAction<InitializeAuthenticationPayload>,
    ) => {
      const { user, customer } = action.payload;
      state.user = user;
      state.customer = customer;
    },
  },
  extraReducers: () => {
    //
  },
});

export const { initializeAuthentication } = authSlice.actions;
export const { reducer: authReducer } = authSlice;
