import ReactGA, { EventArgs } from "react-ga";
import ReactGA4 from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
import { getConfigs } from "~/client/lib";

const { REACT_APP_COCHL_WEB_STAGE: stage } = getConfigs();

export const pageView = (
  path: string,
  trackerNames?: ReactGA.TrackerNames | undefined,
  title?: string | undefined,
) => {
  if (stage === "test") return;
  ReactGA.pageview(path, trackerNames, title);
  ReactGA4.send({ hitType: "pageview", path, title });
};

export const sendEvent = (args: EventArgs | UaEventOptions) => {
  if (stage === "test") return;
  ReactGA.event(args);
  ReactGA4.event(args);
};
