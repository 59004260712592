import {
  APIError,
  API_ERROR_STATUS,
  Project,
  ProjectType,
} from "~/client/types";
import {
  AnalyticsFilterItems,
  FilterParamsKeys,
  FilterParamsValues,
  FilterParamsWithCheckedOld,
} from "~/client/types/analytics";
import {
  initializeFilterParamsOld,
  setDisabledFilterParamsOld,
  setServerError,
  updateFilterParamsOld,
} from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetParameters } from "~/client/api";
import { isOfficialSenseValue } from "~/client/lib/helpers";
import { selectedAnalyticsState } from "~/client/lib";
import { uniqBy } from "lodash-es";
import useSWR from "swr";
import useSdkChannelList from "./useSdkChannelList";

function useAnalyticsParameters({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const dispatch = useAppDispatch();
  const { filterParamsWithCheckedOld, disabledFilterParams } = useAppSelector(
    selectedAnalyticsState,
  );
  const { channelList } = useSdkChannelList({ currentProject });
  const [originalFilterParams, setOriginalFilterParams] =
    useState<FilterParamsWithCheckedOld | null>(null);
  const filterItems: AnalyticsFilterItems = useMemo(
    () =>
      filterParamsWithCheckedOld
        ? Object.keys(filterParamsWithCheckedOld).map(
            (key: keyof FilterParamsWithCheckedOld | string) => {
              let label = "";
              switch (key) {
                case "ioType":
                  label = "IOType";
                  break;
                case "contentType":
                  label = "ContentType";
                  break;
                case "tag":
                  label = "Tag";
                  break;
                case "deviceName":
                  label = "Device Name";
                  break;
                default:
                  break;
              }
              return {
                label,
                value: key,
              };
            },
          )
        : null,
    [filterParamsWithCheckedOld],
  );
  const selectedFilterItemCount = useMemo(() => {
    if (!filterParamsWithCheckedOld) return 0;
    return Object.keys(filterParamsWithCheckedOld).reduce(
      (prevCount, filterKey) => {
        const items: FilterParamsValues =
          filterParamsWithCheckedOld[filterKey as FilterParamsKeys];
        return (
          prevCount + (items ? items.filter((item) => item.checked).length : 0)
        );
      },
      0,
    );
  }, [filterParamsWithCheckedOld]);
  const { data, error, isValidating, mutate } = useSWR(
    SWR_KEY.ANALYTICS_INITIALIZE_PARAMETERS,
    async () => {
      const res = await fetchGetParameters({
        params: {},
      });
      const { ioType, contentType, tag } = res.data;
      const uniqTags = uniqBy(tag, "Name");
      const data: FilterParamsWithCheckedOld = {
        ioType: ioType.map((item) => ({ value: item, checked: false })),
        contentType: contentType.map((item) => ({
          value: item,
          checked: false,
        })),
        tag: uniqTags
          .filter((item) =>
            isOfficialSenseValue({ value: item.Name, type: "tag" }),
          )
          .map((item) => ({
            value: item.Name,
            checked: false,
          }))
          .sort((a, b) => a.value.localeCompare(b.value)),
      };
      return data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const toggleFilterItem = useCallback(
    ({
      section,
      value,
    }: {
      section: keyof FilterParamsWithCheckedOld;
      value: string;
    }) => {
      if (!filterParamsWithCheckedOld) return;
      dispatch(updateFilterParamsOld({ section, value }));
    },
    [dispatch, filterParamsWithCheckedOld],
  );

  const convertedFilters = useCallback(
    (filters) => {
      return currentProject?.product_type === ProjectType.EDGE_SDK &&
        channelList.length > 0
        ? {
            ...filters,
            deviceName: channelList.map((item) => ({
              value: item.device_name,
              checked: false,
            })),
          }
        : filters;
    },
    [channelList, currentProject?.product_type],
  );

  const resetFilterItems = useCallback(() => {
    dispatch(initializeFilterParamsOld(convertedFilters(originalFilterParams)));
  }, [convertedFilters, dispatch, originalFilterParams]);

  const disableFilterItems = useCallback(
    ({ sections }: { sections: FilterParamsKeys[] }) => {
      dispatch(setDisabledFilterParamsOld(sections));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!data) return;
    const filters = convertedFilters(data);
    dispatch(initializeFilterParamsOld(filters));
    !originalFilterParams && setOriginalFilterParams(filters);
  }, [convertedFilters, data, dispatch, originalFilterParams]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    if (e.response?.status === API_ERROR_STATUS.FORBIDDEN) {
      throw e;
    } else {
      dispatch(setServerError(e));
    }
  }, [dispatch, error]);

  return {
    filterParamsWithCheckedOld,
    filterItems,
    disabledFilterParams,
    selectedFilterItemCount,
    toggleFilterItem,
    disableFilterItems,
    resetFilterItems,
    mutate,
    isValidating,
  };
}

export default useAnalyticsParameters;
