import moment from "moment";
import React, { FC } from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";
import { ANALYTICS_COLOR, Z_INDEXES } from "~/client/lib/constants";

const ChartContainer = styled(Chart)`
  .apexcharts-text {
    fill: ${(p) => p.theme.colors.black};
  }
  .apexcharts-legend-text {
    color: ${(p) => p.theme.colors.black} !important;
  }
  .apexcharts-tooltip {
    z-index: ${Z_INDEXES.POP_OVER};
  }
`;

interface Props {
  data: { name: string; data: (number | null)[] }[];
  labels?: string[];
  scale?: string;
}

const LineChart: FC<Props> = ({ data, labels, scale }) => {
  return (
    <ChartContainer
      type="line"
      height="400"
      series={data}
      options={{
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ANALYTICS_COLOR,
        markers: {
          size: 3.5,
          strokeWidth: 0,
        },
        stroke: {
          width: 2.5,
        },
        grid: {
          strokeDashArray: 3,
          padding: { left: 40 },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: labels,
          labels: {
            formatter: function (val: string) {
              return moment(val).format(
                `MM/DD/YYYY${scale === "hour" ? " HH:mm" : ""}`,
              );
            },
          },
          // tickAmount: maxDataLength,
          // tickPlacement: "on",
        },
        legend: {
          showForSingleSeries: true,
          position: "bottom",
          itemMargin: {
            horizontal: 10,
            vertical: 10,
          },
        },
        tooltip: {
          theme: "dark",
        },
      }}
    />
  );
};

export default LineChart;
