import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Answer } from "../../types";

interface PricePlanState {
  completed: boolean | null;
  order: number | null | undefined;
  answers: Answer[];
}

const initialState: PricePlanState = {
  completed: null,
  order: undefined,
  answers: [],
};

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<number | null>) => {
      state.order = action.payload;
    },
    setAnswers: (state, action: PayloadAction<Answer[]>) => {
      state.answers = action.payload;
    },
    setCompleted: (state, action: PayloadAction<boolean>) => {
      state.completed = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});
export const { setOrder, setAnswers, setCompleted } = surveySlice.actions;
export const { reducer: surveyReducer } = surveySlice;
