import { ChangeEvent } from "react";

import styled from "styled-components";
import { Typo } from "../Typo";

const Label = styled.label<{ isBtnType?: boolean; checked?: boolean }>`
  background: #ffffff;
  color: ${(p) =>
    p.isBtnType && p.checked ? p.theme.colors.blue[60] : "#000000"};
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: ${(p) => (p.isBtnType ? "10px" : "6px 8px")};
  display: flex;
  gap: 24px;
  cursor: pointer;
  width: 100%;
  justify-content: ${(p) => (p.isBtnType ? "center" : "start")};
  align-items: center;
`;

export interface RadioButtonProps {
  name: string;
  value: string;
  checked?: boolean;
  isBtnType?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * React component used to create a radio button.
 * @param name - Name of the radio button.
 * @param value - Value of the radio button.
 * @param checked - Whether the radio button is checked or not.
 * @param isBtnType - Whether the radio button is a button or not.
 * @param onChange - A callback when the radio button is changed.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/radio-button--default
 */
export const RadioButton = ({
  name,
  value,
  checked,
  isBtnType,
  onChange,
}: RadioButtonProps) => {
  return (
    <Label htmlFor={value} isBtnType={isBtnType} checked={checked}>
      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        style={{
          cursor: "pointer",
          margin: 0,
          display: isBtnType ? "none" : "block",
        }}
        checked={checked ?? false}
        onChange={onChange}
      />
      <Typo variant={isBtnType ? "button" : "body"}>{value}</Typo>
    </Label>
  );
};
