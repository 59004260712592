import { BaseStyleProps } from "../types/style";
import { MouseEventHandler, PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.tr`
  display: table-row;
`;

export interface TableRowProps extends BaseStyleProps {
  onClick?: MouseEventHandler<HTMLTableRowElement>;
}
/**
 * React component used to create a table row.
 * @param style - Style of the table row.
 * @param className - Class name of the table row.
 * @param onClick - Event handler for the table row.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/table--default
 */
export const TableRow = ({
  children,
  onClick,
  className,
  style,
}: PropsWithChildren<TableRowProps>) => {
  return (
    <Container className={className} onClick={onClick} style={style}>
      {children}
    </Container>
  );
};
