import { BaseStyleProps } from "../types/style";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.thead`
  display: table-header-group;
`;

export interface TableHeadProps extends BaseStyleProps {}
/**
 * React component used to create a table header.
 * @param style - Style of the table header.
 * @param className - Class name of the table header.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/table--default
 */
export const TableHead = ({
  children,
  className,
  style,
}: PropsWithChildren<TableHeadProps>) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  );
};
