import { Scroll, Typo } from "@cochlearai/ui";
import styled, { useTheme } from "styled-components";

import { ANALYTICS_TYPE_UI_LABELS } from "~/client/lib/constants";
import { AnalyticsType } from "~/client/types/analytics";
import { FC } from "react";
import { getConfigs } from "~/client/lib";
import moment from "moment";
import { selectedAnalyticsState } from "~/client/lib/selectors";
import { useAppSelector } from "~/client/hooks";

const { REACT_APP_COCHL_WEB_STAGE: stage } = getConfigs();

const EventDetailInfoItemContainer = styled.div`
  display: flex;
  align-items: baseline;
  & + & {
    margin-top: 20px;
  }
`;

const EventDetailInfoItemLabelContainer = styled.div`
  width: 110px;
`;

const EventDetailInfoItemValueContainer = styled(Scroll)`
  flex: 1;
`;

const EventDetailContentText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[50]};
`;

interface Props {
  filterType?: AnalyticsType;
}

const AnalyticsDetailView: FC<Props> = ({ filterType }) => {
  const { colors } = useTheme();
  const { selectedEvent } = useAppSelector(selectedAnalyticsState);

  if (!selectedEvent) return null;

  const { date, platform, tagCount } = selectedEvent;
  return (
    <>
      <Typo variant="h3">Details</Typo>
      {filterType && (
        <Typo
          variant="caption1"
          style={{
            paddingTop: "4px",
            paddingBottom: "20px",
            color: colors.grey[80],
          }}
        >
          {ANALYTICS_TYPE_UI_LABELS[filterType]}
        </Typo>
      )}
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Date
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          {stage !== "test" && (
            <Typo variant="body">
              {moment(date).format("MMM DD YYYY, HH:mm")}
            </Typo>
          )}
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Platform
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{platform?.toUpperCase()}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      {tagCount && tagCount.length > 0 && (
        <EventDetailInfoItemContainer>
          <EventDetailInfoItemLabelContainer>
            <EventDetailContentText variant="caption2">
              Tag Count
            </EventDetailContentText>
          </EventDetailInfoItemLabelContainer>
          <EventDetailInfoItemValueContainer>
            {tagCount?.map((item) => (
              <Typo variant="body" key={item.tag}>
                {`${item.tag} (${item.value})`}
              </Typo>
            ))}
          </EventDetailInfoItemValueContainer>
        </EventDetailInfoItemContainer>
      )}
    </>
  );
};

export default AnalyticsDetailView;
