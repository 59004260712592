import { BaseListProps, PaymentMethod } from "~/client/types";
import React, { FC } from "react";

import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
`;

type Props = BaseListProps<PaymentMethod>;

const PaymentMethodList: FC<Props> = ({ data, renderItem }) => {
  return (
    <Container>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          {renderItem && renderItem(item)}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default PaymentMethodList;
