import {
  ProjectFilterButton,
  ProjectFilterButtonSkeleton,
  ProjectItemList,
  ProjectItemListSkeleton,
  ProjectTab,
  ProjectTabSkeleton,
} from "~/client/components";
import React, { FC, Suspense } from "react";

interface Props {
  onClickAddProjectButton: () => void;
}

const ProjectOverviewContent: FC<Props> = ({ onClickAddProjectButton }) => {
  return (
    <>
      <Suspense fallback={<ProjectTabSkeleton />}>
        <ProjectTab />
      </Suspense>
      <div style={{ height: "14px" }} />
      <Suspense fallback={<ProjectFilterButtonSkeleton />}>
        <ProjectFilterButton />
      </Suspense>
      <Suspense fallback={<ProjectItemListSkeleton />}>
        <ProjectItemList onClickAddProjectButton={onClickAddProjectButton} />
      </Suspense>
    </>
  );
};

export default ProjectOverviewContent;
