import { useCallback, useRef } from "react";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";
import {
  fetchReadNotification,
  fetchReadAllNotification,
} from "~/client/api/notification";
import { APIError } from "~/client/types";
import { useSWRConfig } from "swr";
import { SWR_KEY } from "~/client/types/swrKey";

function useReadNotification() {
  const isReading = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();

  const readAll = useCallback(async () => {
    if (isReading.current) return;
    isReading.current = true;
    try {
      await fetchReadAllNotification({
        params: {},
      });
      mutate(SWR_KEY.NOTIFICATION_INITIALIZE_NOTIFICATION_LIST);
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      isReading.current = false;
    }
  }, [dispatch, mutate]);

  const read = useCallback(
    async (id) => {
      if (isReading.current || !id) return;
      isReading.current = true;
      try {
        await fetchReadNotification({
          params: {
            id,
          },
        });
        mutate(SWR_KEY.NOTIFICATION_INITIALIZE_NOTIFICATION_LIST);
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        isReading.current = false;
      }
    },
    [dispatch, mutate],
  );

  return {
    read,
    readAll,
    isValidating: isReading.current,
  };
}

export default useReadNotification;
