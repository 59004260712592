import { CopyIcon, Typo } from "@cochlearai/ui";
import styled, { useTheme } from "styled-components";

import { ANALYTICS_ID_UI_LABELS } from "~/client/lib/constants";
import { AnalyticsEvent } from "~/client/types/analytics";
import { FC } from "react";
import { SensePlatforms } from "~/client/types/sense";
import { copyToClipboard } from "~/client/lib/helpers";
import { getConfigs } from "~/client/lib";
import moment from "moment";

const { REACT_APP_COCHL_WEB_STAGE: stage } = getConfigs();

const EventDetailInfoItemContainer = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 20px;
  }
`;

const EventDetailInfoItemLabelContainer = styled.div`
  width: 110px;
`;

const EventDetailInfoItemValueContainer = styled.div`
  flex: 1;
`;

const SessionUUIDText = styled(Typo)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventDetailContentText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[50]};
`;

const TopInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const CustomCopyIcon = styled(CopyIcon)`
  margin-left: auto;
  cursor: pointer;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
`;

const IOTypeText = styled(Typo)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

type Props = AnalyticsEvent;

const AnalyticsDetailViewOld: FC<Props> = ({
  session_id,
  channel_id,
  platform,
  io_type,
  content_type,
  tag,
  device_id,
  device_name,
  timestamp,
}) => {
  const { colors } = useTheme();
  const targetId =
    platform === SensePlatforms.SDK && channel_id ? channel_id : session_id;
  return (
    <>
      <Typo
        variant="h3"
        style={{
          paddingBottom: "20px",
          borderBottom: `1px solid ${colors.grey[20]}`,
        }}
      >
        Details
      </Typo>
      <EventDetailContentText variant="caption2" style={{ marginTop: "10px" }}>
        {ANALYTICS_ID_UI_LABELS[platform || SensePlatforms.API]}
      </EventDetailContentText>
      <TopInfoContainer>
        <SessionUUIDText variant="body">{targetId}</SessionUUIDText>
        <CustomCopyIcon
          onClick={() => {
            copyToClipboard(targetId);
          }}
        />
      </TopInfoContainer>
      {platform === SensePlatforms.SDK && device_id && (
        <>
          <div
            style={{
              backgroundColor: colors.grey[20],
              height: "1px",
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <EventDetailContentText variant="caption2">
            Device id
          </EventDetailContentText>
          <TopInfoContainer>
            <SessionUUIDText variant="body">{device_id}</SessionUUIDText>
            <CustomCopyIcon
              onClick={() => {
                copyToClipboard(device_id);
              }}
            />
          </TopInfoContainer>
          <EventDetailContentText
            variant="caption2"
            style={{ marginTop: "10px" }}
          >
            Device name
          </EventDetailContentText>
          <TopInfoContainer>
            <SessionUUIDText variant="body">{device_name}</SessionUUIDText>
          </TopInfoContainer>
        </>
      )}
      <div
        style={{
          backgroundColor: colors.grey[20],
          height: "1px",
          width: "100%",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      />
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Tag
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{tag}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            ContentType
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{content_type}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Platform
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <Typo variant="body">{platform?.toUpperCase()}</Typo>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            IOType
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          <IOTypeText variant="body">{io_type}</IOTypeText>
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
      <EventDetailInfoItemContainer>
        <EventDetailInfoItemLabelContainer>
          <EventDetailContentText variant="caption2">
            Date
          </EventDetailContentText>
        </EventDetailInfoItemLabelContainer>
        <EventDetailInfoItemValueContainer>
          {stage !== "test" && (
            <Typo variant="body">
              {moment(new Date(timestamp * 1000)).format("MM.DD.YYYY HH:mm:ss")}
            </Typo>
          )}
        </EventDetailInfoItemValueContainer>
      </EventDetailInfoItemContainer>
    </>
  );
};

export default AnalyticsDetailViewOld;
