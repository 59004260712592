import { ExternalIcon, Typo } from "@cochlearai/ui";
import { FC } from "react";

import { media } from "@cochlearai/util";
import styled from "styled-components";

const DocsContainer = styled.div`
  width: 548px;
  padding: 18px 30px;
  background: ${(p) => p.theme.colors.grey[10]};
  border-radius: 4px;
  margin-top: 10px;
  &:hover {
    background: ${(p) => p.theme.colors.grey[20]};
    & path {
      fill: ${(p) => p.theme.colors.black};
    }
  }

  ${media.query.lg} {
    width: 100%;
  }

  ${media.query.md} {
    width: 100%;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 12px;
`;

interface Props {
  title: string;
  description: string;
  url: string;
}

const DocsLinkButton: FC<Props> = ({ title, description, url }) => {
  return (
    <a href={url ?? ""} target="_blank" rel="noopener">
      <DocsContainer>
        <FlexWrapper>
          <Typo variant="body">{title}</Typo>
          <ExternalIcon style={{ marginLeft: "8px" }} />
        </FlexWrapper>
        <Typo
          variant="caption1"
          style={{ width: "100%", display: "inline-block", textAlign: "left" }}
        >
          {description}
        </Typo>
      </DocsContainer>
    </a>
  );
};

export default DocsLinkButton;
