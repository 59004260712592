import "~/client/lib/ga/init";

import { BaseThemeProvider, BrowserSupport, Helmet } from "~/client/components";
import React, { FC } from "react";

import App from "~/client/components/App";
import { AuthenticatorErrorBoundary } from "~/client/lib";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";
import { createRoot } from "react-dom/client";
import { isBlockedUserAgent } from "~/client/lib/helpers";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";

const AppRoot: FC = () => {
  return (
    <Router>
      <Helmet />
      <Provider store={store}>
        <BaseThemeProvider>
          <AuthenticatorErrorBoundary>
            <SWRConfig>
              {isBlockedUserAgent() ? <BrowserSupport /> : <App />}
            </SWRConfig>
          </AuthenticatorErrorBoundary>
        </BaseThemeProvider>
      </Provider>
    </Router>
  );
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    // <React.StrictMode>
    <AppRoot />,
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
