import Select, { ActionMeta } from "react-select";

import { color } from "@cochlearai/util";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { RoundCloseIcon } from "../Icons";
import { Z_INDEXES } from "../lib/constants";
import { MultiValueLabel } from "./MultiValueLabel";
import { MultiValueRemove } from "./MultiValueRemove";
import { Option } from "./Option";

const MultiSelectContainer = styled.div`
  position: relative;
`;

const MultiSelect = styled(Select)`
  margin-top: 4px;
  width: 100%;
  border: none;
  background: transparent;
`;

const CloseIcon = styled.div<{
  $show?: string;
}>`
  position: absolute;
  right: 20px;
  --height: calc(100%);
  top: calc(var(--height) / 2 - 7px);
  z-index: ${Z_INDEXES.POP_OVER};
  cursor: pointer;
  visibility: ${(p) => (p.$show ? "visible" : "hidden")};
`;

const SelectAllButton = styled.p<{ disabled?: boolean }>`
  cursor: pointer;
  color: ${(p) => p.theme.colors.blue[60]};
  position: absolute;
  right: 0;
  top: -22px;
  z-index: ${Z_INDEXES.POP_OVER};
  pointer-events: ${(p) => (p.disabled ? "none" : "")};
`;

export interface MultiSelectDropdownProps {
  data: Array<unknown>;
  value: Array<unknown>;
  handleChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  disable?: boolean;
  clearAll?: boolean;
  onClearAll?: () => void;
  placeholder?: string;
  style?: CSSProperties;
  onSelectAll?: () => void;
}

/**
 * React component used to create a multi-select dropdown.
 * @param data - Array of options.
 * @param value - Array of selected options.
 * @param placeholder - Placeholder text.
 * @param disable - Indicates whether the dropdown is disabled or not.
 * @param clearAll - Indicates whether the user can clear all selected options.
 * @param onClearAll - Function that will be called when user clicks on the clear all button.
 * @param handleChange - Callback function that will be called when user selects an option.
 * @param onSelectAll - Function that will be called when user clicks on the select all button.
 * @param style - CSS properties.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/multi-select-dropdown--default
 */
export const MultiSelectDropdown = ({
  data,
  value,
  handleChange,
  disable,
  clearAll,
  onClearAll,
  placeholder,
  style,
  onSelectAll,
}: MultiSelectDropdownProps) => {
  const { colors } = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const handleClickOutside = useCallback(
    (ev: Event) => {
      if (disable) {
        if (menuIsOpen) setMenuIsOpen(false);
        return;
      }

      const target = ev.target as HTMLElement;
      const hasAriaLabel = target.getAttribute("aria-label");
      if (hasAriaLabel) return;
      if (ref.current && !ref.current.contains(target)) {
        setMenuIsOpen(false);
      }
    },
    [disable, menuIsOpen],
  );

  const handleClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    ) => {
      const target = e.target as HTMLElement;
      if (
        ["svg", "circle", "path"].indexOf(target.tagName) !== -1 ||
        target.classList.contains("select-all")
      )
        return;
      if (!disable && !menuIsOpen) {
        setMenuIsOpen(true);
      }
    },
    [disable, menuIsOpen],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <MultiSelectContainer
      ref={ref}
      onClick={handleClick}
      onTouchStart={handleClick}
      style={style}
    >
      <MultiSelect
        className="multi-select-dropdown"
        value={value}
        onChange={handleChange}
        options={data}
        styles={{
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            height: "100%",
            color: colors.grey[60],
            border: "none",
            flexWrap: "wrap",
            gap: "6px",
            overflow: "unset",
            "@media (max-width: 960px)": {
              overflow: "auto",
              maxHeight: "50px",
            },
          }),
          control: (base) => ({
            ...base,
            border: `1px solid ${colors.grey[30]}`,
            backgroundColor: "transparent",
            minHeight: "60px",
            borderRadius: "4px",
            padding: "17px 20px",
            paddingRight: "40px",
            boxShadow: "none",
            cursor: "pointer",
            "&:hover": {
              borderColor: colors.grey[80],
              boxShadow: "none",
            },
          }),
          indicatorsContainer: (base) => {
            return {
              ...base,
              "& > div": {
                padding: "0",
              },
            };
          },
          indicatorSeparator: (base) => {
            return {
              ...base,
              display: "none",
            };
          },
          dropdownIndicator: (base) => {
            return {
              ...base,
              display: "none",
            };
          },
          placeholder: (base) => {
            return {
              ...base,
              color: colors.grey[60],
            };
          },
          multiValue: (base, { selectProps }) => ({
            ...base,
            borderRadius: "74px",
            background: selectProps.menuIsOpen
              ? colors.grey[10]
              : color.convertHexToRGB({
                  hex: colors.blue[60],
                  alpha: 0.05,
                }),
            padding: "4.5px 10px",
            display: "flex",
            alignItems: "center",
            margin: 0,
          }),
          multiValueLabel: (base, { selectProps }) => ({
            ...base,
            padding: 0,
            paddingLeft: 0,
            "& p": {
              color: selectProps.menuIsOpen ? colors.black : colors.blue[60],
            },
          }),
          multiValueRemove: (base, { selectProps }) => ({
            ...base,
            display: selectProps.menuIsOpen ? "flex" : "none",
            paddingLeft: 0,
            paddingRight: 0,
            cursor: "pointer",
            marginLeft: "8px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
          menu: (base) => ({
            ...base,
            border: "none !important",
            margin: "4px 0",
            background: colors.white,
            boxShadow: `${color.convertHexToRGB({
              hex: colors.black,
              alpha: 0.3,
            })} 0px 0px 5px`,
            zIndex: Z_INDEXES.MODAL_POP_OVER,
            position: "absolute",
          }),
          menuList: (base) => ({
            ...base,
            display: "grid",
            gridTemplateColumns: "auto auto",
            maxHeight: "196px",
            padding: 0,
            marginTop: "8px",
            marginBottom: "8px",
            marginRight: "2px",
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: color.convertHexToRGB({
                hex: colors.black,
                alpha: 0.4,
              }),
              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: color.convertHexToRGB({
                hex: colors.black,
                alpha: 0.6,
              }),
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: color.convertHexToRGB({
                hex: colors.black,
                alpha: 0.2,
              }),
            },
            "@media (max-width: 960px)": {
              gridTemplateColumns: "none",
            },
          }),
          option: (base) => ({
            ...base,
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
        }}
        placeholder={placeholder || "Select tags"}
        isDisabled={disable}
        menuIsOpen={menuIsOpen}
        components={{
          Option,
          MultiValueLabel,
          MultiValueRemove,
        }}
        isMulti
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        openMenuOnClick={false}
      />
      <CloseIcon onClick={onClearAll} $show={clearAll ? "show" : ""}>
        <RoundCloseIcon />
      </CloseIcon>
      {onSelectAll && (
        <SelectAllButton
          onClick={onSelectAll}
          className="select-all"
          disabled={disable}
        >
          Select all
        </SelectAllButton>
      )}
    </MultiSelectContainer>
  );
};
