import {
  ProjectType,
  PlanType,
  SenseServices,
  ThemeColor,
} from "../types/colors";
import { ThemeType } from "../types/theme";

export const theme: { [key in ThemeType]: ThemeColor } = {
  [ThemeType.LIGHT]: {
    blue: {
      90: "#2D3E99",
      80: "#3C53CC",
      70: "#445EE5",
      60: "#4B68FF",
      50: "#637DFF",
      40: "#7D92FF",
      30: "#96A7FF",
      20: "#B0BDFF",
      10: "#C9D2FF",
    },
    grey: {
      90: "#333333",
      80: "#727273",
      70: "#8C8C8C",
      60: "#A5A5A6",
      50: "#B2B2B2",
      40: "#CBCBCC",
      30: "#E5E5E5",
      20: "#F1F1F2",
      10: "#F9F9FA",
    },
    black: "#131313",
    white: "#FFFFFF",
    red: "#E02E2E",
    red_stroke: "#EDB1B1",
    body: "#FAFAFA",
    shadow: "#989898",
    code: "#272823",
    semantic: {
      grey: {
        10: "#F9F9FA",
        20: "#F1F1F2",
      },
    },
    plan_color: {
      [PlanType.FREE]: "#832BFB",
      [PlanType.STANDARD]: "#4B68FF",
      [PlanType.ENTERPRISE]: "#001974",
    },
    product_type_color: {
      [ProjectType.ALL]: "",
      [ProjectType.CLOUD_API]: "#832BFB",
      [ProjectType.EDGE_SDK]: "#4B68FF",
    },
    service_color: {
      [SenseServices.EMERGENCY_DETECTION]: "#E02E2E",
      [SenseServices.HOME_CONTEXT]: "#FFAC2F",
      [SenseServices.HUMAN_INTERACTION]: "#59B90D",
      [SenseServices.HUMAN_STATUS]: "#D410D8",
      [SenseServices.VEHICLE_MONITOR]: "#2D3E99",
    },
  },
  [ThemeType.DARK]: {
    blue: {
      90: "#2F43AF",
      80: "#3C53CC",
      70: "#445EE5",
      60: "#4057D0",
      50: "#637DFF",
      40: "#7D92FF",
      30: "#96A7FF",
      20: "#B0BDFF",
      10: "#C9D2FF",
    },
    grey: {
      90: "#FFFFFF",
      80: "#73787A",
      70: "#73787A",
      60: "#6A6E70",
      50: "#65696B",
      40: "#545859",
      30: "#303233",
      20: "#242526",
      10: "#171717",
    },
    black: "#FFFFFF",
    white: "#1C1D1E",
    red: "#B54444",
    red_stroke: "#611414",
    body: "#171717",
    shadow: "#131313",
    code: "#131313",
    semantic: {
      grey: {
        10: "#18191A",
        20: "#111112",
      },
    },
    plan_color: {
      [PlanType.FREE]: "#832BFB",
      [PlanType.STANDARD]: "#4B68FF",
      [PlanType.ENTERPRISE]: "#001974",
    },
    product_type_color: {
      [ProjectType.ALL]: "",
      [ProjectType.CLOUD_API]: "#832BFB",
      [ProjectType.EDGE_SDK]: "#4B68FF",
    },
    service_color: {
      [SenseServices.EMERGENCY_DETECTION]: "#B54444",
      [SenseServices.HOME_CONTEXT]: "#D08D29",
      [SenseServices.HUMAN_INTERACTION]: "#4B980E",
      [SenseServices.HUMAN_STATUS]: "#AD11B1",
      [SenseServices.VEHICLE_MONITOR]: "#2F43AF",
    },
  },
};

export const USAGE_COLORS = [
  theme[ThemeType.LIGHT].blue[90],
  theme[ThemeType.LIGHT].blue[80],
  theme[ThemeType.LIGHT].blue[70],
  theme[ThemeType.LIGHT].blue[60],
  theme[ThemeType.LIGHT].blue[50],
  theme[ThemeType.LIGHT].blue[40],
  theme[ThemeType.LIGHT].blue[30],
  theme[ThemeType.LIGHT].blue[20],
  theme[ThemeType.LIGHT].blue[10],
  theme[ThemeType.LIGHT].blue[10] + "E6",
  theme[ThemeType.LIGHT].blue[10] + "CC",
  theme[ThemeType.LIGHT].blue[10] + "B3",
  theme[ThemeType.LIGHT].blue[10] + "99",
  theme[ThemeType.LIGHT].blue[10] + "80",
  theme[ThemeType.LIGHT].blue[10] + "66",
  theme[ThemeType.LIGHT].blue[10] + "4D",
  theme[ThemeType.LIGHT].blue[10] + "33",
  theme[ThemeType.LIGHT].blue[10] + "1A",
];
