import { APIError, TotalUsage } from "~/client/types";
import { useEffect, useState } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { fetchUsages } from "~/client/api";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useSWR from "swr";

interface Props {
  from?: number;
  to?: number;
  organization_id?: string;
}

function useUsage({ from, to, organization_id }: Props) {
  const dispatch = useAppDispatch();
  const [usage, setUsage] = useState<TotalUsage>();

  const { data, error } = useSWR(
    organization_id && from && to
      ? [SWR_KEY.SENSE_API_INITIALIZE_USAGE, { id: organization_id, from, to }]
      : null,
    async (_, params) => {
      if (!params) return;
      const res = await fetchUsages({
        params,
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setUsage(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    usage,
  };
}

export default useUsage;
