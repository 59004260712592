import moment from "moment";

export type TimeValue = {
  from: number;
  to: number;
  month: string;
};

function getBetweenMonths(
  startDate: string | number | Date,
  endDate: string | number | Date,
) {
  const dateStart =
    typeof startDate === "number" ? moment.unix(startDate) : moment(startDate);
  const dateEnd =
    typeof endDate === "number" ? moment.unix(endDate) : moment(endDate);
  const timeValues: TimeValue[] = [];

  while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    const firstDay = moment(dateStart.startOf("month"));
    const lastDay = moment(dateStart.endOf("month"));
    const month = dateStart.format("YYYY-MM");
    timeValues.push({
      month,
      from: firstDay.unix(),
      to: lastDay.unix() + 1,
    });
    dateStart.add(1, "month");
  }

  return timeValues;
}

export default getBetweenMonths;
