import React, { FC, SVGProps } from "react";

const MinusIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width="12" height="1" fill="white" />
  </svg>
);

export default MinusIcon;
