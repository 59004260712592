import React, { FC } from "react";
import { PageRoute } from "~/client/types";
import { PageContentErrorBoundary } from "~/client/lib";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";

interface Props {
  pageData: PageRoute;
  pageProps: RouteComponentProps<
    | {
        //
      }
    | {
        projectId: string;
      },
    StaticContext,
    unknown
  >;
}

const PageRenderer: FC<Props> = ({ pageData, pageProps }) => {
  const { component: Component } = pageData;

  if (!Component) {
    return null;
  }

  return (
    <PageContentErrorBoundary>
      <Component {...pageProps} />
    </PageContentErrorBoundary>
  );
};

export default PageRenderer;
