/* eslint-disable no-case-declarations */
import { PAGE_ROUTE_PATH_NAME, PageRoute } from "~/client/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import routes from "~/client/lib/routes";

export interface PageRouteState {
  current: PageRoute | null;
  hostRoute: PageRoute | null;
  subRoutes: PageRoute[];
}

const initialState: PageRouteState = {
  current: null,
  hostRoute: null,
  subRoutes: [],
};

const pageRouteSlice = createSlice({
  name: "pageRoute",
  initialState,
  reducers: {
    changeCurrentRoute: (state, action: PayloadAction<PageRoute | null>) => {
      state.current = action.payload;
      if (action.payload) {
        let hostRoutePathname: PAGE_ROUTE_PATH_NAME | null = null;
        switch (action.payload.pathname) {
          case PAGE_ROUTE_PATH_NAME.PROJECT:
          case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL:
          case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS:
          case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS_GENERAL:
          case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS_ADVANCED:
            hostRoutePathname = PAGE_ROUTE_PATH_NAME.PROJECT;
            break;
          case PAGE_ROUTE_PATH_NAME.USAGE:
            hostRoutePathname = PAGE_ROUTE_PATH_NAME.USAGE;
            break;
          case PAGE_ROUTE_PATH_NAME.BILLING:
          case PAGE_ROUTE_PATH_NAME.BILLING_INFO:
          case PAGE_ROUTE_PATH_NAME.BILLING_INVOICES:
            hostRoutePathname = PAGE_ROUTE_PATH_NAME.BILLING;
            break;
          default:
            break;
        }
        const hostRoute = routes.find(
          (item) => item.pathname === hostRoutePathname,
        );
        if (hostRoute) {
          state.hostRoute = {
            title: hostRoute.title,
            pathname: hostRoute.pathname,
            exact: hostRoute.exact,
          };
        }
      } else {
        state.hostRoute = null;
      }
    },
  },
});

export const { changeCurrentRoute } = pageRouteSlice.actions;

export const { reducer: pageRouteReducer } = pageRouteSlice;
