import { useEffect } from "react";
import useSWR from "swr";
import { fetchGetProjectSDKUsage } from "~/client/api";
import { Project, ProjectType } from "~/client/types/project";
import { APIError } from "~/client/types";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";

function useProjectSdkUsage({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const dispatch = useAppDispatch();
  const { data, error, isValidating } = useSWR(
    currentProject?.product_type === ProjectType.EDGE_SDK
      ? [
          SWR_KEY.SENSE_SDK_INITIALIZE_PROJECT_USAGE,
          { project_id: currentProject.id },
        ]
      : null,
    async (_, params) => {
      const res = await fetchGetProjectSDKUsage({
        params,
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    sdkUsage: data,
    isValidating,
  };
}

export default useProjectSdkUsage;
