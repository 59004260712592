import "react-loading-skeleton/dist/skeleton.css";
import "~/client/lib/helpers/initializeMoment";
import "~/client/lib/i18n";

import { ErrorToast, InfoToast, PageTemplate } from "~/client/components";
import { FC, Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useAlert, useCopyClipboard, usePageReload } from "~/client/hooks";

import { FullCenterLoading } from "./FullCenterLoading";
import { IMAGE_PRELOAD } from "../lib/constants";
import { PageRenderer } from "~/client/lib";
import routes from "~/client/lib/routes";

const App: FC = () => {
  useCopyClipboard();
  usePageReload();

  const {
    clientError,
    serverError,
    errorMessage,
    clientInfo,
    initializeError,
    initializeInfo,
  } = useAlert();

  useEffect(() => {
    IMAGE_PRELOAD.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return (
    <>
      <PageTemplate>
        <Switch>
          {routes.map((route) => {
            return (
              <Route
                key={route.pathname}
                exact={route.exact}
                path={route.pathname}
                render={(props) => {
                  return (
                    <Suspense fallback={<FullCenterLoading dim={false} />}>
                      <PageRenderer pageData={route} pageProps={props} />
                    </Suspense>
                  );
                }}
              />
            );
          })}
        </Switch>
      </PageTemplate>
      <ErrorToast
        open={clientError !== null || serverError !== null}
        message={errorMessage}
        onClose={initializeError}
      />
      <InfoToast
        open={clientInfo !== null}
        message={clientInfo?.text}
        onClose={initializeInfo}
        onClick={clientInfo?.onClick}
        buttonText={clientInfo?.buttonText}
      />
    </>
  );
};

export default App;
