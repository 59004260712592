import { fetchCreateTrackingGroup } from "~/client/api/analytics";
import { useCallback, useEffect, useState } from "react";
import {
  setClientInfo,
  setLoading,
  setServerError,
} from "~/client/store/modules";
import {
  useAppDispatch,
  useAppSelector,
  useProjectList,
  useSubscription,
} from "~/client/hooks";

import { APIError } from "~/client/types";
import { PROJECT_NAME_LIMIT } from "~/client/lib/constants";
import { ProjectType } from "~/client/types/project";
import { SDKTag } from "~/client/types/sense";
import { fetchCreateProject } from "~/client/api/project";
import { useHistory } from "react-router-dom";
import { useSWRConfig } from "swr";
import { SWR_KEY } from "~/client/types/swrKey";
import { selectedProjectState } from "~/client/lib";

interface Validation {
  validating: boolean;
  validated: boolean;
  error: string;
}

const initialValidation: Validation = {
  validating: false,
  validated: false,
  error: "",
};

function useCreateProject() {
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectedProjectState);
  const history = useHistory();
  const { projectList } = useProjectList();
  const { currentSubscription } = useSubscription();
  const [validation, setValidation] = useState<Validation>(initialValidation);
  const [name, setName] = useState<string>("");
  const [projectType, setProjectType] = useState<ProjectType>(
    ProjectType.CLOUD_API,
  );

  const [tags, setTags] = useState<SDKTag[]>([]);
  const [tagPrice, setTagPrice] = useState<number>(0);

  const validate = useCallback(() => {
    const onlyText = name.split(" ").join("");
    if (onlyText === "") {
      setValidation({
        validating: name.length > 0,
        validated: false,
        error: `Input at least 1 characters, with a maximum of ${PROJECT_NAME_LIMIT} characters`,
      });
      return;
    }

    if (projectList?.find((project) => project.name === name)) {
      setValidation({
        validating: name.length > 0,
        validated: false,
        error: "This name is already taken",
      });
      return;
    }

    setValidation({
      validating: true,
      validated: true,
      error: "",
    });
  }, [name, projectList]);

  const create = useCallback(async () => {
    if (isLoading) return;
    if (!validation.validating) {
      setValidation({
        ...validation,
        validating: true,
      });
    }
    if (
      !validation.validated ||
      !projectType ||
      (projectType === ProjectType.EDGE_SDK && tags.length === 0)
    )
      return;

    dispatch(setLoading(true));
    try {
      const res = await fetchCreateProject({
        params: {
          name: name.trim(),
          product_type: projectType,
          tags: tags.map((tag) => tag.value),
        },
      });
      const { data } = res;

      if (projectType === ProjectType.EDGE_SDK) {
        await fetchCreateTrackingGroup({
          params: {
            project_id: data.id,
            tags: tags.map((tag) => tag.value),
          },
        });
      }

      dispatch(
        setClientInfo({
          text: "Project has been created",
          buttonText: "View project",
          onClick: () => {
            dispatch(setClientInfo(null));
            history.push(`/project/${data.id}`);
          },
        }),
      );
      mutate(SWR_KEY.PROJECT_LIST_INITIALIZE);
      setValidation(initialValidation);
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      dispatch(setLoading(false));
    }
  }, [
    isLoading,
    validation,
    projectType,
    tags,
    dispatch,
    name,
    mutate,
    history,
  ]);

  useEffect(() => {
    validate();
  }, [name, validate]);

  useEffect(() => {
    const tagPrice = currentSubscription.prices?.sdk.tag_unit_price ?? 0;
    const basePrice = currentSubscription.prices?.sdk.base_price ?? 0;

    setTagPrice(tags.length > 0 ? tagPrice * tags.length + basePrice : 0);
  }, [tags, currentSubscription]);

  return {
    create,
    name,
    setName,
    validation,
    setValidation,
    projectType,
    setProjectType,
    tags,
    setTags,
    tagPrice,
  };
}

export default useCreateProject;
