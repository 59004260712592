import { AppDispatch, RootState } from "../../redux/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export * from "./error";
export { default as useCurrentProject } from "./useCurrentProject";
export { default as useCreateProject } from "./useCreateProject";
export { default as useCreateSubscription } from "./useCreateSubscription";
export { default as useProjectList } from "./useProjectList";
export { default as useUpdateProjectName } from "./useUpdateProjectName";
export { default as useResetProjectKey } from "./useResetProjectKey";
export { default as useRemoveProject } from "./useRemoveProject";
export { default as useSdkChannelList } from "./useSdkChannelList";
export { default as useDeleteSdkChannel } from "./useDeleteSdkChannel";
export { default as useSdkQuota } from "./useSdkQuota";
export { default as useSdkTags } from "./useSdkTags";
export { default as usePricePlans } from "./usePricePlans";
export { default as useSubscription } from "./useSubscription";
export { default as useCreatePaymentMethod } from "./useCreatePaymentMethod";
export { default as useDeletePaymentMethod } from "./useDeletePaymentMethod";
export { default as useUpdatePaymentMethod } from "./useUpdatePaymentMethod";
export { default as usePaymentMethodList } from "./usePaymentMethodList";
export { default as useInvoiceList } from "./useInvoiceList";
export { default as useUpcomingInvoice } from "./useUpcomingInvoice";
export { default as useAnalyticsHeatmap } from "./useAnalyticsHeatmap";
export { default as useAnalyticsTimeseries } from "./useAnalyticsTimeseries";
export { default as useAnalyticsTopTag } from "./useAnalyticsTopTag";
export { default as useAnalyticsTopTagBreakdown } from "./useAnalyticsTopTagBreakdown";
export { default as useAnalyticsTrackingGroup } from "./useAnalyticsTrackingGroup";
export { default as useAnalyticsFilter } from "./useAnalyticsFilter";
export { default as useTheme } from "./useTheme";
export { default as useSideBarAnimation } from "./useSideBarAnimation";
export { default as useReactDates } from "./useReactDates";
export { default as useCopyClipboard } from "./useCopyClipboard";
export { default as useResponsiveSize } from "./useResponsiveSize";
export { default as usePageRoute } from "./usePageRoute";
export { default as useQuery } from "./useQuery";
export { default as useAlert } from "./useAlert";
export { default as useProjectTags } from "./useProjectTags";
export { default as useOrganization } from "./useOrganization";
export { default as useOrganizationMember } from "./useOrganizationMember";
export { default as useAddMember } from "./useAddMember";
export { default as useUsage } from "./useUsage";
export { default as useProjectAPIUsage } from "./useProjectAPIUsage";
export { default as useProjectSdkUsage } from "./useProjectSdkUsage";
export { default as useRemoveMember } from "./useRemoveMember";
export { default as useCancelInvitation } from "./useCancelInvitation";
export { default as useLeaveOrganization } from "./useLeaveOrganization";
export { default as useReinviteMember } from "./useReinviteMember";
export { default as usePageReload } from "./usePageReload";
export { default as useReadNotification } from "./useReadNotification";
export { default as useUpdateDeviceName } from "./useUpdateDeviceName";
export { default as useCanvas } from "./useCanvas";
export { default as useAudio } from "./useAudio";
export { default as useUpload } from "./useUpload";
export { default as useUploadHistory } from "./useUploadHistory";
export { default as useWebhookList } from "./useWebhookList";
export { default as useWebhookHistory } from "./useWebhookHistory";
export { default as useEmailList } from "./useEmailList";
export { default as useCreateWebhookNC } from "./useCreateWebhookNC";
export { default as useCreateEmailNC } from "./useCreateEmailNC";
export { default as useTriggerCondition } from "./useTriggerCondition";
export { default as useUpdateMedium } from "./useUpdateMedium";
export { default as useRemoveMedium } from "./useRemoveMedium";
export { default as useMultiDropdown } from "./useMultiDropdown";
export { default as useSearch } from "./useSearch";
export { default as useAnalyticsEventList } from "./useAnalyticsEventList";
export { default as useAnalyticsParameters } from "./useAnalyticsParameters";
export { default as useReactDatesOld } from "./useReactDatesOld";
export { default as useSurveyQuestion } from "./useSurveyQuestion";
export { default as useSurveyCompleted } from "./useSurveyCompleted";
