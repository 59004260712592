import { FC } from "react";

import { Button, FilterIcon, Typo } from "@cochlearai/ui";
import styled, { useTheme } from "styled-components";

const Container = styled(Button)<{ selected?: boolean }>`
  border-color: ${(p) =>
    p.selected
      ? (p) => p.theme.colors.grey[80]
      : (p) => p.theme.colors.grey[30]};
  height: 30px;
  padding: 6px 8px;
  &:hover {
    border-color: ${(p) => p.theme.colors.grey[80]};
  }
`;

interface Props {
  selected?: boolean;
  text?: string;
  onClick: () => void;
}

const FilterButton: FC<Props> = ({ selected, text, onClick }) => {
  const { colors } = useTheme();
  return (
    <Container
      selected={selected}
      color="secondary"
      onClick={onClick}
      leftIcon={
        <FilterIcon
          width={14}
          height={14}
          onClick={onClick}
          fill={colors.black}
        />
      }
    >
      {text && (
        <Typo
          variant="caption1"
          style={{ marginLeft: "8px", color: colors.black }}
        >
          {text}
        </Typo>
      )}
    </Container>
  );
};

export default FilterButton;
