import { Button, Chip, Typo } from "@cochlearai/ui";
import { FC, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import {
  AnalyticsFilterItems,
  FilterItemUI,
  FilterParamsKeys,
  FilterParamsWithCheckedOld,
} from "~/client/types/analytics";

import { color } from "@cochlearai/util";
import { getOfficialSenseValue } from "~/client/lib/helpers";

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const FilterCategoryButton = styled(Button)<{
  selected: boolean;
}>`
  height: 30px;
  border-color: ${(p) =>
    p.selected ? p.theme.colors.grey[80] : p.theme.colors.grey[30]};
  & + & {
    margin-left: 7px;
  }
  &:hover {
    border-color: ${(p) => p.theme.colors.grey[80]};
  }
`;

const FilterValueListPositionContainer = styled.div`
  height: min-content;
`;

const FilterValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FilterChip = styled(Chip)`
  margin-top: 8px;
  margin-right: 8px;
  cursor: pointer;
  height: 24px;
`;

const SetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  margin-top: 40px;
`;

interface Props {
  filterItems: AnalyticsFilterItems;
  selectedFilterCategory: keyof FilterParamsWithCheckedOld;
  filterParamsWithChecked: FilterParamsWithCheckedOld | null;
  onClickFilterCategoryItem?: (item: FilterItemUI) => void;
  onClickFilterValueItem?: ({
    section,
    value,
  }: {
    section: keyof FilterParamsWithCheckedOld;
    value: string;
  }) => void;
  onClickClearAll?: () => void;
  onClickConfirm?: () => void;
  disabledFilterParams?: FilterParamsKeys[];
}

const AnalyticsFilterViewOld: FC<Props> = ({
  filterItems,
  selectedFilterCategory,
  filterParamsWithChecked,
  onClickFilterCategoryItem,
  onClickFilterValueItem,
  onClickClearAll,
  onClickConfirm,
  disabledFilterParams,
}) => {
  const { colors } = useTheme();
  const [prevFilterParamsWithCheckedOld, setPrevFilterParamsWithCheckedOld] =
    useState<FilterParamsWithCheckedOld | null>(null);

  const confirmButtonDisabled =
    JSON.stringify(prevFilterParamsWithCheckedOld) ===
    JSON.stringify(filterParamsWithChecked);

  useEffect(() => {
    if (prevFilterParamsWithCheckedOld === null) {
      setPrevFilterParamsWithCheckedOld(filterParamsWithChecked);
    }
    if (filterParamsWithChecked !== null) {
      const allHasBeenUnchecked =
        typeof Object.keys(filterParamsWithChecked)
          .flatMap((keyItem) => {
            return filterParamsWithChecked[
              keyItem as keyof FilterParamsWithCheckedOld
            ]?.map((item) => item.checked);
          })
          .find((item) => item === true) === "undefined";
      if (allHasBeenUnchecked) {
        setPrevFilterParamsWithCheckedOld(filterParamsWithChecked);
      }
    }
  }, [filterParamsWithChecked]);

  return (
    <>
      <Typo variant="h3">Filter</Typo>
      <Typo
        variant="caption1"
        style={{
          marginTop: "32px",
          fontWeight: 600,
          color: colors.grey[50],
        }}
      >
        Category
      </Typo>
      <TopContainer>
        {filterItems?.map((item, index) => {
          return (
            <FilterCategoryButton
              selected={item.value === selectedFilterCategory}
              color="secondary"
              key={`${item.value}-${index}`}
              disabled={
                disabledFilterParams &&
                disabledFilterParams.includes(item.value as FilterParamsKeys)
              }
              onClick={() =>
                onClickFilterCategoryItem && onClickFilterCategoryItem(item)
              }
            >
              <Typo
                variant="caption1"
                style={{
                  color: colors.black,
                }}
              >
                {item.label}
              </Typo>
            </FilterCategoryButton>
          );
        })}
      </TopContainer>
      <Typo
        variant="caption1"
        style={{
          marginTop: "30px",
          fontWeight: 600,
          color: colors.grey[50],
          marginBottom: "4px",
        }}
      >
        Value
      </Typo>
      <FilterValueListPositionContainer>
        <FilterValueContainer>
          {filterParamsWithChecked &&
            !disabledFilterParams?.includes(selectedFilterCategory) &&
            filterParamsWithChecked[selectedFilterCategory]?.map(
              (item, index) => {
                return (
                  <FilterChip
                    key={`${item.value}-${index}`}
                    style={{
                      color: item.checked ? colors.blue[50] : undefined,
                      background: !item.checked ? colors.grey[10] : undefined,
                    }}
                    onClick={() =>
                      onClickFilterValueItem &&
                      onClickFilterValueItem({
                        section: selectedFilterCategory,
                        value: item.value,
                      })
                    }
                  >
                    {getOfficialSenseValue({
                      value: item.value,
                      type: selectedFilterCategory,
                    })}
                  </FilterChip>
                );
              },
            )}
        </FilterValueContainer>
      </FilterValueListPositionContainer>
      <SetButtonContainer>
        <Typo
          variant="button"
          style={{
            color: confirmButtonDisabled
              ? color.convertHexToRGB({
                  hex: colors.grey[80],
                  alpha: 0.4,
                })
              : colors.blue[60],
            cursor: confirmButtonDisabled ? "not-allowed" : "pointer",
            marginLeft: "16px",
          }}
          onClick={() => {
            if (confirmButtonDisabled) {
              return;
            }
            onClickConfirm && onClickConfirm();
            setPrevFilterParamsWithCheckedOld(filterParamsWithChecked);
          }}
        >
          Confirm
        </Typo>
        <Typo
          variant="button"
          style={{
            color: colors.grey[80],
            cursor: "pointer",
          }}
          onClick={() => {
            if (onClickClearAll) {
              onClickClearAll();
            }
          }}
        >
          Clear all
        </Typo>
      </SetButtonContainer>
    </>
  );
};

export default AnalyticsFilterViewOld;
