function numberWithCommas({
  value,
  currency,
  decimal,
  maxDecimal,
}: {
  value: string | number;
  currency?: string;
  decimal?: number;
  maxDecimal?: number;
}) {
  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: decimal || 0,
    maximumFractionDigits: maxDecimal || 20,
    ...(currency && {
      style: "currency",
      currency: currency.toUpperCase() || "USD",
    }),
  });
}

export default numberWithCommas;
