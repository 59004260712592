import React, { FC, SVGProps } from "react";

const InputCancelIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill="#F1F1F2" />
    <path
      d="M13.2727 6L13.9999 6.72726L6.72731 13.9999L6.00005 13.2726L13.2727 6Z"
      fill="#727273"
    />
    <path
      d="M13.9999 13.2727L13.2726 14L6 6.7274L6.72726 6.00014L13.9999 13.2727Z"
      fill="#727273"
    />
  </svg>
);

export default InputCancelIcon;
