import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchGetNotiConfigList } from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";
import { APIError, MediumType, NotificationConfig } from "~/client/types";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";
import { useParams } from "react-router-dom";

function useEmailList() {
  const params = useParams<{ projectId?: string }>();
  const dispatch = useAppDispatch();
  const [emailList, setEmailList] = useState<NotificationConfig[]>([]);

  const { data, error, isValidating } = useSWR(
    params.projectId ? SWR_KEY.POST_ACTION_INITIALIZE_EMAIL_LIST : null,
    async () => {
      const res = await fetchGetNotiConfigList({
        params: {
          project_id: params.projectId!,
          medium_type: MediumType.EMAIL,
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setEmailList(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    emailList,
    isValidating,
  };
}

export default useEmailList;
