import {
  DayPickerRangeControllerInputs,
  DayPickerRangeControllerPopover,
  FocusedInputShape,
} from "@cochlearai/calendar";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  Dropdown,
  DropdownButton,
  DropdownItem,
  Typo,
} from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch } from "~/client/hooks";
import useReactDates from "~/client/hooks/useReactDates";
import {
  checkDateLimit,
  getBetweenYear,
  getConfigs,
  getDateRangeByScale,
} from "~/client/lib";
import {
  ANALYTICS_DATE_FILTER_TYPE,
  ANALYTICS_DATE_FILTER_TYPE_HOUR,
  Z_INDEXES,
} from "~/client/lib/constants";
import { setClientInfo } from "~/client/store/modules";
import {
  AnalyticsType,
  FilterParams,
  FilterType,
} from "~/client/types/analytics";

const { REACT_APP_COCHL_WEB_STAGE: stage } = getConfigs();

const DateDropdownButton = styled(DropdownButton)<{ selected: boolean }>`
  width: 100%;
  justify-content: start;
  border-color: ${(p) =>
    p.selected
      ? (p) => p.theme.colors.grey[80]
      : (p) => p.theme.colors.grey[30]};

  ${media.query.md} {
    padding-left: 5px;
  }
`;

const DayContent = styled.div<{ highlighted?: boolean }>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 32px;
    z-index: 1;
    border-radius: ${(p) => (p.highlighted ? "50%" : "inherit")};
    background-color: ${(p) =>
      p.highlighted
        ? (p) => p.theme.colors.blue[60] + " !important"
        : "inherit"};
  }
`;

const DateBadge = styled.div`
  width: 41px;
  min-width: 41px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(p) => p.theme.colors.grey[30]};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.grey[80]};
  font-size: 10px;
  line-height: 15px;
  margin-right: 8px;
`;

const DateText = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
  white-space: nowrap;
`;

interface Props {
  type: AnalyticsType;
  filters: FilterParams;
  onClickFilter: (value: { from: string; to: string; badge?: string }) => void;
}

const AnalyticsDatePicker: FC<Props> = ({ type, filters, onClickFilter }) => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const {
    calendarDatesUI,
    setCalendarDatesUI,
    calendarInputValidation,
    updateCalendarInputValidation,
    inputValues,
    onStartDateInputChange,
    onStartDateInputBlur,
    onEndDateInputChange,
    onEndDateInputBlur,
  } = useReactDates();
  const fromTo = useMemo(
    () => filters[FilterType.FROM_TO] ?? { from: 0, to: 0, badge: "1m" },
    [filters],
  );
  const scale = useMemo(
    () =>
      filters[FilterType.SCALE]?.find((item) => item.checked)?.value ?? "day",
    [filters],
  );
  const [dateRangeInfo, setDateRangeInfo] = useState<{
    title?: string;
    values?: { value: string; disabled?: boolean }[];
    selected?: string;
  }>({ title: "", values: [], selected: "" });
  const [dateLimit, setDateLimit] = useState<{
    min: number;
    max: number;
    unit: "days" | "months";
  }>({
    min: 0,
    max: 0,
    unit: "days",
  });
  const [popoverOpenInfo, setPopoverOpenInfo] = useState<string | null>();
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    "startDate",
  );

  const onDatesChange = useCallback(
    ({ startDate, endDate }) => {
      if (
        startDate &&
        endDate &&
        !startDate.isSame(endDate) &&
        !checkDateLimit(startDate, endDate, dateLimit)
      ) {
        dispatch(
          setClientInfo({
            text: `You cannot choose longer than ${
              dateLimit.unit === "months"
                ? `${dateLimit.max} ${dateLimit.unit}`
                : "1 week"
            }.`,
          }),
        );
        return;
      }

      setCalendarDatesUI({
        startDate: moment(startDate).startOf("day"),
        endDate: moment(endDate ?? startDate).endOf("day"),
      });
    },
    [dateLimit, dispatch, setCalendarDatesUI],
  );

  const onClickDateArrow = useCallback(
    (dir: string) => {
      if (!dateRangeInfo?.title) return;
      const targetDate = +dateRangeInfo?.title + (dir === "prev" ? -1 : +1);
      const today = new Date();
      const currentYear = today.getFullYear();
      if (
        (dir === "prev" && targetDate < currentYear - 11) ||
        (dir === "next" && targetDate > currentYear)
      )
        return;
      const currentDate = moment(fromTo.from);

      setDateRangeInfo({
        ...dateRangeInfo,
        title: targetDate.toString(),
        values: moment.monthsShort().map((month) => ({
          value: month,
          disabled: moment(`${targetDate.toString()}-${month}`).isAfter(today)
            ? true
            : false,
        })),
        selected:
          targetDate === +currentDate.format("YYYY")
            ? currentDate.format("MMM")
            : "",
      });
    },
    [dateRangeInfo, fromTo.from],
  );

  useEffect(() => {
    const { startDate, endDate } = calendarDatesUI;
    updateCalendarInputValidation({
      startDate: moment(startDate).isValid() || startDate === null,
      endDate: moment(endDate).isValid() || endDate === null,
    });
  }, [calendarDatesUI]);

  useEffect(() => {
    if (fromTo) {
      setDateLimit(
        scale === "hour"
          ? {
              min: type !== AnalyticsType.TAG_FREQUENCY ? 0 : 6,
              max: 6,
              unit: "days",
            }
          : { min: 0, max: 6, unit: "months" },
      );
      setCalendarDatesUI({
        startDate: moment(fromTo.from),
        endDate: moment(fromTo.to),
      });
    }

    if (type === AnalyticsType.TAG_FREQUENCY) {
      let title = "",
        values,
        selected;
      const today = new Date();
      const targetMoment = moment(fromTo.from);
      if (scale === "day") {
        title = targetMoment.format("YYYY");
        values = moment.monthsShort().map((month) => ({
          value: month,
          disabled: moment(`${title}-${month}`).isAfter(today) ? true : false,
        }));
        selected = targetMoment.format("MMM");
      } else if (scale === "month") {
        values = getBetweenYear(new Date("2012"), today).map((year) => ({
          value: year,
        }));
        title = `${values[0].value} - ${values[values.length - 1].value}`;
        selected = targetMoment.format("YYYY");
      }

      if (title && values && selected) {
        setDateRangeInfo({
          title,
          values,
          selected,
        });
      }
    }
  }, [scale, fromTo, setCalendarDatesUI, type]);

  return (
    <DayPickerRangeControllerPopover
      showCalendar={scale === "hour" || type !== AnalyticsType.TAG_FREQUENCY}
      popover={{
        isOpen: popoverOpenInfo === "calendar",
        positions: ["bottom"],
        align: "end",
        containerStyle: {
          zIndex: `${Z_INDEXES.POP_OVER}`,
        },
        onClickOutside: () => {
          setPopoverOpenInfo(null);
          if (calendarDatesUI) {
            const { startDate, endDate } = calendarDatesUI;
            if (startDate && endDate) {
              onClickFilter({
                from: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
                to: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
                badge:
                  type !== AnalyticsType.TAG_FREQUENCY
                    ? "calendar"
                    : fromTo.badge,
              });
            }
          }
        },
      }}
      monthYearProps={{
        ...dateRangeInfo,
        onClickPrev:
          scale !== "month" ? () => onClickDateArrow("prev") : undefined,
        onClickNext:
          scale !== "month" ? () => onClickDateArrow("next") : undefined,
        onClickItem: (target: string) => {
          const { startDate, endDate, dateBadge } = getDateRangeByScale(
            scale,
            scale === "day" ? `${dateRangeInfo.title}-${target}` : target,
          );
          if (startDate && endDate) {
            onClickFilter({
              from: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
              to: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
              badge: dateBadge,
            });
            setDateRangeInfo({
              ...dateRangeInfo,
              selected: target,
            });
          }
          setPopoverOpenInfo(null);
        },
      }}
      calendar={{
        isOutsideRange: (day) => day.isAfter(moment().endOf("day")),
        enableOutsideDays: false,
        weekDayFormat: "ddd",
        ...(type === AnalyticsType.TAG_FREQUENCY && {
          startDateOffset: (day) => day.subtract(6, "day"),
        }),
        renderWeekHeaderElement: (day) => <small>{day.toUpperCase()}</small>,
        startDate: calendarDatesUI.startDate,
        endDate: calendarDatesUI.endDate,
        onDatesChange,
        focusedInput: focusedInput,
        onFocusChange: (focusedInput) => {
          setFocusedInput(!focusedInput ? "startDate" : focusedInput);
        },
        numberOfMonths: 1,
        daySize: 50,
        initialVisibleMonth: null,
        hideKeyboardShortcutsPanel: true,
        noBorder: true,
        navPrev: (
          <ArrowLeftIcon
            width={12}
            height={12}
            fill={colors.grey[70]}
            style={{
              position: "absolute",
              left: "40px",
              top: "30px",
            }}
          />
        ),
        navNext: (
          <ArrowRightIcon
            width={12}
            height={12}
            fill={colors.grey[70]}
            style={{
              position: "absolute",
              right: "40px",
              top: "30px",
            }}
          />
        ),
        renderDayContents: (day) => {
          const highlighted =
            ((calendarDatesUI.startDate &&
              day.isSame(calendarDatesUI.startDate, "day")) ||
              (calendarDatesUI.endDate &&
                day.isSame(calendarDatesUI.endDate, "day"))) ??
            false;
          return (
            <DayContent highlighted={highlighted}>
              <div>{day.toDate().getDate()}</div>
            </DayContent>
          );
        },
        renderInputComponents: () => {
          return (
            <DayPickerRangeControllerInputs
              startDateInvalid={!calendarInputValidation.startDate}
              endDateInvalid={!calendarInputValidation.endDate}
              inputValues={inputValues}
              onStartDateInputBlur={() => onStartDateInputBlur(dateLimit)}
              onStartDateInputChange={onStartDateInputChange}
              onEndDateInputBlur={() => onEndDateInputBlur(dateLimit)}
              onEndDateInputChange={onEndDateInputChange}
            />
          );
        },
      }}
    >
      <div>
        <Dropdown
          containerStyle={{
            maxHeight: "unset",
          }}
          isOpen={
            type !== AnalyticsType.TAG_FREQUENCY && popoverOpenInfo === "list"
          }
          data={
            scale === "hour"
              ? ANALYTICS_DATE_FILTER_TYPE_HOUR
              : ANALYTICS_DATE_FILTER_TYPE
          }
          onClickOutside={() => setPopoverOpenInfo(null)}
          renderItem={({ value, dateBadge, label }) => {
            return (
              <DropdownItem
                key={value}
                onClick={(e) => {
                  e.stopPropagation();
                  let startDate, endDate;
                  const currentDate = new Date();
                  if (value === "calendar") {
                    setPopoverOpenInfo("calendar");
                  } else {
                    startDate = moment(currentDate).subtract(
                      value,
                      dateBadge.includes("m") ? "months" : "seconds",
                    );
                    endDate = moment(currentDate);

                    if (!dateBadge.includes("h")) {
                      startDate = (
                        dateBadge === "1d" ? moment(currentDate) : startDate
                      ).startOf("day");
                      endDate = endDate.endOf("day");
                    }
                    onClickFilter({
                      to: endDate.format("YYYY-MM-DDTHH:mm:ssZ"),
                      from: startDate.format("YYYY-MM-DDTHH:mm:ssZ"),
                      badge: dateBadge,
                    });
                    setPopoverOpenInfo(null);
                  }
                }}
              >
                <DateBadge>
                  {dateBadge === "calendar" ? (
                    <CalendarIcon
                      width={14}
                      height={14}
                      fill={colors.grey[80]}
                    />
                  ) : (
                    dateBadge
                  )}
                </DateBadge>
                <Typo variant="body">{label}</Typo>
              </DropdownItem>
            );
          }}
        >
          <DateDropdownButton
            onClick={() => {
              setPopoverOpenInfo(
                !popoverOpenInfo
                  ? type !== AnalyticsType.TAG_FREQUENCY
                    ? "list"
                    : "calendar"
                  : null,
              );
            }}
            selected={!!(fromTo.from && fromTo.to)}
          >
            <DateBadge>
              {fromTo.badge === "calendar" ? (
                <CalendarIcon width={14} height={14} fill={colors.grey[80]} />
              ) : (
                fromTo.badge
              )}
            </DateBadge>
            {stage !== "test" && (
              <DateText variant="caption1">
                {moment(fromTo.from).format("MMM DD, YYYY, h:mma")}
                {" - "}
                {moment(fromTo.to).format("MMM DD, YYYY, h:mma")}
              </DateText>
            )}
          </DateDropdownButton>
        </Dropdown>
      </div>
    </DayPickerRangeControllerPopover>
  );
};

export default AnalyticsDatePicker;
