import React, { FC, SVGProps } from "react";

const SRIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill={props.fill ?? "#727273"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.11771 0V1.87188L2.62187 1.87187V4.36771H0.75V1.06366e-06L5.11771 0Z" />
    <path d="M23.8781 4.36771V1.87188L21.3823 1.87188V8.72744e-07L25.75 0V4.36771H23.8781Z" />
    <path d="M0.750102 24.9581H4.88902C5.01538 24.9581 5.11781 24.8557 5.11781 24.7293V23.315C5.11781 23.1887 5.01538 23.0862 4.88903 23.0862L2.62187 23.0862V20.8192C2.62187 20.6928 2.51944 20.5904 2.39309 20.5904H0.978785C0.85243 20.5904 0.75 20.6928 0.75 20.8192L0.750102 24.9581Z" />
    <path d="M21.8399 24.9581C21.5872 24.9581 21.3823 24.7532 21.3823 24.5005V23.5438C21.3823 23.2911 21.5872 23.0862 21.8399 23.0862L23.8781 23.0862V21.048C23.8781 20.7953 24.083 20.5904 24.3357 20.5904H25.2924C25.5451 20.5904 25.75 20.7953 25.75 21.048L25.75 24.9581H21.8399Z" />
    <path d="M10.6086 8.83931C10.6086 8.43727 10.9345 8.11136 11.3365 8.11136C11.7385 8.11136 12.0645 8.43727 12.0645 8.83931V16.0776C12.0645 16.4796 11.7385 16.8055 11.3365 16.8055C10.9345 16.8055 10.6086 16.4796 10.6086 16.0775V8.83931Z" />
    <path d="M8.73669 11.3233C8.73669 10.9213 9.0626 10.5954 9.46464 10.5954C9.86667 10.5954 10.1926 10.9213 10.1926 11.3233V13.5935C10.1926 13.9955 9.86667 14.3215 9.46464 14.3215C9.0626 14.3215 8.73669 13.9955 8.73669 13.5935V11.3233Z" />
    <path d="M13.2084 8.93937C12.8064 8.93937 12.4804 9.26529 12.4804 9.66732V15.2495C12.4804 15.6516 12.8064 15.9775 13.2084 15.9775C13.6104 15.9775 13.9363 15.6516 13.9363 15.2495V9.66732C13.9363 9.26529 13.6104 8.93937 13.2084 8.93937Z" />
    <path d="M14.3523 12.1721C14.3523 11.77 14.6782 11.4441 15.0803 11.4441C15.4823 11.4441 15.8082 11.77 15.8082 12.1721V12.7448C15.8082 13.1468 15.4823 13.4727 15.0803 13.4727C14.6782 13.4727 14.3523 13.1468 14.3523 12.7448V12.1721Z" />
    <path d="M16.9521 9.72598C16.5501 9.72598 16.2242 10.0519 16.2242 10.4539V14.4629C16.2242 14.865 16.5501 15.1909 16.9521 15.1909C17.3542 15.1909 17.6801 14.865 17.6801 14.4629V10.4539C17.6801 10.0519 17.3542 9.72598 16.9521 9.72598Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08134 22.3961C6.99935 22.4598 6.87999 22.4014 6.87999 22.2975L6.87999 19.9666H5.74159C5.05238 19.9666 4.49367 19.4079 4.49367 18.7187V5.6156C4.49367 4.9264 5.05238 4.36769 5.74158 4.36769H20.7166C21.4058 4.36769 21.9645 4.9264 21.9645 5.6156V18.7187C21.9645 19.4079 21.4058 19.9666 20.7166 19.9666H10.2089L7.08134 22.3961ZM8.54387 18.3028L8.54387 19.1531L9.6386 18.3028H20.3006V6.03158H6.15756V18.3028H8.54387Z"
    />
  </svg>
);

export default SRIcon;
