function bytesToSize(bytes?: number): string {
  const sizes = ["bytes", "KB", "MB", "GB", "TB"];
  if (!bytes || bytes === 0) return "n/a";
  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes.length - 1,
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
}

export default bytesToSize;
