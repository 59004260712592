import React, { FC, SVGProps } from "react";

const ShowMoreArrowDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 0c.39-.001.767.135 1.067.383L12 7.85l8.95-7.2A1.667 1.667 0 0 1 23.3.9a1.668 1.668 0 0 1-.25 2.35l-10 8.05a1.667 1.667 0 0 1-2.117 0l-10-8.334A1.667 1.667 0 0 1 .717.616 1.667 1.667 0 0 1 2 0Z"
      fill={props.fill ? props.fill : "#131313"}
    />
  </svg>
);

export default ShowMoreArrowDownIcon;
