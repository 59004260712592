import React, { FC, ImgHTMLAttributes } from "react";

const DiscoverIcon: FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <img
    src="https://static.cochl.ai/dashboard/discover_card.png"
    width={props.width || "29px"}
    {...props}
  />
);

export default DiscoverIcon;
