import { Fragment, ReactNode } from "react";
import styled from "styled-components";
import { Typo } from "../Typo";

const Container = styled.div`
  width: 100%;
`;

const UnitLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const UsageText = styled(Typo)<{ color?: string }>`
  color: ${(p) => p.color ?? p.theme.colors.black};
`;

const MaxUsageText = styled(Typo)<{ isMaxUnit?: boolean }>`
  color: ${(p) => p.theme.colors.grey[50]};
  margin-left: ${(p) => (p.isMaxUnit ? "auto" : "unset")};
`;

const SenseUsageBar = styled.div<{ height?: number; $borderRadius?: number }>`
  width: 100%;
  background-color: ${(p) => p.theme.colors.grey[20]};
  height: ${(p) => p.height ?? 20}px;
  border-radius: ${(p) => p.$borderRadius ?? 4}px;
  display: flex;
  justify-content: flex-start;
`;

export interface UsageBarProps<ItemT> {
  className?: string;
  currentValueLabel?: string;
  currentValueLabelColor?: string;
  maxValueLabel?: string;
  height?: number;
  data?: ItemT[];
  borderRadius?: number;
  renderItem?: (item: ItemT, index?: number) => ReactNode;
}

/**
 * React component used to create a usage bar.
 * @param className - Class name added to the usage bar.
 * @param currentValueLabel - Label for the current value.
 * @param currentValueLabelColor - Color for the current value.
 * @param maxValueLabel - Label for the max value.
 * @param height - Height of the usage bar.
 * @param data - Data used to render the usage bar.
 * @param borderRadius - Border radius of the usage bar.
 * @param renderItem - Function that renders each item in the usage bar.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/usagebar--default
 */
export const UsageBar = <ItemT,>({
  className,
  currentValueLabel,
  currentValueLabelColor,
  maxValueLabel,
  height,
  data,
  borderRadius,
  renderItem,
}: UsageBarProps<ItemT>) => {
  return (
    <Container className={className}>
      <UnitLine>
        {currentValueLabel && (
          <UsageText variant="h5" color={currentValueLabelColor}>
            {currentValueLabel}
          </UsageText>
        )}
        {maxValueLabel && (
          <MaxUsageText variant="h5" isMaxUnit>
            {maxValueLabel}
          </MaxUsageText>
        )}
      </UnitLine>
      <SenseUsageBar height={height} $borderRadius={borderRadius}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <Fragment key={index}>
                {renderItem && renderItem(item, index)}
              </Fragment>
            );
          })}
      </SenseUsageBar>
    </Container>
  );
};
