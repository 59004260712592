import { media } from "@cochlearai/util";
import { FC } from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";
import { ANALYTICS_COLOR, Z_INDEXES } from "~/client/lib/constants";

const ChartContainer = styled(Chart)`
  margin: auto;
  width: 700px;

  ${media.query.md} {
    width: auto;
  }

  .tooltip-piechart {
    padding: 16px;
    text-align: center;
    font-size: 12px;
  }
  .tooltip-piechart > span:first-child {
    font-weight: bold;
  }
  .tooltip-p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 18px;
    margin: 8px 0;
  }
  .apexcharts-legend-text {
    color: ${(p) => p.theme.colors.black} !important;
  }
  .apexcharts-tooltip {
    z-index: ${Z_INDEXES.POP_OVER};
  }
`;

interface Props {
  labels: string[];
  data: number[];
  tooltipFormatter?: ({ seriesIndex, w }) => string;
}

const PieChart: FC<Props> = ({ labels, data, tooltipFormatter }) => {
  return (
    <ChartContainer
      type="pie"
      height={330}
      series={data}
      options={{
        labels,
        colors: ANALYTICS_COLOR,
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        stroke: {
          width: 0,
        },
        tooltip: {
          custom: tooltipFormatter,
        },
      }}
    />
  );
};

export default PieChart;
