import { useCallback, useState } from "react";
import {
  fetchDeleteSDKChannel,
  fetchCancelDeleteSDKChannel,
} from "~/client/api";
import { APIError } from "~/client/types";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";
import { useSWRConfig } from "swr";
import { SWR_KEY } from "~/client/types/swrKey";

function useDeleteSdkChannel() {
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const deleteSdkChannel = useCallback(
    async (channelId: string) => {
      if (isDeleting) return;
      setIsDeleting(true);
      try {
        await fetchDeleteSDKChannel({
          params: {
            channel_id: channelId,
          },
        });
        mutate(SWR_KEY.SENSE_SDK_INITIALIZE_CHANNEL_LIST);
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setIsDeleting(false);
      }
    },
    [dispatch, isDeleting, mutate],
  );

  const cancelDeleteSdkChannel = useCallback(
    async (channelId: string) => {
      if (isCancelling) return;
      setIsCancelling(true);
      try {
        await fetchCancelDeleteSDKChannel({
          params: {
            channel_id: channelId,
          },
        });
        mutate(SWR_KEY.SENSE_SDK_INITIALIZE_CHANNEL_LIST);
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setIsCancelling(false);
      }
    },
    [dispatch, isCancelling, mutate],
  );

  return {
    isValidating: isDeleting || isCancelling,
    deleteSdkChannel,
    cancelDeleteSdkChannel,
  };
}

export default useDeleteSdkChannel;
