import {
  FetchEventListParams,
  FetchEventQueryParams,
} from "~/client/types/analytics";

import { ProjectType } from "~/client/types/project";

const getAnalyticsParams = ({
  filterParamsWithChecked,
  currentProject,
  page,
  limit,
  sort,
  from,
  to,
  searchText,
  channelList,
}: FetchEventQueryParams) => {
  const { ioType, contentType, tag, deviceName } = filterParamsWithChecked;
  const isSDK =
    currentProject && currentProject.product_type === ProjectType.EDGE_SDK;

  const params: FetchEventListParams = {
    filter: {
      group: [],
      io_type: ioType.filter((item) => item.checked).map((item) => item.value),
      content_type: contentType
        .filter((item) => item.checked)
        .map((item) => item.value),
      tag_name: tag.filter((item) => item.checked).map((item) => item.value),
      platform: [isSDK ? ProjectType.EDGE_SDK : ProjectType.CLOUD_API],
      ...(isSDK &&
        deviceName && {
          device_id: deviceName.reduce(function (result: string[], item) {
            const deviceId = channelList?.find(
              (channel) => channel.device_name === item.value,
            )?.device_id;
            if (item.checked && deviceId) {
              return [...result, ...[deviceId]];
            }
            return result;
          }, []),
        }),
    },
    from,
    to,
    project_id: [currentProject.id],
  };

  if (searchText) {
    if (isSDK) {
      params.channel_id = searchText;
    } else {
      params.session_id = searchText;
    }
  }

  if (typeof page === "number") params.page = page;
  if (limit) params.limit = limit;
  if (sort) params.sort = sort;

  return params;
};

export default getAnalyticsParams;
