import {
  APIError,
  DetailOwnerInfo,
  Project,
  ProjectFilterType,
  ProjectType,
  SenseServices,
} from "~/client/types";
import {
  initializeProjectList,
  setFilterType,
  setProjectTab,
  setServerError,
  setVisibleCount,
} from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetProjectList } from "~/client/api/project";
import { selectedProjectState, refineUserName } from "~/client/lib";
import useAuthenticate from "./useAuthenticate";
import useSWR from "swr";
import { useTheme } from "styled-components";

function useProjectList() {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const { customer } = useAuthenticate();
  const {
    projectList,
    projectCount,
    selectedProjectTab,
    visibleCount,
    filterType,
  } = useAppSelector(selectedProjectState);

  const { data, error, isValidating } = useSWR(
    SWR_KEY.PROJECT_LIST_INITIALIZE,
    async () => {
      const res = await fetchGetProjectList({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const organizationOwnerList = useMemo(() => {
    const list = [] as DetailOwnerInfo[];
    if (!projectList) return list;

    const colorSet = Object.keys(colors.service_color) as SenseServices[];
    projectList.forEach((project) => {
      if (
        project.organization_id !== customer?.organization_id &&
        !list.find((item) => item.organization_id === project.organization_id)
      ) {
        list.push({
          ...project.owner,
          organization_id: project.organization_id,
          color: "",
          full_name: refineUserName({
            givenName: project.owner?.given_name,
            familyName: project.owner?.name,
          }),
        });
      }
    });

    return list
      .sort((a, b) => {
        return a.full_name
          .toLowerCase()
          .localeCompare(b.full_name.toLowerCase());
      })
      .map((item, i) => ({
        ...item,
        color: colors.service_color[colorSet[i % 4]],
      }));
  }, [colors, customer?.organization_id, projectList]);

  const filteredProjectList = useMemo(() => {
    return projectList
      ?.filter((project: Project) =>
        selectedProjectTab === ProjectType.ALL
          ? true
          : project.product_type === selectedProjectTab,
      )
      .filter((project: Project) =>
        filterType === ProjectFilterType.INVITED
          ? project.organization_id !== customer?.organization_id
          : filterType === ProjectFilterType.CREATED_BY_ME
            ? project.organization_id === customer?.organization_id
            : true,
      );
  }, [projectList, selectedProjectTab, filterType, customer]);

  const changeProjectTab = useCallback(
    (tab: ProjectType) => {
      dispatch(setProjectTab(tab));
    },
    [dispatch],
  );

  const changeFilterType = useCallback(
    (type: ProjectFilterType) => {
      dispatch(setFilterType(type));
    },
    [dispatch],
  );

  const changeVisibleCount = useCallback(
    (count: number) => {
      dispatch(setVisibleCount(count));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!data) return;
    dispatch(initializeProjectList(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    projectCount,
    projectList,
    filteredProjectList,
    isValidating,
    selectedProjectTab,
    changeProjectTab,
    filterType,
    changeFilterType,
    visibleCount,
    changeVisibleCount,
    organizationOwnerList,
  };
}

export default useProjectList;
