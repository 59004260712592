import { MouseEventHandler, PropsWithChildren } from "react";

import { BaseStyleProps } from "../types";
import { ToastCloseIcon } from "../Icons";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px 20px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #edf0ff 52.19%, #4b68ff 127.97%);
  color: ${(p) => p.theme.colors.blue[60]};
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export interface BannerProps extends BaseStyleProps {
  onClose?: MouseEventHandler<SVGSVGElement>;
}

/**
 * React component used to create a Banner layout.
 * @param className  - The className of the banner.
 * @param style      - The style of the banner.
 * @param onClose    - The onClose handler of the banner.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/banner--default
 */
export const Banner = ({
  children,
  className,
  style,
  onClose,
}: PropsWithChildren<BannerProps>) => {
  return (
    <Container className={className} style={style}>
      <Content>{children}</Content>
      <ToastCloseIcon
        onClick={onClose}
        fill="white"
        style={{ cursor: "pointer" }}
      />
    </Container>
  );
};
