import { STORAGE_KEYS } from "~/client/types/storage";

const storage = {
  get: function get<ParsedStorageItemType>(
    key: STORAGE_KEYS,
  ): ParsedStorageItemType | null {
    if (!localStorage) {
      return null;
    }
    if (!localStorage[key]) {
      return null;
    }
    try {
      if (typeof localStorage[key] === "string") {
        return localStorage[key] as ParsedStorageItemType;
      }
      const localStorageValue = localStorage[key] as string | undefined;
      if (!localStorageValue) {
        throw Error("local storage value is undefined");
      }
      const parsed = JSON.parse(localStorageValue) as ParsedStorageItemType;
      return parsed;
    } catch (e) {
      return null;
    }
  },
  set: (key: STORAGE_KEYS, object: unknown): void => {
    if (!localStorage) {
      return;
    }
    localStorage[key] =
      typeof object === "string" ? object : JSON.stringify(object);
  },
};

export default storage;
