import { Chip, ToastCheckIcon, Typo } from "@cochlearai/ui";
import React, { FC } from "react";
import { color, media } from "@cochlearai/util";

import styled from "styled-components";

const Container = styled.div<{
  selected?: boolean;
  $disable?: boolean;
}>`
  border: 1px solid
    ${(p) => (p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[50])};
  background: ${(p) =>
    p.selected
      ? color.convertHexToRGB({
          hex: p.theme.colors.blue[60],
          alpha: 0.1,
        })
      : "transparent"};
  border-radius: 4px;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[80]};
  cursor: ${(p) => (p.$disable ? "" : "pointer")};
  opacity: ${(p) => (p.$disable ? "0.4" : "1")};
  padding: 20px 24px;

  & + & {
    margin-left: 8px;
  }

  ${media.query.md} {
    & + & {
      margin-left: 0;
      margin-top: 8px;
    }
  }
`;

const SelectedProjectCheck = styled(ToastCheckIcon)``;

const BETAChip = styled(Chip)<{ selected: boolean }>`
  color: ${(p) => (p.selected ? p.theme.colors.blue[60] : "")};
  background: ${(p) =>
    p.selected
      ? color.convertHexToRGB({
          hex: p.theme.colors.blue[60],
          alpha: 0.05,
        })
      : p.theme.colors.white};
`;

interface Props {
  text: string;
  icon: React.ReactElement;
  selected: boolean;
  disabled?: boolean;
  isBETA?: boolean;
  onClick: () => void;
}

const TypeSelectButton: FC<Props> = ({
  text,
  icon,
  selected,
  disabled,
  isBETA,
  onClick,
}) => {
  return (
    <Container selected={selected} onClick={onClick} $disable={disabled}>
      {icon && icon}
      <Typo variant="button">{text}</Typo>
      {isBETA && <BETAChip selected={selected}>BETA</BETAChip>}
      {selected && <SelectedProjectCheck />}
    </Container>
  );
};

export default TypeSelectButton;
