import {
  APICallPayloads,
  FetchDeletePaymentMethodByIdParams,
  FetchDeletePaymentMethodByIdResponse,
  FetchGetPlansParams,
  FetchGetPlansResponse,
  FetchGetSubscriptionsParams,
  FetchGetSubscriptionsResponse,
  FetchGetUsagesParams,
  FetchGetUsagesResponse,
  FetchGetWhoamiParams,
  FetchGetWhoamiResponse,
  FetchInvoicesParams,
  FetchInvoicesResponse,
  FetchNewSubscriptionParams,
  FetchNewSubscriptionResponse,
  FetchPaymentFingerprintParams,
  FetchPaymentFingerprintResponse,
  FetchPaymentMethodFlowParams,
  FetchPaymentMethodFlowResponse,
  FetchPaymentMethodsParams,
  FetchPaymentMethodsResponse,
  FetchUpcomingInvoiceParams,
  FetchUpcomingInvoiceResponse,
  FetchUpdatePaymentMethodByIdParams,
  FetchUpdatePaymentMethodByIdResponse,
} from "~/client/types";

import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_PAYMENT_API_BASE_URL: paymentAPIBaseURL } = getConfigs();

const API_URL_PREFIX = paymentAPIBaseURL ? `${paymentAPIBaseURL}` : "";

export const fetchGetWhoami = ({
  config,
}: APICallPayloads<FetchGetWhoamiParams>): Promise<FetchGetWhoamiResponse> => {
  const url = `${API_URL_PREFIX}/whoami`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetPlans = ({
  config,
}: APICallPayloads<FetchGetPlansParams>): Promise<FetchGetPlansResponse> => {
  const url = `${API_URL_PREFIX}/plans`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetSubscriptions = ({
  config,
}: APICallPayloads<FetchGetSubscriptionsParams>): Promise<FetchGetSubscriptionsResponse> => {
  const url = `${API_URL_PREFIX}/subscriptions`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchNewSubscription = ({
  params,
  config,
}: APICallPayloads<FetchNewSubscriptionParams>): Promise<FetchNewSubscriptionResponse> => {
  const url = `${API_URL_PREFIX}/subscriptions`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchPaymentMethodFlow = ({
  config,
}: APICallPayloads<FetchPaymentMethodFlowParams>): Promise<FetchPaymentMethodFlowResponse> => {
  const url = `${API_URL_PREFIX}/payment-methods-flow`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchPaymentMethods = ({
  config,
}: APICallPayloads<FetchPaymentMethodsParams>): Promise<FetchPaymentMethodsResponse> => {
  const url = `${API_URL_PREFIX}/payment-methods`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchPaymentFingerprint = ({
  params,
  config,
}: APICallPayloads<FetchPaymentFingerprintParams>): Promise<FetchPaymentFingerprintResponse> => {
  const url = `${API_URL_PREFIX}/payment-methods/${params.id}/fingerprint`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchDeletePaymentMethodById = ({
  params,
  config,
}: APICallPayloads<FetchDeletePaymentMethodByIdParams>): Promise<FetchDeletePaymentMethodByIdResponse> => {
  const url = `${API_URL_PREFIX}/payment-methods/${params.id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

export const fetchUpdatePaymentMethodById = ({
  params,
  config,
}: APICallPayloads<FetchUpdatePaymentMethodByIdParams>): Promise<FetchUpdatePaymentMethodByIdResponse> => {
  const url = `${API_URL_PREFIX}/payment-methods/default`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchUpcomingInvoice = ({
  config,
}: APICallPayloads<FetchUpcomingInvoiceParams>): Promise<FetchUpcomingInvoiceResponse> => {
  const url = `${API_URL_PREFIX}/upcoming-invoice`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchInvoices = ({
  params,
  config,
}: APICallPayloads<FetchInvoicesParams>): Promise<FetchInvoicesResponse> => {
  const { year } = params;
  let url = `${API_URL_PREFIX}/invoices?`;
  if (year) {
    url += `year=${year}`;
  }
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchUsages = ({
  params,
  config,
}: APICallPayloads<FetchGetUsagesParams>): Promise<FetchGetUsagesResponse> => {
  const { id, from, to } = params;
  let url = `${API_URL_PREFIX}/organizations/${id}/usage?`;
  if (from) {
    url += `from=${from}&`;
  }
  if (to) {
    url += `to=${to}`;
  }
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};
