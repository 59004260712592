import React, { FC, SVGProps } from "react";

const ArrowForwardIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09243 0L12 4L9.09243 8L8.20225 7.2567L10.1299 4.60478L0 4.60478V3.39533L10.13 3.39533L8.20225 0.743301L9.09243 0Z"
      fill={props.fill ?? "#4B68FF"}
    />
  </svg>
);

export default ArrowForwardIcon;
