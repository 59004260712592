import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BaseStyleProps,
  Typo,
} from "@cochlearai/ui";
import { media } from "@cochlearai/util";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 402px;
  flex-direction: column;
  padding: 16px;
`;

const Navigator = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const SelectionArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
`;

const PrevButton = styled(ArrowLeftIcon)`
  cursor: pointer;
  color: ${(p) => p.theme.colors.grey[70]};
`;

const NextButton = styled(ArrowRightIcon)`
  cursor: pointer;
  color: ${(p) => p.theme.colors.grey[70]};
`;

const DateItem = styled(Typo)<{ selected: boolean; disabled: boolean }>`
  background: ${(p) => (p.selected ? p.theme.colors.blue[60] : "unset")};
  color: ${(p) =>
    p.selected
      ? p.theme.colors.white
      : p.disabled
        ? p.theme.colors.grey[50]
        : p.theme.colors.black};
  border-radius: 2px;
  width: 41px;
  height: 32px;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 12px;
  & + & {
    margin-left: 60px;
  }
  &:nth-child(4n + 1) {
    margin-left: unset !important;
  }

  ${media.query.md} {
    & + & {
      margin-left: 40px;
    }
  }
`;

export interface MonthYearPickerProps extends BaseStyleProps {
  title?: string;
  values?: { value: string; disabled?: boolean }[];
  selected?: string;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  onClickItem?: (target: string) => void;
}

export const MonthYearPicker = ({
  title,
  values,
  selected,
  onClickPrev,
  onClickNext,
  onClickItem,
  className,
  style,
}: MonthYearPickerProps) => {
  return (
    <Container style={style} className={className}>
      <Navigator>
        {onClickPrev && (
          <PrevButton onClick={onClickPrev} width={12} height={12} />
        )}
        <Typo variant="h5" style={{ width: "100%", textAlign: "center" }}>
          {title}
        </Typo>
        {onClickNext && (
          <NextButton onClick={onClickNext} width={12} height={12} />
        )}
      </Navigator>
      <SelectionArea>
        {values?.map(({ value, disabled }) => (
          <DateItem
            variant="caption1"
            key={value}
            onClick={() => !disabled && onClickItem && onClickItem(value)}
            selected={selected === value}
            disabled={disabled ?? false}
          >
            {value}
          </DateItem>
        ))}
      </SelectionArea>
    </Container>
  );
};
