import { useEffect } from "react";
import useSWR from "swr";
import { fetchPaymentMethods } from "~/client/api";
import { APIError } from "~/client/types";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { initializePaymentList, setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";
import { selectedPaymentState } from "~/client/lib";

function usePaymentMethodList() {
  const dispatch = useAppDispatch();
  const { paymentMethodList } = useAppSelector(selectedPaymentState);
  const { data, error } = useSWR(
    SWR_KEY.PAYMENT_METHOD_INITIALIZE_LIST,
    async () => {
      const res = await fetchPaymentMethods({
        params: {},
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    dispatch(initializePaymentList(data));
  }, [dispatch, data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    paymentMethodList,
  };
}

export default usePaymentMethodList;
