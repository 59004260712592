import { ConfirmModal } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";
import useWebhookHistory from "~/client/hooks/useWebhookHistory";
import { NotificationConfig, WebhookHistory } from "~/client/types";
import { WebhookHistoryTable } from ".";

const ModalContainer = styled(ConfirmModal)`
  padding: 40px;
`;

interface Props {
  webhook?: NotificationConfig;
  open: boolean;
  onClose: () => void;
  onClickView?: (item: WebhookHistory) => void;
}

const WebhookHistoryModal: FC<Props> = ({
  webhook,
  open,
  onClose,
  onClickView,
}) => {
  const { webhookHistory } = useWebhookHistory({
    id: webhook?.id,
  });

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Webhook history"
      size="large"
    >
      <WebhookHistoryTable data={webhookHistory} onClickViewMsg={onClickView} />
    </ModalContainer>
  );
};

export default WebhookHistoryModal;
