import { FC, useState } from "react";

import {
  Chip,
  Dropdown,
  DropdownButton,
  DropdownItem,
  Typo,
} from "@cochlearai/ui";
import { color } from "@cochlearai/util";
import styled, { useTheme } from "styled-components";

const Container = styled.div<{ selected?: boolean }>`
  display: flex;
  padding: 3px 4px 3px 17px;
  align-items: center;
  gap: 5px;
  border-radius: 100px;
  border: 1px dashed ${(p) => p.theme.colors.grey[40]};
`;

const DateDropdownButton = styled(DropdownButton)`
  justify-content: start;
  width: 100%;
  height: 24px;
  padding: 4px 10px;
  border-radius: 100px;
  background: ${(p) =>
    color.convertHexToRGB({ hex: p.theme.colors.blue[60], alpha: 0.05 })};
  border: none;
`;

const DateDropDownItem = styled(DropdownItem)`
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
`;

const FilterChip = styled(Chip)`
  cursor: pointer;
  height: 24px;
`;

interface Props {
  text?: string;
  data?: { label: string; value: string }[];
  type?: string;
}

const DetailFilterButton: FC<Props> = ({ text, data, type = "number" }) => {
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isNumberType = type === "number";

  if (!data) return null;

  return (
    <Container>
      {text && (
        <Typo variant="caption1" style={{ color: colors.black }}>
          {text}
        </Typo>
      )}
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {!isNumberType && <FilterChip>{data[0]?.label}</FilterChip>}
        {((!isNumberType && data.length > 1) || isNumberType) && (
          <Dropdown
            isOpen={isOpen}
            data={isNumberType ? data : data.slice(1)}
            onClickOutside={() => setIsOpen(false)}
            contentWidth="auto"
            align="end"
            renderItem={({ label, value }) => {
              return (
                <DateDropDownItem key={value}>
                  <Typo variant="body">{label}</Typo>
                </DateDropDownItem>
              );
            }}
          >
            <DateDropdownButton onClick={() => setIsOpen(!isOpen)}>
              <Typo variant="caption1" style={{ color: colors.black }}>
                {!isNumberType ? "Others" : data?.length}
              </Typo>
            </DateDropdownButton>
          </Dropdown>
        )}
      </div>
    </Container>
  );
};

export default DetailFilterButton;
