import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ThemeType } from "@cochlearai/ui/src/types/theme";
import storage from "~/client/lib/storage";
import { STORAGE_KEYS } from "~/client/types/storage";

interface ThemeState {
  theme: ThemeType;
}

const initialState: ThemeState = {
  theme: ThemeType.LIGHT,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeType>) => {
      state.theme = action.payload;
    },
    switchTheme: (state) => {
      if (state.theme === ThemeType.LIGHT) {
        state.theme = ThemeType.DARK;
        storage.set(STORAGE_KEYS.CURRENT_THEME, ThemeType.DARK);
      } else {
        state.theme = ThemeType.LIGHT;
        storage.set(STORAGE_KEYS.CURRENT_THEME, ThemeType.LIGHT);
      }
    },
  },
  extraReducers: () => {
    //
  },
});

export const { switchTheme, setTheme } = themeSlice.actions;

export const { reducer: themeReducer } = themeSlice;
