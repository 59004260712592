import { MouseEventHandler } from "react";

import { ArrowForwardIcon } from "../Icons";
import styled from "styled-components";
import { BaseStyleProps } from "../types";

const ButtonWrapper = styled.span`
  color: ${(p) => p.theme.colors.blue[60]};

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export interface HyperlinkButtonProps extends BaseStyleProps {
  text: string;
  rightArrow?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
}

/**
 * React component used to create a hyperlink button.
 * @param text - The text of the button.
 * @param style - The style of the button.
 * @param rightArrow - The right arrow icon will be displayed if set true.
 * @param className - The class name of the button
 * @param onClick - A callback when clicked.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/hyperlink-button--default
 */
export const HyperlinkButton = ({
  text,
  style,
  rightArrow,
  className,
  onClick,
}: HyperlinkButtonProps) => {
  return (
    <ButtonWrapper
      style={{
        ...style,
        cursor: onClick ? "pointer" : "unset",
      }}
      className={className}
      onClick={onClick}
    >
      {text}
      {rightArrow && <ArrowForwardIcon style={{ marginLeft: "8px" }} />}
    </ButtonWrapper>
  );
};
