import { Button, HamburgerIcon, HeaderLogoIcon } from "@cochlearai/ui";
import { FC, Suspense } from "react";
import styled, { useTheme as useStyledTheme } from "styled-components";

import { media } from "@cochlearai/util";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { HeaderLink } from "~/client/components";
import { Z_INDEXES } from "~/client/lib/constants";
import { HeaderMenuItemType } from "~/client/types/header";
import HeaderNotification from "./HeaderNotification";
import HeaderProfile from "./HeaderProfile";

const Container = styled.header`
  z-index: ${Z_INDEXES.HEADER};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 66px;
`;

const Inner = styled.div`
  position: relative;
  width: ${media.size.lg};
  height: 100%;
  z-index: ${Z_INDEXES.HEADER};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: unset;
  padding-right: unset;

  ${media.query.xl} {
    width: ${media.size.lg};
    padding-left: unset;
    padding-right: unset;
  }

  ${media.query.lg} {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }

  ${media.query.md} {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  ${media.query.sm} {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const HeaderLogo = styled.div`
  cursor: pointer;
  width: 96.3px;
  height: 20px;

  ${media.query.md} {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`;

const HeaderLinkItemsWrapper = styled.nav`
  margin-left: auto;
  margin-right: 20px;
  align-items: center;

  width: 450px;

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  text-align: center;

  & a:last-child {
    margin-left: 27px;
  }

  ${media.query.md} {
    display: none;
  }
`;

const MenuIconButton = styled(Button)`
  display: none;

  ${media.query.md} {
    display: block;
    margin-left: auto;
  }
`;

const MenuIcons = styled.div`
  display: flex;
  margin-left: 26px;

  ${media.query.md} {
    display: none;
  }
`;

const SkeletonWrapper = styled.div`
  width: 25px;
  height: 25px;

  & span {
    line-height: unset;
  }
`;

interface Props {
  onClickSideBarMenu?: () => void;
  menus: HeaderMenuItemType[];
}

const Header: FC<Props> = ({ onClickSideBarMenu, menus }) => {
  const { colors } = useStyledTheme();

  return (
    <Container>
      <Inner>
        <HeaderLogo>
          <Link to="/" aria-label="cochl-logo">
            <HeaderLogoIcon
              style={{
                width: "100%",
                height: "100%",
              }}
              fill={colors.black}
            />
          </Link>
        </HeaderLogo>
        <HeaderLinkItemsWrapper>
          {menus.map((menuItem) => (
            <HeaderLink key={menuItem.to} {...menuItem} />
          ))}
        </HeaderLinkItemsWrapper>
        <MenuIcons style={{ marginLeft: "50px" }}>
          <Suspense
            fallback={
              <SkeletonWrapper>
                <Skeleton circle width="25px" height="25px" />
              </SkeletonWrapper>
            }
          >
            <HeaderNotification />
          </Suspense>
        </MenuIcons>
        <MenuIcons>
          <Suspense
            fallback={
              <SkeletonWrapper>
                <Skeleton circle width="25px" height="25px" />
              </SkeletonWrapper>
            }
          >
            <HeaderProfile />
          </Suspense>
        </MenuIcons>
        <MenuIconButton
          onClick={onClickSideBarMenu}
          rightIcon={
            <HamburgerIcon width={23} height={23} fill={colors.black} />
          }
        />
      </Inner>
    </Container>
  );
};

export default Header;
