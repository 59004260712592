import { theme } from "./Colors";

import { ComponentThemeColor, ThemeType } from "../types/theme";

export const ThemeColors: ComponentThemeColor = {
  button: {
    border: {
      default: {
        light: "transparent",
        dark: "transparent",
      },
      primary: {
        light: theme[ThemeType.LIGHT].blue[60],
        dark: theme[ThemeType.DARK].blue[60],
      },
      secondary: {
        light: theme[ThemeType.LIGHT].grey[70],
        dark: theme[ThemeType.DARK].grey[70],
      },
      danger: {
        light: theme[ThemeType.LIGHT].red,
        dark: theme[ThemeType.DARK].red,
      },
      hover: {
        default: {
          light: "transparent",
          dark: "transparent",
        },
        primary: {
          light: theme[ThemeType.LIGHT].blue[90],
          dark: theme[ThemeType.DARK].blue[90],
        },
        secondary: {
          light: theme[ThemeType.LIGHT].blue[60],
          dark: theme[ThemeType.DARK].blue[60],
        },
        danger: {
          light: theme[ThemeType.LIGHT].red,
          dark: theme[ThemeType.DARK].red,
        },
      },
      disabled: {
        default: {
          light: "transparent",
          dark: "transparent",
        },
        primary: {
          light: theme[ThemeType.LIGHT].blue[60],
          dark: theme[ThemeType.DARK].blue[60],
        },
        secondary: {
          light: theme[ThemeType.LIGHT].grey[70],
          dark: theme[ThemeType.DARK].grey[70],
        },
        danger: {
          light: theme[ThemeType.LIGHT].red,
          dark: theme[ThemeType.DARK].red,
        },
      },
    },
    backgroundColor: {
      default: {
        light: "transparent",
        dark: "transparent",
      },
      primary: {
        light: theme[ThemeType.LIGHT].blue[60],
        dark: theme[ThemeType.DARK].blue[60],
      },
      danger: {
        light: theme[ThemeType.LIGHT].red,
        dark: theme[ThemeType.DARK].red,
      },
      hover: {
        default: {
          light: "transparent",
          dark: "transparent",
        },
        primary: {
          light: theme[ThemeType.LIGHT].blue[90],
          dark: theme[ThemeType.DARK].blue[90],
        },
        danger: {
          light: "#CB2C2B",
          dark: "#CB2C2B",
        },
      },
      disabled: {
        default: {
          light: "transparent",
          dark: "transparent",
        },
        primary: {
          light: theme[ThemeType.LIGHT].blue[60],
          dark: theme[ThemeType.DARK].blue[60],
        },
        danger: {
          light: theme[ThemeType.LIGHT].red,
          dark: theme[ThemeType.DARK].red,
        },
      },
    },
    color: {
      default: {
        light: "inherit",
        dark: "inherit",
      },
      primary: {
        light: theme[ThemeType.LIGHT].white,
        dark: theme[ThemeType.LIGHT].white,
      },
      danger: {
        light: theme[ThemeType.LIGHT].white,
        dark: theme[ThemeType.LIGHT].white,
      },
      secondary: {
        light: theme[ThemeType.LIGHT].grey[70],
        dark: theme[ThemeType.DARK].grey[70],
      },
      hover: {
        default: {
          light: "inherit",
          dark: "inherit",
        },
        primary: {
          light: theme[ThemeType.LIGHT].white,
          dark: theme[ThemeType.LIGHT].white,
        },
        danger: {
          light: theme[ThemeType.LIGHT].white,
          dark: theme[ThemeType.LIGHT].white,
        },
        secondary: {
          light: theme[ThemeType.LIGHT].blue[60],
          dark: theme[ThemeType.DARK].blue[60],
        },
      },
      disabled: {
        default: {
          light: "inherit",
          dark: "inherit",
        },
        primary: {
          light: theme[ThemeType.LIGHT].white,
          dark: theme[ThemeType.LIGHT].white,
        },
        danger: {
          light: theme[ThemeType.LIGHT].white,
          dark: theme[ThemeType.LIGHT].white,
        },
        secondary: {
          light: theme[ThemeType.LIGHT].grey[70],
          dark: theme[ThemeType.DARK].grey[70],
        },
      },
    },
  },
};
