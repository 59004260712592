import type { AxiosRequestConfig, AxiosResponse } from "axios";

import { axiosClient } from "~/client/lib";

async function makeAPIRequest<ResponseType>(
  config: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  if (
    config.method === "POST" ||
    config.method === "PUT" ||
    config.method === "PATCH"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
    };
  }
  return axiosClient.request<ResponseType>(config);
}

export default makeAPIRequest;
