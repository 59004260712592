import { useEffect } from "react";
import useSWR from "swr";
import { fetchGetAPIUsageByProjectId } from "~/client/api";
import { Project, ProjectType } from "~/client/types/project";
import { SWR_KEY } from "~/client/types/swrKey";
import { APIError } from "~/client/types";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";

interface Props {
  from?: string;
  to?: string;
  currentProject: Project | null;
}

function useProjectAPIUsage({ from, to, currentProject }: Props) {
  const dispatch = useAppDispatch();
  const { data, error, isValidating } = useSWR(
    currentProject?.product_type === ProjectType.CLOUD_API
      ? [
          SWR_KEY.SENSE_API_INITIALIZE_PROJECT_USAGE,
          { projectId: currentProject.id, from, to },
        ]
      : null,
    async (_, params) => {
      const { data } = await fetchGetAPIUsageByProjectId({
        params,
      });
      return data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    apiUsage: data,
    isValidating,
  };
}

export default useProjectAPIUsage;
