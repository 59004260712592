import { Button, Modal, Typo, Z_INDEXES } from "@cochlearai/ui";
import { FC, ReactNode } from "react";
import styled, { useTheme } from "styled-components";

const CenterModal = styled(Modal)`
  padding: 50px;
  z-index: ${Z_INDEXES.MODAL_BACKGROUND};
`;

interface Props {
  content: ReactNode;
  modal?: {
    title: string;
    subtitle: string;
    description: string | ReactNode;
    buttonLabel: string;
    onClickButton: () => void;
  };
}

const AccessBlock: FC<Props> = ({ content, modal }) => {
  const { colors } = useTheme();
  return (
    <>
      {content}
      {modal && (
        <CenterModal open={true} size="small" dim={false}>
          <Typo variant="h2">{modal.title}</Typo>
          <div style={{ marginTop: "10px", marginBottom: "28px" }}>
            <Typo variant="h5">{modal.subtitle}</Typo>
            <Typo variant="h5" style={{ color: colors.grey[70] }}>
              {modal.description}
            </Typo>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="primary"
              onClick={modal.onClickButton}
              style={{
                padding: "11px 14px",
              }}
            >
              {modal.buttonLabel}
            </Button>
          </div>
        </CenterModal>
      )}
    </>
  );
};

export default AccessBlock;
