/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  FetchGetSurveyQuestionsParams,
  FetchGetSurveyQuestionsResponse,
  FetchSubmitSurveyParams,
  FetchSubmitSurveyResponse,
  FetchGetSurveyCompletedParams,
  FetchGetSurveyCompletedResponse,
} from "~/client/types/api/survey";

import { APICallPayloads } from "~/client/types/api";
import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_BACK_OFFICE_URL: surveyAPIBaseURL } = getConfigs();

const API_URL_PREFIX = surveyAPIBaseURL ? `${surveyAPIBaseURL}` : "";

export const fetchGetSurveyQuestion = ({
  config,
}: APICallPayloads<FetchGetSurveyQuestionsParams>): Promise<FetchGetSurveyQuestionsResponse> => {
  const url = `${API_URL_PREFIX}/survey/questions`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchSubmitSurvey = ({
  config,
  params,
}: APICallPayloads<FetchSubmitSurveyParams>): Promise<FetchSubmitSurveyResponse> => {
  const url = `${API_URL_PREFIX}/survey/questions`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchGetSurveyCompleted = ({
  config,
  params,
}: APICallPayloads<FetchGetSurveyCompletedParams>): Promise<FetchGetSurveyCompletedResponse> => {
  const url = `${API_URL_PREFIX}/survey/users/${params.user_id}/has_answered`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};
