import { ComponentPropsWithoutRef, forwardRef } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";

export type UserProfileSize = "small" | "medium";

const Container = styled.div<{ size: UserProfileSize }>`
  width: ${(p) => (p.size === "medium" ? "48px" : "25px")};
  min-width: ${(p) => (p.size === "medium" ? "48px" : "25px")};
  height: ${(p) => (p.size === "medium" ? "48px" : "25px")};
  border-radius: 50%;
  background-color: ${(p) =>
    p.size === "medium" ? p.theme.colors.grey[40] : p.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p<{ size: UserProfileSize }>`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${(p) => (p.size === "medium" ? "bold" : "normal")};
  font-size: ${(p) => (p.size === "medium" ? "24px" : "14px")};
  line-height: ${(p) => (p.size === "medium" ? "28px" : "16px")};

  color: ${(p) => p.theme.colors.white};
`;

export interface UserProfileProps
  extends ComponentPropsWithoutRef<"div">,
    BaseStyleProps {
  text: string;
  onClick?: () => void;
  size?: UserProfileSize;
}

/**
 * React component used to create a user profile.
 * @param text - Text of the user profile.
 * @param onClick - Function called when clicking on the user profile.
 * @param size - Size of the user profile. `medium`, `small`. Default is `medium`.
 * @param className - Class name added to the user profile.
 * @param style - Style object added to the user profile.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/user-profile--default
 */
export const UserProfile = forwardRef<HTMLDivElement, UserProfileProps>(
  ({ text, onClick, className, size = "medium", style }, ref) => {
    return (
      <Container
        ref={ref}
        className={className}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "unset", ...style }}
        data-testid="@cochl-ui/user-pofile"
        size={size}
      >
        <Text size={size}>{text}</Text>
      </Container>
    );
  },
);
