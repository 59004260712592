import { ConfirmModal, Input, InputCancelIcon, Typo } from "@cochlearai/ui";
import { FC, useCallback } from "react";
import styled, { useTheme } from "styled-components";

import { media } from "@cochlearai/util";
import { useTranslation } from "react-i18next";
import useUpdateDeviceName from "~/client/hooks/useUpdateDeviceName";
import { SDKChannel } from "~/client/types/sdk";

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputWrapper = styled(FlexCenter)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 40px;
  }

  ${media.query.lg} {
    flex-direction: column;
    align-items: start;
  }
`;

const InputContainer = styled.div`
  width: 586px;

  & svg {
    margin-right: 23px;
  }
  ${media.query.lg} {
    width: 100%;
  }
`;

const ContentContainer = styled(FlexCenter)`
  height: 60px;
`;

const DeviceIdContainer = styled(ContentContainer)`
  width: 586px;
  padding: 20px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.grey[10]};
  color: ${(p) => p.theme.colors.grey[80]};

  & p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${media.query.lg} {
    width: 100%;
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  channel: SDKChannel;
}

const EdgeSdkDeviceModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  channel,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { device_id, device_name, id } = channel;
  const { name, setName, update, validation } = useUpdateDeviceName({
    deviceName: device_name,
    channel_id: id,
  });

  const onCloseModal = useCallback(() => {
    device_name && setName(device_name);
    onClose && onClose();
  }, [device_name, onClose, setName]);

  const onConfirmClick = useCallback(async () => {
    await update();
    onConfirm && onConfirm();
  }, [onConfirm, update]);

  return (
    <ConfirmModal
      open={open}
      onClose={onCloseModal}
      title="Edit device name"
      subTitle={
        "Friendly name for your device can be more easily distinguished in the dashboard."
      }
      confirmText={t("Save", { ns: "common" })}
      onConfirm={onConfirmClick}
      confirmDisabled={
        !validation.validating || (validation.validating && !!validation.error)
      }
      onAbort={onCloseModal}
      abortText={t("Cancel", { ns: "common" })}
    >
      <ModalInputWrapper>
        <Typo variant="h4" style={{ color: colors.grey[50] }}>
          Device id
        </Typo>
        <DeviceIdContainer>
          <Typo variant="body">{device_id}</Typo>
        </DeviceIdContainer>
      </ModalInputWrapper>
      <ModalInputWrapper style={{ alignItems: "start" }}>
        <ContentContainer>
          <Typo variant="h4">Device name</Typo>
        </ContentContainer>
        <InputContainer>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            rightIcon={
              name !== device_name && (
                <InputCancelIcon
                  width={14}
                  height={14}
                  style={{ cursor: "pointer" }}
                  onClick={() => device_name && setName(device_name)}
                />
              )
            }
            validated={validation.validating ? validation.validated : undefined}
            error={
              validation.validating && !!validation.error
                ? validation.error
                : undefined
            }
          />
        </InputContainer>
      </ModalInputWrapper>
    </ConfirmModal>
  );
};

export default EdgeSdkDeviceModal;
