import { useCallback, useEffect } from "react";
import {
  setTheme as dispatchSetTheme,
  switchTheme as dispatchSwitchTheme,
} from "~/client/store/modules/theme";
import { useAppDispatch, useAppSelector } from ".";

import { ThemeType } from "@cochlearai/ui/src/types/theme";
import { STORAGE_KEYS } from "~/client/types/storage";
import { storage } from "..";

function useTheme(): {
  theme: ThemeType;
  switchTheme: () => void;
} {
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const setTheme = useCallback(
    (theme: ThemeType) => {
      dispatch(dispatchSetTheme(theme));
    },
    [dispatch],
  );
  const switchTheme = useCallback(() => {
    dispatch(dispatchSwitchTheme());
  }, [dispatch]);

  useEffect(() => {
    const currentTheme = storage.get<ThemeType>(STORAGE_KEYS.CURRENT_THEME);
    const systemPrefersDark =
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");

    if (currentTheme) {
      setTheme(currentTheme);
    } else if (systemPrefersDark?.matches) {
      setTheme(systemPrefersDark.matches ? ThemeType.DARK : ThemeType.LIGHT);
      systemPrefersDark.addEventListener("change", ({ matches }) => {
        setTheme(matches ? ThemeType.DARK : ThemeType.LIGHT);
      });
    }
  }, [setTheme]);

  return {
    theme,
    switchTheme,
  };
}

export default useTheme;
