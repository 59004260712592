import { Modal, ModalCloseIcon } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";
import { PricePlanItem, PricePlanItemList } from "~/client/components";

import { Plan } from "~/client/types";

const ConfirmModal = styled(Modal)`
  background-color: ${(p) => p.theme.colors.white};
  padding-top: 50px;
`;

const PricePlanListModalInner = styled.div`
  background-color: ${(p) => p.theme.colors.grey[10]};
  width: 100%;
  height: 100%;
`;

interface Props {
  open: boolean;
  onClose: () => void;
  data: {
    plan: Plan;
    disabled: boolean;
    onClickItem: (clickedPlan: Plan) => void;
  }[];
}

const PricePlanListModal: FC<Props> = ({ open, onClose, data }) => {
  const { colors } = useTheme();

  return (
    <ConfirmModal open={open} onClose={onClose} size="large">
      <PricePlanListModalInner>
        <ModalCloseIcon
          style={{
            position: "absolute",
            right: "30px",
            top: "30px",
            cursor: "pointer",
          }}
          onClick={onClose}
          fill={colors.black}
        />
        <PricePlanItemList
          data={data}
          renderItem={(item) => {
            return (
              <PricePlanItem
                data={{
                  ...item,
                }}
              />
            );
          }}
        />
      </PricePlanListModalInner>
    </ConfirmModal>
  );
};

export default PricePlanListModal;
