export enum GAEventCategory {
  PROJECT = "project",
  BILLING = "billing",
}

export enum GAEventAction {
  CREATE_PROJECT = "create_project",
  OPEN_CREATE_PROJECT_MODAL = "open_create_project_modal",
  OPEN_MANAGE_PLAN_MODAL = "open_manage_plan_modal",
  OPEN_ADD_NEW_CARD_MODAL = "open_add_new_card_modal",
  CLOSE_MANAGE_PLAN_MODAL = "close_manage_plan_modal",
  CLICK_COMPARE_PRICE_PLANS_LINK = "click_compare_price_plans_link",
  CLICK_PROJECT_CARD = "click_project_card",
}
