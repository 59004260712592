import { COPY_TEXT_AREA_ID } from "../constants";

function copyToClipboard(value: string): void {
  const tempTextArea = document.createElement("textarea");
  tempTextArea.id = COPY_TEXT_AREA_ID;
  tempTextArea.value = value;
  document.body.appendChild(tempTextArea);
  tempTextArea.select();
  document.execCommand("copy");
  document.body.removeChild(tempTextArea);
}

export default copyToClipboard;
