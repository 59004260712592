import React, { FC } from "react";

import { Typo } from "@cochlearai/ui";
import styled from "styled-components";

const Button = styled.button`
  background: unset;
  border: unset;
  cursor: pointer;
  padding: 0;
  color: ${(p) => p.theme.colors.grey[80]};
`;

const ConfirmBtn = styled(Button)`
  background: unset;
  border: unset;
  margin-left: 16px;
  color: ${(p) => p.theme.colors.blue[60]};
`;

interface Props {
  onClickConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  onClickClear?: React.MouseEventHandler<HTMLButtonElement>;
  confirmDisabled?: boolean;
}

const CalendarBottomButtons: FC<Props> = ({
  onClickClear,
  onClickConfirm,
  confirmDisabled,
}) => {
  return (
    <>
      <ConfirmBtn onClick={onClickConfirm} disabled={confirmDisabled}>
        <Typo variant="button">Confirm</Typo>
      </ConfirmBtn>
      <Button onClick={onClickClear}>
        <Typo variant="button">Cancel</Typo>
      </Button>
    </>
  );
};

export default CalendarBottomButtons;
