import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchGetWebhookHistory } from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";
import { APIError, WebhookHistory } from "~/client/types";
import { SWR_KEY } from "~/client/types/swrKey";
import { setServerError } from "~/client/store/modules";

function useWebhookHistory({ id }: { id?: string }) {
  const dispatch = useAppDispatch();
  const [webhookHistory, setWebhookHistory] = useState<WebhookHistory[]>([]);

  const { data, error, isValidating } = useSWR(
    id ? SWR_KEY.POST_ACTION_INITIALIZE_WEBHOOK_HISTORY : null,
    async () => {
      const res = await fetchGetWebhookHistory({
        params: {
          id: id!,
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setWebhookHistory(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    webhookHistory,
    isValidating,
  };
}

export default useWebhookHistory;
