import { useCallback, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "~/client/hooks";
import {
  setClientError,
  setClientInfo,
  setServerError,
} from "~/client/store/modules";
import { selectedAlertState } from "~/client/lib";

function useAlert() {
  const { clientError, serverError, clientInfo } =
    useAppSelector(selectedAlertState);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const initializeError = useCallback(() => {
    if (clientError) {
      dispatch(setClientError(null));
    }
    if (serverError) {
      dispatch(setServerError(null));
    }
  }, [clientError, dispatch, serverError]);

  const initializeInfo = useCallback(() => {
    dispatch(setClientInfo(null));
  }, [dispatch]);

  useEffect(() => {
    if (!clientError) return;
    setErrorMessage(clientError.text);
  }, [clientError]);

  useEffect(() => {
    if (!serverError) return;
    setErrorMessage(`http ${serverError.response?.status} error`);
  }, [serverError]);

  return {
    errorMessage,
    clientError,
    serverError,
    clientInfo,
    initializeError,
    initializeInfo,
  };
}

export default useAlert;
