import { APIError, InvoiceHistory } from "~/client/types";
import { useEffect, useState } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { fetchInvoices } from "~/client/api";
import { getBetweenYear } from "~/client/lib/helpers";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useAuthenticate from "./useAuthenticate";
import useSWR from "swr";

function useInvoiceList() {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticate();
  const createdAt = user?.identity?.created_at;
  const yearsFromUserCreatedAt = createdAt
    ? getBetweenYear(createdAt, new Date())
    : [];
  const [invoiceList, setInvoiceList] = useState<InvoiceHistory[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>(
    yearsFromUserCreatedAt[yearsFromUserCreatedAt.length - 1] ?? "",
  );

  const { data, error, isValidating } = useSWR(
    user ? SWR_KEY.INVOICE_INITIALIZE_INVOICE_LIST : null,
    async () => {
      const res = await fetchInvoices({
        params: {
          year: selectedYear,
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setInvoiceList(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    invoiceList,
    selectedYear,
    setSelectedYear,
    yearsFromUserCreatedAt,
    isValidating,
  };
}

export default useInvoiceList;
