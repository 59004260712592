import React, { FC, SVGProps } from "react";

const CodeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill={props.fill ?? "white"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_1086_6162">
      <path d="M4.66455 5.20508L8.8293 9.36983L4.66455 13.5346L0.499799 9.36983L4.66455 5.20508Z" />
    </mask>
    <path
      d="M0.499799 9.36983L-0.560862 8.30917L-1.62152 9.36983L-0.560862 10.4305L0.499799 9.36983ZM5.72521 12.4739L1.56046 8.30917L-0.560862 10.4305L3.60389 14.5952L5.72521 12.4739ZM1.56046 10.4305L5.72521 6.26574L3.60389 4.14442L-0.560862 8.30917L1.56046 10.4305Z"
      mask="url(#path-1-inside-1_1086_6162)"
    />
    <mask id="path-3-inside-2_1086_6162">
      <path d="M13.3354 5.20508L9.1707 9.36983L13.3354 13.5346L17.5002 9.36983L13.3354 5.20508Z" />
    </mask>
    <path
      d="M17.5002 9.36983L18.5609 8.30917L19.6215 9.36983L18.5609 10.4305L17.5002 9.36983ZM12.2748 12.4739L16.4395 8.30917L18.5609 10.4305L14.3961 14.5952L12.2748 12.4739ZM16.4395 10.4305L12.2748 6.26574L14.3961 4.14442L18.5609 8.30917L16.4395 10.4305Z"
      mask="url(#path-3-inside-2_1086_6162)"
    />
    <rect
      x="10.123"
      y="3.41699"
      width="1.5"
      height="12.066"
      transform="rotate(17 10.123 3.41699)"
    />
  </svg>
);

export default CodeIcon;
