export enum UPLOAD_HISTORY_TABLE_COLUMN_TYPE {
  DATE = "DATE",
  FILE_NAME = "FILE_NAME",
  FILE_SIZE = "FILE_SIZE",
  FILE_LENGTH = "FILE_LENGTH",
}

export interface UploadHistoryTableColumnItem {
  columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE;
  label: string;
}

export interface UploadHistory {
  session_id: string;
  created_at: number;
  file_name: string;
  file_size: number;
  file_length: number;
}

export enum UploadStatus {
  INITIATING = "initiating",
  UPLOADING = "uploading",
  ANALYZING = "analyzing",
  FINISHED = "finished",
}

export interface FileInfo {
  name: string;
  size: number;
  duration?: number;
}
