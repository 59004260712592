import React, { FC, SVGProps } from "react";

const NotFoundIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    fill="none"
    viewBox="0 0 588 230"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M177 144.066v35.7H2.331L0 152.122 101.071 0h38.131L97.908 65.556l-50.286 78.51H177ZM149.526 0v230h-47.955V0h47.955Z"
      fill="#F1F1F2"
    />
    <path
      d="M588 144.066v35.7H413.331L411 152.122 512.071 0h38.131l-41.294 65.556-50.286 78.51H588ZM560.526 0v230h-47.955V0h47.955Z"
      fill="#E4E4E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M375 134.064V95.321c0-13.427-1.261-25.317-3.783-35.669a113.453 113.453 0 0 0-2.003-7.265l-41.745 41.835v45.684c0 10.147-.713 18.757-2.138 25.829-1.425 7.072-3.618 12.812-6.579 17.22-2.85 4.304-6.359 7.482-10.526 9.532-4.056 1.947-8.716 2.921-13.979 2.921-4.276 0-8.224-.615-11.842-1.845-3.509-1.23-6.633-3.178-9.375-5.842-2.631-2.768-4.934-6.304-6.907-10.609-1.864-4.407-3.289-9.686-4.276-15.835a89.168 89.168 0 0 1-.156-1.136l-35.116 35.192c.348.597.702 1.186 1.063 1.766 4.824 7.482 10.525 13.683 17.104 18.603 6.688 4.92 14.199 8.558 22.532 10.916 8.333 2.255 17.324 3.382 26.973 3.382 12.061 0 23.025-1.845 32.893-5.535 9.868-3.69 18.366-9.378 25.492-17.065 7.237-7.79 12.774-17.732 16.612-29.826 3.837-12.197 5.756-26.701 5.756-43.51Z"
      fill="#E5E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M369.214 52.387c-2.317-7.405-5.268-13.952-8.852-19.64-4.714-7.584-10.416-13.785-17.104-18.603-6.688-4.817-14.199-8.353-22.532-10.608C312.503 1.18 303.566 0 293.918 0c-12.061 0-23.026 1.845-32.894 5.535-9.868 3.587-18.42 9.224-25.656 16.912-7.127 7.687-12.664 17.578-16.612 29.672C214.919 64.111 213 78.512 213 95.321v38.743c0 13.53 1.261 25.522 3.783 35.976 2.344 9.72 5.636 18.199 9.876 25.438a18.673 18.673 0 0 1-.084-.145l35.116-35.192c-.773-5.886-1.16-12.631-1.16-20.235V89.325c0-10.045.713-18.552 2.138-25.522 1.535-7.072 3.728-12.709 6.579-16.911 2.85-4.305 6.304-7.431 10.361-9.379 4.167-1.947 8.936-2.92 14.309-2.92 4.276 0 8.168.614 11.677 1.844 3.509 1.23 6.633 3.178 9.375 5.842 2.741 2.665 5.043 6.15 6.907 10.455 1.864 4.202 3.235 9.327 4.112 15.374.987 6.047 1.48 13.12 1.48 21.217v50.581-45.684l41.745-41.835Z"
      fill="#F1F1F2"
    />
  </svg>
);

export default NotFoundIcon;
