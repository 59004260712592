import { Toast } from "@cochlearai/ui";
import { FC } from "react";
import { BaseToastProps } from "~/client/types";

const ErrorToast: FC<BaseToastProps> = ({ open, message, onClose }) => {
  return (
    <Toast open={open} message={message} onClose={onClose} severity="error" />
  );
};

export default ErrorToast;
