import { Card, HyperlinkButton, InfoTooltip, Typo } from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import moment from "moment";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { convertUsageTimeFormat, numberWithCommas } from "~/client/lib/helpers";
import { InvoiceInfo, PAGE_ROUTE_PATH_NAME } from "~/client/types";

const UsageCostCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  margin-bottom: 24px;
  border: ${(p) => `1px solid ${p.theme.colors.grey[20]}`};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const UsageContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.query.md} {
    flex-direction: column;

    h1 {
      text-align: start;
      margin-top: 20px;
    }
  }
`;

const BalanceContainer = styled(Flex)`
  margin-top: 20px;
`;

interface Props {
  invoice: InvoiceInfo | null;
}

const UsageCostInfo: FC<Props> = ({ invoice }) => {
  const cost = invoice?.sub_total || 0;
  const balance = invoice?.applied_balance || 0;
  const discount = invoice?.discount;
  const remainedDiscount = convertUsageTimeFormat({
    seconds: discount?.remaining ?? 0,
  });
  const totalDiscount = convertUsageTimeFormat({
    seconds: discount?.total ?? 0,
  });
  const history = useHistory();
  const { colors } = useTheme();
  const [usageInfoPopoverIsOpen, setUsageInfoPopoverOpen] =
    useState<boolean>(false);
  const [balancePopoverIsOpen, setBalancePopoverOpen] =
    useState<boolean>(false);

  return (
    <UsageCostCard>
      <UsageContainer>
        <div>
          <Flex>
            <Typo variant="h3" style={{ marginRight: "8px" }}>
              Usage cost
            </Typo>
            <InfoTooltip
              open={usageInfoPopoverIsOpen}
              text="Usage cost is the amount of cost you spent on this month."
              onMouseOver={() => setUsageInfoPopoverOpen(true)}
              onMouseOut={() => setUsageInfoPopoverOpen(false)}
            />
          </Flex>
          <BalanceContainer>
            <Typo variant="body" style={{ color: colors.grey[70] }}>
              {balance !== 0 ? (
                "You have a balance :"
              ) : cost > 0 ? (
                <>
                  You will be charged in{" "}
                  <span style={{ color: colors.black }}>
                    {moment().add(1, "month").format("MMM")} 1.
                  </span>
                </>
              ) : (
                "You won’t be charged until you have a usage cost."
              )}
            </Typo>
            {balance !== 0 && (
              <>
                <Typo
                  variant="button"
                  style={{ color: colors.red, margin: "0 8px" }}
                >
                  {balance < 0 ? "-" : ""}${Math.abs(balance).toFixed(2)}
                </Typo>
                <InfoTooltip
                  open={balancePopoverIsOpen}
                  text="Usage cost will be deducted from your balance first."
                  onMouseOver={() => setBalancePopoverOpen(true)}
                  onMouseOut={() => setBalancePopoverOpen(false)}
                />
              </>
            )}
          </BalanceContainer>
        </div>
        <div style={{ textAlign: "end" }}>
          <Typo variant="h1">
            {numberWithCommas({
              value: cost,
              decimal: 2,
              currency: "usd",
            })}
          </Typo>
          {discount && (
            <Typo
              variant="caption1"
              style={{
                color: colors.grey[70],
                marginTop: "10px",
                marginBottom: "17px",
              }}
            >
              {`Remaining Free API : ${remainedDiscount.minutes}min ${remainedDiscount.seconds}sec / ${totalDiscount.minutes}min ${totalDiscount.seconds}sec`}
            </Typo>
          )}
          <HyperlinkButton
            text="View more details"
            rightArrow
            onClick={() => history.push(PAGE_ROUTE_PATH_NAME.BILLING_INVOICES)}
          />
        </div>
      </UsageContainer>
    </UsageCostCard>
  );
};

export default UsageCostInfo;
