import {
  Button,
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToastCloseIcon,
  Typo,
} from "@cochlearai/ui";
import { color, media } from "@cochlearai/util";
import React, { FC, ReactNode } from "react";
import styled, { useTheme } from "styled-components";
import {
  EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE,
  SDKChannel,
} from "~/client/types/sdk";

import moment from "moment";
import { EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_ITEMS } from "~/client/lib/constants";
import { getEdgeSDKDeviceListTableColumnWidth } from "~/client/lib/helpers";

const Container = styled(Table)`
  min-width: ${media.size.lg};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getEdgeSDKDeviceListTableColumnWidth(p.columnType)};
  padding: 12.5px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
`;

const TableHeadItemText = styled(Typo)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: left;
`;

const TableRowContainer = styled(TableRow)<{ selected: boolean }>`
  cursor: pointer;
  background-color: ${(p) =>
    p.selected ? p.theme.colors.semantic.grey[10] : p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
  &:hover {
    background-color: ${(p) => p.theme.colors.semantic.grey[10]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE;
}>`
  padding: 20px;
  max-width: ${(p) => getEdgeSDKDeviceListTableColumnWidth(p.columnType)};
`;

const TableBodyItemText = styled(Typo)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DeleteButton = styled(Button)`
  background-color: transparent;
  border-color: ${(p) =>
    color.convertHexToRGB({ hex: p.theme.colors.red, alpha: 0.3 })};
  height: 30px;
  padding: 0 16px;

  &:hover {
    border-color: ${(p) => p.theme.colors.red};
  }
`;

const PendingButton = styled(Button)`
  background-color: transparent;
  border-color: ${(p) => p.theme.colors.grey[30]};
  height: 30px;
  padding: 0 16px;
  cursor: default;
  &:hover {
    border-color: ${(p) => p.theme.colors.grey[30]};
  }
`;

const PendingTypo = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
`;

const PendingBar = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${(p) => p.theme.colors.grey[30]};
  margin-left: 8px;
`;

interface Props {
  data: SDKChannel[];
  selectedSDKChannel?: SDKChannel | null;
  EmptyComponent?: ReactNode;
  onClickItem: (item: SDKChannel) => void;
  onDeleteItem: (item: SDKChannel) => void;
  onCancelDeleteItem: (item: SDKChannel) => void;
}

const EdgeSDKDeviceTable: FC<Props> = ({
  data,
  selectedSDKChannel,
  EmptyComponent,
  onClickItem,
  onDeleteItem,
  onCancelDeleteItem,
}) => {
  const { colors } = useTheme();
  return (
    <Scroll>
      <Container>
        <TableHead>
          <TableRow>
            {EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_ITEMS.map((item) => {
              return (
                <TableHeadItem
                  key={item.columnType}
                  component="th"
                  columnType={item.columnType}
                >
                  <TableHeadItemText variant="body">
                    {item.label}
                  </TableHeadItemText>
                </TableHeadItem>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRowContainer
                key={item.id}
                onClick={() => onClickItem(item)}
                selected={selectedSDKChannel?.id === item.id}
              >
                <TableBodyItem
                  columnType={
                    EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.INSTALLED_DATE
                  }
                >
                  <TableBodyItemText variant="body">
                    {moment(new Date(item.created_at))
                      .format("MM.DD.YYYY HH:mm:ss")
                      .toString()}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.ID}
                >
                  <TableBodyItemText variant="body">
                    {item.id}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={
                    EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.DEVICE_NAME
                  }
                >
                  <TableBodyItemText
                    variant="body"
                    style={{ color: colors.grey[70] }}
                  >
                    {item.device_name}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.LAST_SEEN}
                >
                  <TableBodyItemText
                    variant="body"
                    style={{ color: colors.grey[70] }}
                  >
                    {moment(new Date(item.last_seen)).fromNow()}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE.DELETE}
                >
                  {item.delete_pending ? (
                    <PendingButton
                      color="secondary"
                      onClick={(e: React.MouseEvent) => e.stopPropagation()}
                      rightIcon={
                        <ToastCloseIcon
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            onCancelDeleteItem(item);
                          }}
                          fill={colors.black}
                          style={{
                            cursor: "pointer",
                            marginLeft: "8px",
                            width: 8,
                            height: 8,
                          }}
                        />
                      }
                    >
                      <PendingTypo variant="caption1">pending</PendingTypo>
                      <PendingBar />
                    </PendingButton>
                  ) : (
                    <DeleteButton
                      color="secondary"
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        onDeleteItem(item);
                      }}
                    >
                      <Typo variant="caption1" style={{ color: colors.red }}>
                        delete
                      </Typo>
                    </DeleteButton>
                  )}
                </TableBodyItem>
              </TableRowContainer>
            );
          })}
          {data.length === 0 && EmptyComponent}
        </TableBody>
      </Container>
    </Scroll>
  );
};

export default EdgeSDKDeviceTable;
