import { MediumIcon, Typo, WhiteLogoIcon } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";
import getConfigs from "~/client/lib/getConfigs";

const {
  REACT_APP_OFFICIAL_WEB_URL: webSiteURL,
  REACT_APP_MEDIUM_URL: mediumURL,
} = getConfigs();

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 20px;
  justify-content: center;
`;
const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background: #d9d9d9;
`;

interface Props {
  onClose?: () => void;
  onClickStart?: () => void;
}

const CompleteStep: FC<Props> = ({ onClose, onClickStart }) => {
  const { colors } = useTheme();
  return (
    <div style={{ textAlign: "center" }}>
      <Typo variant="h4" style={{ color: "#ffffff" }}>
        Thank you for completing the survey!
      </Typo>
      <Typo variant="body" style={{ marginTop: "20px", color: "#ffffff" }}>
        For further inquiries, contact us at{" "}
        <a href="mailto:contact@cochl.ai">contact@cochl.ai</a>
      </Typo>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginTop: "12px",
          marginBottom: "20px",
        }}
      >
        <a href={mediumURL} target="_blank" rel="noopener">
          <MediumIcon style={{ cursor: "pointer" }} />
        </a>
        <a href={webSiteURL} target="_blank" rel="noopener">
          <WhiteLogoIcon style={{ cursor: "pointer" }} />
        </a>
      </div>
      <Divider />
      <ButtonWrapper>
        <Typo
          variant="button"
          style={{ padding: "10px", cursor: "pointer", color: "#ffffff" }}
          onClick={onClose}
        >
          Close survey
        </Typo>
        <Typo
          variant="button"
          style={{
            padding: "10px 14px",
            cursor: "pointer",
            background: "#ffffff",
            color: colors.blue[60],
            borderRadius: "100000px",
          }}
          onClick={onClickStart}
        >
          Take survey again
        </Typo>
      </ButtonWrapper>
    </div>
  );
};

export default CompleteStep;
