import {
  ArrowForwardIcon,
  ConfirmModal,
  HyperlinkButton,
  ToastCheckIcon,
  Typo,
} from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import { useTranslation } from "react-i18next";
import { PLAN_META } from "~/client/lib/constants";
import { formatPlanTitle } from "~/client/lib/helpers";
import { PlanType } from "~/client/types";

const LastConfirmModalInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const LastConfirmModalSubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.grey[70]};
  margin-top: 14px;
`;

const LastConfirmModalInfoBox = styled.div`
  width: 100%;
  padding: 25px 30px;
  background-color: ${(p) => p.theme.colors.grey[10]};
  border: 1px solid ${(p) => p.theme.colors.grey[20]};
  border-radius: 4px;

  display: flex;
  align-items: center;
`;

const LastConfirmModalBottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 14px;
`;

const PricePlanBadge = styled.div`
  background: #f9f9fa;
  border-radius: 2px;
  padding: 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onClickOtherPlan?: () => void;
  currentSubscriptionId?: PlanType;
  newSubscriptionId?: PlanType;
}

const PricePlanConfirmModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  onClickOtherPlan,
  currentSubscriptionId,
  newSubscriptionId,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const currentSubscriptionColor = currentSubscriptionId
    ? colors.plan_color[currentSubscriptionId]
    : "";

  const newSubscriptionColor = newSubscriptionId
    ? colors.plan_color[newSubscriptionId]
    : "";

  const lastConfirmPlanFeaturesWithKey = newSubscriptionId
    ? PLAN_META[newSubscriptionId].featuresWithKey
    : [];

  return (
    <ConfirmModal
      open={open}
      onClose={onClose}
      onAbort={onClose}
      onConfirm={onConfirm}
      title="Confirm your plan changes."
      confirmText="Confirm"
      abortText={t("Cancel", { ns: "common" })}
      subTitle={
        <LastConfirmModalSubTitleContainer>
          <Typo variant="h5">You are going to change your plan to</Typo>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          >
            <PricePlanBadge>
              <Typo
                variant="body"
                style={{
                  color: currentSubscriptionColor,
                }}
              >
                {formatPlanTitle(currentSubscriptionId ?? "")}
              </Typo>
            </PricePlanBadge>
            <ArrowForwardIcon
              fill={colors.black}
              style={{
                marginLeft: "4px",
                marginRight: "4px",
              }}
            />
            <PricePlanBadge>
              <Typo variant="body" style={{ color: newSubscriptionColor }}>
                {newSubscriptionId && formatPlanTitle(newSubscriptionId)}
              </Typo>
            </PricePlanBadge>
          </span>
          <Typo variant="h5">plan.</Typo>
        </LastConfirmModalSubTitleContainer>
      }
    >
      <LastConfirmModalInner>
        <LastConfirmModalInfoBox>
          {newSubscriptionId && <ToastCheckIcon fill={newSubscriptionColor} />}
          <Typo
            style={{
              color: newSubscriptionColor,
              marginLeft: "10px",
              marginRight: "20px",
            }}
            variant="h4"
          >
            {newSubscriptionId && formatPlanTitle(newSubscriptionId)}
          </Typo>
          {lastConfirmPlanFeaturesWithKey.map(({ key, value }, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <Typo variant="button">{key}</Typo>{" "}
                <Typo
                  variant="caption1"
                  style={{
                    textAlign: "left",
                    marginLeft: "6px",
                    color: colors.grey[90],
                  }}
                >
                  {value}
                </Typo>
              </div>
            );
          })}
        </LastConfirmModalInfoBox>
        <LastConfirmModalBottomTextContainer>
          <Typo
            variant="caption1"
            style={{
              textAlign: "left",
              color: colors.grey[60],
              marginRight: "10px",
            }}
          >
            Changed your mind?
          </Typo>
          <HyperlinkButton
            text="Choose other plan"
            rightArrow
            onClick={onClickOtherPlan}
          />
        </LastConfirmModalBottomTextContainer>
      </LastConfirmModalInner>
    </ConfirmModal>
  );
};

export default PricePlanConfirmModal;
