import React, { FC } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

const Linker = styled(Link)``;

const LinkItemText = styled.div<{ selected?: boolean }>`
  font-weight: ${(p) => (p.selected ? "500" : "400")};
  &:hover {
    font-weight: 500;
  }
`;

interface Props {
  selected?: boolean;
  to: string;
  label: string;
}

const HeaderLink: FC<Props> = ({ selected, to, label }) => {
  return (
    <Linker to={to}>
      <LinkItemText selected={selected}>{label}</LinkItemText>
    </Linker>
  );
};

export default HeaderLink;
