export const Z_INDEXES = {
  BUTTON: 16,
  HEADER: 18,
  FOOTER: 18,
  SIDE_BAR: 19,
  POP_OVER: 20,
  MODAL_BACKGROUND: 21,
  MODAL: 22,
  MODAL_POP_OVER: 23,
  FULL_CENTER_LOADING: 24,
  TOAST: 25,
};

export const MODAL_WIDTH_BY_SIZE = {
  small: "540px",
  medium: "821px",
  large: "990px",
};
