import { Card, CardAddIcon, Typo } from "@cochlearai/ui";
import React, { FC } from "react";

import styled from "styled-components";

const Container = styled.div`
  width: 282px;
  height: 220px;
  cursor: pointer;
  margin-top: 24px;
`;

const CardContainer = styled(Card)`
  width: 100%;
  height: 100%;
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 45px 0;
`;

const CardTitleText = styled(Typo)`
  margin-top: 20px;
`;

const SubTitleText = styled(Typo)`
  margin-top: 10px;
  color: ${(p) => p.theme.colors.grey[70]};
`;

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const EmptyProjectItem: FC<Props> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <CardContainer hoverEffect>
        <CardContentContainer>
          <CardAddIcon style={{ margin: "auto" }} />
          <CardTitleText variant="h3">No projects, yet!</CardTitleText>
          <SubTitleText variant="body">Create a new project</SubTitleText>
        </CardContentContainer>
      </CardContainer>
    </Container>
  );
};

export default EmptyProjectItem;
