import {
  FetchGetWebhookHistoryParams,
  FetchGetWebhookHistoryResponse,
  FetchGetNotiConfigListParams,
  FetchGetNotiConfigListResponse,
  FetchUpdateNotiConfigParams,
  FetchUpdateNotiConfigResponse,
  FetchCreateTriggerConditionParams,
  FetchCreateTriggerConditionResponse,
  FetchUpdateTriggerConditionParams,
  FetchUpdateTriggerConditionResponse,
  FetchRemoveTriggerConditionParams,
  FetchRemoveTriggerConditionResponse,
  FetchRemoveMediumParams,
  FetchRemoveMediumResponse,
  FetchCreateMediumSettingParams,
  FetchCreateMediumSettingResponse,
  FetchUpdateMediumParams,
  FetchUpdateMediumResponse,
} from "~/client/types/api/postAction";

import { APICallPayloads } from "~/client/types/api";
import getConfigs from "~/client/lib/getConfigs";
import { makeAPIRequest } from "~/client/lib/api";

const {
  REACT_APP_POST_ACTION_API_BASE_URL: postActionApiBaseURL,
  REACT_APP_BFF_API_BASE_URL,
} = getConfigs();

const API_URL_PREFIX = postActionApiBaseURL ? `${postActionApiBaseURL}` : "";

export const fetchGetWebhookHistory = ({
  params,
  config,
}: APICallPayloads<FetchGetWebhookHistoryParams>): Promise<FetchGetWebhookHistoryResponse> => {
  const url = `${API_URL_PREFIX}/notification-configurations/${params.id}/webhook-history`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetNotiConfigList = ({
  params,
  config,
}: APICallPayloads<FetchGetNotiConfigListParams>): Promise<FetchGetNotiConfigListResponse> => {
  let url = `${API_URL_PREFIX}/notification-configurations?&`;
  const { project_id, medium_id, medium_type } = params;
  if (project_id) {
    url += `project_id=${project_id}&`;
  }
  if (medium_id) {
    url += `medium_id=${medium_id}&`;
  }
  if (medium_type) {
    url += `medium_type=${medium_type}&`;
  }
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchUpdateNotiConfig = ({
  params,
  config,
}: APICallPayloads<FetchUpdateNotiConfigParams>): Promise<FetchUpdateNotiConfigResponse> => {
  const { config_id, interval, active } = params;
  const url = `${API_URL_PREFIX}/notification-configurations/${config_id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
    data: {
      ...(typeof interval === "number" && { interval }),
      ...(typeof active === "boolean" && { active }),
    },
  });
};

export const fetchCreateTriggerCondition = ({
  params,
  config,
}: APICallPayloads<FetchCreateTriggerConditionParams>): Promise<FetchCreateTriggerConditionResponse> => {
  const { config_id, tag_name, interval } = params;
  const url = `${API_URL_PREFIX}/notification-configurations/${config_id}/trigger-conditions`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: {
      tag_name,
      ...(typeof interval === "number" && { interval }),
    },
  });
};

export const fetchRemoveTriggerCondition = ({
  params,
  config,
}: APICallPayloads<FetchRemoveTriggerConditionParams>): Promise<FetchRemoveTriggerConditionResponse> => {
  const url = `${API_URL_PREFIX}/notification-configurations/${params.config_id}/trigger-conditions/${params.id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

export const fetchUpdateTriggerCondition = ({
  params,
  config,
}: APICallPayloads<FetchUpdateTriggerConditionParams>): Promise<FetchUpdateTriggerConditionResponse> => {
  const { config_id, id, interval, active } = params;
  const url = `${API_URL_PREFIX}/notification-configurations/${config_id}/trigger-conditions/${id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
    data: {
      ...(typeof interval === "number" && { interval }),
      ...(typeof active === "boolean" && { active }),
    },
  });
};

export const fetchCreateMediumSetting = ({
  config,
  params,
}: APICallPayloads<FetchCreateMediumSettingParams>): Promise<FetchCreateMediumSettingResponse> => {
  const url = `${REACT_APP_BFF_API_BASE_URL}/notifications-manager/notification-configuration-settings/`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchRemoveMediumSetting = ({
  params,
  config,
}: APICallPayloads<FetchRemoveMediumParams>): Promise<FetchRemoveMediumResponse> => {
  const url = `${API_URL_PREFIX}/notification-configurations/${params.id}/settings`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

export const fetchUpdateMedium = ({
  params,
  config,
}: APICallPayloads<FetchUpdateMediumParams>): Promise<FetchUpdateMediumResponse> => {
  const { id, emails, urls, active } = params;
  const url = `${API_URL_PREFIX}/media/${id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
    data: {
      ...(emails && { emails }),
      ...(urls && { urls }),
      ...(typeof active === "boolean" && { active }),
    },
  });
};
