import { useAppDispatch, useAppSelector } from "..";

import { ClientError } from "~/client/types/error";
import { setClientError as setClientErrorAction } from "~/client/store/modules";
import { useCallback } from "react";

function useClientError(): {
  clientError: ClientError | null;
  setClientError: (clientError: ClientError | null) => void;
} {
  const clientError = useAppSelector((state) => state.alert.clientError);
  const dispatch = useAppDispatch();
  const setClientError = useCallback(
    (clientError: ClientError | null) => {
      dispatch(setClientErrorAction(clientError));
    },
    [dispatch],
  );

  return {
    clientError,
    setClientError,
  };
}

export default useClientError;
