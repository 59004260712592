import React, { FC, SVGProps } from "react";

const MoreIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="4"
    viewBox="0 0 16 4"
    fill="#131313"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" />
    <circle cx="8" cy="2" r="2" transform="rotate(-90 8 2)" />
    <circle cx="14" cy="2" r="2" transform="rotate(-90 14 2)" />
  </svg>
);

export default MoreIcon;
