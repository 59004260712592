import moment from "moment";

function isValidCalendarInputDate(date: Date | string): boolean {
  let targetDate = date;
  if (!(date instanceof Date)) {
    targetDate = new Date(date);
  }

  if (!moment(targetDate).isValid()) {
    return false;
  }
  const momentTargetDate = moment(targetDate);
  const currentYear = moment().year();
  if (Math.abs(currentYear - momentTargetDate.year()) > 100) {
    return false;
  }
  return true;
}

export default isValidCalendarInputDate;
