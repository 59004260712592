import { useCallback, useState } from "react";
import { fetchDeletePaymentMethodById } from "~/client/api";
import { useAppDispatch } from "~/client/hooks";
import { APIError } from "~/client/types";
import { filterPaymentList, setServerError } from "~/client/store/modules";

function useDeletePaymentMethod() {
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deletePaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      if (isDeleting) return;
      setIsDeleting(true);
      try {
        await fetchDeletePaymentMethodById({
          params: {
            id: paymentMethodId,
          },
        });
        dispatch(filterPaymentList({ paymentId: paymentMethodId }));
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        setIsDeleting(false);
      }
    },
    [dispatch, isDeleting],
  );

  return {
    deletePaymentMethod,
    isValidating: isDeleting,
  };
}

export default useDeletePaymentMethod;
