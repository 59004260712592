import { useCallback, useRef } from "react";

import { APIError } from "~/client/types";
import { OrganizationMember } from "~/client/types/organization";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchRemoveMember } from "~/client/api/organization";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useAuthenticate from "./useAuthenticate";
import { useSWRConfig } from "swr";

function useRemoveMember() {
  const isRemoving = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const { customer } = useAuthenticate();

  const remove = useCallback(
    async (members: OrganizationMember[]) => {
      if (isRemoving.current) return;
      if (members.length === 0 || !customer) return;
      isRemoving.current = true;
      try {
        await Promise.all(
          members.map(async ({ user_id }) => {
            await fetchRemoveMember({
              params: {
                user_id,
                organization_id: customer.organization_id,
              },
            });
          }),
        );
        mutate(SWR_KEY.ORGANIZATION_INITIALIZE_MEMBER_LIST);
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        isRemoving.current = false;
      }
    },
    [customer, mutate, dispatch],
  );

  return {
    remove,
    isValidating: isRemoving.current,
  };
}

export default useRemoveMember;
