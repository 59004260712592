import { Toast } from "@cochlearai/ui";
import React, { ErrorInfo, PropsWithChildren } from "react";
import { handleUnauthenticated, handleUnverified } from "~/client/lib/auth";
import { isHTTPError } from "~/client/lib/helpers";
import { APIError, API_ERROR_STATUS, HTTPError } from "~/client/types";

const PAYMENT_WHOAMI_URL_PATH = "/payment/whoami";
const ACCOUNT_WHOAMI_URL_PATH = "/kratos/sessions/whoami";

interface Props extends PropsWithChildren {}
interface State {
  error: Error | HTTPError | null;
  errorInfo: ErrorInfo | null;
  toastMessage: string | null;
}

class AuthenticatorErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      toastMessage: null,
    };
    this.parseHTTPErrorMessage = this.parseHTTPErrorMessage.bind(this);
  }

  componentDidCatch(error: Error | HTTPError, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
      toastMessage: null,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { error } = this.state;
    if (!error) {
      return;
    }
    if (JSON.stringify(prevState.error) !== JSON.stringify(error)) {
      if (isHTTPError(error)) {
        const httpError = error as HTTPError;
        this.setState((prevState) => ({
          ...prevState,
          toastMessage: this.parseHTTPErrorMessage(httpError),
        }));
        if (
          httpError.response?.config?.url?.includes(ACCOUNT_WHOAMI_URL_PATH)
        ) {
          handleUnauthenticated();
        }
        if (
          httpError.response?.config?.url?.includes(PAYMENT_WHOAMI_URL_PATH)
        ) {
          handleUnverified();
        }
      } else {
        const commonError = error as Error;
        this.setState((prevState) => ({
          ...prevState,
          toastMessage: commonError.message,
        }));
      }
    }
  }

  private parseHTTPErrorMessage(httpError: HTTPError): string {
    const { response } = httpError;

    switch (response?.status) {
      case API_ERROR_STATUS.UNAUTHORIZED:
        if (
          httpError.response?.config?.url?.includes(PAYMENT_WHOAMI_URL_PATH)
        ) {
          return "Email is not verified. Please verify account.";
        }
        return "Unauthenticated";
      default:
        return (
          (
            httpError as APIError<{
              error?: {
                message?: string;
              };
            }>
          ).response?.data?.error?.message ?? "Something went wrong"
        );
    }
  }

  render() {
    const { children } = this.props;
    const { error, toastMessage } = this.state;
    if (!error) {
      return children;
    }
    return (
      <Toast
        open={error !== null}
        onClose={() => {
          //
        }}
        message={toastMessage ?? "Something went wrong."}
        severity="error"
      />
    );
  }
}

export default AuthenticatorErrorBoundary;
