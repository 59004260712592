function refineUserName({
  givenName,
  familyName,
}: {
  givenName?: string;
  familyName?: string;
}) {
  const gName = givenName?.trim() || "";
  const fName = familyName?.trim() || "";
  return `${gName}${gName && fName ? " " : ""}${fName}`;
}

export default refineUserName;
