import {
  Button,
  Card,
  CopyIcon,
  Input,
  InputCancelIcon,
  Typo,
} from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import { color, media } from "@cochlearai/util";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "~/client/lib/helpers";
import { FilterParamsValue } from "~/client/types/analytics";
import { Project, ProjectType } from "~/client/types/project";

const Container = styled(Card)`
  border: 1px solid ${(p) => p.theme.colors.grey[20]};
  box-shadow: none;
  padding: 40px;

  ${media.query.lg} {
    padding: 20px;
  }
`;

const SettingItem = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;

  ${media.query.lg} {
    flex-direction: column;
    align-items: unset;
  }
`;

const TitleText = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
`;

const SubTitleText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[70]};
  margin-top: 14px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 446px;

  ${media.query.lg} {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.colors.grey[20]};
  height: 1px;
  margin: 40px 0;
`;

const InputActionWrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.query.lg} {
    flex-direction: column;
    align-items: unset;
    margin-top: 24px;
  }
`;

const ActionButton = styled(Button)`
  min-width: 95px;
  height: 40px;
  margin-left: 57px;
  ${media.query.lg} {
    margin-left: unset;
    margin-top: 24px;
  }
`;

const SettingItemInput = styled(Input)`
  width: 486px;
  & svg {
    margin-right: 20px;
  }

  ${media.query.lg} {
    width: 100%;
  }
`;

const SettingItemCopy = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 486px;
  min-height: 60px;
  height: auto;
  padding: 20px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.colors.grey[10]};
  color: ${(p) => p.theme.colors.grey[80]};
  gap: 10px;
  ${media.query.lg} {
    width: 100%;
  }
`;

const ProjectKeyTypo = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TagChip = styled(Typo)`
  padding: 4px 10px;
  border-radius: 100px;
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) =>
    color.convertHexToRGB({
      hex: p.theme.colors.blue[60],
      alpha: 0.05,
    })};
`;

interface Props {
  currentProject: Project;
  projectNameValue: string;
  onChangeProjectNameValue: (projectName: string) => void;
  projectKey: string;
  onClickUpdateProjectName: () => void;
  onClickResetKey?: () => void;
  trackedTags?: FilterParamsValue[];
  onClickEditTags?: () => void;
}

const ProjectSettingItem: FC<Props> = ({
  currentProject,
  projectNameValue,
  onChangeProjectNameValue,
  projectKey,
  onClickUpdateProjectName,
  onClickResetKey,
  trackedTags = [],
  onClickEditTags,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  return (
    <Container>
      <SettingItem>
        <TitleWrapper>
          <TitleText variant="h3">
            {t("Project name", { ns: "common" })}
          </TitleText>
          <SubTitleText variant="body">
            {t("Used to identify your project on the dashboard.", {
              ns: "common",
            })}
          </SubTitleText>
        </TitleWrapper>
        <InputActionWrapper>
          <SettingItemInput
            value={projectNameValue}
            onChange={(e) => onChangeProjectNameValue(e.target.value)}
            rightIcon={
              projectNameValue !== currentProject.name && (
                <InputCancelIcon
                  width={20}
                  height={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => onChangeProjectNameValue(currentProject.name)}
                />
              )
            }
          />
          <ActionButton
            color="primary"
            disabled={
              projectNameValue === currentProject.name ||
              projectNameValue.split(" ").join("").length === 0
            }
            onClick={onClickUpdateProjectName}
          >
            Save
          </ActionButton>
        </InputActionWrapper>
      </SettingItem>
      <Divider />
      <SettingItem>
        <TitleWrapper>
          <TitleText variant="h3">
            {t("Project key", { ns: "common" })}
          </TitleText>
          <SubTitleText variant="body">
            {t("Used when starting with Cochl.Sense API and Cochl.Sense SDK.", {
              ns: "common",
            })}
          </SubTitleText>
        </TitleWrapper>
        <InputActionWrapper>
          <SettingItemCopy>
            <ProjectKeyTypo variant="body">{projectKey}</ProjectKeyTypo>
            <CopyIcon
              width={20}
              height={20}
              fill={colors.grey[50]}
              style={{
                cursor: "pointer",
                marginLeft: "auto",
                minWidth: "20px",
              }}
              onClick={() => copyToClipboard(projectKey)}
            />
          </SettingItemCopy>
          <ActionButton
            color="primary"
            onClick={() => {
              onClickResetKey && onClickResetKey();
            }}
          >
            Reset key
          </ActionButton>
        </InputActionWrapper>
      </SettingItem>
      {currentProject.product_type === ProjectType.CLOUD_API && (
        <>
          <Divider />
          <SettingItem>
            <TitleWrapper>
              <TitleText variant="h3">Analytics Preselected Tags</TitleText>
              <SubTitleText variant="body">
                Get analytics on specific tags and get insights on them.
              </SubTitleText>
            </TitleWrapper>
            <InputActionWrapper>
              <SettingItemCopy>
                {trackedTags?.map((tag, i) => (
                  <TagChip variant="caption1" key={i}>
                    {tag.label}
                  </TagChip>
                ))}
              </SettingItemCopy>
              <ActionButton
                color="primary"
                onClick={() => {
                  onClickEditTags && onClickEditTags();
                }}
              >
                Edit Tags
              </ActionButton>
            </InputActionWrapper>
          </SettingItem>
        </>
      )}
    </Container>
  );
};

export default ProjectSettingItem;
