import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchGetNotiConfigList } from "~/client/api/postAction";
import { useAppDispatch } from "~/client/hooks";
import {
  APIError,
  MediumType,
  NotificationConfig,
  Project,
} from "~/client/types";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";

function useWebhookList({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const dispatch = useAppDispatch();
  const [webhookList, setWebhookList] = useState<NotificationConfig[]>();

  const { data, error, isValidating } = useSWR(
    currentProject ? SWR_KEY.POST_ACTION_INITIALIZE_WEBHOOK_LIST : null,
    async () => {
      const res = await fetchGetNotiConfigList({
        params: {
          project_id: currentProject!.id,
          medium_type: MediumType.WEBHOOK,
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setWebhookList(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    webhookList,
    isValidating,
  };
}

export default useWebhookList;
