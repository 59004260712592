import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Project,
  ProjectFilterType,
  ProjectType,
} from "~/client/types/project";

import { SHOW_MORE_LIMIT } from "~/client/lib/constants";
import { cloneDeep } from "lodash-es";

export interface ProjectState {
  projectTab: ProjectType;
  projectCount: {
    [ProjectType.ALL]: number;
    [ProjectType.CLOUD_API]: number;
    [ProjectType.EDGE_SDK]: number;
  };
  projectList: Project[] | null;
  currentProject: Project | null;
  selectedProjectTab: ProjectType;
  filterType: ProjectFilterType;
  visibleCount: number;
  isLoading: boolean;
}

const initialState: ProjectState = {
  projectTab: ProjectType.ALL,
  projectCount: {
    [ProjectType.ALL]: 0,
    [ProjectType.CLOUD_API]: 0,
    [ProjectType.EDGE_SDK]: 0,
  },
  projectList: null,
  currentProject: null,
  selectedProjectTab: ProjectType.ALL,
  filterType: ProjectFilterType.ALL,
  visibleCount: SHOW_MORE_LIMIT.PROJECT,
  isLoading: false,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    initializeProjectList: (state, action: PayloadAction<Project[]>) => {
      state.projectList = action.payload;
      const count = cloneDeep(initialState.projectCount);
      action.payload.map((project: Project) => {
        count.all++;
        count[project.product_type]++;
      });
      state.projectCount = count;
    },
    unshiftProjectList: (state, action: PayloadAction<Project>) => {
      if (state.projectList) {
        state.projectList = [action.payload, ...state.projectList];
      } else {
        state.projectList = [action.payload];
      }

      state.projectCount = {
        ...state.projectCount,
        all: state.projectCount.all + 1,
        [action.payload.product_type]:
          state.projectCount[action.payload.product_type] + 1,
      };
    },
    filterProjectList: (
      state,
      action: PayloadAction<{ projectId: string }>,
    ) => {
      if (state.projectList) {
        state.projectList = state.projectList.filter((item) => {
          if (item.id === action.payload.projectId) {
            state.projectCount = {
              ...state.projectCount,
              all: state.projectCount.all - 1,
              [item.product_type]: state.projectCount[item.product_type] - 1,
            };
          }
          return item.id !== action.payload.projectId;
        });
      }
    },
    initializeCurrentProject: (state, action: PayloadAction<Project>) => {
      state.currentProject = action.payload;

      if (state.projectList) {
        state.projectList = state.projectList.map((project) =>
          project.id === action.payload.id ? action.payload : project,
        );
      }
    },
    setProjectTab: (state, action: PayloadAction<ProjectType>) => {
      state.selectedProjectTab = action.payload;
      state.visibleCount = initialState.visibleCount;
      state.filterType = initialState.filterType;
    },
    setFilterType: (state, action: PayloadAction<ProjectFilterType>) => {
      state.filterType = action.payload;
    },
    setVisibleCount: (state, action: PayloadAction<number>) => {
      state.visibleCount = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});

export const {
  initializeProjectList,
  unshiftProjectList,
  filterProjectList,
  initializeCurrentProject,
  setProjectTab,
  setFilterType,
  setVisibleCount,
  setLoading,
} = projectSlice.actions;
export const { reducer: projectReducer } = projectSlice;
