/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  FetchGetNotificationsParams,
  FetchGetNotificationsResponse,
  FetchReadNotificationParams,
  FetchReadNotificationResponse,
  FetchReadAllNotificationParams,
  FetchReadAllNotificationResponse,
} from "~/client/types/api/notification";

import { APICallPayloads } from "~/client/types/api";
import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_NOTIFICATIONS_API_BASE_URL: notificationAPIBaseURL } =
  getConfigs();

const API_URL_PREFIX = notificationAPIBaseURL
  ? `${notificationAPIBaseURL}`
  : "";

export const fetchGetNotifications = ({
  config,
}: APICallPayloads<FetchGetNotificationsParams>): Promise<FetchGetNotificationsResponse> => {
  const url = `${API_URL_PREFIX}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchReadAllNotification = ({
  config,
}: APICallPayloads<FetchReadAllNotificationParams>): Promise<FetchReadAllNotificationResponse> => {
  const url = `${API_URL_PREFIX}/status`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
  });
};

export const fetchReadNotification = ({
  params,
  config,
}: APICallPayloads<FetchReadNotificationParams>): Promise<FetchReadNotificationResponse> => {
  const url = `${API_URL_PREFIX}/status/${params.id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
  });
};
