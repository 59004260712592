import React, { FC, SVGProps } from "react";

const VisaIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="8"
    viewBox="0 0 24 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.11245 0.141272L5.97034 7.87885H3.92035L2.37411 1.70391C2.28024 1.32357 2.19861 1.18423 1.91312 1.024C1.44699 0.762998 0.677247 0.518127 0 0.366146L0.0459998 0.141272H3.34586C3.76648 0.141272 4.1446 0.430267 4.2401 0.930202L5.05672 5.40756L7.07496 0.141143H9.11245V0.141272ZM17.1447 5.35273C17.1529 3.31054 14.4087 3.19804 14.4275 2.28577C14.4334 2.00813 14.6895 1.71294 15.2502 1.6376C15.528 1.60005 16.2937 1.57141 17.162 1.98387L17.5027 0.343053C17.036 0.168236 16.4357 0 15.6887 0C13.7718 0 12.4228 1.05174 12.4114 2.55773C12.3991 3.67165 13.3743 4.29325 14.1091 4.6634C14.8649 5.04245 15.1185 5.28603 15.1157 5.62508C15.1103 6.14411 14.5128 6.37324 13.9544 6.38227C12.9797 6.39775 12.4141 6.11005 11.9632 5.89369L11.6117 7.58883C12.0648 7.80338 12.9012 7.99058 13.7683 8C15.8057 8 17.1384 6.96129 17.1447 5.35273ZM22.2064 7.87898H24L22.4344 0.141272H20.7789C20.4066 0.141272 20.0926 0.364985 19.9536 0.708941L17.0435 7.87885H19.0799L19.4841 6.72313H21.9723L22.2064 7.87885V7.87898ZM20.0425 5.1374L21.0633 2.23223L21.6508 5.1374H20.0425ZM11.8833 0.141272L10.2797 7.87885H8.34046L9.9447 0.141272H11.8833Z"
      fill="#1434CB"
    />
  </svg>
);

export default VisaIcon;
