import { useEffect, useState } from "react";

function useResponsiveSize() {
  const [windowSize, setWindowSize] = useState<number>(
    document.body.clientWidth,
  );

  const resizeListener = () => {
    setWindowSize(document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [windowSize]);

  return {
    windowSize,
  };
}

export default useResponsiveSize;
