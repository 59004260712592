import { FC } from "react";
import styled from "styled-components";

import { UserProfile } from "@cochlearai/ui";

const Wrapper = styled.div`
  position: relative;
  margin-left: 7px;
`;

const UserRoundProfile = styled(UserProfile)<{ color?: string }>`
  margin-right: unset;
  min-width: 25px;
  background: ${(p) => p.color ?? p.theme.colors.black};
  border: 1px solid ${(p) => p.theme.colors.white};
  position: absolute;
  top: 0;
  right: 7px;

  & p {
    color: ${(p) => p.theme.colors.white} !important;
  }
`;

const ProfileBackground = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.grey[30]};
`;

interface Props {
  text: string;
  color?: string;
}

const SharedUserProfile: FC<Props> = ({ text, color }) => {
  return (
    <Wrapper>
      <UserRoundProfile
        text={text}
        size="small"
        className="profile"
        color={color}
      />
      <ProfileBackground />
    </Wrapper>
  );
};

export default SharedUserProfile;
