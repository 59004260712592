import React, { FC, SVGProps } from "react";

const InfoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={10} cy={10} r={10} fill={props.fill || "#B2B2B3"} />
    <path fill="#fff" d="M9 5h2v2H9zM9 9h2v7H9z" />
  </svg>
);

export default InfoIcon;
