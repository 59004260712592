import { Button, Typo } from "@cochlearai/ui";
import {
  CardCvcElement,
  CardCvcElementComponent,
  CardExpiryElement,
  CardExpiryElementComponent,
  CardNumberElement,
  CardNumberElementComponent,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
  StripeElements,
} from "@stripe/stripe-js";
import { FC, useState } from "react";
import styled, { useTheme } from "styled-components";

import { media } from "@cochlearai/util";
import { CARD_ELEMENT } from "~/client/types/payment";

const CardInfoContainer = styled.div`
  display: flex;
  gap: 12px;

  ${media.query.md} {
    flex-direction: column;
    align-items: start;
  }
`;

const CardInfoItem = styled.div<{ width: number }>`
  width: ${(p) => p.width}px;

  ${media.query.md} {
    width: 100%;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 38px;
  button {
    flex: 1;
    height: 50px;
  }
`;

const InputContainer = styled.div<{ focused?: boolean; error?: boolean }>`
  padding: 12px;
  border: 1px solid
    ${(p) =>
      p.focused
        ? p.theme.colors.blue[40]
        : p.error
          ? p.theme.colors.red
          : "#e6e6e6"};
  border-radius: 5px;
  align-items: center;

  background: #ffffff;
  box-shadow: ${(p) =>
    p.focused
      ? `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
  0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08)`
      : p.error
        ? `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${p.theme.colors.red}`
        : "0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)"};

  margin: 2px 0;
`;

const StripeElementOption: StripeCardNumberElementOptions = {
  style: {
    base: {
      backgroundColor: "white",
      iconColor: "black",
      color: "black",
      fontWeight: 400,
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: "17px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#8C8C8C",
      },
      "::placeholder": {
        color: "#8C8C8C",
      },
    },
  },
};

const CARD_ELEMENT_INFO: {
  name: CARD_ELEMENT;
  label: string;
  width: number;
  option?: StripeCardNumberElementOptions;
  element:
    | CardNumberElementComponent
    | CardExpiryElementComponent
    | CardCvcElementComponent;
}[] = [
  {
    label: "Card Number",
    name: CARD_ELEMENT.NUMBER,
    element: CardNumberElement,
    width: 354,
    option: {
      iconStyle: "default",
      showIcon: true,
      ...StripeElementOption,
    },
  },
  {
    label: "Card Expiry",
    name: CARD_ELEMENT.EXPIRY,
    element: CardExpiryElement,
    width: 171,
    option: StripeElementOption,
  },
  {
    label: "CVC",
    name: CARD_ELEMENT.CVC,
    element: CardCvcElement,
    width: 171,
    option: StripeElementOption,
  },
];

interface Props {
  onAbort: () => void;
  abortButtonLabel?: string;
  onClickConfirm?: (
    stripe: Stripe | null,
    elements: StripeElements | null,
  ) => void;
}

const StripePaymentElement: FC<Props> = ({
  onAbort,
  abortButtonLabel,
  onClickConfirm,
}) => {
  const { colors } = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const [focusInfo, setFocusInfo] = useState<{
    [key in CARD_ELEMENT]?: boolean;
  }>({});
  const [stateErrors, setStateErrors] = useState<{
    [key in CARD_ELEMENT]?: string;
  }>({});

  const handlePayment_InputChange =
    (name: string, label: string) =>
    (elementData: StripeElementChangeEvent) => {
      if (!elementData.complete && !elementData.error) {
        setStateErrors({
          ...stateErrors,
          [name]: `Your ${label} is incomplete or invalid.`,
        });
      } else if (elementData.complete && !elementData.error) {
        setStateErrors({
          ...stateErrors,
          [name]: null,
        });
      }
    };

  return (
    <>
      <CardInfoContainer>
        {CARD_ELEMENT_INFO.map((item, i) => (
          <CardInfoItem key={i} width={item.width}>
            <label htmlFor={item.name}>
              <Typo variant="body" style={{ color: colors.grey[70] }}>
                {item.label}
              </Typo>
            </label>
            <InputContainer
              focused={focusInfo[item.name]}
              error={!!stateErrors[item.name]}
            >
              <item.element
                id={item.name}
                onChange={handlePayment_InputChange(item.name, item.label)}
                onFocus={() => setFocusInfo({ [item.name]: true })}
                onBlur={() => setFocusInfo({ [item.name]: false })}
                options={item.option}
              />
            </InputContainer>
            {stateErrors[item.name] && (
              <Typo variant="body" style={{ color: colors.red }}>
                {stateErrors[item.name]}
              </Typo>
            )}
          </CardInfoItem>
        ))}
      </CardInfoContainer>
      <Typo
        variant="body"
        style={{
          color: colors.grey[70],
          fontSize: "12px",
          marginTop: "20px",
        }}
      >
        By providing your card information, you allow Cochl, Inc. to charge your
        card for future payments in accordance with their terms.
      </Typo>
      <ActionButtonsContainer>
        <Button
          color="secondary"
          onClick={() => {
            onAbort();
          }}
        >
          {abortButtonLabel ?? "Cancel"}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (onClickConfirm) {
              onClickConfirm(stripe, elements);
            }
          }}
          style={{ marginLeft: "21px" }}
        >
          Continue
        </Button>
      </ActionButtonsContainer>
    </>
  );
};

export default StripePaymentElement;
