export interface OrganizationOwner {
  user_id: string;
  email: string;
  name: string;
  given_name: string;
}

export interface DetailOwnerInfo extends OrganizationOwner {
  color: string;
  organization_id: string;
  full_name: string;
}

export interface Project {
  id: string;
  name: string;
  key: string;
  created_at: string;
  updated_at: string;
  product_type: ProjectType;
  organization_id: string;
  owner: OrganizationOwner;
}

export interface ProjectWithChecked extends Project {
  checked: boolean;
}

export enum ProjectType {
  ALL = "all",
  CLOUD_API = "api",
  EDGE_SDK = "sdk",
}

export enum ProjectFilterType {
  ALL = "all",
  CREATED_BY_ME = "created_by_me",
  INVITED = "invited",
}
