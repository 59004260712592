import { ChangeEvent, DragEvent, FC } from "react";

import { AUDIO_FILE_DRAG_DROP_TYPE } from "~/client/lib/constants";
import { Typo } from "@cochlearai/ui";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileUploadContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const DropArea = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
`;

const InputLabel = styled.label`
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  margin-left: 5px;
  z-index: 1;
  color: ${(p) => p.theme.colors.blue[60]};
`;

const InputSubText = styled(Flex)`
  margin-top: 14px;

  ${media.query.md} {
    flex-direction: column;
  }
`;

const TypeText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};
  margin-right: 8px;
`;

const TextDivider = styled.div`
  width: 1px;
  height: 14px;
  background: ${(p) => p.theme.colors.grey[30]};
  margin: 0 10px;
  margin-left: 2px;
`;

interface Props {
  onFileChange: (files: FileList) => void;
  fileInfo?: {
    name: string;
    value: string;
  }[];
  multiple?: boolean;
}

const FileUpload: FC<Props> = ({
  onFileChange,
  fileInfo,
  multiple = false,
}) => {
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "drop") {
      const { files } = e.dataTransfer;
      onFileChange(files);
      e.dataTransfer.clearData();
    }
  };

  return (
    <FileUploadContainer>
      <DropArea onDrop={handleDrop} onDragOver={handleDrop} />
      <Flex>
        <Typo variant="h4">Drag and drop or</Typo>
        <FileInput
          type="file"
          id="file"
          multiple={multiple}
          accept={AUDIO_FILE_DRAG_DROP_TYPE.join(", ")}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const { files } = e.target;
            files && onFileChange(files);
            e.target.value = "";
          }}
        />
        <InputLabel htmlFor="file">
          <Typo variant="h4">Browse</Typo>
        </InputLabel>
      </Flex>
      <InputSubText>
        {fileInfo?.map(({ name, value }, i) => (
          <Flex key={i}>
            <TypeText variant="caption2">{name}</TypeText>
            <TypeText variant="caption1">{value}</TypeText>
            {i !== fileInfo?.length - 1 && <TextDivider />}
          </Flex>
        ))}
      </InputSubText>
    </FileUploadContainer>
  );
};

export default FileUpload;
