import { createGlobalStyle } from "styled-components";
import { BaseStyleProps } from "../types";

const METRIC = {
  COLUMN_WIDTH: {
    medium: "3px",
    large: "5px",
  },
  COLUMN_HEIGHT: {
    medium: "28px",
    large: "54px",
  },
  BETWEEN_SPACE_SIZE: {
    medium: "4px",
    large: "7px",
  },
  BORDER_RADIUS: {
    medium: "1.5px",
    large: "2.5px",
  },
};

const SpinnerStyles = createGlobalStyle<{
  size: "medium" | "large";
  color?: string;
}>`
  @-webkit-keyframes line-scale-pulse-out {
    0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
    50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
    }
    100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
  }

  @keyframes line-scale-pulse-out {
    0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
    50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
    }
    100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
    }
  }

  .line-scale-pulse-out > div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
    animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);

    /* customized from loader.css */
    width: ${(p) => METRIC.COLUMN_WIDTH[p.size] ?? "4px"};
    height: ${(p) => METRIC.COLUMN_HEIGHT[p.size] ?? "35px"};
    border-radius: ${(p) => METRIC.BORDER_RADIUS[p.size] ?? "2px"};
    background-color: ${(p) => {
      if (p.color) return p.color;
      switch (p.size) {
        case "large":
          return p.theme.colors.black;
        case "medium":
          return p.theme.colors.grey[70];
        default:
          return p.theme.colors.white;
      }
    }};
    margin: ${(p) => (p.size ? "0px" : "2px")};
  }
  .line-scale-pulse-out > div:nth-child(2),
  .line-scale-pulse-out > div:nth-child(4) {
    -webkit-animation-delay: -0.4s !important;
    animation-delay: -0.4s !important;
  }
  .line-scale-pulse-out > div:nth-child(1),
  .line-scale-pulse-out > div:nth-child(5) {
    -webkit-animation-delay: -0.2s !important;
    animation-delay: -0.2s !important;
  }
  .line-scale-pulse-out > div + div {
    margin-left: ${(p) => METRIC.BETWEEN_SPACE_SIZE[p.size] ?? "2px"};
  }
`;

export interface ProgressIndicatorProps extends BaseStyleProps {
  size?: "large" | "medium";
  color?: string;
}

/**
 * React component used to create a progress indicator.
 * @param size - Size of the progress indicator. `large` or `medium`. Default is `large`.
 * @param color - Color of the progress indicator.
 * @param className - Class name of the progress indicator.
 * @param style - Style of the progress indicator.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/progress-indicator--default
 */
export const ProgressIndicator = ({
  size = "large",
  color,
  className,
  style,
}: ProgressIndicatorProps) => {
  return (
    <>
      <div className={`line-scale-pulse-out ${className}`} style={style}>
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} />
        ))}
      </div>
      <SpinnerStyles size={size} color={color} />
    </>
  );
};
