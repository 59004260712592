import { useEffect, useState } from "react";

import { APIError } from "~/client/types/error";
import { Plan } from "~/client/types/priceplan";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchGetPlans } from "~/client/api/payment";
import { setServerError } from "~/client/store/modules/alert";
import { useAppDispatch } from "~/client/hooks";
import useSWR from "swr";

function usePricePlans() {
  const dispatch = useAppDispatch();
  const [plans, setPlans] = useState<Plan[]>([]);
  const swrInitializePlans = useSWR(
    SWR_KEY.PRICEPLAN_INITIALIZE_PLANS,
    async () => {
      const { data: plans } = await fetchGetPlans({
        params: {},
      });
      return {
        plans,
      };
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!swrInitializePlans.data) return;
    const { plans } = swrInitializePlans.data;
    setPlans(plans);
  }, [swrInitializePlans.data]);

  useEffect(() => {
    if (!swrInitializePlans.error) return;
    const e = swrInitializePlans.error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, swrInitializePlans.error]);

  return {
    plans,
    isValidating: swrInitializePlans.isValidating,
  };
}

export default usePricePlans;
