import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Subscription, PlanType } from "~/client/types/priceplan";

interface PricePlanState {
  currentSubscription: Subscription;
}

const initialState: PricePlanState = {
  currentSubscription: {
    id: 0,
    plan_id: PlanType.FREE,
    created_at: "",
    active: true,
    product_types: [],
  },
};

export const pricePlanSlice = createSlice({
  name: "pricePlan",
  initialState,
  reducers: {
    setCurrentSubscription: (state, action: PayloadAction<Subscription>) => {
      state.currentSubscription = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});
export const { setCurrentSubscription } = pricePlanSlice.actions;
export const { reducer: pricePlanReducer } = pricePlanSlice;
