import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetchGetProjectSDKTags } from "~/client/api";
import { APIError, Project, ProjectType } from "~/client/types";
import { useAppDispatch } from "~/client/hooks";
import { setServerError } from "~/client/store/modules";
import { SWR_KEY } from "~/client/types/swrKey";
import { SenseTagObject } from "~/client/types/sense";

function useProjectTags({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const dispatch = useAppDispatch();
  const [projectTags, setProjectTags] = useState<SenseTagObject[]>([]);
  const { data, error, isValidating } = useSWR(
    currentProject && currentProject?.product_type === ProjectType.EDGE_SDK
      ? SWR_KEY.PROJECT_INITIALIZE_PROJECT_TAGS
      : null,
    async () => {
      const res = await fetchGetProjectSDKTags({
        params: {
          project_id: currentProject!.id,
        },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setProjectTags(data.tags);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    projectTags,
    isValidating,
  };
}

export default useProjectTags;
