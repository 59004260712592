import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, menu, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
      display: none;
  }
  body {
    line-height: 1;
  }
  menu, ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'IBM Plex Sans', sans-serif;
    min-height: calc(100vh);
    min-width: fit-content;
    width: calc(100vw);
    background-color: ${(p) => p.theme.colors.body};
    color: ${(p) => p.theme.colors.black};
    overflow-x: hidden;
  }

  input, textarea {
    font-family: inherit;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
  }

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;

    &.button {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      font-family: 'IBM Plex Sans', sans-serif !important;
    }
    &.caption1 {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
    }
    &.caption2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
    }
    &.caption3 {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: left;
    }
  }

  caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  }

  button {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
  }

  header {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
  }

  footer {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
  }

  pre {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
  } 
`;
