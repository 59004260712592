import {
  initializeFilterParams,
  setClientInfo,
  setSdkTagFilter,
  setServerError,
} from "~/client/store/modules";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import {
  fetchGetTrackingGroup,
  fetchCreateTrackingGroup,
  fetchRemoveTrackingGroup,
} from "~/client/api";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { convertSenseTagValueToUILabel } from "~/client/lib/helpers";
import { selectedAnalyticsState } from "~/client/lib/selectors";
import { ProjectType, SDKTag } from "~/client/types";
import { FilterType } from "~/client/types/analytics";
import useCurrentProject from "./useCurrentProject";

function useAnalyticsTrackingGroup() {
  const dispatch = useAppDispatch();
  const params = useParams<{ projectId?: string }>();
  const { sdkTagFilter } = useAppSelector(selectedAnalyticsState);
  const [tags, setTags] = useState<SDKTag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentProject } = useCurrentProject();
  const isSDK = currentProject?.product_type === ProjectType.EDGE_SDK;

  const { data, error, isValidating, mutate } = useSWR(
    params.projectId && !isSDK
      ? SWR_KEY.ANALYTICS_INITIALIZE_TRACKING_GROUP
      : null,
    async () => {
      return (
        await fetchGetTrackingGroup({
          params: {
            project_id: params.projectId!,
          },
        })
      ).data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );
  const trackedTags = useMemo(
    () =>
      (isSDK
        ? sdkTagFilter.filter((tag) => tag.checked).map((tag) => tag.value)
        : data
      )
        ?.sort((a, b) => a.localeCompare(b))
        .map((tag) => ({
          label: convertSenseTagValueToUILabel(tag),
          value: tag,
          checked: true,
        })) ?? [],
    [data, isSDK, sdkTagFilter],
  );

  const update = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    // SDK
    if (isSDK) {
      dispatch(
        setSdkTagFilter(
          sdkTagFilter.map((tag) => ({
            ...tag,
            checked: tags.find((item) => item.value === tag.value)
              ? true
              : false,
          })),
        ),
      );
      return;
    }
    // API
    try {
      await fetchCreateTrackingGroup({
        params: {
          project_id: params.projectId!,
          tags: tags.map((tag) => tag.value),
        },
      });
      dispatch(
        setClientInfo({
          text: "Tracked tags have been updated",
        }),
      );
      mutate();
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [
    dispatch,
    isLoading,
    isSDK,
    mutate,
    params.projectId,
    sdkTagFilter,
    tags,
  ]);

  const clearAll = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    // SDK
    if (isSDK) {
      dispatch(
        setSdkTagFilter(trackedTags.map((tag) => ({ ...tag, checked: false }))),
      );
      return;
    }
    // API
    try {
      await fetchRemoveTrackingGroup({
        params: {
          project_id: params.projectId!,
        },
      });
      dispatch(
        setClientInfo({
          text: "Tracked tags have been removed",
        }),
      );
      mutate();
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, isLoading, isSDK, mutate, params.projectId, trackedTags]);

  const reset = useCallback(() => {
    setTags(trackedTags);
  }, [trackedTags]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  useEffect(() => {
    setTags(trackedTags);
    dispatch(
      initializeFilterParams({
        value: { [FilterType.TAG]: trackedTags },
      }),
    );
  }, [dispatch, setTags, trackedTags]);

  return {
    trackedTags,
    tags,
    setTags,
    update,
    clearAll,
    reset,
    isValidating,
  };
}

export default useAnalyticsTrackingGroup;
