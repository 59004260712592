import React, { FC, SVGProps } from "react";

const RefreshIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.346 0C5.408 0 3.544.73 2.164 2.038.783 3.349 0 5.133 0 7h1.273c0-1.508.631-2.961 1.767-4.038 1.136-1.078 2.685-1.69 4.306-1.69 1.472 0 2.883.504 3.981 1.403l-1.78 1.78h4.455V0l-1.771 1.771A7.558 7.558 0 0 0 7.346 0ZM6.656 14c1.938 0 3.802-.73 5.182-2.039 1.381-1.31 2.164-3.093 2.164-4.961H12.73c0 1.508-.63 2.961-1.766 4.038-1.137 1.078-2.686 1.69-4.307 1.69a6.292 6.292 0 0 1-3.981-1.403l1.78-1.78H0V14l1.771-1.771A7.558 7.558 0 0 0 6.656 14Z"
      fill={props.fill || "#131313"}
    />
  </svg>
);

export default RefreshIcon;
