import React, { FC, SVGProps } from "react";

const AmexIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_799_3794)">
      <path d="M0 0V8.9712V18H9H18V9V0H9.0036H0Z" fill="white" />
      <path
        d="M14.7888 4.4172L15.3648 2.8512H18V0H0V18H18V15.156H15.516L14.598 14.0832L13.644 15.156H6.588V9.4392H4.2588L7.1712 2.8512H10.0044L10.6884 4.3452V2.8512H14.2056L14.7924 4.4172H14.7888ZM12.8016 5.454L12.7944 4.824L13.0356 5.454L14.2056 8.5788H15.3684L16.5456 5.454L16.7724 4.8312V8.5824H17.9964V3.78H15.9624L15.0372 6.2172L14.7924 6.876L14.5404 6.2172L13.6116 3.78H11.5776V8.5788H12.8016V5.454V5.454ZM10.1664 8.5788H11.5776L9.4644 3.78H7.8264L5.7024 8.5788H7.0956L7.4664 7.6536H9.7956L10.1664 8.5788ZM8.3916 5.4036L8.6328 4.8024L8.874 5.4036L9.3708 6.6168H7.8948L8.3916 5.4036V5.4036ZM7.5708 9.4428V14.2236H11.5776V13.1868H8.7948V12.3516H11.5236V11.3184H8.7948V10.4796H11.5776V9.4428H7.5708V9.4428ZM16.038 14.2236H17.6292L15.3864 11.8224L17.6292 9.4392H16.0632L14.616 10.9944L13.176 9.4392H11.5812L13.8204 11.8404L11.5812 14.2236H13.1292L14.5872 12.6648L16.038 14.2236V14.2236ZM16.6428 11.8188L18 13.1976V10.4544L16.6428 11.8152V11.8188Z"
        fill="#006FCF"
      />
    </g>
    <defs>
      <clipPath id="clip0_799_3794">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AmexIcon;
