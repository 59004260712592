import { PlanType, Subscription } from "~/client/types/priceplan";
import { fetchGetSubscriptions } from "~/client/api/payment";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import { useEffect } from "react";
import useSWR from "swr";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import { currentSubscriptionSelector, formatPlanTitle } from "~/client/lib";
import { setCurrentSubscription } from "~/client/store/modules/pricePlan";
import { setServerError } from "~/client/store/modules/alert";

const DEFAULT_SUBSCRIPTION: Subscription = {
  id: 0,
  plan_id: PlanType.FREE,
  created_at: "",
  active: true,
  product_types: [],
};

function useSubscription() {
  const dispatch = useAppDispatch();
  const currentSubscription = useAppSelector(currentSubscriptionSelector);

  const { data, error, isValidating } = useSWR(
    SWR_KEY.PRICEPLAN_INITIALIZE_CURRENT_SUBSCRIPTIONS,
    async () => {
      const { data: subscriptions } = await fetchGetSubscriptions({
        params: {},
      });
      const currentSubscription =
        subscriptions.find((subscription) => subscription.active) ??
        DEFAULT_SUBSCRIPTION;

      return currentSubscription;
    },
    { suspense: true, revalidateOnFocus: false, revalidateOnMount: true },
  );

  useEffect(() => {
    if (!data) return;
    dispatch(setCurrentSubscription(data));
  }, [dispatch, data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    currentSubscription,
    formattedCurrentSubscriptionInfo: {
      title: formatPlanTitle(currentSubscription.plan_id ?? ""),
    },
    isValidating,
  };
}

export default useSubscription;
