import React, { FC, SVGProps } from "react";

const JCBIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_799_3737)">
      <path
        d="M24 14.3659C24 16.3727 22.3285 18.0007 20.2664 18.0007H0V3.63488C0 1.6273 1.6715 0 3.73358 0H24V14.3659Z"
        fill="white"
      />
      <path
        d="M17.3923 10.6848H18.9358C18.984 10.6848 19.0758 10.6775 19.1202 10.668C19.4167 10.6057 19.6636 10.3493 19.6636 10.0005C19.6636 9.65173 19.4167 9.39456 19.1202 9.33228C19.0766 9.32276 18.9847 9.31543 18.9358 9.31543H17.3923V10.6848Z"
        fill="url(#paint0_linear_799_3737)"
      />
      <path
        d="M18.7552 1.21188C17.2824 1.21188 16.0888 2.37393 16.0888 3.80781V6.50411H19.8623C19.9496 6.50411 20.0564 6.50997 20.1234 6.51363C20.9776 6.55539 21.6068 6.98255 21.6068 7.7233C21.6068 8.30579 21.1763 8.80402 20.3929 8.91612V8.94543C21.2583 9.00404 21.9206 9.4671 21.9206 10.1983C21.9206 10.9889 21.1936 11.4967 20.2243 11.4967H16.0888V16.7889H20C21.4728 16.7889 22.6664 15.6261 22.6664 14.193V1.21188H18.7552Z"
        fill="url(#paint1_linear_799_3737)"
      />
      <path
        d="M19.4799 7.91088C19.4799 7.56798 19.2331 7.33791 18.9434 7.29029C18.9163 7.28589 18.8425 7.27856 18.7913 7.27856H17.3923V8.54246H18.7913C18.8425 8.54246 18.9163 8.53513 18.9434 8.53073C19.2339 8.48311 19.4799 8.25304 19.4799 7.91014V7.91088Z"
        fill="url(#paint2_linear_799_3737)"
      />
      <path
        d="M3.99999 1.21188C2.52718 1.21188 1.33358 2.37393 1.33358 3.80781V10.2188C2.08165 10.5713 2.8538 10.8013 3.64026 10.8013C4.56218 10.8013 5.06867 10.2533 5.06867 9.5162V6.50411H7.3618V9.5162C7.3618 10.6944 6.619 11.6395 4.06998 11.6395C2.53621 11.6395 1.33282 11.3171 1.33282 11.3171V16.7881H5.24402C6.71683 16.7881 7.91044 15.6254 7.91044 14.1922V1.21188H3.99999Z"
        fill="url(#paint3_linear_799_3737)"
      />
      <path
        d="M11.3776 1.21188C9.90478 1.21188 8.71118 2.37393 8.71118 3.80781V7.20676C9.387 6.64258 10.555 6.29016 12.4538 6.37295C13.4698 6.41692 14.5475 6.68215 14.5475 6.68215V7.78118C14.0079 7.51522 13.3614 7.2683 12.5283 7.20749C11.0879 7.10125 10.2209 7.78705 10.2209 9.00038C10.2209 10.2137 11.0879 10.8988 12.5283 10.7925C13.3614 10.7317 14.0169 10.4782 14.5475 10.2196V11.3179C14.5475 11.3179 13.4698 11.5831 12.4538 11.6278C10.5558 11.7106 9.387 11.3582 8.71118 10.794V16.7889H12.6224C14.0952 16.7889 15.2888 15.6261 15.2888 14.193V1.21188H11.3776Z"
        fill="url(#paint4_linear_799_3737)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_799_3737"
        x1="16.0106"
        y1="9.95094"
        x2="22.5759"
        y2="9.95094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007F49" />
        <stop offset="0.29" stopColor="#1F9248" />
        <stop offset="0.76" stopColor="#4FAF46" />
        <stop offset="1" stopColor="#62BB46" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_799_3737"
        x1="16.0106"
        y1="8.95472"
        x2="22.5759"
        y2="8.95472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007F49" />
        <stop offset="0.29" stopColor="#1F9248" />
        <stop offset="0.76" stopColor="#4FAF46" />
        <stop offset="1" stopColor="#62BB46" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_799_3737"
        x1="16.0106"
        y1="7.8707"
        x2="22.5759"
        y2="7.8707"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007F49" />
        <stop offset="0.29" stopColor="#1F9248" />
        <stop offset="0.76" stopColor="#4FAF46" />
        <stop offset="1" stopColor="#62BB46" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_799_3737"
        x1="1.31211"
        y1="8.95472"
        x2="7.97943"
        y2="8.95472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#333376" />
        <stop offset="0.09" stopColor="#2D3A7D" />
        <stop offset="0.7" stopColor="#0C66AD" />
        <stop offset="1" stopColor="#0077C0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_799_3737"
        x1="8.62677"
        y1="8.95472"
        x2="15.1019"
        y2="8.95472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#743237" />
        <stop offset="0.4" stopColor="#AA263D" />
        <stop offset="0.8" stopColor="#DA1B43" />
        <stop offset="1" stopColor="#ED1846" />
      </linearGradient>
      <clipPath id="clip0_799_3737">
        <rect width="24" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default JCBIcon;
