import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "~/client/hooks";
import {
  filterProjectList,
  setLoading,
  setServerError,
} from "~/client/store/modules";
import { fetchRemoveProject } from "~/client/api/project";
import { APIError } from "~/client/types";
import { Project } from "~/client/types/project";
import { selectedProjectState } from "~/client/lib";

function useRemoveProject({
  currentProject,
}: {
  currentProject: Project | null;
}) {
  const { isLoading } = useAppSelector(selectedProjectState);
  const dispatch = useAppDispatch();
  const remove = useCallback(async () => {
    if (isLoading) return;
    if (!currentProject) return;
    dispatch(setLoading(true));
    const { id } = currentProject;
    try {
      await fetchRemoveProject({
        params: {
          id,
        },
      });
      dispatch(
        filterProjectList({
          projectId: id,
        }),
      );
    } catch (e) {
      console.error(e);
      const error = e as APIError<unknown>;
      dispatch(setServerError(error));
    } finally {
      dispatch(setLoading(false));
    }
  }, [currentProject, dispatch, isLoading]);

  return {
    remove,
    isLoading,
  };
}

export default useRemoveProject;
