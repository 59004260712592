import React, { FC, SVGProps } from "react";

const PlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill={props.fill ?? "#4B68FF"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.6235 8.91649C18.4569 9.39762 18.4569 10.6004 17.6235 11.0816L2.62353 19.7418C1.7902 20.2229 0.748534 19.6215 0.748534 18.6593L0.748535 1.33877C0.748535 0.376519 1.7902 -0.224888 2.62354 0.256237L17.6235 8.91649Z" />
  </svg>
);

export default PlayIcon;
