import React, { FC, SVGProps } from "react";

const NotiIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="#131313"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.4998 23.8055C12.9372 23.7954 13.357 23.631 13.6849 23.3412C14.0128 23.0514 14.2277 22.6551 14.2914 22.2222H10.6387C10.7043 22.6668 10.9292 23.0725 11.2715 23.3638C11.6139 23.655 12.0504 23.812 12.4998 23.8055Z" />
    <path d="M22.8126 19.5347L22.5765 19.3264C21.9067 18.7296 21.3204 18.0452 20.8334 17.2917C20.3017 16.2518 19.9829 15.1162 19.8959 13.9514V10.5209C19.8931 10.1042 19.856 9.6884 19.7848 9.27781C18.6085 9.03603 17.5519 8.39518 16.7939 7.46376C16.0358 6.53235 15.6229 5.36759 15.6251 4.16669V3.72919C14.9001 3.37238 14.1211 3.13774 13.3196 3.03475V2.15975C13.3196 1.91387 13.2219 1.67806 13.048 1.5042C12.8742 1.33034 12.6383 1.23267 12.3925 1.23267C12.1466 1.23267 11.9108 1.33034 11.7369 1.5042C11.5631 1.67806 11.4654 1.91387 11.4654 2.15975V3.06947C9.67074 3.32263 8.02828 4.21652 6.84123 5.58609C5.65418 6.95567 5.0027 8.70846 5.00705 10.5209V13.9514C4.92008 15.1162 4.60134 16.2518 4.06955 17.2917C3.591 18.0433 3.01413 18.7276 2.35428 19.3264L2.11816 19.5347V21.4931H22.8126V19.5347Z" />
    <path d="M20.8336 7.63878C22.7512 7.63878 24.3058 6.08421 24.3058 4.16656C24.3058 2.2489 22.7512 0.694336 20.8336 0.694336C18.9159 0.694336 17.3613 2.2489 17.3613 4.16656C17.3613 6.08421 18.9159 7.63878 20.8336 7.63878Z" />
  </svg>
);

export default NotiIcon;
