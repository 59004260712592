import React, { FC, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { PAGE_TITLE } from "~/client/types";
import { Helmet as ReactHelmet } from "react-helmet";
import routes from "~/client/lib/routes";

const Helmet: FC = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState<string>("");

  useEffect(() => {
    const match = matchPath(location.pathname, {
      path: routes.map((route) => route.pathname ?? ""),
      exact: true,
    });
    if (!match) {
      return;
    }
    const matchedPage = routes.find((route) => route.pathname === match.path);
    if (matchedPage && matchedPage.title) {
      setPageTitle(
        [
          PAGE_TITLE.PROJECT_DETAIL,
          PAGE_TITLE.PROJECT_DETAIL_ANALYTICS,
          PAGE_TITLE.PROJECT_DETAIL_ANALYTICS_OLD,
          PAGE_TITLE.PROJECT_DETAIL_SENSE_SDK,
          PAGE_TITLE.PROJECT_DETAIL_UPLOAD,
          PAGE_TITLE.PROJECT_DETAIL_POST_ACTION,
        ].indexOf(matchedPage.title) !== -1
          ? PAGE_TITLE.PROJECT
          : matchedPage.title,
      );
    }
  }, [location]);

  return (
    <ReactHelmet>
      <title>{pageTitle} | Cochl.Sense</title>
    </ReactHelmet>
  );
};

export default Helmet;
