import { FC, useState } from "react";
import {
  NotificationPopover,
  NotificationPopoverContent,
} from "~/client/components";

import { Notification } from "@cochlearai/ui";
import useNotification from "~/client/hooks/useNotification";

const HeaderNotification: FC = () => {
  const [notiPopOverOpen, setNotiPopOverOpen] = useState(false);
  const { notifications } = useNotification();

  const handleClickNotiIcon = () => {
    setNotiPopOverOpen(true);
  };
  const onClickOutsideNotiPopover = () => {
    setNotiPopOverOpen(false);
  };

  return (
    <NotificationPopover
      isOpen={notiPopOverOpen}
      onClickOutside={onClickOutsideNotiPopover}
      content={
        <NotificationPopoverContent
          onClick={onClickOutsideNotiPopover}
          notifications={notifications}
        />
      }
    >
      <Notification
        onClick={handleClickNotiIcon}
        confirmed={!notifications.find((item) => !item.read)}
      />
    </NotificationPopover>
  );
};

export default HeaderNotification;
