import { InputHTMLAttributes, ReactNode } from "react";

import styled from "styled-components";
import { BaseStyleProps } from "../types";
import { Typo } from "../Typo";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => p.theme.colors.grey[30]};
  &:focus-within {
    border-color: ${(p) => p.theme.colors.black};
  }
  &[aria-invalid="true"] {
    border-color: ${(p) => p.theme.colors.blue[60]};
  }
  &[aria-invalid="false"] {
    border-color: ${(p) => p.theme.colors.red};
  }
  &[aria-disabled="true"] {
    border-color: transparent;
    opacity: 0.4;
  }
`;

const InputText = styled.input<{ ["data-testid"]: string }>`
  width: 100%;
  height: 100%;
  color: ${(p) => p.theme.colors.black};
  padding: 20px;
  background-color: ${(p) => p.theme.colors.white};
  border: unset;
  border-radius: inherit;

  &:focus {
    outline: none;
  }
  &:disabled {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &::placeholder {
    color: ${(p) => p.theme.colors.grey[60]};
  }
`;

const ErrorText = styled(Typo)`
  color: ${(p) => p.theme.colors.red};
  margin-top: 5px;
  text-align: left;
`;

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    BaseStyleProps {
  validated?: boolean;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  error?: string;
}

/**
 * React component used to create an input.
 * @param style - The style of the input.
 * @param className - The class name of the input.
 * @param validated - Indicates whether the input is validated or not.
 * @param rightIcon - The right icon of the input.
 * @param leftIcon - The left icon of the input.
 * @param error - The error message of the input.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/input--default
 */
export const Input = ({
  style,
  className,
  validated,
  rightIcon,
  leftIcon,
  error,
  disabled,
  ...rest
}: InputProps) => {
  return (
    <>
      <Container
        className={className}
        style={style}
        aria-disabled={disabled ? "true" : "false"}
        aria-invalid={
          typeof validated === "boolean"
            ? `${validated.toString() as "true" | "false"}`
            : undefined
        }
      >
        {leftIcon && leftIcon}
        <InputText data-testid="@cochl-ui/InputText" {...rest} />
        {rightIcon && rightIcon}
      </Container>
      {error && <ErrorText variant="caption3">{error}</ErrorText>}
    </>
  );
};
