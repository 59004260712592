import { useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { ToastCheckIcon, ToastCloseIcon, ToastErrorIcon } from "../Icons";

import { Z_INDEXES } from "../lib/constants";

const TOAST_DURATION = 6000;
const ANIMATION_DURATION = 500;

const Container = styled.div<{ severity: "info" | "error" }>`
  height: 50px;
  border-radius: 4px;

  background-color: ${(p) => p.theme.colors.white};

  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, 0);
  margin: 0 auto;

  display: flex;
  align-items: center;

  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;

  background: ${(p) => (p.severity === "info" ? "#EDF0FF" : "#FFF4F4")};
  border: 1px solid
    ${(p) =>
      p.severity === "info" ? p.theme.colors.blue[60] : p.theme.colors.red};
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  color: ${(p) =>
    p.severity === "info" ? p.theme.colors.blue[60] : p.theme.colors.red};

  z-index: ${Z_INDEXES.TOAST};

  animation:
    fadeIn ${ANIMATION_DURATION}ms,
    fadeOut ${ANIMATION_DURATION}ms
      ${TOAST_DURATION - (ANIMATION_DURATION - 200)}ms;

  @keyframes fadeIn {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
`;

const Button = styled.a`
  cursor: pointer;
  margin-left: auto;
  text-decoration: underline;
`;

const ToastContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 10px;
`;

export interface ToastProps {
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
  message: string | React.ReactNode;
  severity: "info" | "error";
  buttonText?: string;
}
/**
 * React component used to create a toast.
 * @param open - Whether the toast is open or not.
 * @param onClose - Event handler for closing the toast.
 * @param onClick - Event handler for the toast.
 * @param message - Message of the toast.
 * @param severity - Severity of the toast. `info` or `error`.
 * @param buttonText - Button text of the toast.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/toast--default
 */
export const Toast = ({
  open,
  onClose,
  onClick,
  message,
  severity,
  buttonText,
}: ToastProps) => {
  const { colors } = useTheme();
  const timeoutId: { current: NodeJS.Timeout | null } = useRef(null);
  useEffect(() => {
    if (open) {
      timeoutId.current = setTimeout(() => {
        if (timeoutId) {
          onClose();
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
        }
      }, TOAST_DURATION);
    } else {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    }
  }, [onClose, open]);

  if (!open) {
    return null;
  }

  return (
    <Container severity={severity}>
      {severity === "info" ? (
        <ToastCheckIcon fill={colors.blue[60]} />
      ) : (
        <ToastErrorIcon fill={colors.red} />
      )}
      <ToastContent>
        <div style={{ display: "flex", alignItems: "center" }}>{message}</div>
        {buttonText && onClick && (
          <Button onClick={onClick}>{buttonText}</Button>
        )}
      </ToastContent>
      <ToastCloseIcon
        style={{
          cursor: "pointer",
          marginLeft: "auto",
        }}
        onClick={onClose}
        fill={severity === "info" ? colors.blue[60] : colors.red}
      />
    </Container>
  );
};
