import {
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
} from "@cochlearai/ui";
import { FC, ReactNode } from "react";
import styled, { useTheme } from "styled-components";
import {
  bytesToSize,
  convertUsageTimeFormat,
  getUploadHistoryTableColumnWidth,
} from "~/client/lib/helpers";
import {
  UPLOAD_HISTORY_TABLE_COLUMN_TYPE,
  UploadHistory,
} from "~/client/types";

import { media } from "@cochlearai/util";
import moment from "moment";
import { ShowMoreButton } from "~/client/components";
import { UPLOAD_HISTORY_TABLE_COLUMN_ITEMS } from "~/client/lib/constants";

const Container = styled(Table)`
  min-width: ${media.size.md};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getUploadHistoryTableColumnWidth(p.columnType)};
  padding: 12.5px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};
`;

const HeaderItemTypo = styled(Typo)<{ right?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: ${(p) => (p.right ? "end" : "left")};
`;

const TableRowContainer = styled(TableRow)<{ selected: boolean }>`
  background-color: ${(p) =>
    p.selected
      ? (p) => p.theme.colors.semantic.grey[10]
      : (p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }

  &:last-child {
    border-bottom: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getUploadHistoryTableColumnWidth(p.columnType)};
  max-width: ${(p) => getUploadHistoryTableColumnWidth(p.columnType)};
  padding: 20px;
  height: 60px;
  vertical-align: middle;
`;

const TableBodyItemText = styled(Typo)<{ right?: boolean }>`
  text-align: ${(p) => (p.right ? "end" : "left")};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TextBtn = styled(Typo)`
  cursor: pointer;
  color: ${(p) => p.theme.colors.blue[60]};
  margin-right: 58px;
`;

interface Props {
  data: UploadHistory[];
  EmptyComponent?: ReactNode;
  canLoadMore?: boolean;
  onClickLoadMore?: () => void;
  onClickBtn?: (item: UploadHistory) => void;
}

const UploadHistoryTable: FC<Props> = ({
  data,
  EmptyComponent,
  canLoadMore,
  onClickLoadMore,
  onClickBtn,
}) => {
  const { colors } = useTheme();
  return (
    <div style={{ padding: "30px", paddingTop: 0 }}>
      <Scroll>
        <Container>
          <TableHead>
            <TableRow>
              {UPLOAD_HISTORY_TABLE_COLUMN_ITEMS.map((item) => {
                return (
                  <TableHeadItem
                    key={item.columnType}
                    component="th"
                    columnType={item.columnType}
                  >
                    <HeaderItemTypo variant="body">{item.label}</HeaderItemTypo>
                  </TableHeadItem>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((history, i) => (
              <TableRowContainer selected={false} key={i}>
                <TableBodyItem
                  columnType={UPLOAD_HISTORY_TABLE_COLUMN_TYPE.DATE}
                >
                  <TableBodyItemText variant="body">
                    {history.created_at
                      ? moment(new Date(history.created_at * 1000)).format(
                          "MM.DD.YYYY HH:mm:ss",
                        )
                      : ""}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_NAME}
                >
                  <TableBodyItemText variant="body">
                    {history.file_name}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_SIZE}
                >
                  <TableBodyItemText
                    variant="body"
                    style={{ color: colors.grey[70] }}
                  >
                    {bytesToSize(history.file_size)}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_LENGTH}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TableBodyItemText
                      variant="body"
                      style={{ color: colors.grey[70] }}
                    >
                      {
                        convertUsageTimeFormat({
                          seconds: history.file_length,
                          showMin: false,
                        }).formattedString
                      }
                    </TableBodyItemText>
                    <TextBtn
                      variant="button"
                      onClick={() => onClickBtn && onClickBtn(history)}
                    >
                      View JSON
                    </TextBtn>
                  </div>
                </TableBodyItem>
              </TableRowContainer>
            ))}
          </TableBody>
        </Container>
        {data.length === 0 && EmptyComponent}
      </Scroll>
      {canLoadMore && <ShowMoreButton onClick={onClickLoadMore} />}
    </div>
  );
};

export default UploadHistoryTable;
