import { Button, ToastCheckIcon, Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";

import { PLAN_META } from "~/client/lib/constants";
import { formatPlanTitle } from "~/client/lib/helpers";
import { Plan } from "~/client/types/priceplan";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background-color: ${(p) => p.theme.colors.white};
  min-width: 328px;
`;

const TitleText = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
`;

const SubTitleText = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[70]};
  margin-top: 6px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
  background-color: ${(p) => p.theme.colors.grey[10]};
  width: 100%;
  height: 368px;
  padding-left: 50px;
  padding-right: 50px;
`;

const ContentTextWithCheckbox = styled.div`
  display: flex;
  align-items: flex-start;
  & + & {
    margin-top: 12px;
  }
`;

const ContentText = styled(Typo)`
  color: ${(p) => p.theme.colors.black};
  margin-left: 10px;
  flex: 1;
`;

const ActionButton = styled(Button)<{
  planColor: string;
  planHoverColor: string;
}>`
  margin-top: 30px;
  margin-bottom: 40px;
  width: 220px;
  height: 50px;
  background-color: ${(p) => p.planColor};
  border-color: ${(p) => p.planColor};

  &:hover {
    background-color: ${(p) => p.planHoverColor};
    border-color: ${(p) => p.planHoverColor};
  }
  &:disabled {
    background-color: ${(p) => p.planColor};
    border-color: ${(p) => p.planColor};
  }
`;

interface Props {
  data: {
    plan: Plan;
    onClickItem: (plan: Plan) => void;
    disabled: boolean;
  };
}

const PricePlanItem: FC<Props> = ({
  data: { plan, onClickItem, disabled },
}) => {
  const { colors } = useTheme();
  const planId = plan.id;
  const planColor = colors.plan_color[planId];
  const planHoverColor =
    planId === "free"
      ? "#7829E4"
      : planId === "standard"
        ? "#465FE7"
        : "#02176A";
  return (
    <Container>
      <TitleText variant="h2">{formatPlanTitle(plan.id)}</TitleText>
      <SubTitleText variant="body">{PLAN_META[planId].subtitle}</SubTitleText>
      <ActionButton
        onClick={() => {
          onClickItem(plan);
        }}
        planColor={planColor}
        planHoverColor={planHoverColor}
        disabled={disabled}
      >
        {disabled ? "Current plan" : PLAN_META[planId].chooseButtonLabel}
      </ActionButton>
      <ContentContainer>
        {PLAN_META[planId].plansModalFeatures.map((featureText, index) => {
          return (
            <ContentTextWithCheckbox key={`${featureText}-${index}`}>
              <ToastCheckIcon fill={planColor} style={{ marginTop: "4px" }} />
              <ContentText variant="body">{featureText}</ContentText>
            </ContentTextWithCheckbox>
          );
        })}
      </ContentContainer>
    </Container>
  );
};

export default PricePlanItem;
