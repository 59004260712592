import React, { FC } from "react";

import { PRODUCT_TYPE_UI_LABELS } from "~/client/lib/constants";
import { ProjectTabItem } from "~/client/components";
import { ProjectType } from "~/client/types";
import { media } from "@cochlearai/util";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 18;
  border-bottom: 2px solid ${(p) => p.theme.colors.grey[20]};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: -2px;

  ${media.query.md} {
    padding: 0 10px;
  }
`;

const TabsContainer = styled.ul`
  margin-top: auto;
  margin-bottom: unset;
  padding: unset;
  display: inline-block;
  list-style-type: none;

  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

const ProjectTabSkeleton: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Inner>
        <TabsContainer>
          {Object.values(ProjectType).map((item, index) => (
            <ProjectTabItem
              key={`${index}_${item}`}
              title={t(PRODUCT_TYPE_UI_LABELS[item], { ns: "common" })}
            />
          ))}
        </TabsContainer>
      </Inner>
    </Container>
  );
};

export default ProjectTabSkeleton;
