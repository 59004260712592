import { AnalyticsType } from "./analytics";

export interface SidebarInfo {
  open: boolean;
  type?: SidebarType;
  filterType?: AnalyticsType;
}

export enum SidebarType {
  TAG_SELECTION = "TAG_SELECTION",
  DETAIL_EVENT = "DETAIL_EVENT",
  FILTER = "FILTER",
}
