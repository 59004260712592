import { InfoTooltip, Typo } from "@cochlearai/ui";
import { FC, useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { HEATMAP_COLOR } from "~/client/lib/constants";

const RangeColor = styled(InfoTooltip)<{ color: string; width: number }>`
  width: ${(p) => p.width ?? 25}%;
  background-color: ${(p) => p.color};
`;

interface Props {
  highestValue?: number;
}

const HeatmapLegend: FC<Props> = ({ highestValue = 0 }) => {
  const { colors } = useTheme();
  const [popoverIsOpen, setPopoverOpen] = useState<
    Array<{ id: number; open: boolean }>
  >([]);
  const mapColors = useMemo(
    () =>
      HEATMAP_COLOR.slice(highestValue <= 5 ? 3 : highestValue <= 10 ? 2 : 0),
    [highestValue],
  );
  const numberToDivide = useMemo(
    () => (highestValue <= 5 ? 1 : highestValue <= 10 ? 2 : 4),
    [highestValue],
  );

  const openPopover = useCallback(
    (id) => {
      const tempArr = popoverIsOpen.map((item) =>
        item.id === id ? { id, open: true } : { ...item, open: false },
      );

      if (popoverIsOpen.find((item) => item.id === id)) {
        setPopoverOpen(tempArr);
      } else {
        setPopoverOpen([...tempArr, { id, open: true }]);
      }
    },
    [popoverIsOpen],
  );

  const closePopover = useCallback(
    (id) => {
      setPopoverOpen(
        popoverIsOpen.map((item) =>
          item.id === id ? { id, open: false } : item,
        ),
      );
    },
    [popoverIsOpen],
  );

  const isPopoverOpen = useCallback(
    (id) => {
      return popoverIsOpen.find((item) => item.id === id)?.open ?? false;
    },
    [popoverIsOpen],
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "14px",
        }}
      >
        {highestValue === 0 ? (
          <InfoTooltip
            open={isPopoverOpen(0)}
            text="No Data"
            onMouseOver={() => openPopover(0)}
            onMouseOut={() => closePopover(0)}
            style={{ width: "100%", backgroundColor: colors.grey[30] }}
          >
            <div />
          </InfoTooltip>
        ) : (
          <>
            {mapColors.map((color, i) => {
              const dividedNumber = Math.round(highestValue / numberToDivide);
              const range_from = dividedNumber * i + 1;
              const range_to =
                i === mapColors.length - 1
                  ? highestValue
                  : dividedNumber * (i + 1);
              return (
                <RangeColor
                  key={i}
                  open={isPopoverOpen(i)}
                  text={`Range ${range_from} - ${range_to}`}
                  onMouseOver={() => openPopover(i)}
                  onMouseOut={() => closePopover(i)}
                  position="top"
                  color={color}
                  width={
                    numberToDivide === 1 ? 100 : numberToDivide === 2 ? 50 : 25
                  }
                >
                  <div />
                </RangeColor>
              );
            })}
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "4px",
        }}
      >
        <Typo variant="caption1" style={{ color: colors.grey[80] }}>
          Lowest event detection
        </Typo>
        <Typo variant="caption1" style={{ color: colors.grey[80] }}>
          Highest event detection
        </Typo>
      </div>
    </div>
  );
};

export default HeatmapLegend;
