import { SenseTags } from ".";

export enum MediumType {
  MOBILE = "mobile",
  WEBHOOK = "webhook",
  EMAIL = "email",
}

export enum WebhookModalType {
  ADD = "Add",
  EDIT = "Edit",
}

export enum TAG_INTERVAL_TIME_TYPE_VALUE {
  MINUTES = "MINUTES",
  SECONDS = "SECONDS",
}

export enum WEBHOOK_MORE_TYPE_VALUE {
  EDIT_WEBHOOK = "EDIT_WEBHOOK",
  DELETE_WEBHOOK = "DELETE_WEBHOOK",
  VIEW_HISTORY = "VIEW_HISTORY",
}

export enum WEBHOOK_HISTORY_TABLE_COLUMN_TYPE {
  DATE = "DATE",
  HTTP_ADDRESS = "HTTP_ADDRESS",
  RESPONSE_CODE = "RESPONSE_CODE",
  STATUS = "STATUS",
  RESPONSE_MESSAGE = "RESPONSE_MESSAGE",
}

export interface WebhookHistoryTableColumnItem {
  columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE;
  label: string;
}

export interface WebhookHistory {
  id: string;
  created_at: string;
  url: string;
  result: number; // status
  response_status_code: number; // response code
  response_body: string; // response message
}

export interface TriggerCondition {
  id: string;
  tag_name: SenseTags;
  interval: number;
  active: boolean;
}

export interface NotificationConfig {
  id: string;
  project_id: string;
  medium_id: string;
  interval: number;
  active: boolean;
  trigger_conditions?: TriggerCondition[];
  medium_values: string[];
}

export interface Medium {
  id: string;
  medium_type: MediumType;
  medium_values: string[];
  active: boolean;
}

export interface TagInterval {
  value: SenseTags;
  label: string;
  interval?: number;
  interval_unit?: TAG_INTERVAL_TIME_TYPE_VALUE;
  error?: string;
}
