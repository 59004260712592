import { CSSProperties } from "react";
import { DayPickerRangeControllerShape as ReactDatesDayPickerControllerShape } from "react-dates";

export interface CustomDayPickerRangeControllerRenderShape {
  renderInputComponents?: () => React.ReactNode | JSX.Element;
  renderButtonComonents?: () => React.ReactNode | JSX.Element;
  className?: string;
  style?: CSSProperties;
}

export type CustomDayPickerRangeControllerShape =
  CustomDayPickerRangeControllerRenderShape &
    ReactDatesDayPickerControllerShape;

export enum CALENDAR_INPUT_NAMES {
  STARTDATE_TIME = "calendarDateTimeInputStartDate",
  STARTDATE_DATE = "calendarDateDateInputStartDate",
  STARTDATE_MONTH = "calendarDateMonthInputStartDate",
  STARTDATE_YEAR = "calendarDateYearInputStartDate",
  ENDDATE_TIME = "calendarDateTimeInputEndDate",
  ENDDATE_DATE = "calendarDateDateInputEndDate",
  ENDDATE_MONTH = "calendarDateMonthInputEndDate",
  ENDDATE_YEAR = "calendarDateYearInputEndDate",
}
