import { Banner, CopyIcon, HyperlinkButton, Typo } from "@cochlearai/ui";
import { FC, useState } from "react";
import { SharedUserProfile, SubHeaderLinkItem } from "~/client/components";
import styled, { useTheme } from "styled-components";

import { Link } from "react-router-dom";
import { copyToClipboard } from "~/client/lib/helpers";
import { media } from "@cochlearai/util";
import usePageRoute from "~/client/hooks/usePageRoute";

const Inner = styled.div`
  width: ${media.size.lg};
  height: 162px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  ${media.query.xl} {
    width: ${media.size.lg};
  }

  ${media.query.lg} {
    width: calc(100vw);
    padding-left: 60px;
    padding-right: 60px;
  }

  ${media.query.md} {
    width: calc(100vw);
    padding-left: 20px;
    padding-right: 20px;
    max-width: calc(100vw);
  }

  ${media.query.sm} {
    width: calc(100vw);
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const ContentLayout = styled.div<{ $isSpaceBetween: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.$isSpaceBetween ? "space-between" : "")};
  gap: 9px;
  padding-top: 50px;

  ${media.query.md} {
    padding-top: 30px;
  }
`;

const TitleText = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

const TabsContainer = styled.ul`
  margin-top: auto;
  margin-bottom: unset;
  padding: unset;
  display: inline-block;
  list-style-type: none;

  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

const ProjectKeyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 6px 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
`;

const ProjectKeyText = styled(Typo)`
  width: 93px;
  ${media.query.md} {
    display: none;
  }
`;

const SubHeaderContent: FC = () => {
  const { colors } = useTheme();
  const { subTabs, subheaderTitle, projectKey, shareInfo, bannerInfo } =
    usePageRoute();
  const [bannerVisible, setBannerVisible] = useState<boolean>(true);

  return (
    <>
      {bannerVisible && bannerInfo && (
        <Banner onClose={() => setBannerVisible(false)}>
          <Typo variant="body" style={{ marginRight: "6px" }}>
            {bannerInfo.text}
          </Typo>
          {bannerInfo.link && (
            <Link to={bannerInfo.link}>
              <HyperlinkButton
                text="Try out the features"
                rightArrow
              ></HyperlinkButton>
            </Link>
          )}
        </Banner>
      )}
      <Inner>
        <ContentLayout $isSpaceBetween={!!(projectKey && !shareInfo)}>
          <TitleText>{subheaderTitle}</TitleText>
          {shareInfo && (
            <SharedUserProfile text={shareInfo.text} color={shareInfo.color} />
          )}
          {projectKey && (
            <ProjectKeyContainer onClick={() => copyToClipboard(projectKey)}>
              <CopyIcon width={15} height={15} fill={colors.black} />
              <ProjectKeyText variant="caption1">
                Copy Project key
              </ProjectKeyText>
            </ProjectKeyContainer>
          )}
        </ContentLayout>
        <TabsContainer>
          {subTabs.map((item, index) => {
            return (
              <SubHeaderLinkItem
                key={index}
                to={item.to}
                label={item.label}
                selected={item.selected}
                disabled={item.disabled}
                isBETA={item.isBETA}
              />
            );
          })}
        </TabsContainer>
      </Inner>
    </>
  );
};

export default SubHeaderContent;
