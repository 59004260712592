import { matchPath, useLocation } from "react-router-dom";

import { MATCHED_PATH } from "../constants";
import { useMemo } from "react";
import useProjectList from "./useProjectList";

function useCurrentProject() {
  const location = useLocation();
  const { projectList } = useProjectList();

  const matched = useMemo(
    () =>
      matchPath<{ projectId?: string }>(location.pathname, {
        path: MATCHED_PATH,
      }),
    [location.pathname],
  );
  const currentProject = useMemo(
    () =>
      projectList?.find(
        (project) => project.id === matched?.params.projectId,
      ) ?? null,
    [matched?.params.projectId, projectList],
  );

  return {
    currentProject,
  };
}

export default useCurrentProject;
