import React, { FC, SVGProps } from "react";

const VoiceIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="40.3638"
      y="51.7681"
      width="6.36364"
      height="16.2863"
      rx="3.18182"
      fill="#4B68FF"
    />
    <rect
      x="48.5454"
      y="40.9105"
      width="6.36364"
      height="38.0014"
      rx="3.18182"
      fill="#4B68FF"
    />
    <rect
      x="56.7275"
      y="44.5297"
      width="6.36364"
      height="30.7631"
      rx="3.18182"
      fill="#4B68FF"
    />
    <rect
      x="64.9092"
      y="55.4777"
      width="6.36364"
      height="8.867"
      rx="3.18182"
      fill="#4B68FF"
    />
    <rect
      x="73.0908"
      y="47.9679"
      width="6.36364"
      height="23.8866"
      rx="3.18182"
      fill="#4B68FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5455 13.6381V5.45632H5.45459L5.45459 5.45633V13.6381V24.5472H13.6364V13.6381L24.5455 13.6381Z"
      fill="#4B68FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.546 24.5472V13.6381L95.6367 13.6381V5.45633L106.546 5.45632H114.728L114.728 13.6381L114.728 24.5472H106.546Z"
      fill="#4B68FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.45503 114.546H5.45459V96.4549C5.45459 95.9026 5.9023 95.4549 6.45459 95.4549H12.6364C13.1887 95.4549 13.6364 95.9026 13.6364 96.4549V106.364L23.5459 106.364C24.0982 106.364 24.5459 106.812 24.5459 107.364V113.546C24.5459 114.098 24.0982 114.546 23.5459 114.546H13.6364H5.45503Z"
      fill="#4B68FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.546 114.546H97.6367C96.5322 114.546 95.6367 113.65 95.6367 112.546V108.364C95.6367 107.259 96.5321 106.364 97.6367 106.364L106.546 106.364V97.4549C106.546 96.3503 107.441 95.4549 108.546 95.4549H112.728C113.832 95.4549 114.728 96.3503 114.728 97.4549V106.364H114.728V114.546H114.728H106.546Z"
      fill="#4B68FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.435 99.2255L39.521 98.3819L46.7988 92.7287H92.727C95.7394 92.7287 98.1815 90.2866 98.1815 87.2741V30.0014C98.1815 26.989 95.7394 24.5469 92.727 24.5469H27.2724C24.26 24.5469 21.8179 26.989 21.8179 30.0014V87.2741C21.8179 90.2866 24.26 92.7287 27.2724 92.7287H32.2483V94.8222V96.1973V100.001L32.2483 102.215L32.2483 102.917C32.2483 103.371 32.77 103.626 33.1284 103.348L33.6829 102.917L38.435 99.2255ZM39.521 89.1728L39.521 85.456H32.2483H29.0906V31.8196H90.9088V85.456H46.7988H44.3059L42.3373 86.9852L39.521 89.1728Z"
      fill="#4B68FF"
    />
  </svg>
);

export default VoiceIcon;
