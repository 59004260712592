import { UPLOAD_HISTORY_TABLE_COLUMN_TYPE } from "~/client/types";

function getUploadHistoryTableColumnWidth(
  columnType: UPLOAD_HISTORY_TABLE_COLUMN_TYPE,
) {
  switch (columnType) {
    case UPLOAD_HISTORY_TABLE_COLUMN_TYPE.DATE:
      return "210px";
    case UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_NAME:
      return "440px";
    case UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_SIZE:
      return "160px";
    case UPLOAD_HISTORY_TABLE_COLUMN_TYPE.FILE_LENGTH:
      return "330px";
    default:
      return "0px";
  }
}

export default getUploadHistoryTableColumnWidth;
