import { MouseEventHandler, PropsWithChildren } from "react";

import { color } from "@cochlearai/util";
import styled from "styled-components";
import { BaseStyleProps, ThemeType } from "../types";

const Container = styled.button`
  border: none;
  background-color: ${(p) =>
    color.convertHexToRGB({
      hex: p.theme.colors.blue[60],
      alpha: p.theme.theme === ThemeType.LIGHT ? 0.05 : 0.1,
    })};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  height: auto;
  color: ${(p) => p.theme.colors.black};
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 15px !important;
`;

export interface ChipProps extends BaseStyleProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * React component used to create a Chip layout.
 * @param className  - The className of the chip.
 * @param style      - The style of the chip.
 * @param onClick    - The onClick handler of the chip.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/chip--default
 */
export const Chip = ({
  children,
  className,
  style,
  onClick,
}: PropsWithChildren<ChipProps>) => {
  return (
    <Container onClick={onClick} className={className} style={style}>
      {children}
    </Container>
  );
};
