import { ProjectType } from ".";

export enum INVOICE_TABLE_COLUMN_TYPE {
  DESCRIPTION = "DESCRIPTION",
  QTY = "QTY",
  UNIT_PRICE = "UNIT_PRICE",
  AMOUNT = "AMOUNT",
}

export interface InvoiceTableColumnItem {
  columnType: INVOICE_TABLE_COLUMN_TYPE;
  label: string;
}

export enum INVOICE_HISTORY_TABLE_COLUMN_TYPE {
  DATE = "DATE",
  INVOICE_NUMBER = "INVOICE_NUMBER",
  AMOUNT = "AMOUNT",
  STATUS = "STATUS",
}

export interface InvoiceHistoryTableColumnItem {
  columnType: INVOICE_HISTORY_TABLE_COLUMN_TYPE;
  label: string;
}

type Period = {
  start: number;
  end: number;
};

export interface PlanSubItem {
  description: string;
  quantity: number;
  unit_price: number;
  amount: number;
  period: Period;
}

export interface PlanItem {
  description: string;
  quantity: number;
  unit_price: number;
  amount: number;
  sub_items: PlanSubItem[] | null;
  product_type: ProjectType;
}

export interface PlanGroup {
  period: Period;
  plan_type: string;
  items: PlanItem[];
}

export interface Discount {
  remaining: number;
  total: number;
}

export interface InvoiceInfo {
  sub_total: number;
  applied_balance: number;
  total: number;
  plan_groups: PlanGroup[];
  discount?: Discount;
}

export interface InvoiceHistory {
  date: string;
  paid_date?: string;
  invoice_number: string;
  invoice_url: string;
  amount: number;
  currency: string;
  billing_reason: string;
}
