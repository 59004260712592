import React, { ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_ERROR_STATUS, HTTPError } from "~/client/types";

import { Toast } from "@cochlearai/ui";
import { handleUnauthenticated } from "~/client/lib/auth";
import { isHTTPError } from "~/client/lib/helpers";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps extends RouteComponentProps, PropsWithChildren {
  //
}

interface State {
  error: Error | HTTPError | null;
  errorInfo: ErrorInfo | null;
  fallback: ReactNode;
}

type Props = OwnProps;

class PageContentErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null, fallback: null };
  }

  componentDidCatch(error: Error | HTTPError, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
      fallback: null,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { error } = this.state;
    if (!error) {
      return;
    }
    if (JSON.stringify(prevState.error) !== JSON.stringify(error)) {
      if (isHTTPError(error)) {
        const httpError = error as HTTPError;
        if (httpError.response?.status === API_ERROR_STATUS.UNAUTHORIZED) {
          handleUnauthenticated();
        }
      }
    }
  }

  render() {
    const { error, fallback } = this.state;
    const { children } = this.props;
    if (!error) {
      return children;
    }
    if (fallback) {
      return fallback;
    }
    const message: string | undefined = isHTTPError(error)
      ? (error as HTTPError).response?.msg
      : (error as Error).message;
    return (
      <Toast
        open={error !== null}
        onClose={() => {
          //
        }}
        message={message ?? "Something went wrong."}
        severity="error"
      />
    );
  }
}

export default withRouter(PageContentErrorBoundary);
