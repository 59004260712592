/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  FetchGetOrganizationMembersParams,
  FetchGetOrganizationMembersResponse,
  FetchInviteToOrganizationParams,
  FetchInviteToOrganizationResponse,
  FetchRemoveMemberParams,
  FetchRemoveMemberResponse,
  FetchCancelInvitationParams,
  FetchCancelInvitationResponse,
  FetchGetJoinedOrganizationParams,
  FetchGetJoinedOrganizationResponse,
  FetchReinviteToOrganizationParams,
  FetchReinviteToOrganizationResponse,
} from "~/client/types/api/organization";

import { APICallPayloads } from "~/client/types/api";
import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_ORGANIZATIONS_API_BASE_URL: organizationAPIBaseURL } =
  getConfigs();

const API_URL_PREFIX = organizationAPIBaseURL
  ? `${organizationAPIBaseURL}`
  : "";

export const fetchGetOrganizationMembers = ({
  params,
  config,
}: APICallPayloads<FetchGetOrganizationMembersParams>): Promise<FetchGetOrganizationMembersResponse> => {
  const url = `${API_URL_PREFIX}/${params.id}/members`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetJoinedOrganization = ({
  config,
}: APICallPayloads<FetchGetJoinedOrganizationParams>): Promise<FetchGetJoinedOrganizationResponse> => {
  const url = `${API_URL_PREFIX}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchInviteToOrganization = ({
  params,
  config,
}: APICallPayloads<FetchInviteToOrganizationParams>): Promise<FetchInviteToOrganizationResponse> => {
  const url = `${API_URL_PREFIX}/${params.id}/invitations`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: {
      emails: params.emails,
    },
  });
};

export const fetchReinviteToOrganization = ({
  params,
  config,
}: APICallPayloads<FetchReinviteToOrganizationParams>): Promise<FetchReinviteToOrganizationResponse> => {
  const url = `${API_URL_PREFIX}/${params.id}/invitations/reinvite`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: {
      email: params.email,
    },
  });
};

export const fetchRemoveMember = ({
  params,
  config,
}: APICallPayloads<FetchRemoveMemberParams>): Promise<FetchRemoveMemberResponse> => {
  const url = `${API_URL_PREFIX}/${params.organization_id}/members/${params.user_id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

export const fetchCancelInvitation = ({
  params,
  config,
}: APICallPayloads<FetchCancelInvitationParams>): Promise<FetchCancelInvitationResponse> => {
  const url = `${API_URL_PREFIX}/${params.organization_id}/invitations/cancel`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: {
      email: params.email,
    },
  });
};
