import { WEBHOOK_HISTORY_TABLE_COLUMN_TYPE } from "~/client/types/postAction";

function getWebhookHistoryTableColumnWidth(
  columnType: WEBHOOK_HISTORY_TABLE_COLUMN_TYPE,
) {
  switch (columnType) {
    case WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.DATE:
      return "200px";
    case WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.HTTP_ADDRESS:
      return "250px";
    case WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.RESPONSE_CODE:
      return "150px";
    case WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.STATUS:
      return "100px";
    case WEBHOOK_HISTORY_TABLE_COLUMN_TYPE.RESPONSE_MESSAGE:
      return "160px";
    default:
      return "0px";
  }
}

export default getWebhookHistoryTableColumnWidth;
