import React, { FC } from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";
import { ANALYTICS_COLOR, Z_INDEXES } from "~/client/lib/constants";

const ChartContainer = styled(Chart)`
  .apexcharts-text {
    fill: ${(p) => p.theme.colors.black};
  }
  .apexcharts-tooltip {
    z-index: ${Z_INDEXES.POP_OVER};
  }
`;

interface Props {
  categories: string[];
  data: number[];
}

const BarChart: FC<Props> = ({ categories, data }) => {
  return (
    <ChartContainer
      type="bar"
      height={330}
      series={[{ data }]}
      options={{
        plotOptions: {
          bar: {
            // columnWidth: "50px",
            distributed: true,
            horizontal: false,
          },
        },
        xaxis: { categories },
        yaxis: {},
        colors: ANALYTICS_COLOR,
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          theme: "dark",
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
        grid: {
          strokeDashArray: 2,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
      }}
    />
  );
};

export default BarChart;
