import { Card, ProgressIndicator, Typo } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";

import { media } from "@cochlearai/util";
import {
  BarChart,
  DetailFilterButton,
  FilterButton,
} from "~/client/components";
import { useAppSelector } from "~/client/hooks";
import useAnalyticsTopTag from "~/client/hooks/useAnalyticsTopTag";
import { selectedAnalyticsState } from "~/client/lib";
import { convertSenseTagValueToUILabel } from "~/client/lib/helpers";
import { SenseTags, SidebarType } from "~/client/types";
import { AnalyticsType, FilterType } from "~/client/types/analytics";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CardItem = styled(Card)`
  width: 100%;
  padding: 30px;
`;

const TopArea = styled(Flex)`
  justify-content: space-between;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const FilterArea = styled(Flex)`
  gap: 10px;

  ${media.query.md} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const Loading = styled(ProgressIndicator)`
  display: flex;
  justify-content: center;
  height: 330px;
  align-items: center;
`;

interface Props {
  openSidebar: (type: SidebarType, filterType?: AnalyticsType) => void;
}

const TopTags: FC<Props> = ({ openSidebar }) => {
  const { topTag, isValidating } = useAnalyticsTopTag();
  const { filterParamsWithChecked } = useAppSelector(selectedAnalyticsState);
  const tagFilters =
    (
      filterParamsWithChecked &&
      filterParamsWithChecked[AnalyticsType.TOP_TAG][FilterType.TAG]
    )?.filter((filter) => filter.checked) ?? [];

  return (
    <CardItem>
      <TopArea>
        <Typo variant="h3">Top Tags</Typo>
        <FilterArea>
          {tagFilters.length > 0 && (
            <DetailFilterButton text="Tags" type="string" data={tagFilters} />
          )}
          <FilterButton
            onClick={() =>
              openSidebar(SidebarType.FILTER, AnalyticsType.TOP_TAG)
            }
            text="Filter"
            selected={tagFilters.length > 0}
          />
        </FilterArea>
      </TopArea>
      {isValidating ? (
        <Loading size="medium" />
      ) : (
        <BarChart
          categories={topTag?.map((data) =>
            convertSenseTagValueToUILabel(data.tag_name as SenseTags),
          )}
          data={topTag?.map((data) => data.count)}
        />
      )}
    </CardItem>
  );
};

export default TopTags;
