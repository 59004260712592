import { Card, Typo, UsageBar, UsageBarContent } from "@cochlearai/ui";
import styled, { useTheme } from "styled-components";

import { FC } from "react";
import { convertSecondsToMinutes } from "../../lib";

const TotalCostBox = styled(Card)<{ focused?: boolean }>`
  background: ${(p) => p.theme.colors.white};
  border: 1px solid
    ${(p) => (p.focused ? p.theme.colors.grey[60] : p.theme.colors.grey[20])};
  border-radius: 4px;
  padding: 14px;

  & p {
    color: ${(p) => p.focused && p.theme.colors.grey[80]};
  }
  & h5 {
    color: ${(p) => p.focused && p.theme.colors.black};
  }
`;

const ProjectName = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[40]};
  margin-bottom: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface Props {
  name: string;
  usage: number;
  maxUsage: number;
  maxNumPerLine?: number;
  usageLabel?: string;
  color?: string;
  focused?: boolean;
}

const ProjectUsageCard: FC<Props> = ({
  name,
  usage,
  maxUsage,
  maxNumPerLine,
  usageLabel,
  color,
  focused,
}) => {
  const { colors } = useTheme();
  const data = [{ usage, color }];
  return (
    <TotalCostBox focused={focused}>
      <ProjectName variant="button">{name ?? "Project name"}</ProjectName>
      <UsageBar
        data={data}
        renderItem={({ usage, color }, index) => (
          <UsageBarContent
            key={index}
            isFirst={index === 0}
            isLast={index === data.length - 1}
            color={color}
            borderRadius={2}
            width={
              (convertSecondsToMinutes({
                seconds: usage ?? 0,
              }) /
                convertSecondsToMinutes({
                  seconds: maxUsage,
                })) *
              100
            }
          />
        )}
        currentValueLabel={usageLabel}
        currentValueLabelColor={colors.grey[80]}
        borderRadius={2}
      />
    </TotalCostBox>
  );
};

export default ProjectUsageCard;
