import { CSSProperties, FC, useEffect, useRef } from "react";

import { Typo, Z_INDEXES } from "@cochlearai/ui";
import { color } from "@cochlearai/util";
import styled, { useTheme } from "styled-components";
import { TRACKED_TAGS_LIMIT } from "~/client/lib/constants";

const Container = styled.div`
  position: relative;
`;

const InnerContainer = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 4px;
  width: 100%;
  height: 30px;
  padding: 3px 9px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  cursor: pointer;
`;

const ExpandedContent = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px;
  visibility: ${(p) => (p.$isOpen ? "visible" : "hidden")};
  top: 0;
  left: 0;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
  z-index: ${Z_INDEXES.POP_OVER};

  border: 1px solid ${(p) => p.theme.colors.grey[30]};
  box-shadow: 0px 0px 5px 0px
    ${(p) =>
      color.convertHexToRGB({
        hex: p.theme.colors.shadow,
        alpha: 0.3,
      })};
  background-color: ${(p) => p.theme.colors.white};
`;

const Chip = styled(Typo)`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 100px;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) =>
    color.convertHexToRGB({
      hex: p.theme.colors.blue[60],
      alpha: 0.05,
    })};
`;

interface Props {
  data: string[];
  className?: string;
  style?: CSSProperties;
  expanded: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const ExpandInput: FC<Props> = ({
  data,
  className,
  style,
  expanded,
  onOpen,
  onClose,
}) => {
  const { colors } = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent | TouchEvent) => {
      const target = e.target as Element;
      if (!ref.current || ref.current.contains(target)) {
        return;
      }
      onClose && onClose();
    };
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose && onClose();
      }
    };
    document.addEventListener("mousedown", onClickOutside);
    document.addEventListener("touchstart", onClickOutside);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
      document.removeEventListener("touchstart", onClickOutside);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onClose]);

  return (
    <Container style={style} className={className}>
      <InnerContainer
        onClick={() => data.length > 0 && onOpen()}
        $isOpen={expanded}
      >
        {data.map((tag, i) => (
          <Chip variant="caption1" key={i}>
            {tag}
          </Chip>
        ))}
      </InnerContainer>
      {data.length > 0 && (
        <ExpandedContent ref={ref} $isOpen={expanded} tabIndex={1}>
          {data.slice(0, TRACKED_TAGS_LIMIT).map((tag, i) => (
            <Chip variant="caption1" key={i}>
              {tag}
            </Chip>
          ))}
          {data.length > TRACKED_TAGS_LIMIT && (
            <Typo variant="caption1" style={{ color: colors.grey[60] }}>
              +{data.length - TRACKED_TAGS_LIMIT} more tags
            </Typo>
          )}
        </ExpandedContent>
      )}
    </Container>
  );
};

export default ExpandInput;
