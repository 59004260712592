import { fetchNewSubscription } from "~/client/api/payment";
import { useAppDispatch } from "~/client/hooks";
import { useCallback, useRef } from "react";
import { useSWRConfig } from "swr";

import { APIError } from "~/client/types/error";
import { SWR_KEY } from "~/client/types/swrKey";
import { setServerError } from "~/client/store/modules/alert";

function useCreateSubscription() {
  const isCreating = useRef<boolean>(false);
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();

  const createSubscription = useCallback(
    async (planId: string) => {
      if (isCreating.current) return;
      isCreating.current = true;
      try {
        await fetchNewSubscription({
          params: {
            plan_id: planId,
          },
        });
        mutate(SWR_KEY.PRICEPLAN_INITIALIZE_CURRENT_SUBSCRIPTIONS);

        // old analytics
        mutate(SWR_KEY.ANALYTICS_INITIALIZE_PARAMETERS, null, {
          populateCache: true,
          revalidate: true,
        });
        return true;
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        isCreating.current = false;
      }
    },
    [dispatch, isCreating, mutate],
  );

  return {
    createSubscription,
    isCreating: isCreating.current,
  };
}

export default useCreateSubscription;
