import {
  ListDropdown,
  MoreIcon,
  Scroll,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typo,
  UserProfile,
} from "@cochlearai/ui";
import { FC, ReactNode } from "react";
import {
  getOrganizationInvitedTableColumnWidth,
  refineUserName,
} from "~/client/lib/helpers";
import {
  ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE,
  Organization,
} from "~/client/types/organization";

import { media } from "@cochlearai/util";
import moment from "moment";
import styled, { useTheme } from "styled-components";
import useMultiDropdown from "~/client/hooks/useMultiDropdown";
import { ORGANIZATION_INVITED_TABLE_COLUMN_ITEMS } from "~/client/lib/constants";
import { DetailOwnerInfo } from "~/client/types";

const Container = styled(Table)`
  min-width: ${media.size.lg};
`;

const TableHeadItem = styled(TableCell)<{
  columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE;
}>`
  width: ${(p) => getOrganizationInvitedTableColumnWidth(p.columnType)};
  padding: 11px 20px;
  background-color: ${(p) => p.theme.colors.semantic.grey[20]};

  &:first-child {
    padding-left: 46px;
  }
`;

const HeaderItemTypo = styled(Typo)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.black};
  text-align: left;
`;

const TableRowContainer = styled(TableRow)`
  user-select: none;
  background-color: ${(p) => p.theme.colors.white};
  & + & {
    border-top: 1px solid ${(p) => p.theme.colors.semantic.grey[20]};
  }
`;

const TableBodyItem = styled(TableCell)<{
  columnType: ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE;
}>`
  max-width: ${(p) => getOrganizationInvitedTableColumnWidth(p.columnType)};
  padding: 20px;
  vertical-align: middle;
  word-break: break-all;

  &:first-child {
    padding-left: 46px;
  }
`;

const TableBodyItemText = styled(Typo)`
  width: 100%;
`;

const UserRoundProfile = styled(UserProfile)<{ color?: string }>`
  margin-right: 8px;
  min-width: 25px;
  background: ${(p) => p.color ?? p.theme.colors.black};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  data: Organization[];
  EmptyComponent?: ReactNode;
  moreItemType: Array<{ label: string; value: string }>;
  onClickMoreItem: (type: string, organization: Organization) => void;
  organizationOwnerList?: DetailOwnerInfo[];
}

const OrganizationInvitedTable: FC<Props> = ({
  data,
  EmptyComponent,
  moreItemType,
  onClickMoreItem,
  organizationOwnerList,
}) => {
  const { colors } = useTheme();
  const { isDropdownOpen, openDropdown, closeDropdown } = useMultiDropdown();

  return (
    <Scroll>
      <Container>
        <TableHead>
          <TableRow>
            {ORGANIZATION_INVITED_TABLE_COLUMN_ITEMS.map((item) => {
              return (
                <TableHeadItem
                  key={item.columnType}
                  component="th"
                  columnType={item.columnType}
                >
                  <HeaderItemTypo variant="button">{item.label}</HeaderItemTypo>
                </TableHeadItem>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            const userName = refineUserName({
              givenName: item.owner_given_name,
              familyName: item.owner_name,
            });
            return (
              <TableRowContainer key={i}>
                <TableBodyItem
                  columnType={ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.NAME}
                >
                  <Flex>
                    <UserRoundProfile
                      text={userName.length > 0 ? userName[0] : "U"}
                      size="small"
                      className="profile"
                      color={
                        organizationOwnerList?.find(
                          (org) => org.organization_id === item.id,
                        )?.color
                      }
                    />
                    <TableBodyItemText
                      variant="button"
                      style={{ width: "auto" }}
                    >
                      {userName || "User"}
                    </TableBodyItemText>
                  </Flex>
                </TableBodyItem>
                <TableBodyItem
                  columnType={ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.EMAIL}
                >
                  <TableBodyItemText variant="body">
                    {item.owner_email}
                  </TableBodyItemText>
                </TableBodyItem>
                <TableBodyItem
                  columnType={
                    ORGANIZATION_MEMBER_LIST_TABLE_COLUMN_TYPE.INVITED_DATE
                  }
                >
                  <Flex>
                    <TableBodyItemText variant="body">
                      {moment(item.joined_date).format("MMM DD, YYYY")}
                    </TableBodyItemText>
                    {!item.is_admin && (
                      <ListDropdown
                        align="end"
                        data={moreItemType}
                        isOpen={isDropdownOpen(i)}
                        onClickItem={(type) => onClickMoreItem(type, item)}
                        onClose={() => closeDropdown(i)}
                        onClickOutside={() => closeDropdown(i)}
                      >
                        <Flex
                          style={{ height: "20px", cursor: "pointer" }}
                          onClick={() => openDropdown(i)}
                        >
                          <MoreIcon fill={colors.black} />
                        </Flex>
                      </ListDropdown>
                    )}
                  </Flex>
                </TableBodyItem>
              </TableRowContainer>
            );
          })}
        </TableBody>
      </Container>
      {data.length === 0 && EmptyComponent}
    </Scroll>
  );
};

export default OrganizationInvitedTable;
