import { Button, RadioButton, Typo } from "@cochlearai/ui";
import { color } from "@cochlearai/util";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Answer, Survey } from "~/client/types/survey";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 40px;
  justify-content: end;
`;

const StepWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 3px;
  width: 100%;
  margin-top: 20px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  & + & {
    margin-top: 12px;
  }
`;

const StepItem = styled.div<{ selected: boolean }>`
  height: 3px;
  border-radius: 100px;
  width: 100%;
  background: ${(p) =>
    color.convertHexToRGB({
      hex: "#ffffff",
      alpha: p.selected ? 1 : 0.2,
    })};
`;

const TextArea = styled.textarea`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 6px 8px;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
`;

const ScoreWrapper = styled.div`
  display: flex;
  gap: 13px;
  width: 100%;
`;

const ScoreItem = styled(RadioButton)`
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
`;

const ConfirmButton = styled(Button)<{ warning?: boolean }>`
  background: #ffffff;
  color: ${(p) => p.theme.colors.blue[60]} !important;
  padding: 10px 14px;
  border-radius: 100000px;

  .btn-inside {
    display: none;
  }
`;

interface Props {
  order: number;
  questions: Survey[];
  answers: Answer[];
  onClickPrevious: () => void;
  onClickNext: () => void;
  onChange: (
    e: ChangeEvent<HTMLElement>,
    question: Survey,
    isTextArea?: boolean,
    removeText?: boolean,
  ) => void;
}

const QuestionTemplate: FC<Props> = ({
  order,
  questions,
  answers,
  onClickPrevious,
  onClickNext,
  onChange,
}) => {
  const currentQuestion = useMemo(
    () => questions[order - 1],
    [order, questions],
  );
  const { question, predefined_answers, question_id, is_rating } =
    currentQuestion;
  const [isValidate, setIsValidate] = useState(false);
  const radioAnswer = useMemo(
    () =>
      answers.find(
        (answer) => answer.question_id === question_id && !answer.isTextArea,
      ),
    [answers, question_id],
  );
  const textAreaAnswer = useMemo(
    () =>
      answers.find(
        (answer) => answer.question_id === question_id && answer.isTextArea,
      ),
    [answers, question_id],
  );

  const renderAnswer = () => {
    if (predefined_answers) {
      return (
        <>
          {predefined_answers.map((answer) => {
            return (
              <RadioWrapper key={answer}>
                <RadioButton
                  name={question}
                  value={answer}
                  checked={radioAnswer?.answer === answer}
                  onChange={(e) =>
                    onChange(
                      e,
                      currentQuestion,
                      undefined,
                      question_id === 7 && e.target.value !== "No"
                        ? true
                        : false,
                    )
                  }
                />
                {question_id === 7 &&
                  answer === "No" &&
                  radioAnswer?.answer === answer && (
                    <TextArea
                      placeholder="Can you tell us why?"
                      rows={1}
                      onChange={(e) => onChange(e, currentQuestion, true)}
                      value={textAreaAnswer?.answer}
                    ></TextArea>
                  )}
              </RadioWrapper>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {is_rating && (
            <>
              <ScoreWrapper>
                {Array.from({ length: 5 }).map((_, i) => (
                  <ScoreItem
                    key={i}
                    name={question}
                    value={`${i + 1}`}
                    checked={radioAnswer?.answer === `${i + 1}`}
                    isBtnType={true}
                    onChange={(e) => {
                      onChange(e, currentQuestion);
                    }}
                  />
                ))}
              </ScoreWrapper>
              <Typo
                variant="caption1"
                style={{
                  margin: "12px 0",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                1: Poor, 5: Excellent
              </Typo>
            </>
          )}
          <TextArea
            placeholder={
              is_rating ? "Can you tell us why?" : "Type your answer"
            }
            rows={is_rating ? 3 : 5}
            onChange={(e) => onChange(e, currentQuestion, true)}
            value={textAreaAnswer?.answer ?? ""}
          ></TextArea>
        </>
      );
    }
  };

  useEffect(() => {
    if (textAreaAnswer || radioAnswer) {
      if (
        (question_id === 7 &&
          radioAnswer?.answer === "No" &&
          !textAreaAnswer) ||
        (question_id === 8 && !(textAreaAnswer && radioAnswer))
      ) {
        setIsValidate(false);
      } else {
        setIsValidate(true);
      }
    } else {
      setIsValidate(false);
    }
  }, [question_id, radioAnswer, textAreaAnswer]);

  return (
    <>
      <Typo variant="h3" style={{ marginBottom: "20px", color: "#ffffff" }}>
        Customer satisfaction survey
      </Typo>
      <StepWrapper>
        {Array.from({ length: questions.length }).map((_, i) => (
          <StepItem selected={i < order} key={i} />
        ))}
      </StepWrapper>
      <Typo variant="h4" style={{ margin: "20px 0", color: "#ffffff" }}>
        {question}
      </Typo>
      {renderAnswer()}
      <ButtonWrapper>
        {order !== 1 && (
          <Typo
            variant="button"
            style={{
              padding: "10px",
              color: "#ffffff",
              cursor: "pointer",
            }}
            onClick={onClickPrevious}
          >
            Previous
          </Typo>
        )}
        {order <= questions.length && (
          <ConfirmButton
            onClick={onClickNext}
            disabled={!isValidate}
            color="primary"
          >
            {order === questions.length ? "Submit" : "Next"}
          </ConfirmButton>
        )}
      </ButtonWrapper>
    </>
  );
};

export default QuestionTemplate;
