import { useCallback, useRef } from "react";

import { APIError } from "~/client/types";
import { OrganizationMember } from "~/client/types/organization";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchCancelInvitation } from "~/client/api/organization";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useAuthenticate from "./useAuthenticate";
import { useSWRConfig } from "swr";

function useCancelInvitation() {
  const isCanceling = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const { customer } = useAuthenticate();

  const cancel = useCallback(
    async (members: OrganizationMember[]) => {
      if (isCanceling.current) return;
      if (members.length === 0 || !customer) return;
      isCanceling.current = true;
      try {
        await Promise.all(
          members.map(async ({ email }) => {
            await fetchCancelInvitation({
              params: {
                email,
                organization_id: customer.organization_id,
              },
            });
          }),
        );

        mutate(SWR_KEY.ORGANIZATION_INITIALIZE_MEMBER_LIST);
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        isCanceling.current = false;
      }
    },
    [customer, mutate, dispatch],
  );

  return {
    cancel,
    isValidating: isCanceling.current,
  };
}

export default useCancelInvitation;
