import React, { FC } from "react";

import { ProjectItem } from "~/client/components";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
  grid-gap: 24px;
  margin-top: 20px;

  ${media.query.md} {
    grid-template-columns: 1fr;
  }
`;

const ProjectItemListSkeleton: FC = () => {
  return (
    <Container data-testid="project-item-list">
      {Array.from({ length: 12 }).map((item, i) => {
        return <ProjectItem key={i} />;
      })}
    </Container>
  );
};

export default ProjectItemListSkeleton;
