import { FC } from "react";

import { Button, Scroll } from "@cochlearai/ui";
import styled from "styled-components";
import useReadNotification from "~/client/hooks/useReadNotification";
import { Notification } from "~/client/types/notification";
import { NotificationMessage } from "..";

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(p) => p.theme.colors.white};

  border-radius: 4px;
`;

const ListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 30px;
  & + & {
    padding-top: 0px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.black};
`;

const MarkText = styled(Button)<{ disabled?: boolean }>`
  margin-left: 64px;
  color: ${(p) => p.theme.colors.blue[60]} !important;
  cursor: pointer;
  pointer-events: ${(p) => (p.disabled ? "none" : "")};
  user-select: none;

  .btn-inside {
    visibility: hidden;
  }
`;

const NoInvitationText = styled(Text)`
  color: ${(p) => p.theme.colors.grey[70]};
  font-weight: 400;
  padding: 20px 30px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(p) => p.theme.colors.grey[20]};
  width: 100%;
`;

const MessageContainer = styled(Scroll)`
  --screenHeight: calc(100vh);
  max-height: calc(var(--screenHeight) - 238px);
`;

interface Props {
  onClick?: () => void;
  notifications: Notification[];
}

const NotificationPopoverContent: FC<Props> = ({ onClick, notifications }) => {
  const { read, readAll, isValidating } = useReadNotification();
  return (
    <Container>
      <ListItem>
        <Text>Notifications</Text>
        <MarkText
          disabled={!notifications.find((item) => !item.read)}
          onClick={() => {
            if (isValidating) return;
            readAll();
          }}
        >
          Mark all as read
        </MarkText>
      </ListItem>
      <Divider />
      <MessageContainer>
        {notifications?.length > 0 ? (
          notifications.map((notiItem, index) => (
            <NotificationMessage
              key={index}
              notification={notiItem}
              isLast={index === notifications.length - 1}
              onClick={() => {
                if (isValidating) return;
                read(notiItem.id);
                onClick && onClick();
              }}
            />
          ))
        ) : (
          <NoInvitationText>You have no notifications</NoInvitationText>
        )}
      </MessageContainer>
    </Container>
  );
};

export default NotificationPopoverContent;
