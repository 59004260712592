export interface SDKQuota {
  channel_limit: number;
  channel_count: number;
}

export interface SDKChannel {
  id: string;
  device_name: string; // device's readable name, ex. XXX's macbook pro
  device_id: string;
  project_id: string;
  sdk_version: string;
  model_version: string;
  delete_pending: boolean; // pending status of delete sdk device process, it's related with device's offline / online status
  created_at: string;
  last_seen: string;
}

export enum EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE {
  INSTALLED_DATE = "INSTALLED_DATE",
  ID = "ID",
  DEVICE_NAME = "DEVICE_NAME",
  LAST_SEEN = "LAST_SEEN",
  DELETE = "DELETE",
}

export interface EdgeSDKDeviceListTableColumnItem {
  columnType: EDGE_SDK_DEVICE_LIST_TABLE_COLUMN_TYPE;
  label: string;
}
