import { Scroll } from "@cochlearai/ui";
import { FC } from "react";
import styled from "styled-components";
import { BaseListProps, SenseCategory } from "~/client/types";

const TabsContainer = styled(Scroll)`
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey[30]};
  white-space: nowrap;
`;

type Props = BaseListProps<SenseCategory>;

const TagCategoryTab: FC<Props> = ({ data, renderItem }) => {
  return <TabsContainer>{data.map((item) => renderItem(item))}</TabsContainer>;
};

export default TagCategoryTab;
