import { useCallback, useRef } from "react";

import { APIError } from "~/client/types";
import { SWR_KEY } from "~/client/types/swrKey";
import { fetchRemoveMember } from "~/client/api/organization";
import { setServerError } from "~/client/store/modules";
import { useAppDispatch } from "~/client/hooks";
import useAuthenticate from "./useAuthenticate";
import { useSWRConfig } from "swr";

function useLeaveOrganization() {
  const isLeaving = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const { user } = useAuthenticate();

  const leave = useCallback(
    async (organizationId: string) => {
      if (isLeaving.current) return;
      if (!organizationId || !user) return;
      isLeaving.current = true;
      try {
        await fetchRemoveMember({
          params: {
            user_id: user.identity.id,
            organization_id: organizationId,
          },
        });

        mutate(SWR_KEY.ORGANIZATION_INITIALIZE_ORGANIZATION_LIST);
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        isLeaving.current = false;
      }
    },
    [user, mutate, dispatch],
  );

  return {
    leave,
    isValidating: isLeaving.current,
  };
}

export default useLeaveOrganization;
