import { ToastCheckIcon, Typo } from "@cochlearai/ui";
import { bytesToSize, convertUsageTimeFormat } from "~/client/lib";

import { FC } from "react";
import { FileInfo } from "~/client/types";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadCompleteMessage = styled.div`
  margin-top: 24px;
  border-radius: 4px;
  background: ${(p) => p.theme.colors.blue[60]};
  color: #ffffff;
  height: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;

  ${media.query.md} {
    height: fit-content;
    padding: 5px 30px;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 14px;
  opacity: 0.6;
  background: #ffffff;
`;

const CheckIcon = styled(ToastCheckIcon)`
  fill: #ffffff;

  & path:nth-child(2) {
    fill: ${(p) => p.theme.colors.blue[60]};
  }
`;

const ResponsiveFlex = styled(Flex)`
  gap: 10px;
  ${media.query.md} {
    gap: unset;
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface Props {
  fileInfo?: FileInfo;
  fileLength?: number;
}

const UploadCompleteMsg: FC<Props> = ({ fileInfo, fileLength = 0 }) => {
  return (
    <UploadCompleteMessage>
      <ResponsiveFlex>
        <Typo variant="button">{fileInfo?.name}</Typo>
        <Flex style={{ gap: "10px" }}>
          <Typo variant="caption1" style={{ opacity: 0.6 }}>
            {bytesToSize(fileInfo?.size)}
          </Typo>
          <Divider />
          <Typo variant="caption1" style={{ opacity: 0.6 }}>
            {
              convertUsageTimeFormat({
                seconds: fileLength,
                showMin: false,
              }).formattedString
            }
          </Typo>
        </Flex>
      </ResponsiveFlex>
      <Flex>
        <CheckIcon style={{ marginRight: "8px" }} />
        <Typo variant="button">Complete</Typo>
      </Flex>
    </UploadCompleteMessage>
  );
};

export default UploadCompleteMsg;
