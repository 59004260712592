import { ConfigEnvironmentVariables } from "~/client/types/configEnvironmentVariables";

const {
  REACT_APP_REMOTE_API_BASE_URL,
  REACT_APP_BFF_API_BASE_URL,
  REACT_APP_ACCOUNT_LOGIN_URL,
  REACT_APP_ACCOUNT_LOGOUT_URL,
  REACT_APP_COCHL_WEB_STAGE,
  REACT_APP_COCHL_DEV_ID,
  REACT_APP_COCHL_DEV_PASS,
  REACT_APP_ACCOUNT_URL,
  REACT_APP_OFFICIAL_WEB_URL,
  REACT_APP_COCHL_SENSE_DOCS_URL,
  REACT_APP_MEDIUM_URL,
  REACT_APP_PROJECT_API_BASE_URL,
  REACT_APP_PAYMENT_API_BASE_URL,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_SENSE_SDK_API_BASE_URL,
  REACT_APP_SENSE_API_API_BASE_URL,
  REACT_APP_ANALYTICS_API_BASE_URL,
  REACT_APP_POST_ACTION_API_BASE_URL,
  REACT_APP_ORGANIZATIONS_API_BASE_URL,
  REACT_APP_NOTIFICATIONS_API_BASE_URL,
  REACT_APP_GA_TRACKING_ID,
  REACT_APP_DASHBOARD_URL,
  REACT_APP_GA4_MEASUREMENT_ID,
  REACT_APP_BACK_OFFICE_URL,
  REACT_APP_SR_URL,
} = process.env;

function getConfigs(): ConfigEnvironmentVariables {
  return {
    REACT_APP_REMOTE_API_BASE_URL,
    REACT_APP_BFF_API_BASE_URL,
    REACT_APP_ACCOUNT_LOGIN_URL,
    REACT_APP_ACCOUNT_LOGOUT_URL,
    REACT_APP_COCHL_WEB_STAGE,
    REACT_APP_COCHL_DEV_ID,
    REACT_APP_COCHL_DEV_PASS,
    REACT_APP_ACCOUNT_URL,
    REACT_APP_OFFICIAL_WEB_URL,
    REACT_APP_COCHL_SENSE_DOCS_URL,
    REACT_APP_MEDIUM_URL,
    REACT_APP_PROJECT_API_BASE_URL,
    REACT_APP_PAYMENT_API_BASE_URL,
    REACT_APP_STRIPE_PUBLIC_KEY,
    REACT_APP_SENSE_SDK_API_BASE_URL,
    REACT_APP_SENSE_API_API_BASE_URL,
    REACT_APP_ANALYTICS_API_BASE_URL,
    REACT_APP_POST_ACTION_API_BASE_URL,
    REACT_APP_ORGANIZATIONS_API_BASE_URL,
    REACT_APP_NOTIFICATIONS_API_BASE_URL,
    REACT_APP_GA_TRACKING_ID,
    REACT_APP_DASHBOARD_URL,
    REACT_APP_GA4_MEASUREMENT_ID,
    REACT_APP_BACK_OFFICE_URL,
    REACT_APP_SR_URL,
  } as ConfigEnvironmentVariables;
}

export default getConfigs;
