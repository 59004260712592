import React, { FC, SVGProps } from "react";

const RoundCloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill="#727273" />
    <path
      d="M9.5461 3.88934L10.1117 4.45498L4.45528 10.1114L3.88963 9.54574L9.5461 3.88934Z"
      fill="white"
    />
    <path
      d="M10.1116 9.54557L9.546 10.1112L3.88953 4.4548L4.45517 3.88916L10.1116 9.54557Z"
      fill="white"
    />
  </svg>
);

export default RoundCloseIcon;
